import styled from 'styled-components';
import '../../../../styles/constants.css';

export const HandCardStyled = styled.div`
  position: relative;
  display: inline-block;
  pointer-events: none;
  z-index: ${({ isHovered }) =>
    isHovered
      ? 'calc(var(--hand-card-z-index) + 1)'
      : 'var(--hand-card-z-index)'};

  height: ${({ cardHeight }) => `${cardHeight}px`};
  width: ${({ cardWidth }) => `${cardWidth}px`};
  transform: ${({ isHovered }) => (isHovered ? 'scale(1.5)' : 'none')};
  transform-origin: 50% 100%;
  margin-right: ${({ cardWidth }) => `${cardWidth / 10}px`};
  transition: transform 0.2s ease;

  .card {
    padding: calc(${({ cardWidth }) => `${cardWidth}px`} / 20);
    width: 100%; // Make the card fill the container
    height: 100%; // Make the card fill the container
    text-align: center;
    box-sizing: border-box;
    z-index: var(--hand-card-z-index));
    pointer-events: auto;
    overflow: hidden;
  }

  .card.dragging {
    pointer-events: none;
    z-index: 110;
  }

  .name-holder {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 80%;
    z-index: 2;
  }

  .name {
    font-size: calc(${({ cardWidth }) => `${cardWidth}px`} / 7);
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  }

  --bottomLine: 90%;
  --topLine: 0%;
  --leftSide: 0%;
  --rightSide: 100%;
  --cutTop: 70%;
  --cutWidth: 20%;

  .main-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    clip-path: polygon(
      var(--leftSide) var(--topLine), 
      var(--rightSide) var(--topLine), 
      var(--rightSide) var(--cutTop), 
      calc(var(--rightSide) - (100% - var(--cutWidth))/2) var(--bottomLine), 
      calc(var(--leftSide) + (100% - var(--cutWidth))/2) var(--bottomLine), 
      var(--leftSide) var(--cutTop));
    overflow: hidden;
    pointer-events: none;
    border: 2px solid ${({ isPlayable }) =>
      isPlayable ? 'var(--border-color-bright)' : 'var(--border-color-dim)'};


    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // clip path border
  .main-image::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: 0; /* Adjust if necessary */
    clip-path: polygon(
      var(--leftSide) var(--topLine), 
      var(--rightSide) var(--topLine), 
      var(--rightSide) var(--cutTop), 
      calc(var(--rightSide) - var(--cutWidth)/2) var(--bottomLine), 
      calc(var(--leftSide) + var(--cutWidth)/2) var(--bottomLine), 
      var(--leftSide) var(--cutTop)
    );
    border: 2px solid ${({ isPlayable }) =>
      isPlayable ? 'var(--border-color-bright)' : 'var(--border-color-dim)'};
  }

  .stat-box {
    width: calc(${({ cardWidth }) => `${cardWidth}px`} / 5);
    height: calc(${({ cardWidth }) => `${cardWidth}px`} / 5);
    font-size: calc(${({ cardWidth }) => `${cardWidth}px`} / 6);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background-color: var(--dark-green);

    p {
      position: relative;
      line-height: 1;
      z-index: 10;
    }
    border: 2px solid ${({ isPlayable }) =>
      isPlayable ? 'var(--border-color-bright)' : 'var(--border-color-dim)'};
  }

  .attack-box {
    position: absolute;
    left: -2%;
    bottom: 18%;
  }

  .health-box {
    position: absolute;
    left: 20%;
    bottom: 0;
  }

  .priority-box {
    position: absolute;
    right: -6%;
    bottom: 18%;
  }

  .hover-details {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .keywords {
    position: absolute;
    top: 0;
    right: 120%;
    transform: translateZ(0);
    padding: calc(${({ cardWidth }) => `${cardWidth}px`} / 20);

    width: ${({ cardWidth }) => `${cardWidth}px`};
    height: calc(${({ cardWidth }) => `${cardWidth}px`} / 3);
    font-size: calc(${({ cardWidth }) => `${cardWidth}px`} / 7);
    text-align: center;
    background-color: black;
    color: white;
    z-index: 2;
  }

  .abilities {
    position: absolute;
    top: 0;
    left: 120%;
    transform: translateZ(0);
    z-index: 2;
  }

  .enchantments {
    position: absolute;
    top: -30px;
    width: 100%;
    text-align: center;
  }

  .mana-cost-holder {
    position: absolute;
    top: -3%;
    right: -2%;
    transform: translateX(50%);
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
  }
`;
