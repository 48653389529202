import React, { useState, useEffect } from 'react';
import { SoundContext } from '../../contexts/SoundContextProvider';
import { ImageContext } from '../../contexts/ImageContextProvider';
import DesktopNavbar from './DesktopNavbar';
import MobileNavbar from './MobileNavbar';
import { NavbarStyled } from './styles/Navbar.styled';

function Navbar() {
  const { navbarBackground } = React.useContext(ImageContext);
  const soundContext = React.useContext(SoundContext);
  const [modalOn, setModalOn] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 500;

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <NavbarStyled backgroundImage={navbarBackground}>
      <div className='navbar-top'>
        <div className='inner-border'></div>
        {width > breakpoint ? (
          <DesktopNavbar />
        ) : (
          <MobileNavbar
            toggleModal={() => setModalOn(!modalOn)}
            playClick={soundContext.playClick}
          />
        )}
      </div>
    </NavbarStyled>
  );
}

export default Navbar;
