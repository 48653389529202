// external
import React, { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// css
import { BattleButtonStyled } from './styles/BattleButton.styled';

// context
import { ImageContext } from '../../../contexts/ImageContextProvider';

function BattleButton() {
  const location = useLocation();
  const navigate = useNavigate();

  // context
  const { battleButtonActiveImage, battleButtonInactiveImage } =
    useContext(ImageContext);

  const isActive = location.pathname === '/lobby';
  // hover state
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    if (!isActive) {
      navigate('/lobby');
    }
  };

  return (
    <BattleButtonStyled
      className='battle-button'
      onMouseDown={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        className='button-image'
        src={
          isActive || isHovered
            ? battleButtonActiveImage
            : battleButtonInactiveImage
        }
        alt='Battle Button'
      />
    </BattleButtonStyled>
  );
}

export default BattleButton;
