// external
import React, { useState } from 'react';

// lol
import { GamePlayState } from '../../../../LegendsOfLeakos/lib/Enums/GamePlayState';

const GameStatusContextProvider = ({ children }) => {
  // game status
  const [gameStatus, setGameStatus] = useState<GamePlayState>(
    GamePlayState.Ongoing
  );

  const value = {
    gameStatus,
    setGameStatus,
  };

  return (
    <GameStatusContext.Provider value={value}>
      {children}
    </GameStatusContext.Provider>
  );
};

interface GameStatusContextType {
  gameStatus: GamePlayState;
  setGameStatus: (status: GamePlayState) => void;
}

const GameStatusContext = React.createContext<GameStatusContextType | null>(
  null
);

export { GameStatusContext, GameStatusContextProvider };
