import React, { useState, useEffect, useContext } from 'react';

// lol
import LibraryCondition from '../../../../../../LegendsOfLeakos/lib/Classes/Condition/LibraryCondition';
import RuntimeCondition from '../../../../../../LegendsOfLeakos/lib/Classes/Condition/RuntimeCondition';
import { ConditionType } from '../../../../../../LegendsOfLeakos/lib/Enums/Condition';

// internal
import ConditionItem from './ConditionItem';

// css
import { ConditionListStyled } from './styles/ConditionList.styled';

function ConditionList({ conditions, save }) {
  const handleNewCondition = (conditionType: ConditionType) => {
    const newCondition = createNewCondition(conditionType);
    conditions.push(newCondition);
    save(conditions);
  };

  const createNewCondition = (conditionType: ConditionType) => {
    const newCondition =
      RuntimeCondition.createSampleLibraryCondition(conditionType);
    return newCondition;
  };

  return (
    <ConditionListStyled>
      <p>Condition List</p>
      <button
        className='add-condition-button'
        onClick={() => handleNewCondition(ConditionType.HasKeywordOfType)}
      >
        Add Condition
      </button>
      <div className='condition-items'>
        {conditions.map((condition, index) => (
          <ConditionItem
            index={index}
            key={index}
            condition={condition}
            conditions={conditions}
            createNewCondition={createNewCondition}
            save={save}
          />
        ))}
      </div>
    </ConditionListStyled>
  );
}

export default ConditionList;
