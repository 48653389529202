import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const PhaseNotificationPopupStyled = styled.div`
  // positioning
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  // content
  display: flex;
  justify-content: center;
  align-items: center;

  // display
  opacity: 0;
  // visibility: hidden;
  // transition: opacity 1s, visibility 3s;
  transition: opacity 1s;
  pointer-events: none;

  /* Conditional styles based on showPopup prop */
  opacity: ${({ showPopup }) => (showPopup ? 1 : 0)};
  // visibility: ${({ showPopup }) => (showPopup ? 'visible' : 'hidden')};

  .phase-notification {
    /* Dynamic font size based on gameContainerWidth */
    font-size: ${({ gameContainerWidth }) => gameContainerWidth / 10}px;
    font-family: var(--logo-font);
    color: var(--lol-red);
  }
`;
