import EffectUpgrade from '../Effect/EffectUpgrade.js';
import { PhaseEnum } from '../../Enums/Phase.js';
import RuntimeAbility from './RuntimeAbility.js';
import PayResourceCost from '../PayResourceCost/PayResourceCost.js';
import PayResourceCostUpgrade from '../PayResourceCost/PayResourceCostUpgrade.js';
import ModifiableInt from '../ModifableInt/ModifiableInt.js';
class ActivatedAbilityUpgrade {
    constructor(abilityIndex, effectUpgrade, addUseablePhases, removeUseablePhases, costUpgrades, usesPerTurnChange, isActive) {
        this.abilityUpgradeIndex = abilityIndex;
        this.effectUpgrade = null;
        this.usesPerTurnChange = new ModifiableInt(usesPerTurnChange.baseValue, usesPerTurnChange.intModifiers);
        this.abilityUpgradeIndex = abilityIndex;
        this.isActive = isActive;
        this.addUseablePhases = [...addUseablePhases];
        this.removeUseablePhases = [...removeUseablePhases];
        this.costUpgrades = costUpgrades.map((upgrade) => new PayResourceCostUpgrade(upgrade.statId, upgrade.valueChange));
    }
    upgradeAbility(ability) {
        this.effectUpgrade.upgradeEffect(ability.effect);
        for (const phaseEnum of this.addUseablePhases) {
            if (!ability.useableInPhases.includes(phaseEnum)) {
                ability.useableInPhases.push(phaseEnum);
            }
        }
        for (const phaseEnum of this.removeUseablePhases) {
            ability.useableInPhases = ability.useableInPhases.filter((c) => c !== phaseEnum);
        }
        if (!(ability instanceof RuntimeAbility))
            return;
        ability.usesPerTurn +=
            this.usesPerTurnChange.effectiveValue;
        for (const costUpgrade of this.costUpgrades) {
            const cost = ability.costs.find((c) => c.statId === costUpgrade.statId);
            if (cost) {
                cost.value += costUpgrade.valueChange.effectiveValue;
            }
            else {
                ability.costs.push(new PayResourceCost(costUpgrade.statId, costUpgrade.valueChange.effectiveValue));
            }
        }
        ability.isActive = this.isActive;
    }
    toJSON() {
        return {
            abilityUpgradeIndex: this.abilityUpgradeIndex,
            effectUpgrade: this.effectUpgrade.toJSON(),
            addUseablePhases: this.addUseablePhases.map((phase) => PhaseEnum[phase]),
            removeUseablePhases: this.removeUseablePhases.map((phase) => PhaseEnum[phase]),
            costUpgrades: this.costUpgrades.map((c) => c.toJSON()),
            usesPerTurnChange: this.usesPerTurnChange.toJSON(),
            isActive: this.isActive,
        };
    }
    static fromJSON(json) {
        return new ActivatedAbilityUpgrade(json.abilityUpgradeIndex, EffectUpgrade.fromJSON(json.effectUpgrade), json.addUseablePhases.map((phase) => PhaseEnum[phase]), json.removeUseablePhases.map((phase) => PhaseEnum[phase]), json.costUpgrades.map((c) => PayResourceCostUpgrade.fromJSON(c)), ModifiableInt.fromJSON(json.usesPerTurnChange), json.isActive);
    }
}
export default ActivatedAbilityUpgrade;
