import React from 'react';
import { AuthContext } from '../../contexts/AuthContextProvider';

function AuthenticatedComponent({ children }) {
  const { isLoggedIn } = React.useContext(AuthContext);

  return isLoggedIn ? children : null;
}

export default AuthenticatedComponent;
