import styled from 'styled-components';
import '../../../../styles/constants.css';

export const LibraryCardDisplayStyled = styled.div`
  width: ${({ cardWidth }) => `${cardWidth}px`};
  height: ${({ cardHeight }) => `${cardHeight}px`};
  position: relative;
  z-index: 10;
  box-sizing: border-box;
  margin-top: 10%;

  .name {
    font-size: calc(${({ cardWidth }) => `${cardWidth}px`} / 7);
    position: absolute;
    bottom: -5%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 2;
    text-align: center;
  }

  .stat-box {
    position: absolute;
    z-index: 20;
    width: 18%;
    height: 18%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: ${({ cardWidth }) => `${cardWidth / 7}px`};
    box-sizing: border-box;
  }

  .attack-box {
    left: 8%;
    bottom: 34%;
  }

  .health-box {
    left: 29%;
    bottom: 17%;
  }

  .priority-box {
    right: 8%;
    bottom: 34%;
  }

  .border {
    position: relative;
    width: 100%;
    z-index: 1;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      pointer-events: none;
      user-select: none;
    }
  }

  .invisible {
    display: none;
  }

  .bottom-land {
    z-index: -1;
    pointer-events: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .forest {
    position: absolute;
    width: 120%;
    bottom: -10%;
    left: -15%;
  }

  .mountain {
    position: absolute;
    width: 150%;
    bottom: -20%;
    left: -25%;
  }

  .ocean {
    position: absolute;
    width: 110%;
    bottom: -10%;
    left: -5%;
  }

  .main-image {
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    clip-path: polygon(50% 3%, 95% 45%, 75% 75%, 25% 75%, 0% 45%);
    overflow: hidden;
    pointer-events: none;
    border: 1px solid black;
    z-index: 0;

    img {
      width: ${({ hover }) => (hover ? '110%' : '100%')};
      height: ${({ hover }) => (hover ? '110%' : '100%')};
      object-fit: cover;
      transform: ${({ hover }) => (hover ? 'translate(-5%, -5%)' : 'none')};
      transition: 0.4s ease;
    }
  }
`;
