import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const RoomSelectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--margin-outside-main);
  height: calc(100vh - var(--navbar-height));
  text-align: left;
  box-sizing: border-box;
  position: relative;

  * {
    z-index: 3;
  }

  .rooms {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - var(--navbar-height) - 100px);
    width: 100%;
  }

  .underlined-title {
    margin-bottom: 10px;
    position: relative;
  }

  .button-bar {
    position: relative;
    margin-bottom: 10px;
  }

  .menu-button {
    position: relative;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: .8rem;
    padding: 5px;
    color: var(--text-color-secondary);
    border: 1px solid var(--border-color-dim);
    transition: 0.5s ease;
    background-color: var(--color-background-lol-dark-green);
  }

  .menu-button:hover {
    border: 1px solid var(--border-color-bright);
    color: var(--border-color-bright);
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
  }

  button {
    display: inline-block;
  ]
`;
