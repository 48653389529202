// a component that displays a realm representing a players land
// external
import React, { useState, useEffect, useContext } from 'react';

// internal components
import ManaCircle from './ManaCircle';

// context
import { PlayerContext } from '../../../contexts/PlayerContextProvider';
import { GameElementSizeContext } from '../../../contexts/GameElementSizeContextProvider';
import { DraggedCardContext } from '../../../contexts/DraggedCardContextProvider';
import { ImageContext } from '../../../contexts/ImageContextProvider';
import { GameStatusContext } from '../../../contexts/GameStatusContextProvider';

// css
import { HandCardStyled } from './styles/HandCard.styled.js';
import RuntimeCard from '../../../../../../LegendsOfLeakos/lib/Classes/Card/RuntimeCard.js';
import AbilityBox from '../BoardCard/Abilities/AbilityBox';
import { PlayerFlowEnum } from '../../../../../../LegendsOfLeakos/lib/Enums/PlayerFlow';
import { GamePlayState } from '../../../../../../LegendsOfLeakos/lib/Enums/GamePlayState';

interface HandCardProps {
  card: RuntimeCard;
  index: number;
  onMouseDown: (e: React.MouseEvent, card: RuntimeCard) => void;
  draggedPos?: { x: number; y: number };
  isDragging?: boolean;
}

const HandCard: React.FC<HandCardProps> = ({
  card,
  index,
  onMouseDown,
  draggedPos = null,
  isDragging = false,
}) => {
  // #region context
  const { masterPlayer, displayState } = useContext(PlayerContext);
  const { gameStatus } = useContext(GameStatusContext);
  const { cardHeight, cardWidth } = useContext(GameElementSizeContext);
  const { draggedCard, setDraggedCard } = useContext(DraggedCardContext);
  const { defaultCardImage } = useContext(ImageContext);

  // #endregion

  const [isHovered, setIsHovered] = useState(false);

  const [isPlayable, setIsPlayable] = useState(false);
  useEffect(() => {
    if (!masterPlayer || !displayState) return;
    if (masterPlayer.playerFlowEnum !== PlayerFlowEnum.PlayerActing) {
      setIsPlayable(false);
      return;
    }
    setIsPlayable(card.isPlayable(displayState));
  }, [masterPlayer, card, masterPlayer && masterPlayer.playerFlowEnum]);

  const [image, setImage] = useState(defaultCardImage);
  useEffect(() => {
    if (!card || !displayState) return;
    setImage(card.getLibraryCard(displayState).imageName);
  }, [card]);

  return (
    <HandCardStyled
      className={`hand-card`}
      style={
        isDragging
          ? {
              position: 'absolute',
              left: `${draggedPos.x - cardWidth / 2}px`,
              top: `${draggedPos.y - cardHeight / 2}px`,
            }
          : {}
      }
      cardHeight={cardHeight}
      cardWidth={cardWidth}
      isHovered={isHovered}
      isPlayable={isPlayable}
      onMouseDown={(e) => onMouseDown(e, card)}
      onMouseEnter={() => {
        if (!!draggedCard) return;
        if (gameStatus !== GamePlayState.Ongoing) return;

        setIsHovered(true);
      }}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={'card' + (isDragging ? ' dragging' : '')}>
        <div className='name-holder'>
          {/* <p className='name'>{card.name + ' ' + card.instanceId}</p> */}
        </div>
        {/* SET TO FALSE - THIS WONT APPEAR */}
        {!!card && displayState && (
          <div className='mana-cost-holder'>
            {card
              .getLibraryCard(displayState)
              .costs.filter((cost) => cost.value > 0)
              .map((cost, index) => (
                <ManaCircle
                  key={index}
                  cost={cost.value}
                  costName={cost.costName()}
                />
              ))}
            {/* <ManaCircle
              cost={
                card
                  .getLibraryCard(displayState)
                  .costs.find((cost) => cost.costName() === 'ForestMana')?.value
              }
              costName='ForestMana'
            />
            <ManaCircle
              cost={
                card
                  .getLibraryCard(displayState)
                  .costs.find((cost) => cost.costName() === 'OceanMana')?.value
              }
              costName='OceanMana'
            />
            <ManaCircle
              cost={
                card
                  .getLibraryCard(displayState)
                  .costs.find((cost) => cost.costName() === 'DesertMana')?.value
              }
              costName='DesertMana'
            />
            <ManaCircle
              cost={
                card
                  .getLibraryCard(displayState)
                  .costs.find((cost) => cost.costName() === 'MountainMana')
                  ?.value
              }
              costName='MountainMana'
            />
            <ManaCircle
              cost={
                card
                  .getLibraryCard(displayState)
                  .costs.find((cost) => cost.costName() === 'PrairieMana')
                  ?.value
              }
              costName='PrairieMana'
            />
            <ManaCircle
              cost={
                card
                  .getLibraryCard(displayState)
                  .costs.find((cost) => cost.costName() === 'FellsMana')?.value
              }
              costName='FellsMana'
            />
            <ManaCircle
              cost={
                card
                  .getLibraryCard(displayState)
                  .costs.find((cost) => cost.costName() === 'TundraMana')?.value
              }
              costName='TundraMana'
            /> */}
          </div>
        )}
        <div className='main-image'>
          <img src={image} alt={card.name} />
        </div>
        <div className='stats'>
          <div className='attack-box stat-box'>
            <p>{card.attack.effectiveValue}</p>
          </div>
          <div className='health-box stat-box'>
            <p>{card.health.effectiveValue}</p>
          </div>
          <div className='priority-box stat-box'>
            <p>{card.priority.effectiveValue}</p>
          </div>
        </div>
      </div>
      {isHovered && (
        <div className='abilities'>
          {card.abilities.map((ability, index) => (
            <AbilityBox key={index} ability={ability} onClick={() => {}} />
          ))}
        </div>
      )}
      {isHovered && (
        <div className='keywords'>
          {card.keywords.map((keyword, index) => (
            <span key={index}>{keyword.getDisplayText()}</span>
          ))}
        </div>
      )}
      {isHovered && (
        <div className='hover-details'>
          <div className='enchantments'>
            {card.enchantments.map((ench, index) => (
              <span key={index}>{ench.name}</span>
            ))}
          </div>
        </div>
      )}
    </HandCardStyled>
  );
};

export default React.memo(HandCard);
