import RuntimeEffect from '../../RuntimeEffect.js';
import { EffectType } from '../../../../Enums/Effect.js';
import RuntimeCard from '../../../Card/RuntimeCard.js';
import StackEvent from '../../../StackEvent/StackEvent.js';
import { StackEntityEnum, StackEventEnum } from '../../../../Enums/Stack.js';
import StackEntity from '../../../StackEvent/StackEntity.js';
class UpgradeCardEffect extends RuntimeEffect {
    targetCriterias() {
        return [];
    }
    constructor(upgradeIndex) {
        super();
        this.effectType = EffectType.UpgradeCardEffect;
        this.upgradeIndex = upgradeIndex;
    }
    // #region Effect Execution
    preEffect(state, sourceEntity, targetInfoList) {
        return true;
    }
    resolve(state, sourceEntity, targetInfoList) {
        if (!(sourceEntity instanceof RuntimeCard)) {
            throw new Error('Why is non card entity attacking?');
        }
        const sourceCard = sourceEntity;
        const libraryCard = state.gameManager.getCardFromLibraryId(sourceCard.libraryId);
        const upgrade = libraryCard.cardUpgrades.find((x) => x.upgradeIndex === this.upgradeIndex);
        upgrade.upgradeCard(sourceCard);
        return [
            new StackEvent(StackEventEnum.EffectResolve, state.clone(), true, 'Upgraded card with index ' + this.upgradeIndex, [new StackEntity(sourceCard.instanceId, StackEntityEnum.Source)]),
        ];
    }
    areTargetsAvailable(state, sourceEntity) {
        return true;
    }
    isAllTargetInfoValid(sourceEntity, state, targetInfoList) {
        return true;
    }
    isTargetInfoValid(sourceEntity, state, targetInfo, targetCriteria) {
        return true;
    }
    // #endregion
    // #region Runtime Utilities
    effectToString() {
        const outText = `Upgrade this card to upgrade with index ${this.upgradeIndex}`;
        return outText;
    }
    // #endregion
    // #region JSON and Creation
    static createUpgradeCardEffect(upgradeLevel) {
        return new UpgradeCardEffect(upgradeLevel);
    }
    toJSON() {
        return {
            effectType: EffectType[this.effectType],
            upgradeIndex: this.upgradeIndex,
        };
    }
    clone() {
        return new UpgradeCardEffect(this.upgradeIndex);
    }
}
export default UpgradeCardEffect;
