import React, { useEffect } from 'react';

// components
import SignInComponent from './SignInComponent';
import Navbar from '../../Navbar/Navbar';
import Fog from '../../Tidings/Fog';

// context
import { ImageContext } from '../../../contexts/ImageContextProvider';

// css
import { SignInPageStyled } from './styles/SignInPage.styled';

function SignInPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // context
  const { justWater, endGamePopupBackgroundVictory } =
    React.useContext(ImageContext);

  return (
    <SignInPageStyled
      className='sign-in-page'
      background={justWater}
      mainBackground={endGamePopupBackgroundVictory}
      height={window.innerHeight}
    >
      <Navbar />
      <Fog />
      <div className='main-content'>
        <SignInComponent />
      </div>
    </SignInPageStyled>
  );
}

export default SignInPage;
