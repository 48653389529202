import { EffectType } from '../../Enums/Effect.js';
// Base class of an effect. It consists of a type (which is associated with a child class, which holds the logic for execution of the effect),
// a list of EffectValues,
class RuntimeEffect {
    resolve(state, sourceEntity, targetInfoList) {
        return null;
    }
    static registerFromRuntimeJSON(type, method) {
        this.fromRuntimeJSONMethods[type] = method;
    }
    static fromRuntimeJSON(json) {
        const effectType = EffectType[json.effectType];
        const method = this.fromRuntimeJSONMethods[effectType];
        if (!method) {
            throw new Error('Effect type not registered for: ' +
                EffectType[effectType] +
                ' - ' +
                JSON.stringify(json) +
                ' - need to initialize in GameManager');
        }
        return method(json);
    }
    static registerFromLibraryJSON(type, method) {
        this.fromLibraryJSONMethods[type] = method;
    }
    static fromLibraryJSON(json) {
        const effectType = EffectType[json.effectType];
        const method = this.fromLibraryJSONMethods[effectType];
        if (!method) {
            throw new Error('Effect type not registered for: ' +
                EffectType[effectType] +
                ' - ' +
                json +
                ' - need to initialize in GameManager');
        }
        return method(json);
    }
    static registerIsLibraryJSONValid(type, method) {
        this.isLibraryJSONValidMethods[type] = method;
    }
    static isLibraryJSONValid(json) {
        const effectType = EffectType[json.effectType];
        const method = this.isLibraryJSONValidMethods[effectType];
        if (!method) {
            throw new Error('Effect type not registered for: ' +
                EffectType[effectType] +
                ' - need to initialize in GameManager');
        }
        return method(json);
    }
    static registerSampleLibraryEffect(type, creator) {
        this.sampleLibraryEffectMethods[type] = creator;
    }
    static createSampleLibraryEffect(type) {
        const creator = this.sampleLibraryEffectMethods[type];
        if (!creator) {
            throw new Error('No sample effect creator for: ' +
                EffectType[type] +
                ' - need to initialize in GameManager');
        }
        return creator();
    }
}
// #endregion
// #region Runtime JSON Methods
RuntimeEffect.fromRuntimeJSONMethods = {};
// #endregion
// #region Library JSON Methods
RuntimeEffect.fromLibraryJSONMethods = {};
// #endregion
// #region isLibraryJSONValid methods
RuntimeEffect.isLibraryJSONValidMethods = {};
// #endregion
// #region Register Sample Effect Creators
RuntimeEffect.sampleLibraryEffectMethods = {};
export default RuntimeEffect;
