// external
import React, { useState, useEffect, useContext } from 'react';

// css
import { PhaseButtonStyled } from './styles/PhaseButton.styled';

// context
import { TurnFlowContext } from '../../../contexts/TurnFlowContextProvider';
import { GameElementSizeContext } from '../../../contexts/GameElementSizeContextProvider';
import { ImageContext } from '../../../contexts/ImageContextProvider';

function PhaseButton({}) {
  // context
  const { phaseButtonActive, handlePhaseButtonClick, phaseButtonMessage } =
    useContext(TurnFlowContext);
  const { compassButtonHeight } = useContext(GameElementSizeContext);
  const {
    // banners
    doneWithActions,
    endQueueReview,
    exploreALand,
    waitingForOpponent,
    // inactive
    inactiveCompassStatic,
    inactiveCompassCenter,
    inactiveCompassNorthTriangles,
    inactiveCompassNorthWestTriangles,
    inactiveCompassSmallTriangles,
    inactiveCompassBanner,
    brownCircle,
    // active
    activeCompassStatic,
    activeCompassCenter,
    activeCompassNorthTriangles,
    activeCompassNorthWestTriangles,
    activeCompassSmallTriangles,
    activeCompassBanner,
    // hover
    hoverCompassStatic,
    hoverCompassCenter,
    hoverCompassNorthTriangles,
    hoverCompassNorthWestTriangles,
    hoverCompassSmallTriangles,
    hoverCompassBanner,
    redCircle,
  } = useContext(ImageContext);

  // #region phase button message check
  useEffect(() => {
    if (
      phaseButtonMessage !== 'Waiting for opponent' &&
      phaseButtonMessage !== 'Done with actions' &&
      phaseButtonMessage !== 'End Queue Review' &&
      phaseButtonMessage !== 'Explore a land' &&
      phaseButtonMessage !== ''
    ) {
      throw new Error(
        'PhaseButton: Invalid phaseButtonMessage: ' + phaseButtonMessage
      );
    }
  }, [phaseButtonMessage]);
  // #endregion

  // hover state
  const [amHovered, setAmHovered] = useState(false);

  // #region bannerScale
  const [bannerScale, setBannerScale] = useState(0.95);
  const goalBannerScale = () => {
    if (amHovered && phaseButtonActive) {
      return 1;
    }
    return 0.95;
  };

  useEffect(() => {
    setBannerScale(goalBannerScale);
  }, [amHovered]);

  useEffect(() => {
    setBannerScale(0.1);

    const timer = setTimeout(() => {
      setBannerScale(goalBannerScale());
    }, 300);

    return () => clearTimeout(timer);
  }, [phaseButtonMessage, phaseButtonActive]);
  // #endregion

  // #region center circle scale
  const [centerCircleScale, setCenterCircleScale] = useState(1);
  useEffect(() => {
    if (!phaseButtonActive) {
      setCenterCircleScale(0.9);
      return;
    }
    if (amHovered) {
      setCenterCircleScale(1.1);
      return;
    }
    setCenterCircleScale(1);
  }, [phaseButtonActive, amHovered]);

  // #endregion

  // #region spin compass

  const [spin, setSpin] = useState(false);

  const handlePhaseButtonClickModified = () => {
    setSpin(true);
    handlePhaseButtonClick();
    setTimeout(() => setSpin(false), 300); // Reset after animation
  };

  // #endregion

  return (
    <PhaseButtonStyled
      className='phase-button-container'
      compassButtonHeight={compassButtonHeight}
      amHovered={amHovered}
      bannerScale={bannerScale}
      phaseButtonActive={phaseButtonActive}
      centerCircleScale={centerCircleScale}
      spin={spin}
    >
      <div className='compass-image-container'>
        <div className='static-container compass-image'>
          <img className='static' src={inactiveCompassStatic} />
          {phaseButtonActive && (
            <img className='static' src={activeCompassStatic} />
          )}
        </div>
        <div className='center-circle-container compass-image'>
          <img className='center-circle' src={brownCircle} />
          {phaseButtonActive && amHovered && (
            <img className='center-circle' src={redCircle} />
          )}
        </div>
        <div className='small-triangles-container compass-image'>
          <img
            className='small-triangles'
            src={inactiveCompassSmallTriangles}
          />
          {phaseButtonActive && amHovered && (
            <img className='small-triangles' src={hoverCompassSmallTriangles} />
          )}
        </div>
        <div className='northwest-triangles-container compass-image'>
          <img
            className='northwest-triangles'
            src={inactiveCompassNorthWestTriangles}
          />
          {phaseButtonActive && amHovered && (
            <img
              className='northwest-triangles'
              src={hoverCompassNorthWestTriangles}
            />
          )}
        </div>
        <div className='north-triangles-container compass-image'>
          <img
            className='north-triangles'
            src={inactiveCompassNorthTriangles}
          />
          {phaseButtonActive && amHovered && (
            <img className='north-triangles' src={hoverCompassNorthTriangles} />
          )}
        </div>
        <div className='center-container compass-image'>
          <img className='center' src={inactiveCompassCenter} />
        </div>
        <div className='banner-container compass-image'>
          <img
            className='banner'
            src={
              amHovered && phaseButtonActive
                ? hoverCompassBanner
                : inactiveCompassBanner
            }
          />
          <div className='compass-image banner-text-container'>
            {phaseButtonMessage === 'Done with actions' && (
              <img className='banner-text' src={doneWithActions} />
            )}
            {phaseButtonMessage === 'End Queue Review' && (
              <img className='banner-text' src={endQueueReview} />
            )}
            {phaseButtonMessage === 'Explore a land' && (
              <img className='banner-text' src={exploreALand} />
            )}
            {phaseButtonMessage === 'Waiting for opponent' && (
              <img className='banner-text' src={waitingForOpponent} />
            )}
          </div>
        </div>
      </div>
      <div
        className={'click-area'}
        onClick={handlePhaseButtonClickModified}
        onMouseEnter={() => setAmHovered(true)}
        onMouseLeave={() => setAmHovered(false)}
      ></div>
    </PhaseButtonStyled>
  );
}

export default PhaseButton;
