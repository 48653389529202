import React, { useContext } from 'react';
import CardListItem from './CardListItem';

// context
import { PlayerContext } from '../../../../contexts/PlayerContextProvider';
import { ImageContext } from '../../../../contexts/ImageContextProvider';

// css
import { CardListStyled } from './styles/CardList.styled.js';

// lol

function CardList({ cards, displayState, removeCard, removeAll }) {
  // context
  const { gameManager } = useContext(PlayerContext);
  const { someLand } = useContext(ImageContext);

  return (
    <CardListStyled backgroundImage={someLand}>
      <div className='underlined-title'>Cards</div>
      <div className='card-item-holder'>
        {!!cards &&
          cards.map((card, index) => (
            <CardListItem
              key={index + card?.libraryId + card?.amount + ''}
              entry={card}
              displayState={displayState}
              removeCard={removeCard}
              removeAll={removeAll}
            />
          ))}
      </div>
    </CardListStyled>
  );
}

export default CardList;
