import React, { useState, useEffect, useContext } from 'react';

// lol
import LibraryAbility from '../../../../../../LegendsOfLeakos/lib/Classes/Ability/LibraryAbility.js';
import RuntimeEffect from '../../../../../../LegendsOfLeakos/lib/Classes/Effect/RuntimeEffect.js';
import { EffectType } from '../../../../../../LegendsOfLeakos/lib/Enums/Effect.js';

// internal
import AbilityItem from './AbilityItem.jsx';

// css
import { AbilityListStyled } from './styles/AbilityList.styled.js';

function AbilityList({ abilities, save }) {
  const handleNewAbility = (effectType: EffectType) => {
    const newAbility = createNewAbility(effectType);
    abilities.push(newAbility);
    save(abilities);
  };

  const createNewAbility = (effectType: EffectType) => {
    const newAbility = new LibraryAbility(
      'New Ability', // set name
      RuntimeEffect.createSampleLibraryEffect(effectType), // effect
      [], // cost
      1, // uses per turn
      [], // useable in phases
      true, // is useable
      'image url'
    );
    return newAbility;
  };

  return (
    <AbilityListStyled>
      <button
        className='add-ability-button'
        onClick={() => handleNewAbility(EffectType.DealSetDamage)}
      >
        Add Ability
      </button>
      <div className='ability-items'>
        {abilities.map((ability, index) => (
          <AbilityItem
            index={index}
            key={index}
            ability={ability}
            abilities={abilities}
            save={save}
          />
        ))}
      </div>
    </AbilityListStyled>
  );
}

export default AbilityList;
