import PlayerQueueline from '../PlayerQueueline.js';
class PlayCardPlayerQueueLine extends PlayerQueueline {
    constructor(myPlayerUserId, sourceCardInstanceId, sourcePlayerUserId, queuePosition, paidCosts, targetInfoList, originZoneZoneEnum, destinationZoneZoneEnum) {
        super();
        this.fillBaseInfo(myPlayerUserId, sourceCardInstanceId, sourcePlayerUserId, queuePosition);
        this.paidCosts = paidCosts;
        this.targetInfoList = targetInfoList;
        this.originZoneZoneEnum = originZoneZoneEnum;
        this.destinationZoneZoneEnum = destinationZoneZoneEnum;
    }
    sendEffectToPlayer(gameState, myPlayer) {
        if (!myPlayer) {
            throw new Error('Player not found');
        }
        return myPlayer.onCardPlayed(this.sourcePlayerUserId, this.sourceCardInstanceId, this.targetInfoList, this.originZoneZoneEnum, this.destinationZoneZoneEnum);
    }
    actionToString(gameState) {
        const sourcePlayer = gameState.getPlayerInfoByUserId(this.sourcePlayerUserId);
        const sourceCard = gameState.getCardFromAnywhere(this.sourceCardInstanceId);
        if (!sourcePlayer) {
            throw new Error('Source player not found for sourcePlayerUserId: ' +
                this.sourcePlayerUserId);
        }
        if (!sourceCard) {
            throw new Error('Source card not found for sourceCardInstanceId: ' +
                this.sourceCardInstanceId);
        }
        return `${sourcePlayer.name}'s ${sourceCard.name} (Instance ID: ${sourceCard.instanceId}) was played to ${this.destinationZoneZoneEnum}`;
    }
    clone() {
        return new PlayCardPlayerQueueLine(this.myPlayerUserId, this.sourceCardInstanceId, this.sourcePlayerUserId, this.queuePosition, this.paidCosts.map((x) => x.clone()), this.targetInfoList.map((x) => x.clone()), this.originZoneZoneEnum, this.destinationZoneZoneEnum);
    }
}
export default PlayCardPlayerQueueLine;
