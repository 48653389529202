var KeywordType;
(function (KeywordType) {
    KeywordType[KeywordType["Attribute"] = 0] = "Attribute";
    KeywordType[KeywordType["Shielded"] = 1] = "Shielded";
    KeywordType[KeywordType["Warleader"] = 2] = "Warleader";
    KeywordType[KeywordType["DamageModification"] = 3] = "DamageModification";
})(KeywordType || (KeywordType = {}));
var Attribute;
(function (Attribute) {
    Attribute[Attribute["Impetus"] = 0] = "Impetus";
    Attribute[Attribute["Skirmisher"] = 1] = "Skirmisher";
})(Attribute || (Attribute = {}));
export { KeywordType, Attribute };
