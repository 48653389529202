// external
import React, { useEffect, useState } from 'react';

const CardRefContextProvider = ({ children }) => {
  // #region card ref data

  const [cardRefData, setCardRefData] = useState([]);
  const addCardRef = (cardInstanceId, ref) => {
    setCardRefData((oldData) => [
      ...oldData.filter((data) => data.cardInstanceId !== cardInstanceId),
      {
        cardInstanceId,
        ref,
      },
    ]);
  };

  // #endregion

  const value: CardRefContextType = {
    cardRefData,
    addCardRef,
  };

  return (
    <CardRefContext.Provider value={value}>{children}</CardRefContext.Provider>
  );
};

interface CardRefContextType {
  cardRefData: {
    cardInstanceId: number;
    ref: React.RefObject<HTMLDivElement>;
  }[];
  addCardRef: (
    cardInstanceId: number,
    ref: React.RefObject<HTMLDivElement>
  ) => void;
}

const CardRefContext = React.createContext<CardRefContextType | null>(null);

export { CardRefContextProvider, CardRefContext };
