import { ServerMessage } from '../../MessageBase.js';
import { NetworkProtocol } from '../../../../Enums/NetworkProtocol.js';
export default class EndGameMessage extends ServerMessage {
    constructor(recipientUserId, winnerUserId) {
        super(recipientUserId);
        this.messageEnum = NetworkProtocol.EndGame;
        this.winnerUserId = winnerUserId;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { winnerUserId: this.winnerUserId });
    }
    static fromJSON(json) {
        return new EndGameMessage(json.recipientUserId, json.winnerUserId);
    }
    // check that all fields in the message are valid
    validate() {
        return this.recipientUserId != null && this.winnerUserId != null;
    }
}
