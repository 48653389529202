// external
import React, { useEffect, useState, useContext } from 'react';

// internal
// components
import RealmTile from './RealmTile';
//css
import { RealmListStyled } from './styles/RealmList.styled.js';

//
import { ImageContext } from '../../contexts/ImageContextProvider';

function RealmList({
  realms,
  selectedRealm,
  onRealmClicked,
  deleteRealm,
  createNewRealm,
}) {
  const { topo } = useContext(ImageContext);

  return (
    <RealmListStyled className='realm-list' backgroundImage={topo}>
      <div className='background-image'></div>
      <div className='underlined-title'>Realms</div>
      <div className='new-realm-button ui-button' onClick={createNewRealm}>
        +
      </div>
      <div className='realmtile-holder'>
        {realms.map((realm) => {
          return (
            <RealmTile
              key={realm._id}
              realm={realm}
              isSelected={realm._id === selectedRealm._id}
              onRealmClicked={onRealmClicked}
              deleteRealm={deleteRealm}
            />
          );
        })}
      </div>
    </RealmListStyled>
  );
}

export default RealmList;
