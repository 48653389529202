import styled from 'styled-components';

export const ConditionListStyled = styled.div`
  border: 1px solid white;
  padding: 5px;
  position: relative;

  .add-condition-button {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;
