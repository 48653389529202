// external
import React, { useContext, useEffect } from 'react';
import axios from 'axios';

// internal
import { SocketContext } from './SocketContextProvider';
import usePersistedState from '../hooks/usePersistedState.js';

const AuthContextProvider = ({ children }) => {
  // context
  const { connectSocket, disconnectSocket } = useContext(SocketContext);

  // state
  const [isLoggedIn, setIsLoggedIn] = usePersistedState('isLoggedIn', false);
  const [isAdmin, setIsAdmin] = usePersistedState('isAdmin', false);
  const [userId, setUserId] = usePersistedState('userId', null);

  useEffect(() => {
    axios
      .get('/auth/check-auth')
      .then((res) => {
        setIsLoggedIn(true);
        setIsAdmin(res.data.isAdmin);
        setUserId(res.data.userId);
        connectSocket();
      })
      .catch((err) => {
        setIsLoggedIn(false);
        setIsAdmin(false);
        setUserId(null);
        disconnectSocket();
      });
  }, []);

  const login = (emailOrUsername: string, password: string): Promise<any> => {
    return axios
      .post('/auth/login', { login: emailOrUsername, password: password })
      .then((res) => {
        setIsLoggedIn(true);
        setIsAdmin(res.data.isAdmin);
        setUserId(res.data.userId);
        connectSocket();
        return res; // for chaining
      })
      .catch((err) => {
        console.error(err);
        disconnectSocket();
        throw err;
      });
  };

  const register = (
    name: string,
    email: string,
    password: string
  ): Promise<any> => {
    return axios
      .post('/auth/register', { name, email, password })
      .then((res) => {
        setIsLoggedIn(true);
        setIsAdmin(res.data.isAdmin);
        setUserId(res.data.userId);
        connectSocket();
        return res; // for chaining
      })
      .catch((err) => {
        disconnectSocket();
        throw err;
      });
  };

  const waitlist = (email: string): Promise<any> => {
    return axios
      .post('/waitlist', { email })
      .then((res) => {
        return res; // for chaining
      })
      .catch((err) => {
        throw err;
      });
  };

  const allowlist = (email: string): Promise<any> => {
    return axios
      .post('/allowlist', { email })
      .then((res) => {
        return res; // for chaining
      })
      .catch((err) => {
        throw err;
      });
  };

  const logout = async () => {
    try {
      await axios.post('/auth/logout');
      setIsLoggedIn(false);
      setIsAdmin(false);
      setUserId(null);
      disconnectSocket();
    } catch (err) {
      console.log(err);
    }
  };

  const value: AuthContextType = {
    isLoggedIn,
    login,
    logout,
    register,
    waitlist,
    allowlist,
    isAdmin,
    userId,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

interface AuthContextType {
  isLoggedIn: boolean;
  login: (emailOrUsername: string, password: string) => Promise<any>;
  logout: () => void;
  register: (name: string, email: string, password: string) => Promise<any>;
  waitlist: (email: string) => Promise<any>;
  allowlist: (email: string) => Promise<any>;
  isAdmin: boolean;
  userId: string | null;
}

const AuthContext = React.createContext<AuthContextType | null>(null);

export { AuthContextProvider, AuthContext };
