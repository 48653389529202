// a component that displays a realm representing a players land
// external
import React, { useState, useEffect, useContext, useRef } from 'react';

// internal components
import HandCard from './HandCard';

// context
import { PlayerContext } from '../../../contexts/PlayerContextProvider';
import { GameElementSizeContext } from '../../../contexts/GameElementSizeContextProvider';
import { TurnFlowContext } from '../../../contexts/TurnFlowContextProvider';
import { DraggedCardContext } from '../../../contexts/DraggedCardContextProvider';

// css
import { HandStyled } from './styles/Hand.styled.js';

// lol
import { ZoneEnum } from '../../../../../../LegendsOfLeakos/lib/Enums/Zone';
import { NetworkProtocol } from '../../../../../../LegendsOfLeakos/lib/Enums/NetworkProtocol';
import QueuePlayCardMessage from '../../../../../../LegendsOfLeakos/lib/Classes/Networking/Cards/Play/QueuePlayCardMessage';
import RuntimeCard from '../../../../../../LegendsOfLeakos/lib/Classes/Card/RuntimeCard';

function Hand({ gameContainerRef }) {
  // #region context
  const { masterPlayer, displayState } = useContext(PlayerContext);
  const { cardHeight } = useContext(GameElementSizeContext);
  // #endregion

  // card vars
  const [displayCards, setDisplayCards] = useState([]);

  // dragging vars
  const { draggedCard, setDraggedCard } = useContext(DraggedCardContext);
  const [draggedPos, setDraggedPos] = useState({ x: 0, y: 0 });
  const draggedCardRef = useRef(null);
  useEffect(() => {
    if (!draggedCard) return;
    draggedCardRef.current = draggedCard;
  }, [draggedCard]);

  // #region cards

  useEffect(() => {
    if (!masterPlayer || !displayState) return;
    let newCards = masterPlayer
      .getPlayerInfo(displayState)
      .getFriendlyZoneFromZoneEnum(ZoneEnum.Hand).cards;

    const cardsBeingPlayed = [];
    for (
      let i = 0;
      i < masterPlayer.getPlayerInfo(displayState).queueMessages.length;
      i++
    ) {
      const tempMessage =
        masterPlayer.getPlayerInfo(displayState).queueMessages[i];
      if (tempMessage.messageEnum === NetworkProtocol.QueuePlayCard) {
        const message = tempMessage as QueuePlayCardMessage;
        const card = masterPlayer
          .getPlayerInfo(displayState)
          .getCardFromInstanceId(message.cardInstanceId);
        if (!card) {
          throw new Error(
            'Could not find card with instanceId ' + message.cardInstanceId
          );
        }
        cardsBeingPlayed.push(card);
      }
    }

    newCards = newCards.filter(
      (card) =>
        !cardsBeingPlayed.find(
          (cardBeingPlayed) => cardBeingPlayed.instanceId === card.instanceId
        )
    );

    setDisplayCards(
      newCards.filter((card) => card.instanceId !== draggedCard?.instanceId)
    );
  }, [masterPlayer, displayState, draggedCard]);

  // #endregion

  // #region Drag and Drop logic for HandCard

  const mouseDownOnHandCard = (event, card: RuntimeCard) => {
    if (!card.isPlayable(displayState)) return;

    const containerRect = gameContainerRef.current.getBoundingClientRect();
    setDraggedCard(card);
    draggedCardRef.current = card;
    setDraggedPos({
      x: event.clientX - containerRect.left,
      y: event.clientY - containerRect.top,
    });
  };

  const onDragCard = (event) => {
    if (!draggedCardRef.current || !gameContainerRef.current) {
      return;
    }
    const containerRect = gameContainerRef.current.getBoundingClientRect();
    setDraggedPos({
      x: event.clientX - containerRect.left,
      y: event.clientY - containerRect.top,
    });
  };

  const onMouseUpWhileDraggingCard = () => {
    setDraggedCard(null);
    draggedCardRef.current = null;
  };

  useEffect(() => {
    document.addEventListener('mousemove', onDragCard);
    document.addEventListener('mouseup', onMouseUpWhileDraggingCard);
    return () => {
      document.removeEventListener('mousemove', onDragCard);
      document.removeEventListener('mouseup', onMouseUpWhileDraggingCard);
    };
  }, []);

  // #endregion

  return (
    <HandStyled className='player-hand' cardHeight={cardHeight}>
      <div className='card-container'>
        {!!displayCards &&
          displayCards.map((card, index) => {
            return (
              <HandCard
                key={card.instanceId}
                card={card}
                index={index}
                onMouseDown={mouseDownOnHandCard}
              />
            );
          })}
      </div>
      {draggedCard && (
        <HandCard
          card={draggedCard}
          isDragging={true}
          draggedPos={draggedPos}
          onMouseDown={null}
          index={null}
        />
      )}
    </HandStyled>
  );
}

export default Hand;
