import React from 'react';

// internal
import TitledIntcreaser from '../Utility/TitledIntcreaser.jsx';
import TypeItem from './TypeItem.jsx';
import { TypesStyled } from './styles/Types.styled.js';

// lol
import { CardType } from '../../../../../../LegendsOfLeakos/lib/Enums/CardType.js';

function Types({ entity, saveEntity }) {
  const addType = () => {
    const newType = CardType.Human;
    entity.cardTypes.push(newType);
    saveEntity(entity);
  };

  const removeType = (index) => {
    entity.cardTypes.splice(index, 1);
    saveEntity(entity);
  };

  return (
    <TypesStyled className='types'>
      <button className='add-type-button' onClick={() => addType()}>
        Add Type
      </button>
      <div className='types-container'>
        {entity.cardTypes.map((type, index) => {
          return (
            <TypeItem
              key={index}
              type={type}
              index={index}
              changeType={(index, newType) => {
                entity.cardTypes[index] = newType;
                saveEntity(entity);
              }}
              removeType={(index) => removeType(index)}
            />
          );
        })}
      </div>
    </TypesStyled>
  );
}

export default Types;
