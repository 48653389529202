import styled from 'styled-components';

export const TypeItemStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .remove-type-button {
    margin-left: 10px;
    &:hover {
      text-decoration: bold;
      cursor: pointer;
    }
  }
`;
