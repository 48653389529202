import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const CardListItemStyled = styled.div`
  position: relative;
  width: 10vw;

  .library-card-holder {
    width: 100%;
  }

  .amount {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3vw;
    z-index: 100;
    color: #fff;
  }

  .remove-all {
    position: absolute;
    top: 3%;
    right: 10%;
    width: 10%;
    height: 10%;
    z-index: 100;
    font-size: 2vw;
  }
  .remove-all:hover {
    cursor: pointer;
    transform: scale(1.2);
    color: red;
    transition: 0.3s ease;
  }
`;
