// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// internal context
import { PlayerContext } from '../../../../contexts/PlayerContextProvider';
import { GameElementSizeContext } from '../../../../contexts/GameElementSizeContextProvider';

// css
import { PhaseNotificationPopupStyled } from './styles/PhaseNotificationPopup.styled.js';

// lol
import { PhaseEnum } from '../../../../../../../LegendsOfLeakos/lib/Enums/Phase';

const PhaseNotificationPopup = () => {
  // #region context
  const { masterPlayer } = useContext(PlayerContext);

  const { gameContainerHeight, gameContainerWidth } = useContext(
    GameElementSizeContext
  );
  // #endregion

  const [phaseMessage, setPhaseMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (!masterPlayer) return;
    if (!masterPlayer.gameState) return;

    setShowPopup(true);

    switch (masterPlayer.gameState.currentPhaseIndex) {
      case PhaseEnum.Recruit:
        setPhaseMessage('Recruit Phase');
        break;
      case PhaseEnum.Maneuver:
        setPhaseMessage('Maneuver Phase');
        break;
      case PhaseEnum.Skirmish:
        setPhaseMessage('Skirmish Phase');
        break;
      case PhaseEnum.Battle:
        setPhaseMessage('Battle Phase');
        break;
      default:
        break;
    }

    const timer = setTimeout(() => {
      setShowPopup(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, [
    masterPlayer &&
      masterPlayer.gameState &&
      masterPlayer.gameState.currentPhaseIndex,
  ]);

  return (
    <PhaseNotificationPopupStyled
      gameContainerHeight={gameContainerHeight}
      gameContainerWidth={gameContainerWidth}
      showPopup={showPopup}
    >
      <div className='all-content'>
        <div className='phase-notification'>{phaseMessage}</div>
      </div>
    </PhaseNotificationPopupStyled>
  );
};

export default PhaseNotificationPopup;
