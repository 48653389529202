import React, { useContext, useRef, useState } from 'react';

// internal contexts
import { CardRefContext } from './CardRefContextProvider';

// lol
import StackEvent from '../../../../LegendsOfLeakos/lib/Classes/StackEvent/StackEvent';
import {
  StackEntityEnum,
  StackEventEnum,
} from '../../../../LegendsOfLeakos/lib/Enums/Stack';
import StackEntity from '../../../../LegendsOfLeakos/lib/Classes/StackEvent/StackEntity';
import GameState from '../../../../LegendsOfLeakos/lib/Classes/Game/GameState';

const AnimationContextProvider = ({ children }) => {
  // context
  const { cardRefData } = useContext(CardRefContext);

  // main functions
  const hasAnimationForEvent = (stackEvent: StackEvent) => {
    if (stackEvent.eventEnum === StackEventEnum.AttackResolve) {
      return true;
    }

    if (stackEvent.eventEnum === StackEventEnum.BlockResolve) {
      return true;
    }

    return false;
  };

  const runAnimationForEvent = async (
    stackEvent: StackEvent,
    setDisplayState: (gameState: GameState) => void
  ) => {
    if (stackEvent.eventEnum === StackEventEnum.AttackResolve) {
      console.log('Running attack animation');
      await playAttackAnimation(stackEvent, setDisplayState);
    } else if (stackEvent.eventEnum === StackEventEnum.BlockResolve) {
      await playBlockAnimation(stackEvent, setDisplayState);
    } else {
      console.error('No animation for event', stackEvent);
    }
  };

  // #region attacking

  const playBlockAnimation = async (
    stackEvent: StackEvent,
    setDisplayState: (gameState: GameState) => void
  ) => {
    const attackingEntity = stackEvent.entities.find(
      (entity) => entity.entityEnum === StackEntityEnum.Source
    );

    const attackingBoardCard = cardRefData.find(
      (card) => card.cardInstanceId === attackingEntity.instanceId
    ).ref.current;

    const blockingEntity = stackEvent.entities.find(
      (entity) => entity.entityEnum === StackEntityEnum.Other
    );

    const blockingBoardCard = cardRefData.find(
      (card) => card.cardInstanceId === blockingEntity.instanceId
    ).ref.current;

    await fullAttackAnimation(
      stackEvent.gameState,
      attackingBoardCard,
      blockingBoardCard,
      setDisplayState
    );
  };

  const playAttackAnimation = async (
    stackEvent: StackEvent,
    setDisplayState: (gameState: GameState) => void
  ) => {
    const attackingEntity = stackEvent.entities.find(
      (entity) => entity.entityEnum === StackEntityEnum.Source
    );

    const defendingEntity = stackEvent.entities.find(
      (entity) => entity.entityEnum === StackEntityEnum.Target
    );

    const attackingBoardCard = cardRefData.find(
      (card) => card.cardInstanceId === attackingEntity.instanceId
    ).ref.current;

    // iterate over all defending entities
    const defendingBoardCard = cardRefData.find(
      (card) => card.cardInstanceId === defendingEntity.instanceId
    ).ref.current;

    await fullAttackAnimation(
      stackEvent.gameState,
      attackingBoardCard,
      defendingBoardCard,
      setDisplayState
    );
    // time to digest
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // Clean up the transition style to avoid affecting other transformations
    attackingBoardCard.style.transition = '';
  };

  const fullAttackAnimation = async (
    newGameState: GameState,
    attackingCard: HTMLDivElement,
    attackedCard: HTMLDivElement,
    setDisplayState: (gameState: GameState) => void
  ) => {
    await attackToTarget(attackingCard, attackedCard, 1);
    setDisplayState(newGameState);
    await returnFromAttack(attackingCard, 1);
  };

  const attackToTarget = async (
    attackingCard: HTMLDivElement,
    attackedCard: HTMLDivElement,
    duration: number
  ) => {
    const attackingRect = attackingCard.getBoundingClientRect();
    const attackedRect = attackedCard.getBoundingClientRect();

    const deltaX = attackedRect.left - attackingRect.left;
    const deltaY = attackedRect.top - attackingRect.top;

    attackingCard.style.transition = `transform ${duration}s ease`;
    attackingCard.style.transform = `translate(${deltaX}px, ${deltaY}px)`;

    await new Promise((resolve) => setTimeout(resolve, duration * 1000));
  };

  const returnFromAttack = async (
    attackingCard: HTMLDivElement,
    duration: number
  ) => {
    attackingCard.style.transition = `transform ${duration}s ease`;
    attackingCard.style.transform = `translate(0, 0)`;

    await new Promise((resolve) => setTimeout(resolve, duration * 1000));
  };

  // #endregion

  // out
  const value: AnimationContextType = {
    hasAnimationForEvent,
    runAnimationForEvent,
  };

  return (
    <AnimationContext.Provider value={value}>
      {children}
    </AnimationContext.Provider>
  );
};

interface AnimationContextType {
  hasAnimationForEvent: (stackEvent: StackEvent) => boolean;
  runAnimationForEvent: (
    stackEvent: StackEvent,
    setDisplayState: (gameState: GameState) => void
  ) => Promise<void>;
}

const AnimationContext = React.createContext<AnimationContextType | null>(null);

export { AnimationContextProvider, AnimationContext };
