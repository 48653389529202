// external
import React, { useState, useEffect, useContext } from 'react';

// context
import { ScreenSizeContext } from './ScreenSizeContextProvider';

// sizing constants
const HAND_CARD_HEIGHT_RATIO = 0.16;
const HAND_CARD_WIDTH_RATIO = 0.8 * HAND_CARD_HEIGHT_RATIO;

// game specific sizing
const GameElementSizeContextProvider = ({ children }) => {
  const {
    masterGameContainerHeight,
    masterGameContainerWidth,
    masterGameContainerMargin,
  } = useContext(ScreenSizeContext);

  // #region game container - size most things off this

  const [gameContainerHeight, setGameContainerHeight] = useState(
    masterGameContainerHeight
  );
  const [gameContainerWidth, setGameContainerWidth] = useState(
    masterGameContainerWidth
  );

  const [gameContainerMargin, setGameContainerMargin] = useState(
    masterGameContainerMargin
  );

  useEffect(() => {
    setGameContainerHeight(masterGameContainerHeight);
  }, [masterGameContainerHeight]);

  useEffect(() => {
    setGameContainerWidth(masterGameContainerWidth);
  }, [masterGameContainerWidth]);

  useEffect(() => {
    setGameContainerMargin(masterGameContainerMargin);
  }, [masterGameContainerMargin]);

  // #endregion

  // #region board height

  const boardContainerHeightRatio = 0.7;
  const [boardContainerHeight, setBoardContainerHeight] = useState(100);
  useEffect(() => {
    setBoardContainerHeight(gameContainerHeight * boardContainerHeightRatio);
  }, [gameContainerHeight]);

  const boardCardRatio = 1.16;

  const [boardHeight, setBoardHeight] = useState(100);
  const [boardCardWidth, setBoardCardWidth] = useState(100 / boardCardRatio);
  useEffect(() => {
    const tempBH = boardContainerHeight / 6;
    setBoardHeight(tempBH - tempBH * 0.2);
  }, [boardContainerHeight]);

  useEffect(() => {
    setBoardCardWidth(boardHeight / boardCardRatio);
  }, [boardHeight]);

  // #endregion

  // #region hand cards

  const [cardHeight, setCardHeight] = useState(100);
  const [cardWidth, setCardWidth] = useState(67);
  useEffect(() => {
    setCardHeight(gameContainerHeight * HAND_CARD_HEIGHT_RATIO);
    setCardWidth(gameContainerHeight * HAND_CARD_WIDTH_RATIO);
  }, [gameContainerHeight]);

  // #endregion

  // #region compass button
  const [compassButtonHeight, setCompassButtonHeight] = useState(20);
  useEffect(() => {
    setCompassButtonHeight(gameContainerHeight * 0.22);
  }, [gameContainerHeight]);

  // #endregion

  // #region realm button

  const [realmButtonHeight, setRealmButtonHeight] = useState(20);
  useEffect(() => {
    setRealmButtonHeight(gameContainerHeight * 0.11);
  }, [gameContainerHeight]);

  const realmButtonNavbarHeight = 120;

  // #endregion

  // #region mana circle

  const [manaCircleHeight, setManaCircleHeight] = useState(20);
  useEffect(() => {
    setManaCircleHeight(gameContainerHeight * 0.057);
  }, [gameContainerHeight]);

  // #endregion

  const value: GameElementSizeContextType = {
    gameContainerHeight,
    gameContainerWidth,
    gameContainerMargin,
    boardContainerHeight,
    boardHeight,
    boardCardWidth,
    boardCardRatio,
    cardHeight,
    cardWidth,
    manaCircleHeight,
    compassButtonHeight,
    realmButtonHeight,
    realmButtonNavbarHeight,
  };

  return (
    <GameElementSizeContext.Provider value={value}>
      {children}
    </GameElementSizeContext.Provider>
  );
};

interface GameElementSizeContextType {
  gameContainerHeight: number;
  gameContainerWidth: number;
  gameContainerMargin: number;
  boardContainerHeight: number;
  boardHeight: number;
  boardCardWidth: number;
  boardCardRatio: number;
  cardHeight: number;
  cardWidth: number;
  manaCircleHeight: number;
  compassButtonHeight: number;
  realmButtonHeight: number;
  realmButtonNavbarHeight: number;
}

const GameElementSizeContext = React.createContext(null);

export { GameElementSizeContextProvider, GameElementSizeContext };
