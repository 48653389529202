// a component that displays a player's mana
// external
import React, { useState, useEffect, useContext } from 'react';

// context
import { TurnFlowContext } from '../../../contexts/TurnFlowContextProvider';
import { GameElementSizeContext } from '../../../contexts/GameElementSizeContextProvider';
import { ImageContext } from '../../../contexts/ImageContextProvider';

// css
import { ManaCircleStyled } from './styles/ManaCircle.styled.js';

// lol
import Stat from '../../../../../../LegendsOfLeakos/lib/Classes/Stat/Stat.js';
import PayResourceCost from '../../../../../../LegendsOfLeakos/lib/Classes/PayResourceCost/PayResourceCost.js';

interface ManaCircleProps {
  isPlayer: boolean;
  playerManaStat: Stat;
}

const ManaCircle: React.FC<ManaCircleProps> = ({
  isPlayer,
  playerManaStat,
}) => {
  const { queuedMana, setQueuedMana } = useContext(TurnFlowContext);
  const { manaCircleHeight } = useContext(GameElementSizeContext);
  const {
    forestMana,
    oceanMana,
    desertMana,
    mountainMana,
    prairieMana,
    fellsMana,
    tundraMana,
  } = React.useContext(ImageContext);

  // #region add and remove mana
  const addMana = () => {
    if (!isPlayer || !queuedMana || !playerManaStat) return;
    if (playerManaStat.effectiveValue === 0) return;
    if (playerManaStat.effectiveValue <= queuedManaValue) return;

    const manaCost = queuedMana.find(
      (mana) => mana.statId === playerManaStat.statId
    );
    if (!manaCost) {
      setQueuedMana([
        ...queuedMana,
        new PayResourceCost(playerManaStat.statId, 1),
      ]);
    } else {
      setQueuedMana((prevQueue) => {
        return prevQueue.map((prc: PayResourceCost) => {
          const newPrc = prc.clone();
          if (prc.statId === playerManaStat.statId) {
            newPrc.value += 1;
          }
          return newPrc;
        });
      });
    }
  };

  const removeMana = () => {
    if (!isPlayer || !queuedMana || !playerManaStat) return;
    setQueuedMana((prevQueue) => {
      return prevQueue
        .map((prc: PayResourceCost) => {
          const newPrc = prc.clone();
          if (prc.statId === playerManaStat.statId && prc.value > 0) {
            newPrc.value -= 1;
          }
          return newPrc;
        })
        .filter((prc: PayResourceCost) => prc.value > 0);
    });
  };

  const queuedManaValue = isPlayer
    ? queuedMana.find((mana) => mana.statId === playerManaStat.statId)?.value
    : 0;

  // #endregion

  // #region mana images
  const imageName = (manaName) => {
    if (manaName === 'ForestMana') return forestMana;
    if (manaName === 'OceanMana') return oceanMana;
    if (manaName === 'DesertMana') return desertMana;
    if (manaName === 'MountainMana') return mountainMana;
    if (manaName === 'PrairieMana') return prairieMana;
    if (manaName === 'FellsMana') return fellsMana;
    if (manaName === 'TundraMana') return tundraMana;
  };

  // #endregion

  return (
    <ManaCircleStyled
      className='mana-circle'
      isPlayer={isPlayer}
      manaCircleHeight={manaCircleHeight}
      backgroundImage={imageName(playerManaStat?.name)}
    >
      <div className={'content' + (isPlayer ? ' player' : ' opponent')}>
        <div className='queued-mana' onClick={removeMana}>
          {queuedManaValue > 0 && isPlayer ? queuedManaValue : ''}
        </div>
        <div className='mana' onClick={addMana}>
          {playerManaStat
            ? (playerManaStat.effectiveValue || 0) - (queuedManaValue || 0)
            : ''}
        </div>
        <div className='mana-name'>{playerManaStat?.name}</div>
      </div>
    </ManaCircleStyled>
  );
};

export default ManaCircle;
