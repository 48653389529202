import RuntimeKeyword from '../RuntimeKeyword.js';
import StatBuff from '../../../Stat/StatBuff.js';
import { KeywordType } from '../../../../Enums/Keyword.js';
import RuntimeCondition from '../../../Condition/RuntimeCondition.js';
import ModifiableInt from '../../../ModifableInt/ModifiableInt.js';
import LibraryKeyword from '../../LibraryKeyword/LibraryKeyword.js';
class WarleaderKeyword extends RuntimeKeyword {
    constructor(myEntityInstanceId, setIsPermanent, setDuration, isActive, conditions, imageName, buffAttackStatAmount, buffHealthStatAmount) {
        super();
        this.keywordType = KeywordType.Warleader;
        this.myEntityInstanceId = myEntityInstanceId;
        this.isPermanent = setIsPermanent;
        this.duration = setDuration;
        this.isActive = isActive;
        this.imageName = imageName;
        this.conditions = conditions;
        this.buffAttackStatAmount = buffAttackStatAmount;
        this.buffHealthStatAmount = buffHealthStatAmount;
    }
    addStatBuff(stat, statCardInstanceId, gameState) {
        const myEntity = gameState.getEntityFromAnywhere(this.myEntityInstanceId);
        if (!myEntity) {
            throw new Error('myEntity is null');
        }
        const statCard = gameState.getEntityFromAnywhere(statCardInstanceId);
        if (!statCard) {
            throw new Error('statCard is null');
        }
        for (const condition of this.conditions) {
            if (!condition.isTrue(statCard.instanceId, this.myEntityInstanceId, gameState))
                return null;
        }
        switch (stat.name) {
            case 'Attack':
                return new StatBuff(this.buffAttackStatAmount.effectiveValue, myEntity.name +
                    ' is granting ' +
                    this.buffAttackStatAmount.effectiveValue +
                    ' attack.');
            case 'Life':
                return new StatBuff(this.buffHealthStatAmount.effectiveValue, myEntity.name +
                    ' is granting ' +
                    this.buffHealthStatAmount.effectiveValue +
                    ' health.');
            default:
                return null;
        }
        // shouldn't need this because of the switch but oh well
        return null;
    }
    getDisplayText() {
        return ('This grants ' +
            this.buffAttackStatAmount.effectiveValue +
            ' attack and ' +
            this.buffHealthStatAmount.effectiveValue +
            ' health.');
    }
    // #region JSON
    toJSON() {
        return {
            keywordType: KeywordType[this.keywordType],
            myEntityInstanceId: this.myEntityInstanceId,
            isPermanent: this.isPermanent,
            duration: this.duration,
            isActive: this.isActive,
            imageName: this.imageName,
            conditions: this.conditions.map((c) => c.toJSON()),
            buffAttackStatAmount: this.buffAttackStatAmount,
            buffHealthStatAmount: this.buffHealthStatAmount,
        };
    }
    clone() {
        return new WarleaderKeyword(this.myEntityInstanceId, this.isPermanent, this.duration, this.isActive, this.conditions.map((c) => c.clone()), this.imageName, this.buffAttackStatAmount.clone(), this.buffHealthStatAmount.clone());
    }
    static fromRuntimeJSON(json) {
        return new WarleaderKeyword(json.myEntityInstanceId, json.isPermanent, json.duration, json.isActive, json.conditions.map((c) => RuntimeCondition.fromRuntimeJSON(c)), json.imageName, ModifiableInt.fromJSON(json.buffAttackStatAmount), ModifiableInt.fromJSON(json.buffHealthStatAmount));
    }
    static fromLibraryJSON(myEntityInstanceId, json) {
        return new WarleaderKeyword(myEntityInstanceId, json.isPermanent, json.duration, json.isActive, json.conditions.map((c) => RuntimeCondition.fromLibraryJSON(c)), json.imageName, new ModifiableInt(json.data.buffAttackStatAmount.value, []), new ModifiableInt(json.data.buffHealthStatAmount.value, []));
    }
    static isLibraryJSONValid(json) {
        if (!json.keywordType ||
            !Object.values(KeywordType)
                .filter((value) => typeof value === 'string')
                .includes(json.keywordType)) {
            console.log('Invalid keywordType');
            return false;
        }
        if (json.isPermanent === undefined ||
            json.isPermanent === null ||
            typeof json.isPermanent !== 'boolean') {
            console.log('Invalid isPermanent');
            return false;
        }
        if (json.duration === undefined ||
            json.duration === null ||
            typeof json.duration !== 'number') {
            console.log('Invalid duration');
            return false;
        }
        if (json.isActive === undefined ||
            json.isActive === null ||
            typeof json.isActive !== 'boolean') {
            console.log('Invalid isActive');
            return false;
        }
        if (!json.imageName || typeof json.imageName !== 'string') {
            console.log('Invalid imageName');
            return false;
        }
        if (!json.conditions ||
            !Array.isArray(json.conditions) ||
            !json.conditions.every((c) => RuntimeCondition.isLibraryJSONValid(c))) {
            console.log('Invalid conditions');
            return false;
        }
        if (!json.data.buffAttackStatAmount || !json.data.buffHealthStatAmount) {
            console.log('Invalid buffAttackStatAmount or buffHealthStatAmount');
            return false;
        }
        return true;
    }
    static createSampleLibraryKeyword() {
        return new LibraryKeyword(KeywordType.Warleader, 'Warleader', true, 0, true, [], 'image', {
            buffAttackStatAmount: {
                type: 'Number',
                value: 1,
            },
            buffHealthStatAmount: {
                type: 'Number',
                value: 1,
            },
        });
    }
    // #endregion
    static initialize() {
        RuntimeKeyword.registerFromLibraryJSON(KeywordType.Warleader, WarleaderKeyword.fromLibraryJSON);
        RuntimeKeyword.registerFromRuntimeJSON(KeywordType.Warleader, WarleaderKeyword.fromRuntimeJSON);
        RuntimeKeyword.registerIsLibraryJSONValid(KeywordType.Warleader, WarleaderKeyword.isLibraryJSONValid);
        RuntimeKeyword.registerSampleLibraryKeyword(KeywordType.Warleader, WarleaderKeyword.createSampleLibraryKeyword);
    }
}
export default WarleaderKeyword;
