import styled from 'styled-components';

export const ManaCircleStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ manaCircleHeight }) => `${manaCircleHeight}px`};
  width: ${({ manaCircleHeight }) => `${manaCircleHeight}px`};
  border-radius: 50%;
  color: white;
  font-size: ${({ manaCircleHeight }) => `${manaCircleHeight / 2}px`};
  cursor: pointer;
  position: relative;
  pointer-events: ${(props) => (props.isPlayer ? 'auto' : 'none')};
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;

  // background
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-position: center;

  .mana {
    height: ${({ manaCircleHeight }) => `${manaCircleHeight}px`};
    width: ${({ manaCircleHeight }) => `${manaCircleHeight}px`};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .queued-mana {
    position: absolute;
    left: ${({ manaCircleHeight }) => `${manaCircleHeight * -1}px`};
    height: ${({ manaCircleHeight }) => `${manaCircleHeight}px`};
    width: ${({ manaCircleHeight }) => `${manaCircleHeight}px`};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // mana name
  .mana-name {
    display: none;
    font-size: 0.5em;
    position: absolute;
    color: white;
  }

  :hover .mana-name {
    display: block;
  }

  .player .mana-name {
    position: absolute;
    bottom: -20px;
    left: -20%;
  }

  .opponent .mana-name {
    position: absolute;
    top: -15px;
    left: -20%;
  }
`;
