// external
import React, { useState, useEffect } from 'react';
import axios from 'axios';

// internal
// context
import { CardLibraryContext } from './CardLibraryContextProvider';
import { AuthContext } from './AuthContextProvider';

// new interface for realm
interface LibraryRealmWithId extends LibraryRealm {
  _id: string | null;
}

// lol
import LibraryRealm from '../../../../LegendsOfLeakos/lib/Classes/RealmsAndLand/Realm/LibraryRealm';

const UserRealmsContextProvider = ({ children }) => {
  // context
  const { userId } = React.useContext(AuthContext);
  const { gameManager } = React.useContext(CardLibraryContext);

  // state
  const [realms, setRealms] = useState<LibraryRealmWithId[]>([]);
  const [selectedRealm, setSelectedRealm] = useState<LibraryRealmWithId | null>(
    null
  );

  // #region Get Realms
  useEffect(() => {
    axios
      .get(`/realms/${userId}`)
      .then((res) => {
        setRealms(createRealmsFromJSON(res.data));
        setSelectedRealm(createRealmFromJSON(res.data[0]));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // #endregion

  // #region Realm CRUD
  const createNewRealm = () => {
    axios
      .post(`/realms/${userId}`)
      .then((res) => {
        _addRealmAndRefresh(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveRealm = () => {
    if (!selectedRealm) return;
    const json = selectedRealm.toJSON();
    if (selectedRealm._id) {
      console.log('saveing realm: ', json);
      axios
        .put(`/realms/${userId}/${selectedRealm._id}`, json)
        .then((res) => {
          _addRealmAndRefresh(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post(`/realms/${userId}`, json)
        .then((res) => {
          _addRealmAndRefresh(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const deleteRealm = (realm_id: string) => {
    if (!realm_id) {
      console.log('Delete Realm - No realm id');
      return;
    }

    // take out of realms
    const tempRealms = realms.filter((realm) => realm._id !== realm_id);
    setRealms(tempRealms);

    // remove from server
    axios
      .delete(`/realms/${userId}/${realm_id}`)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  // #endregion

  // #region CRUD Helpers

  const _addRealmAndRefresh = (data) => {
    const newRealm = createRealmFromJSON(data);
    // add to realms if ._id is not already there
    if (!realms.find((realm) => realm._id === newRealm._id)) {
      setRealms([...realms, newRealm]);
    } else {
      const tempRealms = realms.map((realm) => {
        if (realm._id === newRealm._id) {
          return newRealm;
        }
        return realm;
      });
      setRealms(tempRealms);
    }
    setSelectedRealm(newRealm);
  };

  const createRealmFromJSON = (json) => {
    const realm = LibraryRealm.fromJSON(json) as LibraryRealmWithId;
    // adding this for React keys
    realm._id = json._id || null;
    if (!realm._id) {
      console.log('create realm from json - no realm id');
    }
    return realm;
  };

  const createRealmsFromJSON = (json) => {
    const realms = [];
    json.forEach((realm) => {
      realms.push(createRealmFromJSON(realm));
    });
    return realms;
  };
  // #endregion

  // out
  const value: UserRealmsContextType = {
    realms,
    selectedRealm,
    setSelectedRealm,
    createNewRealm,
    saveRealm,
    deleteRealm,
  };

  return (
    <UserRealmsContext.Provider value={value}>
      {children}
    </UserRealmsContext.Provider>
  );
};

interface UserRealmsContextType {
  realms: LibraryRealmWithId[];
  selectedRealm: LibraryRealmWithId | null;
  setSelectedRealm: (realm: LibraryRealmWithId) => void;
  createNewRealm: () => void;
  saveRealm: () => void;
  deleteRealm: (realm_id: string) => void;
}

const UserRealmsContext = React.createContext<UserRealmsContextType>(null);

export { UserRealmsContextProvider, UserRealmsContext };
