// external
import React, { useRef, useState, useEffect, useContext } from 'react';

// internal
import { SocketContext } from './SocketContextProvider';
import { GameStatusContext } from './GameStatusContextProvider';

// #region lol imports
import Player from '../../../../LegendsOfLeakos/lib/Classes/Player/Player.js';
import { NetworkProtocol } from '../../../../LegendsOfLeakos/lib/Enums/NetworkProtocol.js';
import TargetInfo from '../../../../LegendsOfLeakos/lib/Classes/Target/TargetInfo.js';
import PayResourceCost from '../../../../LegendsOfLeakos/lib/Classes/PayResourceCost/PayResourceCost';
import { ZoneEnum } from '../../../../LegendsOfLeakos/lib/Enums/Zone';
import RuntimeCard from '../../../../LegendsOfLeakos/lib/Classes/Card/RuntimeCard';
import StartGameMessage from '../../../../LegendsOfLeakos/lib/Classes/Networking/GameLoop/Game/StartGameMessage';
import ServerSendingGamestateForRejoin from '../../../../LegendsOfLeakos/lib/Classes/Networking/Connection/ServerSendingGamestateForRejoinMessage';
import LandExploredMessage from '../../../../LegendsOfLeakos/lib/Classes/Networking/Land/LandExporedMessage';
import NextPhaseReadyMessage from '../../../../LegendsOfLeakos/lib/Classes/Networking/GameLoop/PhaseAndQueue/NextPhaseReadyMessage';
import CreatureAttackedMessage from '../../../../LegendsOfLeakos/lib/Classes/Networking/Attacking/CreatureAttackedMessage';
import CardPlayedMessage from '../../../../LegendsOfLeakos/lib/Classes/Networking/Cards/Play/CardPlayedMessage';
import StackEvent from '../../../../LegendsOfLeakos/lib/Classes/StackEvent/StackEvent';
import GameState from '../../../../LegendsOfLeakos/lib/Classes/Game/GameState';
import { PlayerFlowEnum } from '../../../../LegendsOfLeakos/lib/Enums/PlayerFlow';
import PlayerInfo from '../../../../LegendsOfLeakos/lib/Classes/Player/PlayerInfo';
import EndGameMessage from '../../../../LegendsOfLeakos/lib/Classes/Networking/GameLoop/Game/EndGameMessage';
import { GamePlayState } from '../../../../LegendsOfLeakos/lib/Enums/GamePlayState';
import RuntimeLandTile from '../../../../LegendsOfLeakos/lib/Classes/RealmsAndLand/LandTile/RuntimeLandTile';
// #endregion

//
const PlayerContextProvider = ({ children }) => {
  // socket
  const { socket, subscribeToEvent, emitEvent } = useContext(SocketContext);

  // master player state
  const [masterPlayer, setMasterPlayer] = useState<Player>(null);
  const masterPlayerRef = useRef<Player>(); // for socket listeners
  masterPlayerRef.current = masterPlayer;

  // displayed state
  const [displayState, setDisplayState] = useState<GameState>(null);
  const [playerInfo, setPlayerInfo] = useState<PlayerInfo>(null);
  const [opponentInfo, setOpponentInfo] = useState<PlayerInfo>(null);

  // game status
  const { setGameStatus } = useContext(GameStatusContext);

  useEffect(() => {
    if (!masterPlayer) return;
    if (!masterPlayer.gameState) return;
    // when we're not reviewing queue, this is set in queue context
    if (masterPlayer.playerFlowEnum === PlayerFlowEnum.ReviewingQueue) return;

    setDisplayState(() => masterPlayer.gameState);
  }, [masterPlayer]);

  useEffect(() => {
    if (!displayState) return;

    setPlayerInfo(masterPlayer.getPlayerInfo(displayState));
    setOpponentInfo(masterPlayer.getOpponentInfo(displayState));
  }, [displayState]);

  // #region Subscribe to Game Events
  // we do this immediately - it's good practice

  useEffect(() => {
    if (!socket) return;

    // Subscribe to socket events using the provided functions from SocketContext
    const unsubscribeStartGame = subscribeToEvent(
      NetworkProtocol[NetworkProtocol.StartGame],
      onStartGame
    );
    const unsubscribeEndGame = subscribeToEvent(
      NetworkProtocol[NetworkProtocol.EndGame],
      onEndGame
    );
    const unsubscribeGamestateForRejoin = subscribeToEvent(
      NetworkProtocol[NetworkProtocol.ServerSendingGamestateForRejoin],
      onGamestateForRejoin
    );
    const unsubscribeLandExplored = subscribeToEvent(
      NetworkProtocol[NetworkProtocol.LandExplored],
      onLandExplored
    );
    const unsubscribeCreatureAttacked = subscribeToEvent(
      NetworkProtocol[NetworkProtocol.CreatureAttacked],
      receivedCreatureAttackedMessage
    );
    const unsubscribeCardPlayed = subscribeToEvent(
      NetworkProtocol[NetworkProtocol.CardPlayed],
      receivedPlayCardMessage
    );
    const unsubscribeQueueStarted = subscribeToEvent(
      NetworkProtocol[NetworkProtocol.QueueStarted],
      receivedQueueStartedMessage
    );
    const unsubscribeAbilityActivated = subscribeToEvent(
      NetworkProtocol[NetworkProtocol.AbilityActivated],
      receivedAbilityActivatedMessage
    );
    const unsubscribeNextPhaseReady = subscribeToEvent(
      NetworkProtocol[NetworkProtocol.NextPhaseReady],
      receivedNextPhaseReadyMessage
    );

    return () => {
      unsubscribeStartGame();
      unsubscribeEndGame();
      unsubscribeGamestateForRejoin();
      unsubscribeLandExplored();
      unsubscribeCreatureAttacked();
      unsubscribeCardPlayed();
      unsubscribeQueueStarted();
      unsubscribeAbilityActivated();
      unsubscribeNextPhaseReady();
    };
  }, [socket, subscribeToEvent]);

  // #endregion

  // #region Subscribe to Rejoin Event
  useEffect(() => {
    if (!socket) return;

    const handleRejoinedGame = () => {
      let newPlayer = new Player(emitEvent);
      newPlayer.fetchUpdatedGamestate();
    };

    socket.on('rejoined-game', handleRejoinedGame);

    return () => {
      socket.off('rejoined-game', handleRejoinedGame);
    };
  }, [socket]);
  // #endregion

  // #region Recieve Messages From Server

  const onStartGame = (msg: StartGameMessage) => {
    try {
      setGameStatus(GamePlayState.Ongoing);

      const newPlayer = new Player(emitEvent);
      newPlayer.onStartGame(msg);
      setMasterPlayer(newPlayer);
      console.log('onStartGame, newPlayer:', newPlayer);
    } catch (err) {
      console.error('Error in onStartGame:', err);
    }
  };

  const onEndGame = (msg: EndGameMessage) => {
    try {
      const message = EndGameMessage.fromJSON(msg);
      if (!message || !message.validate()) {
        console.log('Invalid EndGameMessage message: ', message);
        return;
      }
      console.log('masterPlayer:', masterPlayer);

      if (message.winnerUserId === '') {
        setGameStatus(GamePlayState.Draw);
      } else if (message.winnerUserId === masterPlayerRef.current.userId) {
        setGameStatus(GamePlayState.PlayerWon);
      } else {
        setGameStatus(GamePlayState.PlayerLost);
      }
    } catch (err) {
      console.error('Error in onEndGame:', err);
    }
  };

  const onGamestateForRejoin = (msg: ServerSendingGamestateForRejoin) => {
    try {
      const newPlayer = new Player(emitEvent);
      newPlayer.onGamestateForRejoin(msg);
      setMasterPlayer(newPlayer);
      console.log('onGamestateForRejoin, newPlayer:', newPlayer);
    } catch (err) {
      console.error('Error in onGamestateForRejoin:', err);
    }
  };

  const onLandExplored = (msg: LandExploredMessage) => {
    try {
      if (!checkForSavedPlayer('onLandExplored')) return;

      setMasterPlayer((prevMasterPlayer) => {
        const newMasterPlayer = prevMasterPlayer.clone();
        newMasterPlayer.onLandExplored(msg);
        console.log('onLandExplored - newMasterPlayer: ', newMasterPlayer);
        return newMasterPlayer;
      });
    } catch (err) {
      console.error('Error in onLandExplored:', err);
    }
  };

  const receivedCreatureAttackedMessage = (msg: CreatureAttackedMessage) => {
    try {
      if (!checkForSavedPlayer('onCreatureAttacked')) return;

      setMasterPlayer((prevMasterPlayer) => {
        const newMasterPlayer = prevMasterPlayer.clone();
        newMasterPlayer.receivedAttackMessage(msg);
        console.log(
          'receiveCreatureAttackedMessage - newMasterPlayer: ',
          newMasterPlayer
        );
        return newMasterPlayer;
      });
    } catch (err) {
      console.error('Error in receivedCreatureAttackedMessage:', err);
    }
  };

  const receivedNextPhaseReadyMessage = (msg: NextPhaseReadyMessage) => {
    try {
      if (!checkForSavedPlayer('receivedNextPhaseReadyMessage')) return;

      setMasterPlayer((prevMasterPlayer) => {
        const newMasterPlayer = prevMasterPlayer.clone();
        newMasterPlayer.receivedNextPhaseReadyMessage(msg);
        console.log(
          'receivedNextPhaseReadyMessage - newMasterPlayer: ',
          newMasterPlayer
        );
        return newMasterPlayer;
      });
    } catch (err) {
      console.error('Error in receivedNextPhaseReadyMessage:', err);
    }
  };

  const receivedPlayCardMessage = (msg: CardPlayedMessage) => {
    try {
      if (!checkForSavedPlayer('receivedPlayCardMessage')) return;

      setMasterPlayer((prevMasterPlayer) => {
        const newMasterPlayer = prevMasterPlayer.clone();
        newMasterPlayer.receivedPlayCardMessage(msg);
        console.log(
          'receivedPlayCardMessage - newMasterPlayer: ',
          newMasterPlayer
        );
        return newMasterPlayer;
      });
    } catch (err) {
      console.error('Error in receivedPlayCardMessage:', err);
    }
  };

  const receivedQueueStartedMessage = (msg) => {
    try {
      if (!checkForSavedPlayer('onQueueStarted')) return;

      setMasterPlayer((prevMasterPlayer) => {
        const newMasterPlayer = prevMasterPlayer.clone();
        newMasterPlayer.receivedQueueStartedMessage(msg);
        console.log(
          'receivedQueueStartedMessage - newMasterPlayer: ',
          newMasterPlayer
        );
        return newMasterPlayer;
      });
    } catch (err) {
      console.error('Error in receivedQueueStartedMessage:', err);
    }
  };

  const receivedAbilityActivatedMessage = (msg) => {
    try {
      if (!checkForSavedPlayer('onAbilityActivated')) return;

      setMasterPlayer((prevMasterPlayer) => {
        const newMasterPlayer = prevMasterPlayer.clone();
        newMasterPlayer.receivedActivateAbilityMessageFromServer(msg);
        console.log(
          'receivedAbilityActivatedMessage - newMasterPlayer: ',
          newMasterPlayer
        );
        return newMasterPlayer;
      });
    } catch (err) {
      console.error('Error in receivedAbilityActivatedMessage:', err);
    }
  };

  // #endregion

  // #region Send Messages To Server

  const queuePlayCard = (
    cardInstanceId: number,
    zoneEnum: ZoneEnum,
    queuedCosts: PayResourceCost[],
    battlecryIndex: number,
    targetInfoList: TargetInfo[]
  ) => {
    if (!checkForSavedPlayer('queuePlayCard')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      try {
        newMasterPlayer.queuePlayCard(
          cardInstanceId,
          zoneEnum,
          queuedCosts,
          battlecryIndex,
          targetInfoList
        );
      } catch (err) {
        console.log(err);
      }
      console.log('queuePlayCard - newMasterPlayer: ', newMasterPlayer);
      return newMasterPlayer;
    });
  };

  const cancelPlayCard = (cardInstanceId: number) => {
    if (!checkForSavedPlayer('cancelPlayCard')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      try {
        newMasterPlayer.cancelPlayCard(cardInstanceId);
      } catch (err) {
        console.log(err);
      }
      console.log('cancelPlayCard - newMasterPlayer: ', newMasterPlayer);
      return newMasterPlayer;
    });
  };

  const queueAttack = (attackingCard: RuntimeCard, targetCard: RuntimeCard) => {
    if (!checkForSavedPlayer('queueAttack')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      try {
        newMasterPlayer.queueAttack(
          attackingCard.instanceId,
          targetCard.instanceId
        );
      } catch (err) {
        console.log(err);
      }
      console.log('queueAttack - newMasterPlayer: ', newMasterPlayer);
      return newMasterPlayer;
    });
  };

  const cancelAttack = (attackingCardInstanceId: number) => {
    if (!checkForSavedPlayer('cancelAttack')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      try {
        newMasterPlayer.cancelAttack(attackingCardInstanceId);
      } catch (err) {
        console.log(err);
      }
      console.log('cancelAttack - newMasterPlayer: ', newMasterPlayer);
      return newMasterPlayer;
    });
  };

  const queueAbility = (
    entityInstanceId: number,
    abilityIndex: number,
    queuedCosts: PayResourceCost[],
    targetInfoList: TargetInfo[]
  ) => {
    if (!checkForSavedPlayer('queueAbility')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      try {
        newMasterPlayer.queueActivateAbility(
          entityInstanceId,
          abilityIndex,
          queuedCosts,
          targetInfoList
        );
      } catch (err) {
        console.log(err);
      }
      console.log('queueAbility - newMasterPlayer: ', newMasterPlayer);
      return newMasterPlayer;
    });
  };

  const finishedQueueActions = () => {
    if (!checkForSavedPlayer('finishedActions')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      try {
        newMasterPlayer.finishedQueueActions();
      } catch (err) {
        console.log(err);
      }
      console.log('finishedActions - newMasterPlayer: ', newMasterPlayer);
      return newMasterPlayer;
    });
  };

  const queueBlock = (
    blockingCardInstanceId: number,
    blockedCardInstanceId: number
  ) => {
    if (!checkForSavedPlayer('queueBlock')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      try {
        newMasterPlayer.queueBlock(
          blockingCardInstanceId,
          blockedCardInstanceId
        );
      } catch (err) {
        console.log(err);
      }
      console.log('queueBlock - newMasterPlayer: ', newMasterPlayer);
      return newMasterPlayer;
    });
  };

  const cancelBlock = (blockingCardInstanceId: number) => {
    if (!checkForSavedPlayer('cancelBlock')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      try {
        newMasterPlayer.cancelBlock(blockingCardInstanceId);
      } catch (err) {
        console.log(err);
      }
      console.log('cancelBlock - newMasterPlayer: ', newMasterPlayer);
      return newMasterPlayer;
    });
  };

  const changeBlockOrder = (
    blockingCardInstanceId: number,
    newBlockOrder: number
  ) => {
    if (!checkForSavedPlayer('changeBlockOrder')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      try {
        newMasterPlayer.changeBlockOrder(blockingCardInstanceId, newBlockOrder);
      } catch (err) {
        console.log(err);
      }
      console.log('changeBlockOrder - newMasterPlayer: ', newMasterPlayer);
      return newMasterPlayer;
    });
  };

  const exploreLand = (landTile: RuntimeLandTile) => {
    if (!checkForSavedPlayer('exploreLand')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      try {
        newMasterPlayer.exploreLand(landTile);
      } catch (err) {
        console.log(err);
      }
      console.log('exploreLand - newMasterPlayer: ', newMasterPlayer);
      return newMasterPlayer;
    });
  };

  // #endregion

  // #region On Start Phase
  // this is special - this will be called by queue review

  const onStartPhase = () => {
    if (!checkForSavedPlayer('onStartPhase')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      try {
        newMasterPlayer.onStartPhase();
      } catch (err) {
        console.log(err);
      }
      console.log('onStartPhase - newMasterPlayer: ', newMasterPlayer);
      return newMasterPlayer;
    });
  };

  // #endregion

  // #region checkForSavedPlayer - utility function

  const checkForSavedPlayer = (msg) => {
    if (!masterPlayerRef.current) {
      console.log(
        'DID NOT FIND THE PLAYER during',
        msg,
        '- FETCHING NEW GAMESTATE'
      );
      const newPlayer = new Player(emitEvent);
      newPlayer.fetchUpdatedGamestate();
      return false;
    }

    return true;
  };

  // #endregion

  const value: PlayerContextType = {
    masterPlayer,
    displayState,
    setDisplayState,
    playerInfo,
    opponentInfo,
    finishedQueueActions,
    onStartPhase,
    queuePlayCard,
    cancelPlayCard,
    queueAttack,
    cancelAttack,
    queueBlock,
    cancelBlock,
    changeBlockOrder,
    queueAbility,
    exploreLand,
  };

  return (
    <PlayerContext.Provider value={value}>{children}</PlayerContext.Provider>
  );
};

interface PlayerContextType {
  masterPlayer: Player;
  displayState: GameState;
  setDisplayState: (gameState: GameState) => void;
  playerInfo: PlayerInfo;
  opponentInfo: PlayerInfo;
  finishedQueueActions: () => void;
  onStartPhase: () => void;
  queuePlayCard: (
    cardInstanceId: number,
    zoneEnum: ZoneEnum,
    queuedCosts: PayResourceCost[],
    battlecryIndex: number,
    targetInfoList: TargetInfo[]
  ) => void;
  cancelPlayCard: (cardInstanceId: number) => void;
  queueAttack: (attackingCard: any, targetCard: any) => void;
  cancelAttack: (attackingCardInstanceId: number) => void;
  queueBlock: (
    blockingCardInstanceId: number,
    blockedCardInstanceId: number
  ) => void;
  cancelBlock: (blockingCardInstanceId: number) => void;
  changeBlockOrder: (
    blockingCardInstanceId: number,
    newBlockOrder: number
  ) => void;
  queueAbility: (
    entityInstanceId: number,
    abilityIndex: number,
    queuedCosts: PayResourceCost[],
    targetInfoList: TargetInfo[]
  ) => void;
  exploreLand: (landTile: RuntimeLandTile) => void;
}

const PlayerContext = React.createContext<PlayerContextType | null>(null);

export { PlayerContext, PlayerContextProvider };
