// a component that displays a realm representing a players land
// external
import React, { useState, useEffect, useContext } from 'react';

// internal components
import AbilityBox from '../BoardCard/Abilities/AbilityBox';

// context
import { PlayerContext } from '../../../contexts/PlayerContextProvider';
import { GameElementSizeContext } from '../../../contexts/GameElementSizeContextProvider';
import { ActiveAbilityContext } from '../../../contexts/ActiveAbilityContextProvider';

// css
import { BoardEnchantmentItemStyled } from './styles/BoardEnchantmentItem.styled.js';

// lol
import RuntimeAbility from '../../../../../../LegendsOfLeakos/lib/Classes/Ability/RuntimeAbility';

const BoardEnchantmentItem = ({
  isPlayer,
  enchantment,
  playerInfo,
  amActing = false,
}) => {
  // #region context
  const { masterPlayer, displayState } = useContext(PlayerContext);
  const { boardHeight, boardCardWidth } = useContext(GameElementSizeContext);
  const {
    startAbility,
    actingAbilityEntity,
    actingAbility,
    currentTargetCriteria,
    addTarget,
  } = useContext(ActiveAbilityContext);

  // #endregion

  // #region hover

  const [hover, setHover] = useState(false);

  // #endregion

  // #region mouse events

  const handleMouseUp = (e) => {
    if (!!actingAbility && !!currentTargetCriteria && !!addTarget) {
      addTarget(e, enchantment);
    }
  };

  const enchantmentItemRef = React.useRef(null);

  const handleAbilityClick = (e, index) => {
    if (amActing) {
      console.log('already acting');
      return;
    }
    if (!!actingAbility) {
      console.log('already acting ability');
      return;
    }
    if (!isPlayer) {
      console.log('not your card');
      return;
    }
    if (!!startAbility) startAbility(e, enchantment, index, enchantmentItemRef);
  };

  // #endregion

  // #region active
  const [amActive, setAmActive] = useState(false);
  useEffect(() => {
    if (!masterPlayer || !displayState) return;
    let tempActive = false;
    enchantment.abilities.forEach((ability: RuntimeAbility) => {
      if (ability.isActivatable(playerInfo, displayState)) {
        tempActive = true;
      }
    });
    setAmActive(tempActive);
  }, [masterPlayer, enchantment]);
  // #endregion

  return (
    <BoardEnchantmentItemStyled
      className={`enchantment-item`}
      cardHeight={boardHeight}
      cardWidth={boardCardWidth}
      onMouseUp={(e) => handleMouseUp(e)}
      hover={hover}
      isPlayer={isPlayer}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      amActive={amActive}
      ref={enchantmentItemRef}
    >
      <div className='enchantment-item-content'>
        <p>{enchantment.name}</p>
        <div
          className={
            'keywords-and-abilities' +
            (hover && !actingAbility ? '' : ' invisible')
          }
        >
          <div className='abilities'>
            {hover &&
              enchantment.abilities.map((ability, index) => (
                <AbilityBox
                  key={index}
                  ability={ability}
                  onClick={(e) => handleAbilityClick(e, index)}
                />
              ))}
          </div>
          <div className='keywords'>
            {enchantment.keywords.map((keyword, index) => (
              <span key={index}>{keyword.getDisplayText()}</span>
            ))}
          </div>
        </div>
      </div>
    </BoardEnchantmentItemStyled>
  );
};

export default BoardEnchantmentItem;
