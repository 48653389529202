// external
import React, { useContext, useEffect, useState, useRef } from 'react';

const DraggedCardContextProvider = ({ children }) => {
  // dragging vars
  const [draggedCard, setDraggedCard] = useState(null);

  const value = {
    draggedCard,
    setDraggedCard,
  };
  return (
    <DraggedCardContext.Provider value={value}>
      {children}
    </DraggedCardContext.Provider>
  );
};

const DraggedCardContext = React.createContext(null);

export { DraggedCardContext, DraggedCardContextProvider };
