import { ConditionType } from '../../Enums/Condition.js';
class RuntimeCondition {
    static registerFromRuntimeJSON(type, method) {
        this.fromRuntimeJSONMethods[type] = method;
    }
    static fromRuntimeJSON(json) {
        const conditionType = ConditionType[json.conditionType];
        const method = this.fromRuntimeJSONMethods[conditionType];
        if (!method) {
            throw new Error('Condition type not registered for: ' +
                ConditionType[conditionType] +
                ' - need to initialize in GameManager');
        }
        return method(json);
    }
    static registerFromLibraryJSON(type, method) {
        this.fromLibraryJSONMethods[type] = method;
    }
    static fromLibraryJSON(json) {
        const conditionType = ConditionType[json.conditionType];
        const method = this.fromLibraryJSONMethods[conditionType];
        if (!method) {
            throw new Error('Condition type not registered for: ' +
                ConditionType[conditionType] +
                ' - need to initialize in GameManager');
        }
        return method(json);
    }
    static registerIsLibraryJSONValid(type, method) {
        this.isLibraryJSONValidMethods[type] = method;
    }
    static isLibraryJSONValid(json) {
        const conditionType = ConditionType[json.conditionType];
        const method = this.isLibraryJSONValidMethods[conditionType];
        if (!method) {
            throw new Error('Condition type not registered for: ' +
                ConditionType[conditionType] +
                ' - need to initialize in GameManager');
        }
        return method(json);
    }
    static registerSampleLibraryCondition(type, creator) {
        this.sampleLibraryConditionMethods[type] = creator;
    }
    static createSampleLibraryCondition(type) {
        const method = this.sampleLibraryConditionMethods[type];
        if (!method) {
            throw new Error('Condition type not registered for: ' +
                ConditionType[type] +
                ' - need to initialize in GameManager');
        }
        return method();
    }
}
// #endregion
// #region fromRuntimeJSON methods
RuntimeCondition.fromRuntimeJSONMethods = {};
// #endregion
// #region fromLibraryJSON methods
RuntimeCondition.fromLibraryJSONMethods = {};
// #endregion
// #region isLibraryJSONValid methods
RuntimeCondition.isLibraryJSONValidMethods = {};
// #endregion
// #region Register Sample Condition Creators
RuntimeCondition.sampleLibraryConditionMethods = {};
export default RuntimeCondition;
