import TargetableRuntimeEntity from './TargetableRuntimeEntity.js';
/**
 * This class extends TargetableRuntimeEntity and provides abilities and keywords to that class. It is currently used by
 * cards and enchantments.
 */
class AbilityKeywordRuntimeEntity extends TargetableRuntimeEntity {
    constructor() {
        super(...arguments);
        this.keywords = [];
        this.abilities = [];
        // I really don't like doing this like this --- let's try not to
        // /**
        //  * The callback that is called when a keyword is added to this card.
        //  */
        // public onKeywordAdded?: (k: RuntimeKeyword) => void;
        // /**
        //  * The callback that is called when a keyword is removed from this card.
        //  */
        // public onKeywordRemoved?: (k: RuntimeKeyword) => void;
        this.keywordsToRemove = [];
        // endregion
    }
    // Keywords Functions
    condemnKeywordToRemoval(k) {
        this.keywordsToRemove.push(k);
    }
    clearKeywordsToRemove() {
        for (const k of this.keywordsToRemove) {
            this.removeKeyword(k);
        }
        this.keywordsToRemove = [];
    }
    removeKeyword(keyword) {
        const index = this.keywords.indexOf(keyword);
        if (index > -1) {
            this.keywords.splice(index, 1);
        }
        // if (this.onKeywordRemoved) {
        //   this.onKeywordRemoved(keyword);
        // }
    }
    hasKeyword(keywordType) {
        const k = this.keywords.find((x) => x.keywordType === keywordType);
        return k !== undefined;
    }
    // Functions for Effect / Keyword Interactions
    // END TURN
    onEndTurn(gameState) {
        for (const activatedAbility of this.abilities) {
            if (!activatedAbility.isActive)
                continue;
            activatedAbility.onEndTurn();
        }
        for (const k of this.keywords) {
            if (!k.isActive)
                continue;
            k.onEndTurn(gameState);
        }
    }
    // CARD EFFECT
    preResolveEffect(e, sourceEntityInstanceId, gameState, targetInfoList) {
        const results = [];
        for (const keyword of this.keywords) {
            if (!keyword.isActive)
                continue;
            const result = keyword.preResolveEffect(e, sourceEntityInstanceId, gameState, targetInfoList);
            if (!!result)
                results.push(result);
        }
        this.clearKeywordsToRemove();
        return results;
    }
    postResolveEffect(e, sourceEntityInstanceId, gameState, targetInfoList) {
        const results = [];
        for (const keyword of this.keywords) {
            if (!keyword.isActive)
                continue;
            const result = keyword.postResolveEffect(e, sourceEntityInstanceId, gameState, targetInfoList);
            if (!!result)
                results.push(result);
        }
        this.clearKeywordsToRemove();
        return results;
    }
}
export default AbilityKeywordRuntimeEntity;
