// external
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// internal context
import { SocketContext } from '../../contexts/SocketContextProvider';
import { GameElementSizeContext } from '../../contexts/GameElementSizeContextProvider';
import { ImageContext } from '../../contexts/ImageContextProvider';

// internal components
import Realm from './Realm/Realm';
import Hand from './Hand/Hand';
import ManaBar from './ManaBar/ManaBar';
import Board from './Board/Board';
import StackList from './Stack/StackList';
import PhaseButton from './Buttons/PhaseButton';
import TargetInfoPanel from './TargetInfo/TargetInfoPanel';
import ActionLines from './ActionLines/ActionLines';
import CurrentAction from './ActionLines/CurrentAction';
import EndGamePopup from './Popups/EndGame/EndGamePopup';
import PhaseNotificationPopup from './Popups/NewPhase/PhaseNotificationPopup';

// css
import { GamePageStyled } from './styles/GamePage.styled.js';

// lol imports
import { ZoneEnum } from '../../../../../LegendsOfLeakos/lib/Enums/Zone';
import { PhaseEnum } from '../../../../../LegendsOfLeakos/lib/Enums/Phase';
import { PlayerFlowEnum } from '../../../../../LegendsOfLeakos/lib/Enums/PlayerFlow';

function GamePage() {
  // #region contexts and refs and vars
  // if I'm not in play, leave this screen and go to the lobby
  const { socket } = useContext(SocketContext);
  const navigate = useNavigate(); // get the navigate function

  const gameContainerRef = useRef(null);

  const {
    gameContainerHeight,
    gameContainerWidth,
    gameContainerMargin,
    boardContainerHeight,
  } = useContext(GameElementSizeContext);

  const {
    backgroundLand,
    backgroundWater,
    justWater,
    noWater,
    border,
    justLines,
    seafoam,
    fog,
  } = useContext(ImageContext);

  // #endregion

  // #region controls (limited to dev and end game screen)

  const handleGameEndedButton = () => {
    navigate('/lobby');
  };

  // #endregion
  console.log('HELLO - GamePage - rendering. minimize this');

  return (
    <GamePageStyled
      className='game-page'
      gameContainerHeight={gameContainerHeight}
      gameContainerWidth={gameContainerWidth}
      gameContainerMargin={gameContainerMargin}
      boardContainerHeight={boardContainerHeight}
    >
      <div className='screen-background water'>
        <img
          className='screen-background-image'
          src={justWater}
          alt='background'
        />
      </div>
      <div className='fog-container'>
        <div className='fog fog-one'>
          <img src={fog} />
        </div>
        <div className='fog fog-two'>
          <img src={fog} />
        </div>
        <div className='fog fog-three'>
          <img src={fog} />
        </div>
        <div className='fog fog-four'>
          <img src={fog} />
        </div>
      </div>
      <div
        className='dev-controls'
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          zIndex: 500,
        }}
      >
        <button
          onClick={() => {
            socket.emit('dev-player-end-game');
          }}
        >
          End Game
        </button>
      </div>
      <div className='game-container' ref={gameContainerRef}>
        {/* backgrounds and borders */}
        <div className='background-container'>
          <div className='game-background lines'>
            <img
              className='background-image'
              src={justLines}
              alt='background'
            />
          </div>
          <div className='game-background seafoam'>
            <img className='background-image' src={seafoam} alt='background' />
          </div>
          <div className='game-background'>
            <img
              className='background-image'
              src={backgroundLand}
              alt='background'
            />
          </div>
          <div className='game-border'>
            <img className='background-image' src={border} alt='background' />
          </div>
        </div>

        {/* mana bars */}
        <div className='player-mana-bar'>
          <ManaBar isPlayer={true} />
        </div>
        <div className='opponent-mana-bar'>
          <ManaBar isPlayer={false} />
        </div>
        {/* player realm */}
        <Realm isPlayerRealm={true} />
        {/* opponent realm */}
        <Realm isPlayerRealm={false} />
        {/* queue area */}
        <StackList />
        {/* player hand */}
        <Hand gameContainerRef={gameContainerRef} />
        {/* opponent hand */}
        {/* phase button */}
        <PhaseButton />
        {/* board */}
        <div className='board-container'>
          <Board isPlayer={false} zoneEnum={ZoneEnum.BackBoard} />
          <Board isPlayer={false} zoneEnum={ZoneEnum.FrontBoard} />
          <Board isPlayer={false} zoneEnum={ZoneEnum.BattleBoard} />
          <Board isPlayer={true} zoneEnum={ZoneEnum.BattleBoard} />
          <Board isPlayer={true} zoneEnum={ZoneEnum.FrontBoard} />
          <Board isPlayer={true} zoneEnum={ZoneEnum.BackBoard} />
        </div>
        <TargetInfoPanel />
      </div>
      {/* arrows */}
      <ActionLines />
      <CurrentAction />
      {/* popups */}
      <PhaseNotificationPopup />
      <EndGamePopup handleGameEndedButton={handleGameEndedButton} />
    </GamePageStyled>
  );
}

export default GamePage;
