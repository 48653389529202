// external
import React, { useEffect, useState, useRef, useContext } from 'react';

// internal
// hooks
import useWindowDimensions from '../../hooks/useWindowDimensions.js';
// components
import RealmMap from '../Tiles/RealmMap';
// context
import { ImageContext } from '../../contexts/ImageContextProvider';

//css
import { RealmWrapperStyled } from './styles/RealmWrapper.styled.js';

function RealmWrapper({ realm }) {
  const { islandsForRealmBuilder } = useContext(ImageContext);

  // #region GETTING TILES FROM REALM
  const [tiles, setTiles] = useState([]);
  useEffect(() => {
    if (!realm) return;
    const tiles = realm.getLandTiles();
    setTiles(realm.getLandTiles());
  }, [realm]);

  // #endregion

  // #region SIZING THE MAP

  const { windowHeight, windowWidth } = useWindowDimensions();
  const [availableHeight, setAvailableHeight] = useState(0);
  const [availableWidth, setAvailableWidth] = useState(0);
  const wrapperRef = useRef(null);
  const [size, setSize] = useState(null);

  useEffect(() => {
    const handleResizeWindow = () => {
      setAvailableHeight(windowHeight - 280);
      setAvailableWidth((windowWidth * 3) / 5);
    };

    window.addEventListener('resize', handleResizeWindow);
    handleResizeWindow();
    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, [windowHeight, windowWidth]);

  useEffect(() => {
    // TODO - rows should come from a constant somewhere
    const rows = [7, 10, 11, 12, 11, 12, 11, 10, 7];
    // hexagon size is 1/2 of the row height
    const sizeImpliedByHeight = ((availableHeight / rows.length) * 2) / 3;
    // hexagon size is 1/sqrt(3) of the hexagon width
    const sizeImpliedByWidth =
      availableWidth / Math.max(...rows) / Math.sqrt(3);

    const hexSize = Math.min(sizeImpliedByHeight, sizeImpliedByWidth);
    setSize({
      height: hexSize * rows.length * 2 * (3 / 4) + 'px',
      width: hexSize * Math.max(...rows) * Math.sqrt(3) + 'px',
    });
  }, [availableHeight, availableWidth]);

  // #endregion

  return (
    <RealmWrapperStyled
      className='realm-wrapper'
      ref={wrapperRef}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      <div className='realm-map-wrapper' style={{ ...size }}>
        <div
          className='background-islands'
          style={{
            backgroundImage: `url(${islandsForRealmBuilder})`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
          }}
        ></div>
        <RealmMap
          tiles={tiles}
          outlineOnHover={false}
          outlinedTileIndices={[]}
          onClickTile={() => {}}
        />
      </div>
    </RealmWrapperStyled>
  );
}

export default RealmWrapper;
