import styled from 'styled-components';
import '../../../styles/constants.css';

export const GamePageStyled = styled.div`
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;

  // #region images

  .screen-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      pointer-events: none;
      user-select: none;
    }
  }

  // #region water, seafoam, lines

  .game-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .game-background .background-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
    pointer-events: none;
    user-select: none;
  }

  .water {
    // img {
    //   animation: complexPulse 35s infinite ease-in-out; // Updated animation name and duration
    // }
  }

  // #region water anim
  @keyframes complexPulse {
    0%,
    100% {
      transform: scale(1.02) translate(-1%, 1%);
    }
    10% {
      transform: scale(1) translate(0, 0);
    }
    20% {
      transform: scale(1.04) translate(2%, 2%);
    }
    30% {
      transform: scale(1.04) translate(2%, 2%);
    }
    40% {
      transform: scale(1.05) translate(-2%, -2%);
    }
    50% {
      transform: scale(1.05) translate(2%, -2%);
    }
    60% {
      transform: scale(1) translate(0, 0);
    }
    70% {
      transform: scale(1.04) translate(2%, 2%);
    }
    80% {
      transform: scale(1.04) translate(-2%, -2%);
    }
    90% {
      transform: scale(1.02) translate(-1%, 1%);
    }
  }
  // #endregion water anim

  .seafoam {
    img {
      animation: simplePulse 10s infinite ease-in-out; // Updated animation name and duration
    }
  }

  @keyframes simplePulse {
    0%,
    100% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.05);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.1);
    }
  }

  // #endregion water, seafoam, lines

  // #region fog

  .fog {
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    pointer-events: none;
    user-select: none;
  }

  .fog-one {
    top: 0;
    left: 0;

    img {
      opacity: 0.2;
      position: absolute;
      width: auto;
      min-width: 100%;
      height: 100%;
      animation: scrollFog 120s linear infinite;
    }
  }

  .fog-two {
    top: 30%;
    left: 0;

    img {
      opacity: 0.2;
      position: absolute;
      width: auto;
      min-width: 100%;
      height: 100%;
      animation: scrollFog 90s linear infinite;
    }
  }

  .fog-three {
    top: -15%;
    left: 0;

    img {
      opacity: 0.2;
      position: absolute;
      width: auto;
      min-width: 100%;
      height: 100%;
      animation: scrollFog 60s linear infinite;
    }
  }

  .fog-four {
    top: 10%;
    left: 0;

    img {
      opacity: 0.2;
      position: absolute;
      width: auto;
      min-width: 100%;
      height: 100%;
      animation: scrollFog 30s linear infinite;
    }
  }

  @keyframes scrollFog {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-50%);
    }
  }

  // #endregion fog

  // #endregion images

  // #region game and board

  .game-container {
    position: relative;
    height: ${(props) => props.gameContainerHeight}px;
    width: ${(props) => props.gameContainerWidth}px;
    margin: ${(props) => props.gameContainerMargin}px;
    border: 1px solid black;
    overflow: hidden;
  }

  .game-border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: var(--game-border-z-index);
    pointer-events: none;
    user-select: none;
  }

  .game-border .background-image {
    height: auto; // Adjust height automatically to maintain aspect ratio
    width: auto; // Adjust width automatically to maintain aspect ratio
    max-width: 100%; // Maximum width is the width of the container
    max-height: 100%; // Maximum height is the height of the container
    object-fit: contain; // The image will be scaled to maintain its aspect ratio while fitting within the container's bounds
  }

  .board-container {
    position: absolute;
    height: ${(props) => props.boardContainerHeight}px;
    width: 85%;
    top: 10%;
    left: 7.5%;

    // spacing of boards
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  // #endregion game and board

  // #region mana

  .player-mana-bar {
    position: absolute;
    top: 16.5%;
    right: 0.66%;
  }

  .opponent-mana-bar {
    position: absolute;
    top: 8.8%;
    left: 0.9%;
  }

  // #endregion mana

  // #region phase button

  .phase-button {
    position: absolute;
    right: 0;
    bottom: 50px;
  }

  .phase-button.inactive {
    background-color: grey;
    cursor: not-allowed;
    pointer-events: none;
  }

  // #endregion phase button
`;
