import Stat from '../Stat/Stat.js';
import RuntimeZone from '../Zone/RuntimeZone.js';
import { ZoneEnum } from '../../Enums/Zone.js';
import { LandType } from '../../Enums/LandAndBiome.js';
import PayResourceCost from '../PayResourceCost/PayResourceCost.js';
import RuntimeRealm from '../RealmsAndLand/Realm/RuntimeRealm.js';
import { ClientMessage } from '../Networking/MessageBase.js';
class PlayerInfo {
    constructor() {
        this.stats = [];
        // queueMessages
        // for rejoining the game, and still seeing what you've done
        this.queueMessages = [];
        // #endregion
    }
    clone() {
        const clone = new PlayerInfo();
        clone.userId = this.userId;
        clone.id = this.id;
        clone.name = this.name;
        clone.isConnected = this.isConnected;
        clone.isHuman = this.isHuman;
        clone.stats = this.stats.map((stat) => stat.clone());
        clone.nameToStat = new Map();
        clone.idToStat = new Map();
        for (const stat of clone.stats) {
            clone.nameToStat.set(stat.name, stat);
            clone.idToStat.set(stat.statId, stat);
        }
        clone.zones = this.zones.map((zone) => zone.clone());
        clone.realm = this.realm.clone();
        clone.currentEntityInstanceId = this.currentEntityInstanceId;
        clone.readyForQueue = this.readyForQueue;
        clone.queueMessages = this.queueMessages.map((message) => message.clone());
        return clone;
    }
    // #region Get Cards
    getCardFromInstanceId(cardInstanceId) {
        for (const zone of this.zones) {
            const tempCard = zone.cards.find((x) => x.instanceId === cardInstanceId);
            if (tempCard !== undefined) {
                return tempCard;
            }
        }
        return null;
    }
    getAllFriendlyCardsInPlay() {
        const cardList = new Array();
        6;
        cardList.push(...this.getFriendlyZoneFromZoneEnum(ZoneEnum.BackBoard).cards);
        cardList.push(...this.getFriendlyZoneFromZoneEnum(ZoneEnum.FrontBoard).cards);
        cardList.push(...this.getFriendlyZoneFromZoneEnum(ZoneEnum.BattleBoard).cards);
        return cardList;
    }
    // #endregion
    // #region Get Zones
    getFriendlyZoneContainingCard(cardInstanceId) {
        for (const zone of this.zones) {
            const tempCard = zone.cards.find((x) => x.instanceId === cardInstanceId);
            if (tempCard !== undefined) {
                return zone;
            }
        }
        return null;
    }
    getZoneFromInstanceId(zoneInstanceId) {
        const zone = this.zones.find((x) => x.instanceId === zoneInstanceId);
        return this.zones.find((c) => c.instanceId === zoneInstanceId);
    }
    getFriendlyZoneFromZoneEnum(zoneEnum) {
        return this.zones.find((c) => c.zoneEnum === zoneEnum);
    }
    // #endregion
    // #region Costs and Mana
    setPlayerManaFromLand() {
        this.nameToStat.get('ForestMana').baseValue = 0;
        this.nameToStat.get('OceanMana').baseValue = 0;
        this.nameToStat.get('DesertMana').baseValue = 0;
        this.nameToStat.get('MountainMana').baseValue = 0;
        this.nameToStat.get('PrairieMana').baseValue = 0;
        this.nameToStat.get('FellsMana').baseValue = 0;
        this.nameToStat.get('TundraMana').baseValue = 0;
        for (const landTile of this.realm.landTiles) {
            if (landTile.explored) {
                switch (landTile.landType) {
                    case LandType.forest:
                        this.nameToStat.get('ForestMana').baseValue += 1;
                        break;
                    case LandType.ocean:
                        this.nameToStat.get('OceanMana').baseValue += 1;
                        break;
                    case LandType.desert:
                        this.nameToStat.get('DesertMana').baseValue += 1;
                        break;
                    case LandType.mountain:
                        this.nameToStat.get('MountainMana').baseValue += 1;
                        break;
                    case LandType.prairie:
                        this.nameToStat.get('PrairieMana').baseValue += 1;
                        break;
                    case LandType.fells:
                        this.nameToStat.get('FellsMana').baseValue += 1;
                        break;
                    case LandType.tundra:
                        this.nameToStat.get('TundraMana').baseValue += 1;
                        break;
                }
            }
        }
    }
    payResourceCosts(costs, goalManaSpend = null) {
        if (!this.canPayResourceCosts(costs)) {
            console.log('CANNOT PAY COSTS');
            return null;
        }
        const outList = new Array();
        for (const cost of costs) {
            if (this.nameToStat.get('AnyMana').statId !== cost.statId) {
                this._payResourceCost(this.idToStat.get(cost.statId), cost.value, outList);
            }
        }
        const anyCost = costs.find((x) => x.statId === this.nameToStat.get('AnyMana').statId);
        if (anyCost !== undefined) {
            let anyValueRemaining = anyCost.value;
            if (goalManaSpend != null) {
                for (const cost of goalManaSpend) {
                    if (anyValueRemaining > 0) {
                        this._payResourceCost(this.idToStat.get(cost.statId), Math.min(anyValueRemaining, cost.value), outList);
                        anyValueRemaining -= cost.value;
                    }
                }
            }
            for (const stat of this.stats) {
                if (anyValueRemaining > 0) {
                    if (this.nameToStat.get('Life').statId !== stat.statId) {
                        const reduceBy = Math.min(anyValueRemaining, stat.effectiveValue);
                        this._payResourceCost(stat, reduceBy, outList);
                        anyValueRemaining -= reduceBy;
                    }
                }
            }
        }
        return outList;
    }
    _payResourceCost(stat, cost, outlist) {
        // Pay the cost from this playerInfo's stats
        stat.baseValue -= cost;
        // Add the payment to the outlist
        // Check if this stat has already been used in outlist
        const tempCost = outlist.find((c) => c.statId === stat.statId);
        // If not, add it
        if (tempCost === undefined) {
            const newCost = new PayResourceCost(stat.statId, cost);
            outlist.push(newCost);
        }
        // If so, just increase the value
        else {
            tempCost.value += cost;
        }
    }
    canPayResourceCosts(costs) {
        const availableResources = [];
        for (const playerResource of this.stats) {
            const tempPRC = new PayResourceCost(playerResource.statId, playerResource.effectiveValue);
            availableResources.push(tempPRC);
        }
        for (const cost of costs) {
            if (this.nameToStat.get('AnyMana').statId !== cost.statId) {
                const availableResource = availableResources.find((ar) => ar.statId === cost.statId);
                if (availableResource && availableResource.value < cost.value) {
                    return false;
                }
                if (availableResource) {
                    availableResource.value -= cost.value;
                }
            }
        }
        let remainingMana = 0;
        for (const c of availableResources) {
            if (c.statId !== this.nameToStat.get('Life').statId) {
                remainingMana += c.value;
            }
        }
        const anyCost = costs.find((x) => x.statId === this.nameToStat.get('AnyMana').statId);
        if (anyCost !== undefined) {
            if (anyCost.value > remainingMana) {
                return false;
            }
        }
        return true;
    }
    // #endregion
    // #region JSON
    /**
     * This is a complete copy of the player's state, to send to the player themselves.
     * It includes cards in zones that should be visible to them and not the opponent (hand)
     */
    toJSONForPlayer() {
        const json = {};
        json.name = this.name;
        json.userId = this.userId;
        json.id = this.id;
        json.currentEntityInstanceId = this.currentEntityInstanceId;
        // Copy player stats.
        json.stats = this.stats.map((stat) => stat.toJSON());
        // Copy player zones - zone.toJSONForPlayer() will not include cards that should be hidden from the player
        json.zones = this.zones.map((zone) => zone.toJSONForPlayer());
        // Copy realm
        json.realm = this.realm.toJSONFullCopy();
        // messages
        json.queueMessages = this.queueMessages.map((message) => message.toJSON());
        return json;
    }
    /**
     * This is a complete copy of the player's state, to send to the opponent.
     * It does not include cards in zones that should be hidden from the opponent (hand)
     */
    toJSONForOpponent() {
        const json = {};
        json.name = this.name;
        json.userId = this.userId;
        json.id = this.id;
        json.currentEntityInstanceId = this.currentEntityInstanceId;
        // Copy player stats.
        json.stats = this.stats.map((stat) => stat.toJSON());
        // Copy player zones - zone.toJSONForOpponent() will not include cards that should be hidden from the opponent
        json.zones = this.zones.map((zone) => zone.toJSONForOpponent());
        // Copy land and realm
        json.realm = this.realm.toJSONForOpponent();
        // don't include messages
        json.queueMessages = [];
        return json;
    }
    /**
     * for use once the game is going - it's unclear to me, if this needs to be parsed away
     * if this will work because of the circular references inherant in giving the gameState
     */
    static fromRuntimeJSON(json) {
        const playerInfo = new PlayerInfo();
        playerInfo.name = json.name;
        playerInfo.userId = json.userId;
        playerInfo.id = json.id;
        playerInfo.currentEntityInstanceId = json.currentEntityInstanceId;
        // Copy player stats.
        playerInfo.stats = json.stats.map((stat) => Stat.fromRuntimeJSON(stat));
        playerInfo.nameToStat = new Map();
        playerInfo.idToStat = new Map();
        for (const stat of playerInfo.stats) {
            playerInfo.nameToStat.set(stat.name, stat);
            playerInfo.idToStat.set(stat.statId, stat);
        }
        // Copy player zones.
        playerInfo.zones = json.zones.map((zone) => RuntimeZone.fromRuntimeJSON(zone));
        // Copy land and realm
        playerInfo.realm = RuntimeRealm.fromRuntimeJSON(json.realm);
        // Messages
        playerInfo.queueMessages = json.queueMessages.map((message) => ClientMessage.fromJSON(message));
        return playerInfo;
    }
}
export default PlayerInfo;
