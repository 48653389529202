import GameProperties from '../Game/GameProperties.js';
class PayResourceCost {
    constructor(statId, value) {
        this.statId = statId;
        this.value = value;
    }
    costName() {
        return GameProperties.playerStats.find((x) => x.statId === this.statId).name;
    }
    clone() {
        return PayResourceCost.fromJSON(this);
    }
    toJSON() {
        return {
            statId: this.statId,
            value: this.value,
        };
    }
    static fromJSON(json) {
        return new PayResourceCost(json.statId, json.value);
    }
    static isLibraryJSONValid(json) {
        const playerStat = GameProperties.playerStats.find((x) => x.statId === json.statId);
        if (playerStat === undefined)
            return false;
        if (typeof json.value !== 'number' ||
            json.value < playerStat.minValue ||
            json.value > playerStat.maxValue)
            return false;
        return true;
    }
}
export default PayResourceCost;
