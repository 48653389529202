import React from 'react';
import { useNavigate } from 'react-router-dom';

// internal
// context
import { SoundContext } from '../../contexts/SoundContextProvider';
import { AuthContext } from '../../contexts/AuthContextProvider';

// components
import { Modal, MenuItem } from './styles/DesktopModal.styled';

function DesktopModal({ isOpen }) {
  const navigate = useNavigate();

  // context
  const { logout, isLoggedIn } = React.useContext(AuthContext);

  // functions
  const handleClickSignIn = () => {
    navigate('/sign-in');
  };

  return (
    <Modal className='desktop-modal' isOpen={isOpen}>
      {/* <MenuItem>Settings</MenuItem> */}
      {isLoggedIn ? (
        <MenuItem onClick={logout}>Logout</MenuItem>
      ) : (
        <MenuItem onClick={handleClickSignIn}>Sign In</MenuItem>
      )}
    </Modal>
  );
}

export default DesktopModal;
