import styled from 'styled-components';
import constants from '../../../../styles/constants.css';

export const BoardStyled = styled.div`
  // done in gamepage, its just easier since positioning is there already
  width: 100%;
  height: ${({ boardHeight }) => `${boardHeight}px`};
  position: relative;

  .board-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // #region cards

  .boardcard-container {
    // border: 4px solid rgba(255, 255, 255, var(--zone-border-transparency)); // Semi-transparent border
    box-sizing: border-box; // Include border in element's total width and height
    height: 100%;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${(props) =>
    props.hovered &&
    !props.amPlayingCard &&
    !props.isAttacking &&
    `
    .boardcard-container {
      border: 2px solid var(--border-color-bright);
    }
  `}

  ${(props) =>
    props.amPlayingCard &&
    props.amPlayableBoard &&
    props.hovered &&
    !props.isAttacking &&
    `
    .boardcard-container {
      border: 2px solid var(--border-color-bright);
    }
  `}

  ${(props) =>
    props.amPlayingCard &&
    props.amPlayableBoard &&
    !props.isAttacking &&
    `
    // height: calc(100% / 3 - ((3 - 1) * 10px / 3));
  `}

  ${(props) =>
    props.amPlayingCard &&
    !props.amPlayableBoard &&
    !props.isAttacking &&
    `
    // height: calc(100% / 12 - ((12 - 1) * 10px / 12));
  `}

  // #endregion

  // #region enchantments

  .board-enchantments {
    position: absolute;
    width: 10%; // adjust as needed
    height: 100%;
  }

  .board-enchantments.player {
    right: 0;
  }

  .board-enchantments.opponent {
    left: 0;
  }

  // #endregion
`;
