// external
import React, { useContext, useEffect, useState } from 'react';

// internal
import { PlayerContext } from './PlayerContextProvider';
import { GameStatusContext } from './GameStatusContextProvider';
import { QueueReviewContext } from './QueueReviewContextProvider';

// lol
import { PhaseEnum } from '../../../../LegendsOfLeakos/lib/Enums/Phase';
import PayResourceCost from '../../../../LegendsOfLeakos/lib/Classes/PayResourceCost/PayResourceCost';
import { PlayerFlowEnum } from '../../../../LegendsOfLeakos/lib/Enums/PlayerFlow';
import { GamePlayState } from '../../../../LegendsOfLeakos/lib/Enums/GamePlayState';

const TurnFlowContextProvider = ({ children }) => {
  // #region Player Context

  const { masterPlayer } = useContext(PlayerContext);
  const { endQueueReview, finishedActions } = useContext(QueueReviewContext);
  const { gameStatus } = useContext(GameStatusContext);
  // #endregion

  // #region Turn Flow  Variables
  const [phaseButtonMessage, setPhaseButtonMessage] = useState('');
  const [phaseButtonActive, setPhaseButtonActive] = useState(false);
  // #endregion

  // #region Queued Mana
  const [queuedMana, setQueuedMana] = useState<PayResourceCost[]>([]);
  // #endregion

  // #region game flow logic

  useEffect(() => {
    if (!masterPlayer || masterPlayer.playerFlowEnum === null) return;

    if (masterPlayer.playerFlowEnum === PlayerFlowEnum.PlayerActing) {
      setQueuedMana([]);
    }
  }, [masterPlayer && masterPlayer.playerFlowEnum]);

  // #endregion

  // #region Phase Button Settings

  useEffect(() => {
    if (!masterPlayer) return;

    if (gameStatus !== GamePlayState.Ongoing) {
      setPhaseButtonMessage('');
      setPhaseButtonActive(false);
      return;
    }

    // reviewing queue
    if (masterPlayer.playerFlowEnum === PlayerFlowEnum.ReviewingQueue) {
      setPhaseButtonMessage('End Queue Review');
      setPhaseButtonActive(true);
      return;
    }

    if (masterPlayer.playerFlowEnum === PlayerFlowEnum.PlayerExploring) {
      setPhaseButtonMessage('Explore a land');
      setPhaseButtonActive(false);
      return;
    }

    if (
      masterPlayer.playerFlowEnum === PlayerFlowEnum.WaitingForOpponentExplore
    ) {
      setPhaseButtonMessage('Waiting for opponent');
      setPhaseButtonActive(false);
      return;
    }

    if (masterPlayer.playerFlowEnum === PlayerFlowEnum.WaitingForServer) {
      setPhaseButtonMessage('Waiting for opponent');
      setPhaseButtonActive(false);
      return;
    }

    if (masterPlayer.playerFlowEnum === PlayerFlowEnum.PlayerActing) {
      setPhaseButtonMessage('Done with actions');
      setPhaseButtonActive(true);
      return;
    }
  }, [masterPlayer && masterPlayer.playerFlowEnum]);

  // #endregion

  // #region Phase Button Actions

  const handlePhaseButtonClick = () => {
    if (!masterPlayer || !masterPlayer.gameState) return;
    if (gameStatus !== GamePlayState.Ongoing) return;

    if (masterPlayer.playerFlowEnum === PlayerFlowEnum.ReviewingQueue) {
      endQueueReview();
    } else if (masterPlayer.playerFlowEnum === PlayerFlowEnum.PlayerActing) {
      finishedActions();
    }
  };

  //#endregion

  const value = {
    phaseButtonMessage,
    setPhaseButtonMessage,
    phaseButtonActive,
    setPhaseButtonActive,
    queuedMana,
    setQueuedMana,
    handlePhaseButtonClick,
  };

  return (
    <TurnFlowContext.Provider value={value}>
      {children}
    </TurnFlowContext.Provider>
  );
};

interface TurnFlowContextType {
  phaseButtonMessage: string;
  setPhaseButtonMessage: (value: string) => void;
  phaseButtonActive: boolean;
  setPhaseButtonActive: (value: boolean) => void;
  queuedMana: PayResourceCost[];
  setQueuedMana: React.Dispatch<React.SetStateAction<PayResourceCost[]>>;
  handlePhaseButtonClick: () => void;
}

const TurnFlowContext = React.createContext<TurnFlowContextType | null>(null);

export { TurnFlowContextProvider, TurnFlowContext };
