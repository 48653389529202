// external
import React, { useState, useEffect } from 'react';

// master sizing for the game container - completely agnostic of the game
const ScreenSizeContextProvider = ({ children }) => {
  const [displayHeight, setDisplayHeight] = useState(1000);
  const [displayWidth, setDisplayWidth] = useState(1000);

  useEffect(() => {
    const handleResize = () => {
      setDisplayHeight(window.innerHeight);
      setDisplayWidth(window.innerWidth);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [masterGameContainerHeight, setMasterGameContainerHeight] =
    useState(1000);
  const [masterGameContainerWidth, setMasterGameContainerWidth] =
    useState(1000);
  const masterGameContainerMargin = 20;
  const masterGameContainerAspectRatio = 3 / 2;

  useEffect(() => {
    const allowableHeight = displayHeight - masterGameContainerMargin * 2;
    const allowableWidth = displayWidth - masterGameContainerMargin * 2;

    const maxWidth = allowableHeight * masterGameContainerAspectRatio;
    const maxHeight = allowableWidth / masterGameContainerAspectRatio;

    const impliedWidth = maxHeight * masterGameContainerAspectRatio;
    const impliedHeight = maxWidth / masterGameContainerAspectRatio;

    const width = Math.min(maxWidth, impliedWidth);
    const height = Math.min(maxHeight, impliedHeight);

    setMasterGameContainerHeight(height);
    setMasterGameContainerWidth(width);
  }, [displayHeight, displayWidth]);

  const value: ScreenSizeContextType = {
    displayHeight,
    displayWidth,
    masterGameContainerHeight,
    masterGameContainerWidth,
    masterGameContainerMargin,
  };

  return (
    <ScreenSizeContext.Provider value={value}>
      {children}
    </ScreenSizeContext.Provider>
  );
};

interface ScreenSizeContextType {
  displayHeight: number;
  displayWidth: number;
  masterGameContainerHeight: number;
  masterGameContainerWidth: number;
  masterGameContainerMargin: number;
}

const ScreenSizeContext = React.createContext(null);

export { ScreenSizeContextProvider, ScreenSizeContext };
