// external
import React, { useState, useEffect, useContext } from 'react';

// lol
import {
  BiomeDepth,
  BiomeType,
} from '../../../../LegendsOfLeakos/lib/Enums/LandAndBiome';

// image kit
const imageKit = 'https://ik.imagekit.io/hfywj4j0a/';
const defaultRes = 2500;

const resString = (res: number) => `tr:w-${res}/`;
const getIKUrl = (url: string, res: number = defaultRes) => {
  if (url.includes(imageKit)) {
    return url.replace(imageKit, imageKit + resString(res));
  } else {
    return imageKit + resString(res) + url;
  }
};

// #region background

// const border =
//   'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/backgrounds/game_border_v4.png';

const border =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/backgrounds/game_border_grey_v2.png';

const backgroundLand =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/backgrounds/background_land_v1.png';
const backgroundWater =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/backgrounds/background_water_v2.png';
const justWater =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/backgrounds/just_water_v1.png';
const noWater =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/backgrounds/no_water_v1.png';
const bgIslands =
  'https://ik.imagekit.io/hfywj4j0a/LoL/Screens/screen-background-islands.png';
const justLines =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/backgrounds/lines_v1.png';
const seafoam =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/backgrounds/seafoam_v1.png';
const bgIslandsNoWater =
  'https://ik.imagekit.io/hfywj4j0a/LoL/Screens/screen-background-islands-no-water.png';
const fog =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/backgrounds/fog_v5.png';

const navbarBackground =
  'https://ik.imagekit.io/hfywj4j0a/LoL/Navbar/navbar_background_v1.png';

const topo = 'https://ik.imagekit.io/hfywj4j0a/LoL/Screens/topo.png';
const someLand = 'https://ik.imagekit.io/hfywj4j0a/LoL/Screens/some-land.png';
const islandsForRealmBuilder =
  'https://ik.imagekit.io/hfywj4j0a/LoL/Screens/islands_for_realm_builder_v2.png';

// #endregion

// #region cards

// #region images

const cardImageRes = 1000;
const defaultCardImage =
  'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png';

// #endregion

// #region board cards

const boardCardRes = 1000;
const activeBorder =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/board_card/v2/active.png';
const inactiveBorder =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/board_card/v2/inactive.png';

// #region bottom land

const forestBottomLand =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/board_card/bottom_land/forest.png';
const mountainBottomLand =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/board_card/bottom_land/mountain2.png';
const prairieBottomLand =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/board_card/bottom_land/forest.png';
const desertBottomLand =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/board_card/bottom_land/forest.png';
const oceanBottomLand =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/board_card/bottom_land/ocean.png';
const tundraBottomLand =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/board_card/bottom_land/forest.png';
const fellsBottomLand =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/board_card/bottom_land/forest.png';

// #endregion

// #endregion

// #endregion

// #region mana

const manaRes = 1000;

const forestMana =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/mana_images/forest.png';
const mountainMana =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/mana_images/mountain.png';
const prairieMana =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/mana_images/prairie.png';
const desertMana =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/mana_images/desert.png';
const oceanMana =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/mana_images/ocean.png';
const tundraMana =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/mana_images/tundra.png';
const fellsMana =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/mana_images/fells.png';

// #endregion

// #region tiles

const tileVersion = 'v2';
const enumsToTile = (type: BiomeType, depth: BiomeDepth) => {
  if (type === BiomeType.city) {
    return `https://ik.imagekit.io/hfywj4j0a/LoL/LandTiles/${tileVersion}/city/city_0_${tileVersion}.png`;
  }
  const typeString = BiomeType[type];
  const depthString = BiomeDepth[depth];
  const num = Math.floor(Math.random() * 4);
  return `https://ik.imagekit.io/hfywj4j0a/LoL/LandTiles/${tileVersion}/${typeString}/${depthString}/${typeString}_${depthString}_${num}_${tileVersion}.png`;
};

// #endregion

// #region buttons

// #region battle button

const battleButtonRes = 1000;

const battleButtonActiveImage =
  'https://ik.imagekit.io/hfywj4j0a/LoL/Navbar/battle_button/active_swords.png';
const battleButtonInactiveImage =
  'https://ik.imagekit.io/hfywj4j0a/LoL/Navbar/battle_button/inactive_swords.png';

// #endregion

// #region banner button

const bannerButtonRes = 1000;

const bannerText =
  'https://ik.imagekit.io/hfywj4j0a/LoL/Navbar/banner_button/text.png';
const greenBackground =
  'https://ik.imagekit.io/hfywj4j0a/LoL/Navbar/banner_button/green_background.png';

// #endregion

// #region compass

const compassRes = 1000;

// banner messages

// game
const doneWithActions =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/banner_messages/done_with_actions.png';
const endQueueReview =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/banner_messages/end_queue_review.png';
const exploreALand =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/banner_messages/explore_a_land.png';
const waitingForOpponent =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/banner_messages/waiting_for_opponent.png';

// lobby screen
const ready =
  'https://ik.imagekit.io/hfywj4j0a/LoL/Screens/ready_button_messages/ready.png';
const setReady =
  'https://ik.imagekit.io/hfywj4j0a/LoL/Screens/ready_button_messages/set_ready.png';
const selectRoom =
  'https://ik.imagekit.io/hfywj4j0a/LoL/Screens/ready_button_messages/select_room.png';
const selectRealm =
  'https://ik.imagekit.io/hfywj4j0a/LoL/Screens/ready_button_messages/select_realm.png';
const selectRoomAndRealm =
  'https://ik.imagekit.io/hfywj4j0a/LoL/Screens/ready_button_messages/select_room_and_realm.png';

// inactive
const inactiveCompassStatic =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/inactive/static_inactive.png';
const inactiveCompassNorthTriangles =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/inactive/n_triangles.png';
const inactiveCompassNorthWestTriangles =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/inactive/nw_triangles.png';
const inactiveCompassSmallTriangles =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/inactive/small_triangles.png';
const inactiveCompassCenter =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/inactive/center.png';
const inactiveCompassBanner =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/inactive/banner.png';
const brownCircle =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/inactive/brown_circle.png';

// active
const activeCompassStatic =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/active/static_active.png';
const activeCompassNorthTriangles =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/active/n_triangles.png';
const activeCompassNorthWestTriangles =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/active/nw_triangles.png';
const activeCompassSmallTriangles =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/active/small_triangles.png';
const activeCompassCenter =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/active/center.png';
const activeCompassBanner =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/active/banner.png';

// hover
const hoverCompassStatic =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/hover/static.png';
const hoverCompassNorthTriangles =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/hover/n_triangles.png';
const hoverCompassNorthWestTriangles =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/hover/nw_triangles.png';
const hoverCompassSmallTriangles =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/hover/small_triangles.png';
const hoverCompassCenter =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/hover/center.png';
const hoverCompassBanner =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/hover/banner.png';
const redCircle =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/compass/hover/red_circle.png';

// #endregion

// #region realm button

const realmButtonRes = 1000;

// navbar
const realmButtonNavbarGlobe =
  'https://ik.imagekit.io/hfywj4j0a/LoL/Navbar/realm_button/earth.png';
const realmButtonNavbarBanner =
  'https://ik.imagekit.io/hfywj4j0a/LoL/Navbar/realm_button/banner.png';
const realmButtonNavbarRedEarth =
  'https://ik.imagekit.io/hfywj4j0a/LoL/Navbar/realm_button/red_earth.png';
const realmButtonNavbarRealmsText =
  'https://ik.imagekit.io/hfywj4j0a/LoL/Navbar/realm_button/realms_text.png';

// player
const playerRealmButtonGlobe =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/realm_button_player/globe.png';
const playerRealmButtonBanner =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/realm_button_player/banner.png';
const playerRealmButtonShow =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/realm_button_player/show.png';
const playerRealmButtonHide =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/realm_button_player/hide.png';
const playerRealmButtonRedEarth =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/realm_button_player/red_earth.png';

// opponent
const opponentRealmButtonGlobe =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/realm_button_opponent/globe.png';
const opponentRealmButtonBanner =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/realm_button_opponent/banner.png';
const opponentRealmButtonShow =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/realm_button_opponent/show.png';
const opponentRealmButtonHide =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/realm_button_opponent/hide.png';
const opponentRealmButtonRedEarth =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/realm_button_opponent/red_earth.png';

// #endregion

// #endregion

// #region popups

const popupRes = 1000;

const endGamePopupBackgroundDefeat =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/popups/end_game/defeat_0.png';

const endGamePopupBackgroundVictory =
  'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/popups/end_game/triumph_0.png';

// #endregion

const ImageContextProvider = ({ children }) => {
  const value = {
    enumsToTile,

    // #region cards

    defaultCardImage: getIKUrl(defaultCardImage, cardImageRes),

    // #region board cards
    activeBorder: getIKUrl(activeBorder, boardCardRes),
    inactiveBorder: getIKUrl(inactiveBorder, boardCardRes),
    // #endregion

    // #region bottom land
    forestBottomLand: getIKUrl(forestBottomLand, boardCardRes),
    mountainBottomLand: getIKUrl(mountainBottomLand, boardCardRes),
    prairieBottomLand: getIKUrl(prairieBottomLand, boardCardRes),
    desertBottomLand: getIKUrl(desertBottomLand, boardCardRes),
    oceanBottomLand: getIKUrl(oceanBottomLand, boardCardRes),
    tundraBottomLand: getIKUrl(tundraBottomLand, boardCardRes),
    fellsBottomLand: getIKUrl(fellsBottomLand, boardCardRes),

    // #endregion

    // #endregion

    // #region mana
    forestMana: getIKUrl(forestMana, manaRes),
    mountainMana: getIKUrl(mountainMana, manaRes),
    prairieMana: getIKUrl(prairieMana, manaRes),
    desertMana: getIKUrl(desertMana, manaRes),
    oceanMana: getIKUrl(oceanMana, manaRes),
    tundraMana: getIKUrl(tundraMana, manaRes),
    fellsMana: getIKUrl(fellsMana, manaRes),
    // #endregion

    // #region background
    backgroundLand: getIKUrl(backgroundLand, defaultRes),
    backgroundWater: getIKUrl(backgroundWater, defaultRes),
    justWater: getIKUrl(justWater, defaultRes),
    noWater: getIKUrl(noWater, defaultRes),
    border: getIKUrl(border, defaultRes),
    bgIslands: getIKUrl(bgIslands, defaultRes),
    bgIslandsNoWater: getIKUrl(bgIslandsNoWater, defaultRes),
    navbarBackground: getIKUrl(navbarBackground, defaultRes),
    topo: getIKUrl(topo, defaultRes),
    someLand: getIKUrl(someLand, defaultRes),
    islandsForRealmBuilder: getIKUrl(islandsForRealmBuilder, defaultRes),
    justLines: getIKUrl(justLines, defaultRes),
    seafoam: getIKUrl(seafoam, defaultRes),
    fog: getIKUrl(fog, defaultRes),
    // #endregion

    // #region battle button
    battleButtonActiveImage: getIKUrl(battleButtonActiveImage, battleButtonRes),
    battleButtonInactiveImage: getIKUrl(
      battleButtonInactiveImage,
      battleButtonRes
    ),
    // #endregion

    // #region compass
    doneWithActions: getIKUrl(doneWithActions, compassRes),
    endQueueReview: getIKUrl(endQueueReview, compassRes),
    exploreALand: getIKUrl(exploreALand, compassRes),
    waitingForOpponent: getIKUrl(waitingForOpponent, compassRes),

    setReady: getIKUrl(setReady, compassRes),
    ready: getIKUrl(ready, compassRes),
    selectRoom: getIKUrl(selectRoom, compassRes),
    selectRealm: getIKUrl(selectRealm, compassRes),
    selectRoomAndRealm: getIKUrl(selectRoomAndRealm, compassRes),

    inactiveCompassStatic: getIKUrl(inactiveCompassStatic, compassRes),
    inactiveCompassNorthTriangles: getIKUrl(
      inactiveCompassNorthTriangles,
      compassRes
    ),
    inactiveCompassNorthWestTriangles: getIKUrl(
      inactiveCompassNorthWestTriangles,
      compassRes
    ),
    inactiveCompassSmallTriangles: getIKUrl(
      inactiveCompassSmallTriangles,
      compassRes
    ),
    inactiveCompassCenter: getIKUrl(inactiveCompassCenter, compassRes),
    inactiveCompassBanner: getIKUrl(inactiveCompassBanner, compassRes),
    brownCircle: getIKUrl(brownCircle, compassRes),
    activeCompassStatic: getIKUrl(activeCompassStatic, compassRes),
    activeCompassNorthTriangles: getIKUrl(
      activeCompassNorthTriangles,
      compassRes
    ),
    activeCompassNorthWestTriangles: getIKUrl(
      activeCompassNorthWestTriangles,
      compassRes
    ),
    activeCompassSmallTriangles: getIKUrl(
      activeCompassSmallTriangles,
      compassRes
    ),
    activeCompassCenter: getIKUrl(activeCompassCenter, compassRes),
    activeCompassBanner: getIKUrl(activeCompassBanner, compassRes),

    hoverCompassStatic: getIKUrl(hoverCompassStatic, compassRes),
    hoverCompassNorthTriangles: getIKUrl(
      hoverCompassNorthTriangles,
      compassRes
    ),
    hoverCompassNorthWestTriangles: getIKUrl(
      hoverCompassNorthWestTriangles,
      compassRes
    ),
    hoverCompassSmallTriangles: getIKUrl(
      hoverCompassSmallTriangles,
      compassRes
    ),
    hoverCompassCenter: getIKUrl(hoverCompassCenter, compassRes),
    hoverCompassBanner: getIKUrl(hoverCompassBanner, compassRes),
    redCircle: getIKUrl(redCircle, compassRes),
    // #endregion

    // #region realm button
    realmButtonNavbarGlobe: getIKUrl(realmButtonNavbarGlobe, realmButtonRes),
    realmButtonNavbarBanner: getIKUrl(realmButtonNavbarBanner, realmButtonRes),
    realmButtonNavbarRedEarth: getIKUrl(
      realmButtonNavbarRedEarth,
      realmButtonRes
    ),
    realmButtonNavbarRealmsText: getIKUrl(
      realmButtonNavbarRealmsText,
      realmButtonRes
    ),

    playerRealmButtonGlobe: getIKUrl(playerRealmButtonGlobe, realmButtonRes),
    playerRealmButtonBanner: getIKUrl(playerRealmButtonBanner, realmButtonRes),
    playerRealmButtonShow: getIKUrl(playerRealmButtonShow, realmButtonRes),
    playerRealmButtonHide: getIKUrl(playerRealmButtonHide, realmButtonRes),
    playerRealmButtonRedEarth: getIKUrl(
      playerRealmButtonRedEarth,
      realmButtonRes
    ),
    opponentRealmButtonGlobe: getIKUrl(
      opponentRealmButtonGlobe,
      realmButtonRes
    ),
    opponentRealmButtonBanner: getIKUrl(
      opponentRealmButtonBanner,
      realmButtonRes
    ),
    opponentRealmButtonShow: getIKUrl(opponentRealmButtonShow, realmButtonRes),
    opponentRealmButtonHide: getIKUrl(opponentRealmButtonHide, realmButtonRes),
    opponentRealmButtonRedEarth: getIKUrl(
      opponentRealmButtonRedEarth,
      realmButtonRes
    ),
    // #endregion

    // #region popups
    endGamePopupBackgroundDefeat: getIKUrl(
      endGamePopupBackgroundDefeat,
      popupRes
    ),
    endGamePopupBackgroundVictory: getIKUrl(
      endGamePopupBackgroundVictory,
      popupRes
    ),
    // #endregion
  };

  return (
    <ImageContext.Provider value={value}>{children}</ImageContext.Provider>
  );
};

interface ImageContextInterface {
  enumsToTile: (type: BiomeType, depth: BiomeDepth) => string;

  // #region cards
  defaultCardImage: string;

  // #region board cards
  activeBorder: string;
  inactiveBorder: string;

  forestBottomLand: string;
  mountainBottomLand: string;
  prairieBottomLand: string;
  desertBottomLand: string;
  oceanBottomLand: string;
  tundraBottomLand: string;
  fellsBottomLand: string;

  // #endregion
  // #endregion

  // #region mana
  forestMana: string;
  mountainMana: string;
  prairieMana: string;
  desertMana: string;
  oceanMana: string;
  tundraMana: string;
  fellsMana: string;
  // #endregion

  // #region background
  backgroundLand: string;
  backgroundWater: string;
  justWater: string;
  noWater: string;
  border: string;
  bgIslands: string;
  bgIslandsNoWater: string;
  navbarBackground: string;
  topo: string;
  someLand: string;
  islandsForRealmBuilder: string;
  justLines: string;
  seafoam: string;
  fog: string;
  // #endregion

  // #region battle button
  battleButtonActiveImage: string;
  battleButtonInactiveImage: string;
  // #endregion

  // #region compass

  doneWithActions: string;
  endQueueReview: string;
  exploreALand: string;
  waitingForOpponent: string;

  setReady: string;
  ready: string;
  selectRoom: string;
  selectRealm: string;
  selectRoomAndRealm: string;

  inactiveCompassStatic: string;
  inactiveCompassNorthTriangles: string;
  inactiveCompassNorthWestTriangles: string;
  inactiveCompassSmallTriangles: string;
  inactiveCompassCenter: string;
  inactiveCompassBanner: string;
  brownCircle: string;

  activeCompassStatic: string;
  activeCompassNorthTriangles: string;
  activeCompassNorthWestTriangles: string;
  activeCompassSmallTriangles: string;
  activeCompassCenter: string;
  activeCompassBanner: string;

  hoverCompassStatic: string;
  hoverCompassNorthTriangles: string;
  hoverCompassNorthWestTriangles: string;
  hoverCompassSmallTriangles: string;
  hoverCompassCenter: string;
  hoverCompassBanner: string;
  redCircle: string;
  // #endregion

  // #region realm button
  realmButtonNavbarGlobe: string;
  realmButtonNavbarBanner: string;
  realmButtonNavbarRedEarth: string;
  realmButtonNavbarRealmsText: string;

  playerRealmButtonGlobe: string;
  playerRealmButtonBanner: string;
  playerRealmButtonShow: string;
  playerRealmButtonHide: string;
  playerRealmButtonRedEarth: string;

  opponentRealmButtonGlobe: string;
  opponentRealmButtonBanner: string;
  opponentRealmButtonShow: string;
  opponentRealmButtonHide: string;
  opponentRealmButtonRedEarth: string;
  // #endregion

  // #region popups
  endGamePopupBackgroundDefeat: string;
  endGamePopupBackgroundVictory: string;
  // #endregion
}

const ImageContext = React.createContext<ImageContextInterface>(null);

export { ImageContextProvider, ImageContext };
