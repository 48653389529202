// external
import React, { useContext } from 'react';

// internal
import { AttackingContext } from '../../../contexts/AttackingContextProvider';
import { CardRefContext } from '../../../contexts/CardRefContextProvider';

// components
import Arrow from '../Arrow/Arrow.jsx';

interface BlockLineData {
  blockingCardInstanceId: number;
  blockedCardInstanceId: number;
}

const BlockLine = ({ blockLineData }: { blockLineData: BlockLineData }) => {
  const { cardRefData } = useContext(CardRefContext);
  const { cancelBlock } = useContext(AttackingContext);

  const getCardPosition = (cardInstanceId) => {
    const cardRef = cardRefData.find(
      (card) => card.cardInstanceId === cardInstanceId
    );
    if (!cardRef || !cardRef.ref?.current) {
      return null;
    }
    const rect = cardRef.ref.current.getBoundingClientRect();
    return {
      x: rect.left + rect.width / 2,
      y: rect.top + rect.height / 2,
    };
  };

  const blockingCardPosition = getCardPosition(
    blockLineData.blockingCardInstanceId
  );
  const blockedCardPosition = getCardPosition(
    blockLineData.blockedCardInstanceId
  );

  if (!blockingCardPosition || !blockedCardPosition) {
    return null;
  }

  return (
    <Arrow
      from={blockingCardPosition}
      to={blockedCardPosition}
      cancelAction={() => cancelBlock(blockLineData.blockingCardInstanceId)}
      type='block'
    />
  );
};

export default BlockLine;
