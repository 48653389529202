import { ZoneOwnerVisibility, ZoneOpponentVisibility, } from '../../Enums/Zone.js';
import RuntimeCard from '../Card/RuntimeCard.js';
import RuntimeEnchantment from '../Enchantment/RuntimeEnchantment.js';
import TargetableRuntimeEntity from '../Entity/TargetableRuntimeEntity.js';
import GameProperties from '../Game/GameProperties.js';
class RuntimeZone extends TargetableRuntimeEntity {
    // I don't like the idea of these - it's hard to track and hard to copy
    // onZoneChanged: (numCards: number) => void;
    // onCardAdded: (card: RuntimeCard) => void;
    // onCardRemoved: (card: RuntimeCard) => void;
    constructor(instanceId, name, zoneEnum, ownerPlayerUserId, cards, enchantments) {
        super();
        this.cards = [];
        this.enchantments = [];
        // #endregion
        // #region JSON
        this.clone = () => {
            const clone = new RuntimeZone(this.instanceId, this.name, this.zoneEnum, this.ownerPlayerUserId, this.cards.map((card) => card.clone()), this.enchantments.map((enchantment) => RuntimeEnchantment.fromRuntimeJSON(enchantment.toJSON())));
            return clone;
        };
        this.instanceId = instanceId;
        this.name = name;
        this.zoneEnum = zoneEnum;
        this.ownerPlayerUserId = ownerPlayerUserId;
        this.cards = cards;
        this.enchantments = enchantments;
    }
    preResolveEffect(e, sourceEntityInstanceId, gameState, targetInfoList) {
        const finalResults = [];
        for (const enchantment of this.enchantments) {
            const results = enchantment.preResolveEffect(e, sourceEntityInstanceId, gameState, targetInfoList);
            finalResults.push(...results);
        }
        for (const card of this.cards) {
            const results = card.preResolveEffect(e, sourceEntityInstanceId, gameState, targetInfoList);
            finalResults.push(...results);
        }
        return finalResults;
    }
    postResolveEffect(e, sourceEntityInstanceId, gameState, targetInfoList) {
        const finalResults = [];
        for (const enchantment of this.enchantments) {
            const results = enchantment.postResolveEffect(e, sourceEntityInstanceId, gameState, targetInfoList);
            finalResults.push(...results);
        }
        for (const card of this.cards) {
            const results = card.postResolveEffect(e, sourceEntityInstanceId, gameState, targetInfoList);
            finalResults.push(...results);
        }
        return finalResults;
    }
    // #region add and remove cards
    addCard(card) {
        const cardToAdd = this.cards.find((c) => c.instanceId === card.instanceId);
        if (!!cardToAdd) {
            console.log(`ERROR: RuntimeZone.getLibraryZone: Cannot add card ${card.instanceId} to zone ${this.instanceId} because it already exists in that zone`);
            return;
        }
        if (this.getLibraryZone().hasMaxCards &&
            this.cards.length >= this.getLibraryZone().maxCards) {
            console.log(`Cannot add card ${card.instanceId} to zone ${this.instanceId} because it is full`);
            return;
        }
        this.cards.push(card);
        card.residingZoneInstanceId = this.instanceId;
    }
    addCardCreatedByEffect(card) {
        const cardToAdd = this.cards.find((c) => c.instanceId === card.instanceId);
        if (cardToAdd === undefined) {
            this.addCard(card);
        }
    }
    removeCard(card) {
        const cardToRemove = this.cards.find((c) => c.instanceId === card.instanceId);
        if (cardToRemove === undefined) {
            return;
        }
        this.cards = this.cards.filter((c) => c.instanceId !== cardToRemove.instanceId);
        // if (this.onZoneChanged) {
        //   this.onZoneChanged(this.numCards);
        // }
        // if (this.onCardRemoved) {
        //   this.onCardRemoved(card);
        // }
    }
    // #endregion
    // #region utilities
    getLibraryZone() {
        const lib = GameProperties.gameZones.find((z) => z.zoneEnum === this.zoneEnum);
        if (!lib) {
            console.log(`ERROR: RuntimeZone.getLibraryZone: Could not find library zone for ${this.zoneEnum}`);
            return;
        }
        return lib;
    }
    toJSONFromRuntimeCopyAllCards() {
        return {
            instanceId: this.instanceId,
            name: this.name,
            zoneEnum: this.zoneEnum,
            ownerPlayerUserId: this.ownerPlayerUserId,
            cards: this.cards.map((c) => c.toJSON()),
            enchantments: this.enchantments.map((e) => e.toJSON()),
        };
    }
    toJSONForPlayer() {
        const libraryZone = this.getLibraryZone();
        const showCards = libraryZone.ownerVisibility === ZoneOwnerVisibility.Visible;
        return {
            instanceId: this.instanceId,
            name: this.name,
            zoneEnum: this.zoneEnum,
            ownerPlayerUserId: this.ownerPlayerUserId,
            cards: showCards ? this.cards.map((c) => c.toJSON()) : [],
            enchantments: this.enchantments.map((e) => e.toJSON()),
        };
    }
    toJSONForOpponent() {
        const libraryZone = this.getLibraryZone();
        const showCards = libraryZone.opponentVisibility === ZoneOpponentVisibility.Visible;
        return {
            instanceId: this.instanceId,
            name: this.name,
            zoneEnum: this.zoneEnum,
            ownerPlayerUserId: this.ownerPlayerUserId,
            cards: showCards ? this.cards.map((c) => c.toJSON()) : [],
            enchantments: this.enchantments.map((e) => e.toJSON()),
        };
    }
    static fromRuntimeJSON(json) {
        const zone = new RuntimeZone(json.instanceId, json.name, json.zoneEnum, json.ownerPlayerUserId, json.cards.map((c) => RuntimeCard.fromRuntimeJSON(c)), json.enchantments.map((e) => RuntimeEnchantment.fromRuntimeJSON(e)));
        return zone;
    }
    //** Create a RuntimeZone from a Library Zone - NO cards or enchantments */
    static fromLibraryJSON(json) {
        const zone = new RuntimeZone(json.instanceId, json.name, json.zoneEnum, json.ownerPlayerUserId, [], // cards
        [] // enchantments
        );
        return zone;
    }
}
export default RuntimeZone;
