// a component that displays a player's mana
// external
import React, { useState, useEffect, useContext } from 'react';

// internal components
import { PlayerContext } from '../../../contexts/PlayerContextProvider';
import { TurnFlowContext } from '../../../contexts/TurnFlowContextProvider';
import ManaCircle from './ManaCircle';

// css
import { ManaBarStyled } from './styles/ManaBar.styled.js';

// lol
import PlayerInfo from '../../../../../../LegendsOfLeakos/lib/Classes/Player/PlayerInfo';
function ManaBar({ isPlayer }) {
  const { playerInfo, opponentInfo } = useContext(PlayerContext);
  const currentInfo = isPlayer ? playerInfo : opponentInfo;

  return (
    <ManaBarStyled className='mana-bar' isPlayer={isPlayer}>
      <div className='mana-container'>
        <ManaCircle
          isPlayer={isPlayer}
          playerManaStat={currentInfo?.nameToStat.get('ForestMana')}
        />
        <ManaCircle
          isPlayer={isPlayer}
          playerManaStat={currentInfo?.nameToStat.get('OceanMana')}
        />
        <ManaCircle
          isPlayer={isPlayer}
          playerManaStat={currentInfo?.nameToStat.get('DesertMana')}
        />
        <ManaCircle
          isPlayer={isPlayer}
          playerManaStat={currentInfo?.nameToStat.get('MountainMana')}
        />
        <ManaCircle
          isPlayer={isPlayer}
          playerManaStat={currentInfo?.nameToStat.get('PrairieMana')}
        />
        <ManaCircle
          isPlayer={isPlayer}
          playerManaStat={currentInfo?.nameToStat.get('FellsMana')}
        />
        <ManaCircle
          isPlayer={isPlayer}
          playerManaStat={currentInfo?.nameToStat.get('TundraMana')}
        />
      </div>
    </ManaBarStyled>
  );
}

export default ManaBar;
