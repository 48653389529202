import { ClientMessage } from '../MessageBase.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
export default class ReorderBlockingCardMessage extends ClientMessage {
    constructor(messageId, senderUserId, blockingCardInstanceId, newBlockingPosition) {
        super(messageId, senderUserId);
        this.messageEnum = NetworkProtocol.ReorderBlockingCard;
        this.blockingCardInstanceId = blockingCardInstanceId;
        this.newBlockingPosition = newBlockingPosition;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { blockingCardInstanceId: this.blockingCardInstanceId, newBlockingPosition: this.newBlockingPosition });
    }
    static fromJSON(json) {
        return new ReorderBlockingCardMessage(json.messageId, json.senderUserId, json.blockingCardInstanceId, json.newBlockingPosition);
    }
    // check that all fields in the message are valid
    validate() {
        return (super.validate() &&
            this.blockingCardInstanceId != null &&
            this.newBlockingPosition != null);
    }
}
ClientMessage.registerFromJSON(NetworkProtocol.ReorderBlockingCard, ReorderBlockingCardMessage.fromJSON);
