import { ServerMessage } from '../../MessageBase.js';
import { NetworkProtocol } from '../../../../Enums/NetworkProtocol.js';
/**
 * This message is for cards the player requested to be moved (currently, always between rows,
 * as cards that were played have their own message)
 */
export default class ServerCardMovedRowMessage extends ServerMessage {
    constructor(recipientUserId, ownerPlayerUserId, movedCardInstanceId, originZoneEnum, destinationZoneEnum, queuePosition) {
        super(recipientUserId);
        this.messageEnum = NetworkProtocol.CardMoved;
        this.ownerPlayerUserId = ownerPlayerUserId;
        this.movedCardInstanceId = movedCardInstanceId;
        this.originZoneEnum = originZoneEnum;
        this.destinationZoneEnum = destinationZoneEnum;
        this.queuePosition = queuePosition;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { ownerPlayerUserId: this.ownerPlayerUserId, movedCardInstanceId: this.movedCardInstanceId, originZoneEnum: this.originZoneEnum, destinationZoneEnum: this.destinationZoneEnum, queuePosition: this.queuePosition });
    }
    static fromJSON(json) {
        return new ServerCardMovedRowMessage(json.recipientUserId, json.ownerPlayerUserId, json.movedCardInstanceId, json.originZoneEnum, json.destinationZoneEnum, json.queuePosition);
    }
    // check that all fields in the message are valid
    validate() {
        return (this.recipientUserId != null &&
            this.ownerPlayerUserId != null &&
            this.movedCardInstanceId != null &&
            this.originZoneEnum != null &&
            this.destinationZoneEnum != null &&
            this.queuePosition != null);
    }
}
