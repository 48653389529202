// external
import React, { useContext } from 'react';

// internal
import { AttackingContext } from '../../../contexts/AttackingContextProvider';
import { CardRefContext } from '../../../contexts/CardRefContextProvider';

// components
import Arrow from '../Arrow/Arrow.jsx';

interface AttackLineData {
  attackingCardInstanceId: number;
  defendingCardInstanceId: number;
}

const AttackLine = ({ attackLineData }: { attackLineData: AttackLineData }) => {
  const { cardRefData } = useContext(CardRefContext);
  const { cancelAttack } = useContext(AttackingContext);

  const getCardPosition = (cardInstanceId) => {
    const cardRef = cardRefData.find(
      (card) => card.cardInstanceId === cardInstanceId
    );
    if (!cardRef || !cardRef.ref?.current) {
      return null;
    }
    const rect = cardRef.ref.current.getBoundingClientRect();
    return {
      x: rect.left + rect.width / 2,
      y: rect.top + rect.height / 2,
    };
  };

  const attackingCardPosition = getCardPosition(
    attackLineData.attackingCardInstanceId
  );
  const defendingCardPosition = getCardPosition(
    attackLineData.defendingCardInstanceId
  );

  if (!attackingCardPosition || !defendingCardPosition) {
    console.log('ERROR: Could not find card position for attack line');
    return null;
  }

  return (
    <Arrow
      from={attackingCardPosition}
      to={defendingCardPosition}
      cancelAction={() => cancelAttack(attackLineData.attackingCardInstanceId)}
      type='attack'
    />
  );
};

export default AttackLine;
