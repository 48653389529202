import styled from 'styled-components';
import '../../../../styles/constants.css';

export const BoardEnchantmentItemStyled = styled.div`
  position: relative;
  border: 1px solid
    ${({ amActive }) =>
      amActive ? 'var(--border-color-bright)' : 'var(--border-color-dim)'};
  width: 100%;
  min-height: calc(${({ cardWidth }) => `${cardWidth}px`} / 3);
  padding: calc(${({ cardWidth }) => `${cardWidth}px`} / 20);
  font-size: calc(${({ cardWidth }) => `${cardWidth}px`} / 7);
  text-align: center;
  background-color: black;
  color: white;
  box-sizing: border-box;

  .enchantment-item-content {
    height: 100%;
    width: 100%;
    border: 1px solid black;
  }

  .keywords-and-abilities {
    position: absolute;
    top: 0;
    ${({ isPlayer }) => (isPlayer ? 'right: 100%;' : 'left: 100%;')}
  }

  .keywords-and-abilities.invisible {
    display: none;
  }

  .keywords {
    padding: calc(${({ cardWidth }) => `${cardWidth}px`} / 20);
    width: ${({ cardWidth }) => `${cardWidth}px`};
    height: calc(${({ cardWidth }) => `${cardWidth}px`} / 3);
    font-size: calc(${({ cardWidth }) => `${cardWidth}px`} / 7);
    text-align: center;
    background-color: black;
    color: white;
    z-index: 2;
    pointer-events: none;
  }

  .abilities {
    z-index: 2;
  }
`;
