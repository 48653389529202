import React, { useEffect } from 'react';

// components
import RegisterComponent from './RegisterComponent';
import Navbar from '../../Navbar/Navbar';
import Fog from '../../Tidings/Fog';

// context
import { ImageContext } from '../../../contexts/ImageContextProvider';

// css
import { RegisterPageStyled } from './styles/RegisterPage.styled';

function RegisterPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // context
  const { justWater, endGamePopupBackgroundVictory } =
    React.useContext(ImageContext);

  return (
    <RegisterPageStyled
      className='register-page'
      background={justWater}
      mainBackground={endGamePopupBackgroundVictory}
      height={window.innerHeight}
    >
      <Navbar />
      <Fog />
      <div className='main-content'>
        <RegisterComponent />
      </div>
    </RegisterPageStyled>
  );
}

export default RegisterPage;
