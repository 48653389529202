import { ServerMessage } from '../../MessageBase.js';
import RuntimeCard from '../../../Card/RuntimeCard.js';
import PayResourceCost from '../../../PayResourceCost/PayResourceCost.js';
import TargetInfo from '../../../Target/TargetInfo.js';
import { NetworkProtocol } from '../../../../Enums/NetworkProtocol.js';
export default class CardPlayedMessage extends ServerMessage {
    constructor(recipientUserId, sourcePlayerUserId, card, originZoneZoneEnum, destinationZoneZoneEnum, paidCosts, targetInfoList, queuePosition, info) {
        super(recipientUserId);
        this.messageEnum = NetworkProtocol.CardPlayed;
        this.sourcePlayerUserId = sourcePlayerUserId;
        this.card = card;
        this.originZoneZoneEnum = originZoneZoneEnum;
        this.destinationZoneZoneEnum = destinationZoneZoneEnum;
        this.paidCosts = paidCosts;
        this.targetInfoList = targetInfoList;
        this.queuePosition = queuePosition;
        this.info = info;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { sourcePlayerUserId: this.sourcePlayerUserId, card: this.card.toJSON(), originZoneZoneEnum: this.originZoneZoneEnum, destinationZoneZoneEnum: this.destinationZoneZoneEnum, paidCosts: this.paidCosts.map((cost) => cost.toJSON()), targetInfoList: this.targetInfoList.map((info) => info.toJSON()), queuePosition: this.queuePosition, info: this.info });
    }
    static fromJSON(json) {
        const paidCosts = json.paidCosts.map(PayResourceCost.fromJSON);
        const targetInfoList = json.targetInfoList.map(TargetInfo.fromJSON);
        return new CardPlayedMessage(json.recipientUserId, json.sourcePlayerUserId, RuntimeCard.fromRuntimeJSON(json.card), json.originZoneZoneEnum, json.destinationZoneZoneEnum, paidCosts.map((cost) => PayResourceCost.fromJSON(cost)), targetInfoList.map((info) => TargetInfo.fromJSON(info)), json.queuePosition, json.info);
    }
    // check that all fields in the message are valid
    validate() {
        return (this.recipientUserId != null &&
            this.sourcePlayerUserId != null &&
            this.card != null &&
            this.originZoneZoneEnum != null &&
            this.destinationZoneZoneEnum != null &&
            this.paidCosts != null &&
            this.targetInfoList != null &&
            this.queuePosition != null &&
            this.info != null);
    }
}
