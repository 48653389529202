import React, { useContext } from 'react';

// components
import TileScroller from '../../../UtilityComponents/TileScroller.jsx';
import RealmTile from './RealmTile.jsx';

// contexts
import { ImageContext } from '../../../../contexts/ImageContextProvider';

// css
import { RealmSelectionStyled } from './styles/RealmSelection.styled.js';

function RealmSelection({ realms, selectRealm, selectedRealmID }) {
  const { bgIslands } = useContext(ImageContext);

  return (
    <RealmSelectionStyled
      className='realm-selection'
      backgroundImage={bgIslands}
    >
      <h3 className='underlined-title'>Choose your Realm</h3>
      <div className='realm-container'>
        {realms.map((realm, index) => (
          <RealmTile
            key={realm._id + index}
            realm={realm}
            selectRealm={selectRealm}
            selected={realm._id === selectedRealmID}
          />
        ))}
      </div>
    </RealmSelectionStyled>
  );
}

export default RealmSelection;
