import styled from 'styled-components';

export const DesktopNavbarStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  z-index: 9999;

  .title-button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .card-builder {
    position: absolute;
    left: 20%;
  }

  .battle-button {
    position: absolute;
    right: 8%;
    top: 20%;
  }

  .realm-button {
    position: absolute;
    top: -33%;
    left: 5.3%;
  }

  .menu-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }

  .clickable-link {
    color: #fcaa03;
    line-height: var(--navbar-height);
    display: table;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
    transition: 0.5s ease;
  }
`;
