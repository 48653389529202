import styled from 'styled-components';
import '../../../styles/constants.css';

export const RealmWrapperStyled = styled.div`
  // height: set based on available height inline
  width: 100%;
  display: inline-block;
  position: relative;
  overflow: visible;

  .realm-map-wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 40px;
    overflow: visible;
  }
  .hoverable.realm-map-wrapper:hover {
    .realm-map {
      transform: scale(1.02);
      -webkit-transform: scale(1.02);
    }
  }

  .realm-map {
    height: 100%;
    transition: 0.5s ease;
  }

  .background-islands {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    // width: calc(100% - 20px);
    height: 1000px;
    width: 190%;
    z-index: -1;
    pointer-events: none;
  }
`;
