import { ClientMessage } from '../MessageBase.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
export default class RejoinedGameMessage extends ClientMessage {
    constructor(messageId) {
        super(messageId, 'client rejoining game - will get a user id from server');
        this.messageEnum = NetworkProtocol.RejoinedGame;
    }
    toJSON() {
        return Object.assign({}, super.toJSON());
    }
    static fromJSON(json) {
        return new RejoinedGameMessage(json.messageId);
    }
    // check that all fields in the message are valid
    validate() {
        return this.senderUserId !== undefined && super.validate();
    }
}
ClientMessage.registerFromJSON(NetworkProtocol.RejoinedGame, RejoinedGameMessage.fromJSON);
