// external
import React, { useEffect } from 'react';

// components
import AllowlistComponent from './AllowlistComponent';
import Navbar from '../../Navbar/Navbar';
import Fog from '../../Tidings/Fog';

// context
import { ImageContext } from '../../../contexts/ImageContextProvider';

// css
import { AllowlistPageStyled } from './styles/AlllowlistPage.styled.js';

function AllowlistPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // context
  const { justWater, endGamePopupBackgroundVictory } =
    React.useContext(ImageContext);

  return (
    <AllowlistPageStyled
      className='allowlist-page'
      background={justWater}
      mainBackground={endGamePopupBackgroundVictory}
      height={window.innerHeight}
    >
      <Navbar />
      <Fog />
      <div className='main-content'>
        <AllowlistComponent />
      </div>
    </AllowlistPageStyled>
  );
}

export default AllowlistPage;
