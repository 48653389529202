import React, { useState, useEffect, useContext } from 'react';

// lol
import RuntimeEffect from '../../../../../../LegendsOfLeakos/lib/Classes/Effect/RuntimeEffect.js';
import LibraryEffect from '../../../../../../LegendsOfLeakos/lib/Classes/Effect/LibraryEffect.js';
import { EffectType } from '../../../../../../LegendsOfLeakos/lib/Enums/Effect.js';

// internal
// hooks
import { CardLibraryContext } from '../../../contexts/CardLibraryContextProvider';

// components
// util comps
import DataEditor from '../Data/DataEditor.jsx';
import Intcreaser from '../Utility/Intcreaser.jsx';
import TitledIntcreaser from '../Utility/TitledIntcreaser.jsx';
import TitledCycler from '../Utility/TitledCycler.jsx';

function EffectItem({ effect, saveEffect }) {
  const { enchantmentLibrary } = useContext(CardLibraryContext);
  LibraryEffect;
  return (
    <div className='effect-item-content'>
      <TitledCycler
        title='Effect Type'
        items={Object.keys(RuntimeEffect.sampleLibraryEffectMethods).map(
          (item) => {
            return EffectType[item];
          }
        )}
        onItemChange={(effectTypeString: string) => {
          const newEffect = RuntimeEffect.createSampleLibraryEffect(
            EffectType[effectTypeString as keyof typeof EffectType]
          );
          saveEffect(newEffect);
        }}
        currentItem={EffectType[effect.effectType]}
      />
      <DataEditor
        data={effect.data}
        save={(data) => {
          const newEffect = effect.clone();
          newEffect.data = data;
          saveEffect(newEffect);
        }}
      />
    </div>
  );
}

export default EffectItem;
