import { ClientMessage } from '../MessageBase.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
export default class CardIsBlockingMessage extends ClientMessage {
    constructor(messageId, senderUserId, blockingCardInstanceId, blockedCardInstanceId, blockOrder) {
        super(messageId, senderUserId);
        this.messageEnum = NetworkProtocol.CardIsBlocking;
        this.blockingCardInstanceId = blockingCardInstanceId;
        this.blockedCardInstanceId = blockedCardInstanceId;
        this.blockOrder = blockOrder;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { blockingCardInstanceId: this.blockingCardInstanceId, blockedCardInstanceId: this.blockedCardInstanceId, blockOrder: this.blockOrder });
    }
    static fromJSON(json) {
        return new CardIsBlockingMessage(json.messageId, json.senderUserId, json.blockingCardInstanceId, json.blockedCardInstanceId, json.blockOrder);
    }
    // check that all fields in the message are valid
    validate() {
        return (super.validate() &&
            this.blockingCardInstanceId != null &&
            this.blockedCardInstanceId != null &&
            this.blockOrder != null);
    }
}
ClientMessage.registerFromJSON(NetworkProtocol.CardIsBlocking, CardIsBlockingMessage.fromJSON);
