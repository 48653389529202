import { ClientMessage } from '../MessageBase.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
export default class PlayerExploredLandMessage extends ClientMessage {
    constructor(messageId, senderUserId, explored, landTileId) {
        super(messageId, senderUserId);
        this.messageEnum = NetworkProtocol.PlayerExploredLand;
        this.explored = explored;
        this.landTileId = landTileId;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { explored: this.explored, landTileId: this.landTileId });
    }
    static fromJSON(json) {
        return new PlayerExploredLandMessage(json.messageId, json.senderUserId, json.explored, json.landTileId);
    }
    // check that all fields in the message are valid
    validate() {
        return super.validate() && this.explored != null && this.landTileId != null;
    }
}
ClientMessage.registerFromJSON(NetworkProtocol.PlayerExploredLand, PlayerExploredLandMessage.fromJSON);
