import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const CardDisplayStyled = styled.div`
  height: calc(100vh - var(--navbar-height) - 3 * var(--screen-margin));
  width: calc(100% - 20px);
  padding: 10px 40px;
  margin: 0 10px;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 0;

  border: 1px solid var(--border-color-bright);
  background-color: var(--light-green);

  background-image: ${({ background }) => `url(${background})`};
  background-size: 100% auto;
  background-repeat: repeat-y;

  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px 5%;
  }

  .filter-label {
    display: inline-block;
    margin-right: 10px;
    color: var(--lol-gold);
    transition: color 0.3s ease, transform 0.3s ease;
    font-size: 1.2em;
    font-family: var(--logo-font);
  }

  .filter-label:hover {
    color: red;
    transform: scale(1.2);
  }

  .filter-label.active {
    color: red;
    transform: scale(1.2);
  }
`;
