var PlayerFlowEnum;
(function (PlayerFlowEnum) {
    PlayerFlowEnum[PlayerFlowEnum["PlayerExploring"] = 0] = "PlayerExploring";
    PlayerFlowEnum[PlayerFlowEnum["WaitingForOpponentExplore"] = 1] = "WaitingForOpponentExplore";
    PlayerFlowEnum[PlayerFlowEnum["PlayerActing"] = 2] = "PlayerActing";
    PlayerFlowEnum[PlayerFlowEnum["WaitingForServer"] = 3] = "WaitingForServer";
    PlayerFlowEnum[PlayerFlowEnum["ProcessingQueue"] = 4] = "ProcessingQueue";
    PlayerFlowEnum[PlayerFlowEnum["ReviewingQueue"] = 5] = "ReviewingQueue";
})(PlayerFlowEnum || (PlayerFlowEnum = {}));
export { PlayerFlowEnum };
