import { ServerMessage } from '../../MessageBase.js';
import PlayerInfo from '../../../Player/PlayerInfo.js';
import GameManager from '../../../Game/GameManager.js';
import { NetworkProtocol } from '../../../../Enums/NetworkProtocol.js';
export default class StartGameMessage extends ServerMessage {
    constructor(recipientUserId, playerIndex, turnDuration, names, player, opponent, rngSeed, gameManager) {
        super(recipientUserId);
        this.messageEnum = NetworkProtocol.StartGame;
        this.playerIndex = playerIndex;
        this.turnDuration = turnDuration;
        this.names = names;
        this.player = player;
        this.opponent = opponent;
        this.rngSeed = rngSeed;
        this.gameManager = gameManager;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { playerIndex: this.playerIndex, turnDuration: this.turnDuration, names: this.names, player: this.player.toJSONForPlayer(), opponent: this.opponent.toJSONForOpponent(), rngSeed: this.rngSeed, gameManger: this.gameManager.toJSON() });
    }
    static fromJSON(json) {
        return new StartGameMessage(json.recipientUserId, json.playerIndex, json.turnDuration, json.names, PlayerInfo.fromRuntimeJSON(json.player), PlayerInfo.fromRuntimeJSON(json.opponent), json.rngSeed, GameManager.fromJSON(json.gameManger));
    }
    // check that all fields in the message are valid
    validate() {
        return (this.recipientUserId != null &&
            this.playerIndex != null &&
            this.turnDuration != null &&
            this.names != null &&
            this.player != null &&
            this.opponent != null &&
            this.rngSeed != null &&
            this.gameManager != null);
    }
}
