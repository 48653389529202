import styled from 'styled-components';
import '../../../styles/constants.css';

export const FogStyled = styled.div`
  .fog {
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    pointer-events: none;
    user-select: none;
  }

  .fog-one {
    top: 0;
    left: 0;

    img {
      opacity: 0.5;
      position: absolute;
      width: auto;
      min-width: 100%;
      height: 100%;
      animation: scrollFog 60s linear infinite;
    }
  }

  .fog-two {
    top: 30%;
    left: 0;

    img {
      opacity: 0.5;
      position: absolute;
      width: auto;
      min-width: 100%;
      height: 100%;
      animation: scrollFog 45s linear infinite;
    }
  }

  .fog-three {
    top: -15%;
    left: 0;

    img {
      opacity: 0.5;
      position: absolute;
      width: auto;
      min-width: 100%;
      height: 100%;
      animation: scrollFog 30s linear infinite;
    }
  }

  .fog-four {
    top: 10%;
    left: 0;

    img {
      opacity: 0.5;
      position: absolute;
      width: auto;
      min-width: 100%;
      height: 100%;
      animation: scrollFog 15s linear infinite;
    }
  }

  @keyframes scrollFog {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-50%);
    }
  }

  @media (max-width: 768px) {
    .fog img {
      animation-duration: 75s; // Slower animation might be smoother
    }
  }

  // #endregion fog
`;
