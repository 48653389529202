import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const EndGamePopupStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 1000;

  .all-content {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
  }

  .toggle-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: all;
  }

  .background-dim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }

  .inner-popup-content {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: ${(props) => props.gameContainerHeight * 0.5}px;
    width: ${(props) => props.gameContainerWidth * 0.5}px;
    border: 1px solid var(--lol-gold);

    background-image: ${({ background }) => `url(${background})`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    z-index: 1;
  }

  .game-result-text {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    font-family: var(--logo-font);
    font-size: ${(props) => props.gameContainerHeight * 0.15}px;
    font-weight: bold;
    color: var(--lol-red);
    text-align: center;

    z-index: 1;
  }

  .buttons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) translateY(${(props) =>
      props.gameContainerHeight * 0.4}px); 
    display: flex;
    justify-content: center;
    height: ${(props) => props.gameContainerHeight * 0.05}px;
    width: ${(props) => props.gameContainerWidth * 0.5}px;

    z-index: 10;
    pointer-events: all;
  }

  .popup-button {
    font-family: var(--logo-font);
    font-size: ${(props) => props.gameContainerHeight * 0.02}px;
    margin 0 2%;
    padding: 1%;
    background-color: var(--dark-green);
    color: var(--lol-gold);
    border: 1px solid var(--lol-gold);
    cursor: pointer;
    width: ${(props) => props.gameContainerWidth * 0.15}px;
  }
  .popup-button:hover {
    background-color: var(--lol-red);
  }

  // #endregion end game popup
`;
