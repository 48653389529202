import styled from 'styled-components';
import '../../../../styles/constants.css';

const CancelButtonStyled = styled.div.attrs((props) => ({
  style: {
    left: `${props.cancelPoint.x}px`,
    top: `${props.cancelPoint.y}px`,
  },
}))`
  position: absolute;
  background-color: white;
  transform: translate(-50%, -50%);
  width: ${(props) => props.boardCardWidth / 5}px;
  height: ${(props) => props.boardCardWidth / 5}px;
  pointer-events: all;
  z-index: 600;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: black;

  &:hover {
    background-color: red; /* Change background color to red on hover */
  }
`;

const ArrowStyled = styled.div`
  pointer-events: none;
  z-index: 9999;

  .svg-arrow {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    stroke: ${({ type }) => {
      switch (type) {
        case 'attack':
          return 'red';
        case 'block':
          return 'white';
        case 'ability':
          return 'var(--lol-gold)';
        default:
          return 'white';
      }
    }};
  }
`;

export { CancelButtonStyled, ArrowStyled };
