import { ServerMessage } from '../../MessageBase.js';
import PlayerInfo from '../../../Player/PlayerInfo.js';
import { NetworkProtocol } from '../../../../Enums/NetworkProtocol.js';
export default class NextPhaseReadyMessage extends ServerMessage {
    constructor(recipientUserId, turn, phaseIndex, player, opponent) {
        super(recipientUserId);
        this.messageEnum = NetworkProtocol.NextPhaseReady;
        this.turn = turn;
        this.phaseIndex = phaseIndex;
        this.player = player;
        this.opponent = opponent;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { turn: this.turn, phaseIndex: this.phaseIndex, player: this.player.toJSONForPlayer(), opponent: this.opponent.toJSONForOpponent() });
    }
    static fromJSON(json) {
        return new NextPhaseReadyMessage(json.recipientUserId, json.turn, json.phaseIndex, PlayerInfo.fromRuntimeJSON(json.player), PlayerInfo.fromRuntimeJSON(json.opponent));
    }
    // check that all fields in the message are valid
    validate() {
        return (this.recipientUserId != null &&
            this.turn != null &&
            this.phaseIndex != null &&
            this.player != null &&
            this.opponent != null);
    }
}
