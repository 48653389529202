import React, { useContext } from 'react';

// internal
import { ImageContext } from '../../../contexts/ImageContextProvider';
import { GameElementSizeContext } from '../../../contexts/GameElementSizeContextProvider';

// css
import { ManaCircleStyled } from './styles/ManaCircle.styled.js';

function ManaCircle({ cost, costName }) {
  const {
    forestMana,
    oceanMana,
    desertMana,
    mountainMana,
    prairieMana,
    fellsMana,
    tundraMana,
  } = React.useContext(ImageContext);
  const { cardHeight, cardWidth } = useContext(GameElementSizeContext);

  const imageName = (manaName) => {
    if (manaName === 'ForestMana') return forestMana;
    if (manaName === 'OceanMana') return oceanMana;
    if (manaName === 'DesertMana') return desertMana;
    if (manaName === 'MountainMana') return mountainMana;
    if (manaName === 'PrairieMana') return prairieMana;
    if (manaName === 'FellsMana') return fellsMana;
    if (manaName === 'TundraMana') return tundraMana;
  };

  return (
    <ManaCircleStyled
      className='mana-circle'
      active={cost > 0}
      backgroundImage={imageName(costName)}
      cardWidth={cardWidth}
    >
      <div className='cost-text'>{!!cost ? cost : 0}</div>
    </ManaCircleStyled>
  );
}

export default ManaCircle;
