import styled from 'styled-components';

export const ConditionItemStyled = styled.div`
  border: 1px solid white;
  padding: 5px;
  position: relative;
  margin-top: 5px;

  .condition-delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;
