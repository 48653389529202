import { EffectType } from '../../../../Enums/Effect.js';
import TargetInfo from '../../../Target/TargetInfo.js';
import { ZoneEnum, ZoneEnumMethods } from '../../../../Enums/Zone.js';
import RuntimeEffect from '../../RuntimeEffect.js';
import ModifiableInt from '../../../ModifableInt/ModifiableInt.js';
import StackEvent from '../../../StackEvent/StackEvent.js';
import { StackEntityEnum, StackEventEnum } from '../../../../Enums/Stack.js';
import StackEntity from '../../../StackEvent/StackEntity.js';
class AttackEffect extends RuntimeEffect {
    // target criteria
    targetCriterias() {
        return [];
    }
    constructor() {
        super();
        // this is all purely to trigger preEffects on other entities - it does NOT effect eventual damage
        this.blockInfo = [];
        this.effectType = EffectType.NormalAttack;
    }
    // #region Effect Execution
    // NOTE: this is all purely to trigger preEffects on other entities
    // it does NOT effect eventual damage
    preEffect(state, sourceEntity, targetInfoList) {
        // check for attacked and attacking
        const attackedCard = state.getCardFromAnywhere(targetInfoList[0].targetEntityInstanceIds[0]);
        if (!attackedCard)
            return false;
        const attackedCardZone = state.getZoneByInstanceId(attackedCard.residingZoneInstanceId);
        if (!ZoneEnumMethods.isBoard(attackedCardZone.zoneEnum))
            return false;
        const attackingCard = sourceEntity;
        if (!attackingCard)
            throw new Error('Why is non card entity attacking?');
        const attackingCardZone = state.getZoneByInstanceId(attackingCard.residingZoneInstanceId);
        if (!ZoneEnumMethods.isBoard(attackingCardZone.zoneEnum))
            return false;
        // done checks
        const releventBlocks = state.blocks.filter((block) => block.blockedCardInstanceId === attackedCard.instanceId);
        // sort blocks by block order (ascending)
        releventBlocks.sort((a, b) => a.blockOrder - b.blockOrder);
        const blockingCards = releventBlocks.map((block) => state.getCardFromAnywhere(block.blockingCardInstanceId));
        let totalDamageFromAttackingCard = attackingCard.attack.effectiveValue;
        for (let i = 0; i < blockingCards.length; i++) {
            const blockingCard = blockingCards[i];
            const blockingCardZone = state.getZoneByInstanceId(blockingCard.residingZoneInstanceId);
            let damageToBlockingCard = Math.min(totalDamageFromAttackingCard, blockingCard.health.effectiveValue);
            let damageToAttackingCard = blockingCard.attack.effectiveValue;
            if (blockingCardZone.zoneEnum !== ZoneEnum.FrontBoard) {
                damageToBlockingCard = 0;
                damageToAttackingCard = 0;
            }
            totalDamageFromAttackingCard -= damageToBlockingCard;
            const newBlockInfo = {
                blockingCardInstanceId: blockingCard.instanceId,
                damageToBlockingCard: new ModifiableInt(damageToBlockingCard, []),
                damageToAttackingCard: new ModifiableInt(totalDamageFromAttackingCard, []),
                damageToBlockingCardPrevented: false,
                damageToAttackingCardPrevented: false,
            };
            this.blockInfo.push(newBlockInfo);
        }
        this.damageToAttackedCard = new ModifiableInt(Math.min(totalDamageFromAttackingCard, attackedCard.health.effectiveValue), []);
        this.damageToAttackedCardPrevented = false;
        this.damageToAttackingCard = new ModifiableInt(attackedCard.attack.effectiveValue, []);
        this.damageToAttackingCardPrevented = false;
        return true;
    }
    // NOTE: resolve is NOT effected by the block list ModifiableInts
    // Those were to trigger preEffects on other entities - dealing damage, boosting health, etc.
    resolve(state, sourceEntity, targetInfoList) {
        const events = [];
        // check for attacked and attacking
        const attackedCard = state.getCardFromAnywhere(targetInfoList[0].targetEntityInstanceIds[0]);
        if (!attackedCard)
            return [
                new StackEvent(StackEventEnum.EffectResolve, state.clone(), false, 'Attack did not occur. Target card not found', [
                    new StackEntity(attackedCard.instanceId, StackEntityEnum.Target),
                    new StackEntity(sourceEntity.instanceId, StackEntityEnum.Source),
                ]),
            ];
        const attackedCardZone = state.getZoneByInstanceId(attackedCard.residingZoneInstanceId);
        if (!ZoneEnumMethods.isBoard(attackedCardZone.zoneEnum))
            return [
                new StackEvent(StackEventEnum.AttackResolve, state.clone(), false, 'Attack did not occur. Target card not on board', [
                    new StackEntity(attackedCard.instanceId, StackEntityEnum.Target),
                    new StackEntity(sourceEntity.instanceId, StackEntityEnum.Source),
                ]),
            ];
        const attackingCard = sourceEntity;
        if (!attackingCard)
            throw new Error('Why is non card entity attacking?');
        const attackingCardZone = state.getZoneByInstanceId(attackingCard.residingZoneInstanceId);
        if (!ZoneEnumMethods.isBoard(attackingCardZone.zoneEnum))
            return [
                new StackEvent(StackEventEnum.AttackResolve, state.clone(), false, 'Attack did not occur. Attacking card not on board', [
                    new StackEntity(attackedCard.instanceId, StackEntityEnum.Target),
                    new StackEntity(attackingCard.instanceId, StackEntityEnum.Source),
                ]),
            ];
        // done checks
        let remainingDamageToAttackedCard = attackingCard.attack.effectiveValue;
        // apply damage to blocking cards
        for (let block of this.blockInfo) {
            const blockingCard = state.getCardFromAnywhere(block.blockingCardInstanceId);
            if (!blockingCard)
                continue;
            const blockingCardZone = state.getZoneByInstanceId(blockingCard.residingZoneInstanceId);
            if (!ZoneEnumMethods.isBoard(blockingCardZone.zoneEnum))
                continue;
            const damageToBlockingCard = Math.min(remainingDamageToAttackedCard, blockingCard.health.effectiveValue);
            remainingDamageToAttackedCard -= damageToBlockingCard;
            if (!block.damageToBlockingCardPrevented) {
                blockingCard.health.baseValue -= damageToBlockingCard;
            }
            if (!block.damageToAttackingCardPrevented) {
                attackingCard.health.baseValue -= blockingCard.attack.effectiveValue;
            }
            events.push(new StackEvent(StackEventEnum.BlockResolve, state.clone(), true, 'Attack Blocked.', [
                new StackEntity(attackingCard.instanceId, StackEntityEnum.Source),
                new StackEntity(blockingCard.instanceId, StackEntityEnum.Other),
                new StackEntity(attackedCard.instanceId, StackEntityEnum.Target),
            ]));
        }
        // only the blockers all get to deal damage if the attacker runs out
        if (remainingDamageToAttackedCard <= 0) {
            events.push(new StackEvent(StackEventEnum.AttackNoDamage, state.clone(), true, 'Attack ended. No damage reached target.', [
                new StackEntity(attackingCard.instanceId, StackEntityEnum.Source),
                new StackEntity(attackedCard.instanceId, StackEntityEnum.Target),
                ...this.blockInfo.map((block) => new StackEntity(block.blockingCardInstanceId, StackEntityEnum.Other)),
            ]));
            return events;
        }
        // apply damage to attacked card
        if (!this.damageToAttackedCardPrevented) {
            attackedCard.health.baseValue -= remainingDamageToAttackedCard;
        }
        if (!this.damageToAttackingCardPrevented) {
            attackingCard.health.baseValue -=
                this.damageToAttackingCard.effectiveValue;
        }
        events.push(new StackEvent(StackEventEnum.AttackResolve, state.clone(), true, 'Attack occurred. Damage reached target.', [
            new StackEntity(attackingCard.instanceId, StackEntityEnum.Source),
            ...this.blockInfo.map((block) => new StackEntity(block.blockingCardInstanceId, StackEntityEnum.Other)),
            new StackEntity(attackedCard.instanceId, StackEntityEnum.Target),
        ]));
        return events;
    }
    applyShieldToAttackedCard(shieldAmount) {
        console.log('Here we need to add a modifier to the DamageToAttackedCardEffectValue');
    }
    hitDivineShield() {
        console.log('Here we need to implement this');
    }
    // #endregion
    // #region Runtime Target Methods
    areTargetsAvailable(state, sourceEntity) {
        const sourceCard = sourceEntity;
        if (!sourceCard)
            return false;
        const sourceCardZone = state.getZoneByInstanceId(sourceCard.residingZoneInstanceId);
        if (!ZoneEnumMethods.isBoard(sourceCardZone.zoneEnum))
            return false;
        // we can implement the rest of this if we want, but for now the server takes care of it
        return true;
    }
    isAllTargetInfoValid(sourceEntity, state, targetInfoList) {
        return true;
    }
    // #endregion
    // #region Runtime Utils
    effectToString() {
        const outText = "Unit attacks another unit. They both take damage equal to the other's attack.";
        return outText;
    }
    // #endregion
    // #region Static Creator Functions
    static createFightEffect() {
        const effect = new AttackEffect();
        return effect;
    }
    static createFightTargetInfoList(attackedCardInstanceId) {
        let targetInfoList = [];
        let targetInfo = new TargetInfo([attackedCardInstanceId], false, false);
        targetInfoList.push(targetInfo);
        return targetInfoList;
    }
    // #endregion
    // #region JSON
    toJSON() {
        return {
            effectType: EffectType[this.effectType],
        };
    }
    clone() {
        return new AttackEffect();
    }
}
export default AttackEffect;
