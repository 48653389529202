// socketEvents.js

export const NEW_ROOM_REQUEST = 'new-room-request'; // Client requests a new room to be created
export const ROOMS_REQUEST = 'rooms-request'; // Client requests the current list of open rooms
export const JOIN_ROOM_REQUEST = 'join-room-request'; // Client requests to join a specific room
export const LEAVE_ROOM_REQUEST = 'leave-room-request'; // Client requests to leave the current room
export const TOGGLE_READY_REQUEST = 'toggle-ready-request'; // Client requests to toggle their ready state
export const FULL_ROOM_REPORT_REQUEST = 'full-room-report-request'; // Client requests a full room report (admin feature)

export const GAME_STARTED_EVENT = 'game-started'; // Notify client of game start
export const REJOINED_GAME_EVENT = 'rejoined-game'; // Notify client they have rejoined a game
export const NOT_IN_GAME_EVENT = 'not-in-game'; // Notify client they are not in any game
export const ROOMS_UPDATE_EVENT = 'rooms-update'; // Update client with the latest rooms information
export const YOU_JOINED_ROOM_EVENT = 'you-joined-room'; // Notify client they have successfully joined a room
export const YOU_LEFT_ROOM_EVENT = 'you-left-room'; // Notify client they have left a room
export const SET_READY_EVENT = 'set-ready'; // Notify client of their ready state change
export const GAME_ENDED_EVENT = 'game-ended'; // Notify all clients that the game has ended
