import PayResourceCost from '../PayResourceCost/PayResourceCost.js';
import { PhaseEnum } from '../../Enums/Phase.js';
import LibraryEffect from '../Effect/LibraryEffect.js';
class LibraryAbility {
    constructor(setName, setEffect, setCosts, setUsesPerTurn, useableInPhases, isActive, image) {
        this.useableInPhases = [];
        this.costs = [];
        this.name = setName;
        this.effect = setEffect;
        this.isActive = isActive;
        setCosts.forEach((cost) => {
            const temp = new PayResourceCost(cost.statId, cost.value);
            this.costs.push(temp);
        });
        this.usesPerTurn = setUsesPerTurn;
        useableInPhases.forEach((phase) => {
            this.useableInPhases.push(phase);
        });
        this.image = image;
    }
    clone() {
        const newAbility = new LibraryAbility(this.name, this.effect.clone(), this.costs.map((c) => c.clone()), this.usesPerTurn, [...this.useableInPhases], this.isActive, this.image);
        return newAbility;
    }
    toJSON() {
        return {
            name: this.name,
            image: this.image,
            effect: this.effect.toJSON(),
            useableInPhases: this.useableInPhases.map((p) => PhaseEnum[p]),
            isActive: this.isActive,
            costs: this.costs.map((c) => c.toJSON()),
            usesPerTurn: this.usesPerTurn,
        };
    }
    static fromJSON(json) {
        const newAbility = new LibraryAbility(json.name, LibraryEffect.fromJSON(json.effect), json.costs.map((c) => PayResourceCost.fromJSON(c)), json.usesPerTurn, json.useableInPhases.map((p) => PhaseEnum[p]), json.isActive, json.image);
        return newAbility;
    }
}
export default LibraryAbility;
