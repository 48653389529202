// external
import React, { useContext, useEffect, useState } from 'react';

// internal
import { PlayerContext } from './PlayerContextProvider';
import { TurnFlowContext } from './TurnFlowContextProvider';

// lol
import Player from '../../../../LegendsOfLeakos/lib/Classes/Player/Player';
import AbilityKeywordRuntimeEntity from '../../../../LegendsOfLeakos/lib/Classes/Entity/AbilityKeywordRuntimeEntity';
import RuntimeAbility from '../../../../LegendsOfLeakos/lib/Classes/Ability/RuntimeAbility';
import TargetCriteria from '../../../../LegendsOfLeakos/lib/Classes/Target/TargetCriteria';
import TargetInfo from '../../../../LegendsOfLeakos/lib/Classes/Target/TargetInfo';
import {
  TargetMethods,
  TargetableTypeSelectionEnum,
} from '../../../../LegendsOfLeakos/lib/Enums/Target';
import TargetableRuntimeEntity from '../../../../LegendsOfLeakos/lib/Classes/Entity/TargetableRuntimeEntity';
import { PlayerFlowEnum } from '../../../../LegendsOfLeakos/lib/Enums/PlayerFlow';

const ActiveAbilityContextProvider = ({ children }) => {
  // #region Context

  const { masterPlayer, displayState, queueAbility } =
    useContext(PlayerContext);
  const { queuedMana, setQueuedMana } = useContext(TurnFlowContext);

  // #endregion

  // #region Ability Context Variables

  // acting ability / effect flow
  // entity
  const [actingAbilityEntity, setActingAbilityEntity] =
    useState<AbilityKeywordRuntimeEntity>(null);
  const [actingAbilityEntityPosition, setActingAbilityEntityPosition] =
    useState({ x: 0, y: 0 });

  // ability
  const [actingAbility, setActingAbility] = useState<RuntimeAbility>(null);

  // targets
  const [currentTargetCriteriaIndex, setCurrentTargetCriteriaIndex] =
    useState<number>(null);

  const [currentTargetCriteria, setCurrentTargetCriteria] =
    useState<TargetCriteria>(null);
  useEffect(() => {
    if (!actingAbility) return;
    if (currentTargetCriteriaIndex === null) {
      setCurrentTargetCriteria(null);
      return;
    }
    console.log('currentTargetCriteriaIndex', currentTargetCriteriaIndex);
    const newTargetCriteria =
      actingAbility.effect.targetCriterias()[currentTargetCriteriaIndex];
    if (!newTargetCriteria) {
      throw new Error(
        'GamePage - startAbility - targetCriteria is null. This should never happen.'
      );
    }
    setCurrentTargetCriteria(newTargetCriteria);
  }, [actingAbility, currentTargetCriteriaIndex]);

  const [targetInfoList, setTargetInfoList] = useState<TargetInfo[]>([]);
  const [currentTargetInfo, setCurrentTargetInfo] = useState<TargetInfo>(null);
  const [currentTargetInfoViable, setCurrentTargetInfoViable] =
    useState<boolean>(false);
  const [addingTargets, setAddingTargets] = useState<boolean>(false);

  // #endregion

  // #region activating abilities

  const startAbility = (
    event,
    entity: AbilityKeywordRuntimeEntity,
    abilityIndex: number,
    sourceRef
  ) => {
    if (!masterPlayer || !displayState) return;

    if (masterPlayer.playerFlowEnum !== PlayerFlowEnum.PlayerActing) {
      console.log('startAbility - player not in acting part of turn');
      return;
    }

    if (!entity) {
      console.log('startAbility - no card');
      return;
    }

    const ability = entity.abilities[abilityIndex];

    if (!ability) {
      console.log('startAbility - no ability');
      return;
    }

    const effect = ability.effect;
    if (effect.targetCriterias().length === 0) {
      queueAbility(entity.instanceId, abilityIndex, queuedMana, []);
    } else {
      setActingAbilityEntity(entity);
      setActingAbility(ability);
      const rect = sourceRef.current.getBoundingClientRect();
      setActingAbilityEntityPosition({
        x: rect.x + rect.width / 2,
        y: rect.y + rect.height / 2,
      });
    }
  };

  const cancelAbility = () => {
    setActingAbilityEntity(null);
    setActingAbility(null);
    setCurrentTargetCriteriaIndex(null);
    setCurrentTargetCriteria(null);
    setTargetInfoList([]);
    setCurrentTargetInfo(null);
  };

  const finishedSelectingTargetsForCurrentTargetInfo = () => {
    if (!currentTargetInfo) return;
    if (!currentTargetCriteria) return;
    if (!masterPlayer || !displayState) return;
    if (!actingAbilityEntity || !actingAbility) return;

    if (
      currentTargetInfo.targetEntityInstanceIds.length <
      currentTargetCriteria.minSelectionsRequired
    ) {
      console.log(
        'finishedSelectingTargetsForCurrentTargetInfo - not enough targets'
      );
      return;
    }

    setTargetInfoList((prevList) => {
      return [...prevList, currentTargetInfo];
    });
    setAddingTargets(false);
  };

  // on new target info, we either queue the ability or we set up the next target criteria
  useEffect(() => {
    if (!targetInfoList) return;
    if (!masterPlayer || !displayState) return;
    if (!actingAbilityEntity || !actingAbility) return;

    const actingEffect = actingAbility.effect;
    if (
      actingEffect.isAllTargetInfoValid(
        actingAbilityEntity,
        displayState,
        targetInfoList
      )
    ) {
      queueAbility(
        actingAbilityEntity.instanceId,
        actingAbilityEntity.abilities.indexOf(actingAbility),
        queuedMana,
        targetInfoList
      );
      setCurrentTargetCriteriaIndex(null);
      setCurrentTargetCriteria(null);
      setTargetInfoList([]);
      setCurrentTargetInfo(null);
      setActingAbilityEntity(null);
      setActingAbility(null);
      setQueuedMana([]);

      return;
    }

    // else
    setCurrentTargetCriteriaIndex((prevIndex) => {
      if (prevIndex === null) {
        return 0;
      }
      return prevIndex + 1;
    });
  }, [targetInfoList, actingAbilityEntity, actingAbility]);

  // on changing the current target criteria index, we need to set up the new target info
  useEffect(() => {
    if (currentTargetCriteria === null) return;
    if (!masterPlayer || !displayState) return;

    const playerSelectsNow =
      currentTargetCriteria.targetableTypeSelectionEnum ===
      TargetableTypeSelectionEnum.TargetableOnActivation;

    const targetInfo = new TargetInfo(
      [],
      // no Targets were selected
      playerSelectsNow,
      // targets are selected later
      currentTargetCriteria.minSelectionsRequired > 0 &&
        TargetMethods.playerSelectsTargets(
          currentTargetCriteria.targetableTypeSelectionEnum
        )
    );

    if (!playerSelectsNow) {
      setTargetInfoList((prevList) => {
        return [...prevList, targetInfo];
      });
    } else {
      setCurrentTargetInfo(targetInfo);
    }
  }, [currentTargetCriteria]);

  // within a target info
  useEffect(() => {
    if (
      !currentTargetInfo ||
      !currentTargetCriteria ||
      !masterPlayer ||
      !displayState ||
      !actingAbilityEntity ||
      !actingAbility
    ) {
      setCurrentTargetInfoViable(false);
      return;
    }

    // setting viable
    if (
      currentTargetCriteria.isTargetInfoValid(
        actingAbilityEntity.instanceId,
        currentTargetInfo,
        displayState
      )
    ) {
      setCurrentTargetInfoViable(true);
    }

    // if finished, force push it without the player needing to click
    if (
      currentTargetInfo.targetEntityInstanceIds.length >=
      currentTargetCriteria.maxSelectionsAllowed
    ) {
      setTargetInfoList((prevList) => {
        return [...prevList, currentTargetInfo];
      });
      setAddingTargets(false);
      return;
    } else {
      // otherwise, we don't do anything and wait for the player to add more
      setAddingTargets(true);
    }
  }, [currentTargetInfo]);

  const addTarget = (event, targetEntity) => {
    if (!currentTargetInfo) {
      console.log('addTarget - no currentTargetInfo');
      return;
    }
    if (!currentTargetCriteria) {
      console.log('addTarget - no currentTargetCriteria');
      return;
    }
    if (!masterPlayer || !displayState) {
      console.log('addTarget - no masterPlayer or gameState');
      return;
    }
    if (!actingAbilityEntity || !actingAbility) {
      console.log('addTarget - no actingAbilityEntity or actingAbility');
      return;
    }

    if (!targetEntity) {
      console.log('addTargets - no targetEntity');
      return;
    }

    const targetInfo = new TargetInfo(
      [...currentTargetInfo.targetEntityInstanceIds, targetEntity.instanceId],
      // no Targets were selected
      false,
      // targets are selected later
      false
    );

    if (
      !currentTargetCriteria.isTargetInfoValid(
        actingAbilityEntity.instanceId,
        targetInfo,
        displayState
      )
    ) {
      console.log('addTargets - targetInfo is not valid');
      return;
    }

    setCurrentTargetInfo(targetInfo);
  };

  // #endregion

  const value = {
    actingAbility,
    actingAbilityEntity,
    actingAbilityEntityPosition,
    currentTargetCriteriaIndex,
    currentTargetCriteria,
    currentTargetInfo,
    currentTargetInfoViable,
    addingTargets,
    startAbility,
    cancelAbility,
    addTarget,
    finishedSelectingTargetsForCurrentTargetInfo,
  };

  return (
    <ActiveAbilityContext.Provider value={value}>
      {children}
    </ActiveAbilityContext.Provider>
  );
};

interface ActiveAbilityContextInterface {
  actingAbility: RuntimeAbility;
  actingAbilityEntity: AbilityKeywordRuntimeEntity;
  actingAbilityEntityPosition: { x: number; y: number };
  currentTargetCriteriaIndex: number;
  currentTargetCriteria: TargetCriteria;
  currentTargetInfo: TargetInfo;
  currentTargetInfoViable: boolean;
  addingTargets: boolean;
  startAbility: (
    event,
    entity: AbilityKeywordRuntimeEntity,
    abilityIndex: number,
    sourceRef
  ) => void;
  cancelAbility: () => void;
  addTarget: (event, targetEntity: TargetableRuntimeEntity) => void;
  finishedSelectingTargetsForCurrentTargetInfo: () => void;
}

const ActiveAbilityContext =
  React.createContext<ActiveAbilityContextInterface>(null);

export { ActiveAbilityContextProvider, ActiveAbilityContext };
