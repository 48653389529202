class IntModifier {
    constructor(value, permanent) {
        this.value = value;
        this.permanent = permanent;
    }
    clone() {
        return new IntModifier(this.value, this.permanent);
    }
}
export default IntModifier;
