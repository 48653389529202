// a component that displays a player's mana
// external
import React, { useContext } from 'react';

// context
import { GameElementSizeContext } from '../../../contexts/GameElementSizeContextProvider';
import { QueueReviewContext } from '../../../contexts/QueueReviewContextProvider';

// css
import { StackListItemStyled } from './styles/StackListItem.styled.js';

// lol
import StackEvent from '../../../../../../LegendsOfLeakos/lib/Classes/StackEvent/StackEvent';
import { StackEventEnum } from '../../../../../../LegendsOfLeakos/lib/Enums/Stack';

interface StackListItemProps {
  stackEvent: StackEvent;
  stackPosition: number;
}

const StackListItem: React.FC<StackListItemProps> = ({
  stackEvent,
  stackPosition,
}) => {
  // context
  const { currentEventIndex, loadStackPosition } =
    useContext(QueueReviewContext);
  const { gameContainerWidth } = useContext(GameElementSizeContext);

  // enum -> title
  const eventEnumToTitle = (eventEnum: StackEventEnum) => {
    switch (eventEnum) {
      case StackEventEnum.Queueline:
        return 'Player Action';
      case StackEventEnum.AttackResolve:
        return 'Attack Resolved';
      case StackEventEnum.AttackNoDamage:
        return 'Attack Blocked';
      case StackEventEnum.BlockResolve:
        return 'Block Resolved';
      case StackEventEnum.EffectResolve:
        return 'Effect Resolved';
      case StackEventEnum.QueueStart:
        return 'Queue Started';
      case StackEventEnum.QueueEnd:
        return 'Queue Ended';
      case StackEventEnum.OtherStackEvent:
        return 'Effect Modified';

      default:
        return 'Unknown';
    }
  };

  return (
    <StackListItemStyled
      onClick={() => {
        loadStackPosition(stackPosition);
      }}
      active={currentEventIndex === stackPosition}
      gameContainerWidth={gameContainerWidth}
      eventEnum={StackEventEnum[stackEvent.eventEnum]}
    >
      <div className='stack-event-title'>
        {eventEnumToTitle(stackEvent.eventEnum)}
      </div>
      <div className='stack-event-description'>{stackEvent.description}</div>
      <div className='background'></div>
    </StackListItemStyled>
  );
};

export default StackListItem;
