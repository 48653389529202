import { EffectType } from '../../Enums/Effect.js';
class LibraryEffect {
    constructor(effectType, data) {
        this.effectType = effectType;
        this.data = data;
    }
    toJSON() {
        return {
            effectType: EffectType[this.effectType],
            data: this.data,
        };
    }
    clone() {
        return new LibraryEffect(this.effectType, JSON.parse(JSON.stringify(this.data)));
    }
    static fromJSON(json) {
        const lib = new LibraryEffect(EffectType[json.effectType], json.data);
        return lib;
    }
}
export default LibraryEffect;
