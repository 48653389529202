import RuntimeKeyword from '../RuntimeKeyword.js';
import { KeywordType, Attribute } from '../../../../Enums/Keyword.js';
import RuntimeCondition from '../../../Condition/RuntimeCondition.js';
import LibraryKeyword from '../../LibraryKeyword/LibraryKeyword.js';
class AttributeKeyword extends RuntimeKeyword {
    constructor(myEntityId, setIsPermanent, setDuration, isActive, imageName, conditions, attribute) {
        super();
        this.myEntityInstanceId = myEntityId;
        this.keywordType = KeywordType.Attribute;
        this.isPermanent = setIsPermanent;
        this.duration = setDuration;
        this.isActive = isActive;
        this.imageName = imageName;
        this.conditions = conditions;
        this.attribute = attribute;
    }
    getDisplayText() {
        return 'This has ' + Attribute[this.attribute] + '.';
    }
    // #region JSON
    toJSON() {
        return {
            keywordType: KeywordType[this.keywordType],
            myEntityInstanceId: this.myEntityInstanceId,
            isPermanent: this.isPermanent,
            duration: this.duration,
            isActive: this.isActive,
            imageName: this.imageName,
            conditions: this.conditions.map((c) => c.toJSON()),
            attribute: Attribute[this.attribute],
        };
    }
    clone() {
        return new AttributeKeyword(this.myEntityInstanceId, this.isPermanent, this.duration, this.isActive, this.imageName, this.conditions.map((c) => c.clone()), this.attribute);
    }
    static fromRuntimeJSON(json) {
        return new AttributeKeyword(json.myEntityInstanceId, json.isPermanent, json.duration, json.isActive, json.imageName, json.conditions.map((c) => RuntimeCondition.fromRuntimeJSON(c)), Attribute[json.attribute]);
    }
    static fromLibraryJSON(myEntityInstanceId, json) {
        return new AttributeKeyword(myEntityInstanceId, json.isPermanent, json.duration, json.isActive, json.imageName, json.conditions.map((c) => RuntimeCondition.fromLibraryJSON(c)), Attribute[json.data.attribute.value]);
    }
    static isLibraryJSONValid(json) {
        if (!json.keywordType ||
            !Object.values(KeywordType)
                .filter((value) => typeof value === 'string')
                .includes(json.keywordType)) {
            console.log('Invalid keywordType');
            return false;
        }
        if (json.isPermanent === undefined ||
            json.isPermanent === null ||
            typeof json.isPermanent !== 'boolean') {
            console.log('Invalid isPermanent');
            return false;
        }
        if (json.duration === undefined ||
            json.duration === null ||
            typeof json.duration !== 'number') {
            console.log('Invalid duration');
            return false;
        }
        if (json.isActive === undefined ||
            json.isActive === null ||
            typeof json.isActive !== 'boolean') {
            console.log('Invalid isActive');
            return false;
        }
        if (!json.imageName || typeof json.imageName !== 'string') {
            console.log('Invalid imageName');
            return false;
        }
        if (!json.conditions ||
            !Array.isArray(json.conditions) ||
            !json.conditions.every((c) => RuntimeCondition.isLibraryJSONValid(c))) {
            console.log('Invalid conditions');
            return false;
        }
        if (!json.data.attribute) {
            console.log('Invalid attribute');
            return false;
        }
        return true;
    }
    static createSampleLibraryKeyword() {
        return new LibraryKeyword(KeywordType.Attribute, 'Attribute', true, 0, true, [], 'image', {
            attribute: {
                type: 'Enum',
                enum: 'Attribute',
                value: Attribute[Attribute.Impetus],
            },
        });
    }
    static initialize() {
        RuntimeKeyword.registerFromLibraryJSON(KeywordType.Attribute, AttributeKeyword.fromLibraryJSON);
        RuntimeKeyword.registerFromRuntimeJSON(KeywordType.Attribute, AttributeKeyword.fromRuntimeJSON);
        RuntimeKeyword.registerIsLibraryJSONValid(KeywordType.Attribute, AttributeKeyword.isLibraryJSONValid);
        RuntimeKeyword.registerSampleLibraryKeyword(KeywordType.Attribute, AttributeKeyword.createSampleLibraryKeyword);
    }
}
export default AttributeKeyword;
// RuntimeKeyword.registerFromLibraryJSON(
//   KeywordType.Attribute,
//   AttributeKeyword.fromLibraryJSON
// );
// RuntimeKeyword.registerFromRuntimeJSON(
//   KeywordType.Attribute,
//   AttributeKeyword.fromRuntimeJSON
// );
// RuntimeKeyword.registerIsLibraryJSONValid(
//   KeywordType.Attribute,
//   AttributeKeyword.isLibraryJSONValid
// );
// RuntimeKeyword.registerSampleLibraryKeyword(
//   KeywordType.Attribute,
//   AttributeKeyword.createSampleLibraryKeyword
// );
