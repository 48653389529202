import styled from 'styled-components';
import '../../../../styles/constants.css';

const divFactor = 2;

export const RealmButtonStyled = styled.div`
  z-index: var(--buttons-z-index);
  position: absolute;
  pointer-events: none;

  // player
  bottom: ${({ isPlayer }) => (isPlayer ? '0.95%' : 'auto')};
  right: ${({ isPlayer }) => (isPlayer ? '15.3%' : 'auto')};

  // opponent
  top: ${({ isPlayer }) => (!isPlayer ? '1.1%' : 'auto')};
  left: ${({ isPlayer }) => (!isPlayer ? '5.3%' : 'auto')};

  height: ${({ realmButtonHeight }) => `${realmButtonHeight}px`};
  width: ${({ realmButtonHeight }) => `${realmButtonHeight}px`};

  .click-area {
    position: absolute;

    // player
    bottom: ${({ isPlayer }) => (isPlayer ? '36%' : 'auto')};
    right: ${({ isPlayer }) => (isPlayer ? '6%' : 'auto')};

    top: ${({ isPlayer }) => (isPlayer ? 'auto' : '36%')};
    left: ${({ isPlayer }) => (isPlayer ? 'auto' : '30%')};

    height: ${({ realmButtonHeight }) => `${realmButtonHeight / divFactor}px`};
    width: ${({ realmButtonHeight }) => `${realmButtonHeight / divFactor}px`};
    cursor: pointer;
    border-radius: 50%;
    z-index: 7;
    pointer-events: auto;
  }

  // #region images
  .globe-image-container {
    position: relative;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }

  img {
    position: absolute;
    bottom: 0;
    right: 0;
    height: ${({ realmButtonHeight }) => `${realmButtonHeight}px`};
    object-fit: cover;
    pointer-events: none;
  }

  .realms {
    z-index: 5;
  }

  .hide {
    z-index: 5;
  }

  .show {
    z-index: 4;
  }

  .red-earth {
    z-index: 3;
  }

  .globe {
    z-index: 2;
  }

  .banner {
    z-index: 1;
  }

  .banner-container {
    position: relative;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;

    // animate
    transform: ${({ amHovered, bannerScale }) =>
      `scale(${amHovered && bannerScale === 1 ? 1.1 : bannerScale})`};
    transition: transform 0.15s ease;
  }
  // #endregion compass image
`;
