import ModifiableInt from '../ModifableInt/ModifiableInt.js';
class PayResourceCostUpgrade {
    constructor(statId, valueChange) {
        this.statId = statId;
        this.valueChange = new ModifiableInt(valueChange.baseValue, valueChange.intModifiers);
    }
    toJSON() {
        return {
            statId: this.statId,
            valueChange: this.valueChange.toJSON(),
        };
    }
    static fromJSON(json) {
        return new PayResourceCostUpgrade(json.statId, ModifiableInt.fromJSON(json.valueChange));
    }
}
export default PayResourceCostUpgrade;
