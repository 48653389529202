// #region imports
import GameState from '../Game/GameState.js';
import EffectSolver from '../Game/EffectSolver.js';
import StartGameMessage from '../Networking/GameLoop/Game/StartGameMessage.js';
import ServerMovedCardMessage from '../Networking/Cards/Move/ServerMovedCardMessage.js';
import ServerCardMovedRowMessage from '../Networking/Cards/MovedRow/ServerCardMovedRowMessage.js';
import NextPhaseReadyMessage from '../Networking/GameLoop/PhaseAndQueue/NextPhaseReadyMessage.js';
import QueueStartedMessage from '../Networking/GameLoop/PhaseAndQueue/QueueStartedMessage.js';
import GameManager from '../Game/GameManager.js';
import { NetworkProtocol } from '../../Enums/NetworkProtocol.js';
import { ClientMessage } from '../Networking/MessageBase.js';
import ServerSendingGamestateForRejoin from '../Networking/Connection/ServerSendingGamestateForRejoinMessage.js';
import RejoinedGameMessage from '../Networking/Connection/RejoinedGameMessage.js';
import CardPlayedMessage from '../Networking/Cards/Play/CardPlayedMessage.js';
import CreatureAttackedMessage from '../Networking/Attacking/CreatureAttackedMessage.js';
import AbilityActivatedMessage from '../Networking/Abilities/AbilityActivatedMessage.js';
import MoveCardEffect from '../Effect/RuntimeEffects/MoveEffects/MoveCardEffect.js';
import RuntimeCard from '../Card/RuntimeCard.js';
import { ZoneEnum, ZoneOpponentVisibility, ZoneOwnerVisibility, } from '../../Enums/Zone.js';
import PlayCardPlayerQueueLine from '../Queueline/PlayerQueueline/PlayerQueuelines/PlayCardPlayerQueueline.js';
import ActivateAbilityPlayerQueueline from '../Queueline/PlayerQueueline/PlayerQueuelines/ActivateAbilityPlayerQueueline.js';
import UpgradeCardEffect from '../Effect/RuntimeEffects/UpgradeEffects/UpgradeCardEffect.js';
import MoveRowPlayerQueueline from '../Queueline/PlayerQueueline/PlayerQueuelines/MoveRowPlayerQueueline.js';
import QueuePlayCardMessage from '../Networking/Cards/Play/QueuePlayCardMessage.js';
import QueueActivateAbilityMessage from '../Networking/Abilities/QueueActivateAbilityMessage.js';
import PlayerExploredLandMessage from '../Networking/Land/PlayerExporedLandMessage.js';
import LandExploredMessage from '../Networking/Land/LandExporedMessage.js';
import PlayerReadyForQueueMessage from '../Networking/GameLoop/PhaseAndQueue/PlayerReadyForQueueMessage.js';
import CancelActionMessage from '../Networking/QueueManagement/CancelActionMessage.js';
import QueueFightCreatureMessage from '../Networking/Attacking/QueueFightCreatureMessage.js';
import FightCreaturePlayerQueueLine from '../Queueline/PlayerQueueline/PlayerQueuelines/FightCreaturePlayerQueueline.js';
import AttackEffect from '../Effect/RuntimeEffects/AttackEffects/AttackEffect.js';
import CardIsBlockingMessage from '../Networking/Blocking/CardIsBlockingMessage.js';
import StopCardBlockingMessage from '../Networking/Blocking/StopCardBlockingMessage.js';
import ReorderBlockingCardMessage from '../Networking/Blocking/ReorderBlockingCardMessage.js';
import StackEvent from '../StackEvent/StackEvent.js';
import { PhaseEnum } from '../../Enums/Phase.js';
import { StackEntityEnum, StackEventEnum } from '../../Enums/Stack.js';
import { PlayerFlowEnum } from '../../Enums/PlayerFlow.js';
import StackEntity from '../StackEvent/StackEntity.js';
// #endregion
class Player {
    // returning targets
    // targetInfoCode: number;
    // returningCardInstanceId: number;
    // returningEffect: RuntimeEffect;
    // returningTargetTypes: TargetCriteria[];
    // #endregion
    constructor(_sendToServer) {
        // Queue
        this.queue = [];
        this.stack = [];
        this.numQueuelinesTotal = null; // the number of actions that we are expecting total - asking for targets doesn't count
        this._sendToServer = _sendToServer;
        this.sendToServer = (protocol, message) => {
            console.log('player send to server - protocol: ', protocol, ' message: ', message);
            const messageString = NetworkProtocol[protocol];
            this._sendToServer(messageString, message.toJSON());
        };
        // initialize all registration functions
        GameManager.initializeClassesWithRegistration();
    }
    // #region clone
    clone() {
        const clone = new Player(this._sendToServer);
        clone.userId = this.userId;
        clone.gameState = this.gameState ? this.gameState.clone() : null;
        clone.tempPostQueueState = this.tempPostQueueState
            ? this.tempPostQueueState.clone()
            : null;
        clone.queue = this.queue
            ? this.queue.map((queueline) => queueline.clone())
            : [];
        clone.stack = this.stack.map((stackEvent) => stackEvent.clone());
        clone.playerFlowEnum = this.playerFlowEnum;
        clone.numQueuelinesTotal = this.numQueuelinesTotal;
        return clone;
    }
    // #endregion
    // #region Utility Methods
    getPlayerInfo(state) {
        try {
            return state.getPlayerInfoByUserId(this.userId);
        }
        catch (error) {
            console.log('Error getting player info: ', error);
            return null;
        }
    }
    getOpponentInfo(state) {
        try {
            return state.players.find((player) => player.userId !== this.userId);
        }
        catch (error) {
            console.log('Error getting opponent info: ', error);
            return null;
        }
    }
    // #endregion
    // #region Start and End Game
    onStartGame(msg) {
        const message = StartGameMessage.fromJSON(msg);
        if (!message || !message.validate()) {
            console.log('Invalid StartGameMessage: ', message);
            return;
        }
        this.userId = message.player.userId;
        this.playerFlowEnum = PlayerFlowEnum.PlayerExploring;
        this.gameState = new GameState(message.gameManager, [message.player, message.opponent], 1, 0, message.rngSeed, []);
    }
    onEndGame(msg) { }
    // #endregion
    // #region Connecting and Reconnecting
    onGamestateForRejoin(msg) {
        const message = ServerSendingGamestateForRejoin.fromJSON(msg);
        if (!message || !message.validate()) {
            console.log('Invalid ServerSendingGamestateForRejoin message: ', message);
            return;
        }
        this.userId = message.player.userId;
        this.playerFlowEnum = PlayerFlowEnum.PlayerActing;
        this.gameState = new GameState(message.gameManager, [message.player, message.opponent], message.turn, message.phaseIndex, message.rngSeed, message.blocks.map((b) => (Object.assign({}, b))));
        if (this.gameState.currentPhaseIndex === PhaseEnum.Recruit) {
            if (this.getPlayerInfo(this.gameState).realm.landTiles.filter((tile) => tile.explored).length <= this.gameState.currentTurn) {
                this.playerFlowEnum = PlayerFlowEnum.PlayerExploring;
            }
            else {
                this.playerFlowEnum = PlayerFlowEnum.WaitingForOpponentExplore;
            }
        }
    }
    fetchUpdatedGamestate() {
        const message = new RejoinedGameMessage(ClientMessage.generateUniqueId());
        this.sendToServer(NetworkProtocol.RejoinedGame, message);
    }
    // #endregion
    // #region Turn and Phase
    receivedNextPhaseReadyMessage(msg) {
        const message = NextPhaseReadyMessage.fromJSON(msg);
        if (!message || !message.validate()) {
            console.log('Invalid NextPhaseReadyMessage');
            return;
        }
        this.tempPostQueueState = this.gameState.clone();
        this.tempPostQueueState.players = [message.player, message.opponent];
        this.tempPostQueueState.currentTurn = message.turn;
        this.tempPostQueueState.currentPhaseIndex = message.phaseIndex;
        EffectSolver.updateStatBuffs(this.tempPostQueueState);
        // phase specific stuff
        // technically this should have already been done on the server and then incorporated
        // into the gamestate we just received
        // we may want to animate some of this later on
        // if we're in recruit phase, go to the next
        if (message.phaseIndex === PhaseEnum.Recruit + 1) {
            this.onStartPhase();
            return;
        }
    }
    onStartPhase() {
        this.gameState = this.tempPostQueueState.clone();
        if (this.gameState.currentPhaseIndex === PhaseEnum.Recruit) {
            this.playerFlowEnum = PlayerFlowEnum.PlayerExploring;
        }
        else {
            this.playerFlowEnum = PlayerFlowEnum.PlayerActing;
        }
        this.tempPostQueueState = null;
        // reset queue
        this.queue = [];
        this.stack = [];
        this.getPlayerInfo(this.gameState).queueMessages = [];
        this.numQueuelinesTotal = null;
    }
    // #endregion
    // #region Queue
    receivedQueueStartedMessage(msg) {
        const message = QueueStartedMessage.fromJSON(msg);
        if (!message || !message.validate()) {
            throw new Error('Invalid QueueStartedMessage: ' + JSON.stringify(message));
        }
        // don't reset queue or stack - messages might be out of order
        // we'll do it at the beginning of the turn
        // we can set these though
        this.numQueuelinesTotal = message.totalQueuelines;
        this.gameState.blocks = message.blocks;
        // move the attacking cards into the battlerow
        // note that this will only work for the ones in the Front Row
        for (let attackingCardInstanceId of message.attackingCardInstanceIds) {
            const moveEffect = MoveCardEffect.createMoveCardEffect(ZoneEnum.FrontBoard, ZoneEnum.BattleBoard);
            const results = EffectSolver.doEffect(this.gameState, attackingCardInstanceId, moveEffect, []);
            // let's not add these to start - we can add them in later if we need to
            // stackline.events.push(...results);
        }
        // getting this vs getting the queuelines might happen out of order
        // this also handlers the case where nothing is in the queue
        if (this.queue.length === this.numQueuelinesTotal) {
            this.startQueue();
        }
    }
    startQueue() {
        this.playerFlowEnum = PlayerFlowEnum.ProcessingQueue;
        // sort queue
        this.queue.sort((a, b) => {
            return a.queuePosition - b.queuePosition;
        });
        // event for start
        this.stack.push(new StackEvent(StackEventEnum.QueueStart, this.gameState.clone(), true, 'Queue Start', []));
        // run the queue here
        for (let queueline of this.queue) {
            this.stack.push(new StackEvent(StackEventEnum.Queueline, this.gameState.clone(), true, queueline.actionToString(this.gameState), [
                new StackEntity(queueline.sourceCardInstanceId, StackEntityEnum.Other),
            ]));
            const events = queueline.sendEffectToPlayer(this.gameState, this);
            this.stack.push(...events);
        }
        // event for end
        this.stack.push(new StackEvent(StackEventEnum.QueueEnd, this.gameState.clone(), true, 'Queue End', []));
        this.playerFlowEnum = PlayerFlowEnum.ReviewingQueue;
    }
    finishedQueueActions() {
        this.playerFlowEnum = PlayerFlowEnum.WaitingForServer;
        const message = new PlayerReadyForQueueMessage(ClientMessage.generateUniqueId(), this.userId);
        this.sendToServer(NetworkProtocol.PlayerReadyForQueue, message);
    }
    // #endregion
    // #region Playing Cards
    queuePlayCard(cardInstanceId, boardZoneEnum, selectedCosts, battlecryIndex, targetInfoList) {
        const card = this.gameState.getCardFromAnywhere(cardInstanceId);
        if (!card) {
            throw new Error('Card not found');
        }
        const libraryCard = this.gameState.gameManager.cardLibrary.find((card) => {
            return card.libraryId === card.libraryId;
        });
        if (!libraryCard) {
            throw new Error('Library card not found');
        }
        // pay costs
        const libraryCosts = libraryCard.costs;
        const playerInfo = this.gameState.getPlayerInfoByUserId(this.userId);
        if (!playerInfo) {
            throw new Error('Player not found');
        }
        if (!playerInfo.canPayResourceCosts(libraryCosts)) {
            throw new Error('Player cannot pay resource costs');
        }
        // update the costs
        const paidCosts = playerInfo.payResourceCosts(libraryCosts, selectedCosts);
        const message = new QueuePlayCardMessage(QueuePlayCardMessage.generateUniqueId(), this.userId, cardInstanceId, boardZoneEnum, paidCosts, targetInfoList);
        this.getPlayerInfo(this.gameState).queueMessages.push(message);
        this.sendToServer(NetworkProtocol.QueuePlayCard, message);
    }
    cancelPlayCard(cardInstanceId) {
        const playCardMessages = this.getPlayerInfo(this.gameState).queueMessages.filter((message) => {
            return message.messageEnum === NetworkProtocol.QueuePlayCard;
        });
        const playCardMessage = playCardMessages.find((message) => {
            return message.cardInstanceId === cardInstanceId;
        });
        if (!playCardMessage) {
            throw new Error('Play card message not found');
        }
        // get the mana back
        const playerInfo = this.getPlayerInfo(this.gameState);
        playCardMessage.paidCosts.forEach((cost) => {
            playerInfo.idToStat.get(cost.statId).baseValue += cost.value;
        });
        const message = new CancelActionMessage(QueuePlayCardMessage.generateUniqueId(), this.userId, playCardMessage.messageId);
        // cancel from the queue
        this.getPlayerInfo(this.gameState).queueMessages = this.getPlayerInfo(this.gameState).queueMessages.filter((message) => {
            return message.messageId !== playCardMessage.messageId;
        });
        this.sendToServer(NetworkProtocol.CancelAction, message);
    }
    receivedPlayCardMessage(msg) {
        if (this.playerFlowEnum !== PlayerFlowEnum.WaitingForServer) {
            console.log('receivedPlayCardMessage: Not ready for queue');
            return; // likely already moved on from reviewing queue
        }
        const message = CardPlayedMessage.fromJSON(msg);
        if (!message || !message.validate()) {
            console.log('Invalid CardPlayedMessage');
            return;
        }
        if (!message.card) {
            throw new Error('Card not found');
        }
        let sourceCard = this.gameState.getCardFromAnywhere(msg.card.instanceId);
        if (!sourceCard) {
            // if we couldn't find the card, it must be a card that we don't know about yet
            // let's first confirm that it didn't come from a place that we should have known about it
            const originZone = this.gameState.getZoneByZoneEnumAndUserId(msg.originZoneZoneEnum, msg.sourcePlayerUserId);
            const isPlayerZone = originZone.ownerPlayerUserId === this.userId;
            if (isPlayerZone) {
                if (originZone.getLibraryZone().ownerVisibility ===
                    ZoneOwnerVisibility.Visible) {
                    throw new Error('We should have known about this card. it was our card. msg.card.instanceId: ' +
                        msg.card.instanceId);
                }
            }
            else if (originZone.getLibraryZone().opponentVisibility ===
                ZoneOpponentVisibility.Visible) {
                throw new Error('We should have known about this card,' +
                    'though it was the opponent card, it was coming from: ' +
                    ZoneEnum[msg.originZoneZoneEnum]);
            }
            // all good - we didn't know about this card, let's create it
            sourceCard = RuntimeCard.fromRuntimeJSON(msg.card);
            originZone.addCard(sourceCard);
            // we don't need to remove it from anywhere because it wasn't anywhere
        }
        const destinationZone = this.gameState.getZoneByZoneEnumAndUserId(msg.destinationZoneZoneEnum, msg.sourcePlayerUserId);
        if (!destinationZone) {
            throw new Error('Destination zone not found');
        }
        if (sourceCard.ownerPlayerUserId !== this.userId) {
            const opponent = this.gameState.getPlayerInfoByUserId(sourceCard.ownerPlayerUserId);
            if (!opponent) {
                throw new Error('Opponent not found');
            }
            opponent.payResourceCosts(message.paidCosts);
        }
        const queueline = new PlayCardPlayerQueueLine(this.userId, sourceCard.instanceId, message.sourcePlayerUserId, message.queuePosition, message.paidCosts, message.targetInfoList, message.originZoneZoneEnum, message.destinationZoneZoneEnum);
        this.queue.push(queueline);
        if (this.queue.length === this.numQueuelinesTotal) {
            this.startQueue();
        }
    }
    onCardPlayed(sourcePlayerUserId, sourceCardInstanceId, targetInfoList, originZoneZoneEnum, destinationZoneZoneEnum) {
        const sourcePlayer = this.gameState.getPlayerInfoByUserId(sourcePlayerUserId);
        if (!sourcePlayer) {
            throw new Error('Source player not found');
        }
        // this will already have been added to the origin zone during receivedPlayCardMessageFromServer
        // if it didn't already exist
        const sourceCard = this.gameState.getCardFromAnywhere(sourceCardInstanceId);
        if (!sourceCard) {
            throw new Error('Source card not found');
        }
        // first we move the card
        const moveEffect = MoveCardEffect.createMoveCardEffect(originZoneZoneEnum, destinationZoneZoneEnum);
        return EffectSolver.doEffect(this.gameState, sourceCardInstanceId, moveEffect, targetInfoList);
    }
    // #endregion
    // #region Drawing Cards
    onPlayerDrewCards(msg) {
        // TODO: Implement method
    }
    onOpponentDrewCards(msg) {
        // TODO: Implement method
    }
    // #endregion
    // #region Card Moved
    // for when the server moves the card - just move it, don't queue it
    onCardMoved(msg) {
        const message = ServerMovedCardMessage.fromJSON(msg);
        if (!message || !message.validate()) {
            console.log('Invalid ServerMovedCardMessage');
            return;
        }
        const moveEffect = MoveCardEffect.createMoveCardEffect(msg.originZoneZoneEnum, msg.destinationZoneZoneEnum);
        if (!msg.card) {
            throw new Error('Card not found');
        }
        let sourceCard = this.gameState.getCardFromAnywhere(msg.card.instanceId);
        if (!sourceCard) {
            // if we couldn't find the card, it must be a card that we don't know about yet
            // let's first confirm that it didn't come from a place that we should have known about it
            const originZone = this.gameState.getZoneByInstanceId(msg.originZoneZoneEnum);
            const isPlayerZone = originZone.ownerPlayerUserId === this.userId;
            if (isPlayerZone) {
                if (originZone.getLibraryZone().ownerVisibility ===
                    ZoneOwnerVisibility.Visible) {
                    throw new Error('We should have known about this card');
                }
            }
            else if (originZone.getLibraryZone().opponentVisibility ===
                ZoneOpponentVisibility.Visible) {
                throw new Error('We should have known about this card');
            }
            // all good - we didn't know about this card, let's create it
            sourceCard = RuntimeCard.fromRuntimeJSON(msg.card);
            originZone.addCard(sourceCard);
            // we don't need to remove it from anywhere because it wasn't anywhere
        }
        return EffectSolver.doEffect(this.gameState, sourceCard.instanceId, moveEffect, []);
    }
    // for when the player moves a card between rows - queue it
    receivedCardMovedRowMessage(msg) {
        if (this.playerFlowEnum !== PlayerFlowEnum.WaitingForServer) {
            throw new Error('Not ready for queue');
        }
        const message = ServerCardMovedRowMessage.fromJSON(msg);
        if (!message || !message.validate()) {
            throw new Error('Invalid ServerCardMovedRowMessage');
        }
        const queueline = new MoveRowPlayerQueueline(this.userId, message.movedCardInstanceId, message.ownerPlayerUserId, message.queuePosition, message.originZoneEnum, message.destinationZoneEnum);
        this.queue.push(queueline);
    }
    onCardMovedRow(movedCardInstanceId, originZoneEnum, destinationZoneEnum) {
        const movedCard = this.gameState.getCardFromAnywhere(movedCardInstanceId);
        if (!movedCard) {
            throw new Error('Moved card not found');
        }
        const moveEffect = MoveCardEffect.createMoveCardEffect(originZoneEnum, destinationZoneEnum);
        return EffectSolver.doEffect(this.gameState, movedCardInstanceId, moveEffect, []);
    }
    // #endregion
    // #region Upgrading Cards
    onCardUpgraded(upgradingCardInstanceId, targetInfoList, upgradeLevel) {
        const upgradingCard = this.gameState.getCardFromAnywhere(upgradingCardInstanceId);
        if (!upgradingCard) {
            throw new Error('Upgrading card not found');
        }
        const libraryCard = this.gameState.gameManager.cardLibrary.find((card) => {
            return card.libraryId === upgradingCard.libraryId;
        });
        if (!libraryCard) {
            throw new Error('Library card not found');
        }
        const upgrade = libraryCard.cardUpgrades[upgradeLevel];
        if (!upgrade) {
            throw new Error('Upgrade not found');
        }
        const upgradeEffect = UpgradeCardEffect.createUpgradeCardEffect(upgradeLevel);
        const results = [];
        results.push(...EffectSolver.doEffect(this.gameState, upgradingCardInstanceId, upgradeEffect, targetInfoList));
        // now do activated ability, if it has one
        const activatedEffect = upgrade.activatedAbility.effect;
        if (activatedEffect) {
            results.push(...EffectSolver.doEffect(this.gameState, upgradingCardInstanceId, activatedEffect, targetInfoList));
        }
        return results;
    }
    queueUpgradeCard(currentCardInstanceId, currentTrackingIndex, currentEffect, upgradeLevel, useEffect) {
        // add to queued messages
        // send to server
    }
    receivedUpgradeCardMessageFromServer(msg) {
        if (this.playerFlowEnum !== PlayerFlowEnum.WaitingForServer) {
            throw new Error('Not ready for queue');
        }
    }
    // #endregion
    // #region Combat and Attacking
    queueAttack(attackingCardInstanceId, attackedCardInstanceId) {
        const attackingCard = this.gameState.getCardFromAnywhere(attackingCardInstanceId);
        if (!attackingCard) {
            throw new Error('Attacking card not found');
        }
        const attackedCard = this.gameState.getCardFromAnywhere(attackedCardInstanceId);
        if (!attackedCard) {
            throw new Error('Attacked card not found');
        }
        const message = new QueueFightCreatureMessage(QueueFightCreatureMessage.generateUniqueId(), this.userId, attackingCardInstanceId, attackedCardInstanceId);
        this.getPlayerInfo(this.gameState).queueMessages.push(message);
        this.sendToServer(NetworkProtocol.QueueFightCreature, message);
    }
    cancelAttack(attackingCardInstanceId) {
        const attackMessages = this.getPlayerInfo(this.gameState).queueMessages.filter((message) => {
            return message.messageEnum === NetworkProtocol.QueueFightCreature;
        });
        const attackMessage = attackMessages.find((message) => {
            return message.attackingCardInstanceId === attackingCardInstanceId;
        });
        if (!attackMessage) {
            throw new Error('Attack message not found');
        }
        const message = new CancelActionMessage(ClientMessage.generateUniqueId(), this.userId, attackMessage.messageId);
        // cancel from the queue
        this.getPlayerInfo(this.gameState).queueMessages = this.getPlayerInfo(this.gameState).queueMessages.filter((message) => {
            return message.messageId !== attackMessage.messageId;
        });
        this.sendToServer(NetworkProtocol.CancelAction, message);
    }
    receivedAttackMessage(msg) {
        if (this.playerFlowEnum !== PlayerFlowEnum.WaitingForServer) {
            return; // likely already moved on from reviewing queue
        }
        const message = CreatureAttackedMessage.fromJSON(msg);
        if (!message || !message.validate()) {
            console.log('Invalid CreatureAttackedMessage');
            return;
        }
        const queueline = new FightCreaturePlayerQueueLine(this.userId, message.attackingCardInstanceId, message.attackedCardInstanceId, message.attackingPlayerUserId, message.queuePosition);
        this.queue.push(queueline);
        if (this.queue.length === this.numQueuelinesTotal) {
            this.startQueue();
        }
    }
    onCreatureAttacked(attackingCardInstanceId, attackedCardInstanceId) {
        const fightEffect = AttackEffect.createFightEffect();
        const tempTargetInfo = AttackEffect.createFightTargetInfoList(attackedCardInstanceId);
        return EffectSolver.doEffect(this.gameState, attackingCardInstanceId, fightEffect, tempTargetInfo);
    }
    // #endregion
    // #region Blocking
    queueBlock(blockingCardInstanceId, blockedCardInstanceId) {
        const blockingCard = this.gameState.getCardFromAnywhere(blockingCardInstanceId);
        if (!blockingCard) {
            throw new Error('Blocking card not found');
        }
        const blockedCard = this.gameState.getCardFromAnywhere(blockedCardInstanceId);
        if (!blockedCard) {
            throw new Error('Blocked card not found');
        }
        const blocksOnSameCard = this.gameState.blocks.filter((block) => block.blockedCardInstanceId === blockedCardInstanceId);
        const blockOrder = blocksOnSameCard.length;
        this.gameState.cardBlocking(blockingCardInstanceId, blockedCardInstanceId, blockOrder);
        const message = new CardIsBlockingMessage(ClientMessage.generateUniqueId(), this.userId, blockingCardInstanceId, blockedCardInstanceId, blockOrder);
        this.sendToServer(NetworkProtocol.CardIsBlocking, message);
    }
    cancelBlock(blockingCardInstanceId) {
        const blockingCard = this.gameState.getCardFromAnywhere(blockingCardInstanceId);
        if (!blockingCard) {
            throw new Error('Blocking card not found');
        }
        this.gameState.stopCardBlocking(blockingCardInstanceId);
        const message = new StopCardBlockingMessage(ClientMessage.generateUniqueId(), this.userId, blockingCardInstanceId);
        this.sendToServer(NetworkProtocol.StopCardBlocking, message);
    }
    changeBlockOrder(blockingCardInstanceId, newBlockOrder) {
        const blockingCard = this.gameState.getCardFromAnywhere(blockingCardInstanceId);
        if (!blockingCard) {
            throw new Error('Blocking card not found');
        }
        this.gameState.reorderBlockingCard(blockingCardInstanceId, newBlockOrder);
        const message = new ReorderBlockingCardMessage(ClientMessage.generateUniqueId(), this.userId, blockingCardInstanceId, newBlockOrder);
        this.sendToServer(NetworkProtocol.ReorderBlockingCard, message);
    }
    // #endregion
    // #region Abilities
    queueActivateAbility(sourceEntityInstanceId, abilityIndex, paidCosts, targetInfoList) {
        const sourceEntity = this.gameState.getEntityFromAnywhere(sourceEntityInstanceId);
        if (!sourceEntity) {
            throw new Error('Entity not found');
        }
        const ability = sourceEntity.abilities[abilityIndex];
        if (!ability) {
            throw new Error('Ability not found');
        }
        if (!ability.effect.areTargetsAvailable(this.gameState, sourceEntity)) {
            throw new Error('No targets available');
        }
        if (!ability.effect.isAllTargetInfoValid(sourceEntity, this.gameState, targetInfoList)) {
            throw new Error('Invalid target info');
        }
        const costs = ability.costs;
        const playerInfo = this.gameState.getPlayerInfoByUserId(this.userId);
        if (!playerInfo) {
            throw new Error('Player not found');
        }
        if (!playerInfo.canPayResourceCosts(costs)) {
            throw new Error('Player cannot pay resource costs');
        }
        if (ability.usesRemaining <= 0) {
            throw new Error('Ability has no uses remaining');
        }
        ability.usesRemaining--;
        console.log('ability.usesRemaining: ', ability.usesRemaining);
        playerInfo.payResourceCosts(costs, paidCosts);
        const message = new QueueActivateAbilityMessage(ClientMessage.generateUniqueId(), this.userId, sourceEntityInstanceId, abilityIndex, paidCosts, targetInfoList);
        this.getPlayerInfo(this.gameState).queueMessages.push(message);
        this.sendToServer(NetworkProtocol.QueueActivateAbility, message);
    }
    receivedActivateAbilityMessageFromServer(msg) {
        if (this.playerFlowEnum !== PlayerFlowEnum.WaitingForServer) {
            return; // likely already moved on from reviewing queue
        }
        const message = AbilityActivatedMessage.fromJSON(msg);
        if (!message || !message.validate()) {
            console.log('Invalid AbilityActivatedMessage');
            return;
        }
        if (message.playerUsingAbilityUserId !== this.userId) {
            const opponent = this.gameState.getPlayerInfoByUserId(message.playerUsingAbilityUserId);
            if (!opponent) {
                throw new Error('Opponent not found');
            }
            opponent.payResourceCosts(message.paidCosts);
        }
        const queueline = new ActivateAbilityPlayerQueueline(this.userId, message.entityUsingAbilityInstanceId, message.playerUsingAbilityUserId, message.queuePosition, message.paidCosts, message.targetInfoList, message.abilityIndex);
        this.queue.push(queueline);
        if (this.queue.length === this.numQueuelinesTotal) {
            this.startQueue();
        }
    }
    onActivateAbility(sourceEntityInstanceId, targetInfoList, abilityIndex) {
        const entity = this.gameState.getEntityFromAnywhere(sourceEntityInstanceId);
        if (!entity) {
            throw new Error('Entity not found');
        }
        const ability = entity.abilities[abilityIndex];
        if (!ability) {
            throw new Error('Ability not found');
        }
        const effect = ability.effect;
        if (!effect) {
            throw new Error('Effect not found');
        }
        return EffectSolver.doEffect(this.gameState, sourceEntityInstanceId, effect, targetInfoList);
    }
    // #endregion
    // #region Returning Targets
    // receivedTargetsRequestedMessage(msg: GetTargetsFromPlayerMessage): void {
    //   if (!this.readyForQueue) {
    //     throw new Error('Not ready for queue');
    //   }
    //   const message = GetTargetsFromPlayerMessage.fromJSON(msg);
    //   if (!message || !message.validate()) {
    //     console.log('Invalid GetTargetsFromPlayerMessage');
    //     return;
    //   }
    //   const queueline = new ServerRequestsTargetsPlayerQueueline(
    //     this.userId,
    //     message.cardInstanceId,
    //     message.recipientUserId,
    //     message.queueOrder,
    //     message.effect,
    //     message.targetInfoCode
    //   );
    //   this.queue.push(queueline);
    //   if (this.queue.length === this.numQueuelinesTotal) {
    //     this.startQueue();
    //   }
    // }
    // onServerRequestsTargets(
    //   effect: RuntimeEffect,
    //   cardInstanceId: number,
    //   targetCriterias: TargetCriteria[],
    //   targetInfoCode: number
    // ): void {
    //   this.targetInfoCode = targetInfoCode;
    //   this.returningCardInstanceId = cardInstanceId;
    //   this.returningEffect = effect;
    //   this.returningTargetTypes = targetCriterias;
    // }
    // returnTargetsToServer(targetInfo: TargetInfo[]): void {
    //   const message = new ReturnTargetsToServerMessage(
    //     ClientMessage.generateUniqueId(),
    //     this.userId,
    //     targetInfo,
    //     this.targetInfoCode
    //   );
    //   this.sendToServer(NetworkProtocol.ReturnTargetsToServer, message);
    //   // clear target tracking
    //   this.targetInfoCode = null;
    //   this.returningCardInstanceId = null;
    //   this.returningEffect = null;
    //   this.returningTargetTypes = null;
    // }
    // #endregion
    // #region Land and Mana
    exploreLand(landTile) {
        const message = new PlayerExploredLandMessage(ClientMessage.generateUniqueId(), this.userId, true, landTile.id);
        // have to put this here because we can overwrite server state if we put
        // it in onLandExplored
        this.playerFlowEnum = PlayerFlowEnum.WaitingForOpponentExplore;
        // send to server
        this.sendToServer(NetworkProtocol.PlayerExploredLand, message);
    }
    onLandExplored(msg) {
        const message = LandExploredMessage.fromJSON(msg);
        if (!message || !message.validate()) {
            console.log('Invalid LandExploredMessage');
            return;
        }
        const player = this.gameState.getPlayerInfoByUserId(message.playerUserId);
        player.realm.exploreLandTile(message.landTileId);
    }
}
export default Player;
