import MoveCardEffect from '../Effect/RuntimeEffects/MoveEffects/MoveCardEffect.js';
import { EffectType } from '../../Enums/Effect.js';
import { ZoneEnum, ZoneEnumMethods, ZoneRefreshType, } from '../../Enums/Zone.js';
import GameProperties from './GameProperties.js';
class EffectSolver {
    // #region On Methods for Turns and Phases
    static onRecruitmentPhaseStarted(gameState) {
        EffectSolver.resetBlockers(gameState);
        // reset all uses of all abilities
        for (let player of gameState.players) {
            for (let zone of player.zones) {
                for (let enchantment of zone.enchantments) {
                    for (let ability of enchantment.abilities) {
                        ability.usesRemaining = ability.usesPerTurn;
                    }
                }
                for (let card of zone.cards) {
                    for (let ability of card.abilities) {
                        ability.usesRemaining = ability.usesPerTurn;
                    }
                    for (let enchantment of card.enchantments) {
                        for (let ability of enchantment.abilities) {
                            ability.usesRemaining = ability.usesPerTurn;
                        }
                    }
                }
            }
        }
        // DoEffect StartPhaseEffect if this is functionality that we need.
    }
    static onRecruitmentPhaseEnded(gameState) {
        EffectSolver.resetBlockers(gameState);
        // DoEffect EndPhaseEffect if this is functionality that we need.
    }
    static onManeuverPhaseStarted(gameState) {
        EffectSolver.resetBlockers(gameState);
        // DoEffect StartPhaseEffect if this is functionality that we need.
        for (let player of gameState.players) {
            player.setPlayerManaFromLand();
        }
    }
    static onManeuverPhaseEnded(gameState) {
        // DoEffect EndPhaseEffect if this is functionality that we need.
    }
    static onSkirmishPhaseStarted(gameState) {
        // DoEffect StartPhaseEffect if this is functionality that we need.
    }
    static onSkirmishPhaseEnded(gameState) {
        // DoEffect EndPhaseEffect if this is functionality that we need.
    }
    static onBattlePhaseStarted(gameState) {
        // DoEffect StartPhaseEffect if this is functionality that we need.
    }
    static onBattlePhaseEnded(gameState) {
        this.resetBlockers(gameState);
        // DoEffect EndPhaseEffect if this is functionality that we need.
    }
    // #endregion
    // #region Blocking
    static resetBlockers(gameState) {
        gameState.blocks = [];
    }
    // #endregion
    // #region Utilities - random numbers
    static getRandomNumber(max) {
        return Math.floor(Math.random() * max);
    }
    static getRandomNumberInRange(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
    // #endregion
    // #region Main Functions - doEffect and updateStatBuffs
    static doEffect(gameState, sourceEntityInstanceId, effect, targetInfoList) {
        const results = [];
        let sourceEntity = gameState.getEntityFromAnywhere(sourceEntityInstanceId);
        if (!sourceEntity) {
            throw new Error('doEffect called with invalid sourceEntityInstanceId: ' +
                sourceEntityInstanceId);
        }
        let success = effect.preEffect(gameState, sourceEntity, targetInfoList);
        if (!success) {
            console.log('preeffect failed for effect: ' + EffectType[effect.effectType]);
            return;
        }
        // order doesn't matter all that much for the other preresolves, becuase they
        // can all create new effects if they really need other thigns to respond to them
        for (let p of gameState.players) {
            for (let zone of p.zones) {
                let zoneDefinition = GameProperties.gameZones.find((x) => x.zoneEnum === zone.zoneEnum);
                if (zoneDefinition.refreshType === ZoneRefreshType.Dynamic) {
                    const preResults = zone.preResolveEffect(effect, sourceEntityInstanceId, gameState, targetInfoList);
                    results.push(...preResults);
                }
            }
        }
        // resolve the effect
        const effectResults = effect.resolve(gameState, sourceEntity, targetInfoList);
        results.push(...effectResults);
        for (let p of gameState.players) {
            for (let zone of p.zones) {
                let zoneDefinition = GameProperties.gameZones.find((x) => x.zoneEnum === zone.zoneEnum);
                if (zoneDefinition.refreshType === ZoneRefreshType.Dynamic) {
                    const postResults = zone.postResolveEffect(effect, sourceEntityInstanceId, gameState, targetInfoList);
                    results.push(...postResults);
                }
            }
        }
        EffectSolver.updateStatBuffs(gameState);
        // check for dead cards
        const deathResults = EffectSolver.checkForDeadCards(gameState);
        results.push(...deathResults);
        return results;
    }
    static updateStatBuffs(gameState) {
        // update stat buffs
        // this bit just iterates through all stats - until marker
        for (let statPlayer of gameState.players) {
            for (let statZone of statPlayer.zones.filter((z) => ZoneEnumMethods.isBoard(z.zoneEnum))) {
                // this returns just the boards
                for (let statCard of statZone.cards) {
                    for (let stat of statCard.getStatList()) {
                        // marker - now we've got a stat
                        // from here, we're going to iterate through all keywords and see if they want to affect our stat
                        // first, we'll keep a record of the current effective value, because if it changes we want
                        // to call an onValueChanged for the stat
                        let oldEffectiveValue = stat.effectiveValue;
                        // have to clear them before we add
                        stat.buffs = [];
                        // now we do our big loop with everything
                        for (let player of gameState.players) {
                            for (let zone of player.zones.filter((z) => ZoneEnumMethods.isBoard(z.zoneEnum))) {
                                for (let enchantment of zone.enchantments) {
                                    for (let keyword of enchantment.keywords) {
                                        let outBuff = keyword.addStatBuff(stat, statCard.instanceId, gameState);
                                        if (outBuff != null) {
                                            stat.addBuff(outBuff.value, outBuff.details);
                                        }
                                    }
                                }
                                for (let card of zone.cards) {
                                    for (let keyword of card.keywords) {
                                        let outBuff = keyword.addStatBuff(stat, statCard.instanceId, gameState);
                                        if (outBuff != null) {
                                            stat.addBuff(outBuff.value, outBuff.details);
                                        }
                                    }
                                    for (let enchantment of card.enchantments) {
                                        for (let keyword of enchantment.keywords) {
                                            let outBuff = keyword.addStatBuff(stat, statCard.instanceId, gameState);
                                            if (outBuff != null) {
                                                stat.addBuff(outBuff.value, outBuff.details);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        let outString = '';
                        for (let buff of stat.buffs) {
                            outString += buff.details;
                        }
                        // call the action - this should presumable update everything important as well
                        if (stat.effectiveValue !== oldEffectiveValue &&
                            stat.onValueChanged != null) {
                            stat.onValueChanged(oldEffectiveValue, stat.effectiveValue);
                        }
                    }
                }
            }
        }
    }
    static checkForDeadCards(gameState) {
        const results = [];
        for (let player of gameState.players) {
            const playZones = player.zones.filter((z) => ZoneEnumMethods.isBoard(z.zoneEnum));
            for (let zone of playZones) {
                for (let card of zone.cards) {
                    if (card.health.effectiveValue <= 0) {
                        results.push(...EffectSolver.doEffect(gameState, card.instanceId, MoveCardEffect.createMoveCardEffect(gameState.getZoneByInstanceId(card.residingZoneInstanceId)
                            .zoneEnum, ZoneEnum.Graveyard), []));
                    }
                }
            }
        }
        return results;
    }
}
export default EffectSolver;
