import React from 'react';

// internal
import TitledIntcreaser from '../Utility/TitledIntcreaser.jsx';
import { TypeItemStyled } from './styles/TypeItem.styled.js';

// lol
import { CardType } from '../../../../../../LegendsOfLeakos/lib/Enums/CardType.js';

function Types({ type, index, changeType, removeType }) {
  return (
    <TypeItemStyled className='type-item'>
      <TitledIntcreaser
        title='Card Type'
        int={type}
        onIntChange={(newType) => {
          changeType(index, newType);
        }}
        _enum={CardType}
      />
      <button className='remove-type-button' onClick={() => removeType(index)}>
        X
      </button>
    </TypeItemStyled>
  );
}

export default Types;
