import styled from 'styled-components';
import '../../../../styles/constants.css';

export const BoardEnchantmentListStyled = styled.div`
  width: 100%;
  min-height: 30px;
  box-sizing: border-box; // Include border in element's total width and height
  z-index: ${(props) =>
    props.amHovering ? '999' : 'var(--board-card-z-index)'};

  .enchantments {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${(props) => (props.isPlayerEnchantment ? 'right: 62%;' : 'left: 62%;')}
    z-index: 2; // Ensure it's above the .length-background
    width: ${({ boardCardWidth }) => `${boardCardWidth}px`};
  }

  .length-background {
    width: ${({ boardCardWidth }) => `${boardCardWidth / 3}px`};
    height: ${({ boardCardWidth }) => `${boardCardWidth / 3}px`};
    background-color: var(--dark-green);
    border: 2px solid
      ${({ zoneHovered }) =>
        zoneHovered ? 'var(--border-color-bright)' : 'var(--border-color-dim)'};
    transform: rotate(45deg);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    z-index: 0;
  }

  .length-text {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .center-line {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 2px;
    background-color: ${({ zoneHovered }) =>
      zoneHovered ? 'var(--border-color-bright)' : 'var(--border-color-dim)'};
    z-index: 0;
  }
`;
