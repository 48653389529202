// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// internal context
import { PlayerContext } from '../../../../contexts/PlayerContextProvider';
import { GameElementSizeContext } from '../../../../contexts/GameElementSizeContextProvider';
import { ImageContext } from '../../../../contexts/ImageContextProvider';
import { GameStatusContext } from '../../../../contexts/GameStatusContextProvider';
import { QueueReviewContext } from '../../../../contexts/QueueReviewContextProvider';

// css
import { EndGamePopupStyled } from './styles/EndGamePopup.styled.js';
import CurrentAction from '../../ActionLines/CurrentAction';
import { GamePlayState } from '../../../../../../../LegendsOfLeakos/lib/Enums/GamePlayState';

// lol

interface EndGamePopupProps {
  handleGameEndedButton: () => void;
}

const EndGamePopup: React.FC<EndGamePopupProps> = ({
  handleGameEndedButton,
}) => {
  // #region context
  const { masterPlayer } = useContext(PlayerContext);
  const { currentEventIndex } = useContext(QueueReviewContext);
  const { gameStatus } = useContext(GameStatusContext);
  const { gameContainerHeight, gameContainerWidth } = useContext(
    GameElementSizeContext
  );
  const { endGamePopupBackgroundDefeat, endGamePopupBackgroundVictory } =
    useContext(ImageContext);
  // #endregion

  // #region toggle end game popup

  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    if (gameStatus === GamePlayState.Ongoing) return;
    if (currentEventIndex == null) return;

    if (currentEventIndex === masterPlayer.stack.length - 1) {
      setShowPopup(true);
    }
  }, [gameStatus, currentEventIndex]);
  // #endregion

  return (
    <EndGamePopupStyled
      gameContainerHeight={gameContainerHeight}
      gameContainerWidth={gameContainerWidth}
      background={
        gameStatus === GamePlayState.PlayerLost
          ? endGamePopupBackgroundDefeat
          : gameStatus === GamePlayState.Draw
          ? endGamePopupBackgroundDefeat
          : endGamePopupBackgroundVictory
      }
    >
      {gameStatus !== GamePlayState.Ongoing && (
        <div className='all-content'>
          <div className='buttons'>
            <button className='popup-button' onClick={handleGameEndedButton}>
              {gameStatus === GamePlayState.PlayerLost
                ? 'Retreat to lobby'
                : gameStatus === GamePlayState.Draw
                ? 'Regroup in lobby'
                : 'Celebrate in lobby'}
            </button>
            <button
              className='popup-button'
              onClick={() => setShowPopup(!showPopup)}
            >
              {showPopup ? 'Review Final Turn' : 'Show Game Summary'}
            </button>
          </div>
          {showPopup && (
            <div className='toggle-content'>
              <div className='background-dim'></div>
              <div className='inner-popup-content'>
                <div className='toggle-content'>
                  <p className='game-result-text'>
                    {gameStatus === GamePlayState.PlayerLost
                      ? 'Defeat'
                      : gameStatus === GamePlayState.Draw
                      ? 'Stalemate'
                      : 'Victory!'}
                  </p>
                  <div className='image-holder'></div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </EndGamePopupStyled>
  );
};

export default EndGamePopup;
