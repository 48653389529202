import styled from 'styled-components';
import '../../../../styles/constants.css';

export const CardStyled = styled.div`
  width: 100px;
  height: 100px;
  z-index: 100;

  .library-card-holder {
    width: 100%;
    height: 100%;
  }
`;
