import { KeywordType } from '../../../Enums/Keyword.js';
class RuntimeKeyword {
    constructor() {
        // conditions for stat buffs
        this.conditions = [];
        // #endregion
    }
    // #region EFFECTS
    addStatBuff(stat, statCardInstanceId, gameState) {
        return null;
    }
    preResolveEffect(e, sourceEntityInstanceId, gameState, targetInfoList) {
        return null;
    }
    postResolveEffect(e, sourceEntityInstanceId, gameState, targetInfoList) {
        return null;
    }
    // #endregion
    // #region End Turn
    onEndTurn(gameState) {
        if (!this.isPermanent) {
            this.duration = this.duration - 1;
            if (this.duration <= 0) {
                const myEntity = gameState.getEntityFromAnywhere(this.myEntityInstanceId);
                if (!myEntity) {
                    throw new Error(`Could not find entity with id ${this.myEntityInstanceId}`);
                }
                myEntity.removeKeyword(this);
            }
        }
    }
    static registerFromRuntimeJSON(type, method) {
        this.fromRuntimeJSONMethods[type] = method;
    }
    static fromRuntimeJSON(json) {
        const keywordType = KeywordType[json.keywordType];
        const method = this.fromRuntimeJSONMethods[keywordType];
        if (!method) {
            throw new Error('Keyword type not registered for: ' +
                KeywordType[keywordType] +
                ' - ' +
                JSON.stringify(json) +
                ' - need to initialize in GameManager');
        }
        return method(json);
    }
    static registerFromLibraryJSON(type, method) {
        this.fromLibraryJSONMethods[type] = method;
    }
    static fromLibraryJSON(myEntityInstanceId, json) {
        const keywordType = KeywordType[json.keywordType];
        const method = this.fromLibraryJSONMethods[keywordType];
        if (!method) {
            throw new Error('Keyword type not registered for: ' +
                KeywordType[keywordType] +
                ' - ' +
                JSON.stringify(json) +
                ' - need to initialize in GameManager');
        }
        return method(myEntityInstanceId, json);
    }
    static registerIsLibraryJSONValid(type, method) {
        this.isLibraryJSONValidMethods[type] = method;
    }
    static isLibraryJSONValid(json) {
        const keywordType = KeywordType[json.keywordType];
        const method = this.isLibraryJSONValidMethods[keywordType];
        if (!method) {
            throw new Error('Keyword type not registered for: ' +
                KeywordType[keywordType] +
                ' - ' +
                JSON.stringify(json) +
                ' - need to initialize in GameManager');
        }
        return method(json);
    }
    static registerSampleLibraryKeyword(type, creator) {
        this.sampleLibraryKeywordMethods[type] = creator;
    }
    static createSampleLibraryKeyword(type) {
        const method = this.sampleLibraryKeywordMethods[type];
        if (!method) {
            throw new Error('Keyword type not registered for: ' +
                KeywordType[type] +
                ' - need to initialize in GameManager');
        }
        return method();
    }
}
// #endregion
// #region fromRuntimeJSON methods
RuntimeKeyword.fromRuntimeJSONMethods = {};
// #endregion
// #region fromLibraryJSON methods
RuntimeKeyword.fromLibraryJSONMethods = {};
// #endregion
// #region isLibraryJSONValid methods
RuntimeKeyword.isLibraryJSONValidMethods = {};
// #endregion
// #region Register Sample Keyword Creators
RuntimeKeyword.sampleLibraryKeywordMethods = {};
export default RuntimeKeyword;
