import RuntimeEffect from '../../RuntimeEffect.js';
import { EffectType } from '../../../../Enums/Effect.js';
import RuntimeCard from '../../../Card/RuntimeCard.js';
import { ZoneEnum } from '../../../../Enums/Zone.js';
import StackEvent from '../../../StackEvent/StackEvent.js';
import { StackEntityEnum, StackEventEnum } from '../../../../Enums/Stack.js';
import StackEntity from '../../../StackEvent/StackEntity.js';
class MoveCardEffect extends RuntimeEffect {
    targetCriterias() {
        return [];
    }
    constructor(originZoneZoneEnum, destinationZoneZoneEnum) {
        super(); // nothing in super
        this.effectType = EffectType.MoveCardEffect;
        this.originZoneZoneEnum = originZoneZoneEnum;
        this.destinationZoneZoneEnum = destinationZoneZoneEnum;
    }
    // #endregion
    // #region Effect Runtime Execution
    preEffect(state, sourceEntity, targetInfoList) {
        const residingZone = state.getZoneByInstanceId(sourceEntity.residingZoneInstanceId);
        if (!residingZone) {
            throw new Error('\n\nERROR: MoveCardEffect.preEffect - no residing zone. \n\n sourceEntity: ' +
                JSON.stringify(sourceEntity));
        }
        if (residingZone.zoneEnum !== this.originZoneZoneEnum)
            return false;
        return true;
    }
    resolve(state, sourceEntity, targetInfoList) {
        // shouldn't be trying to move a non-card
        if (!(sourceEntity instanceof RuntimeCard))
            throw new Error('Why is non card entity attacking?');
        let sourceCard = sourceEntity;
        let ownerPlayer = state.getPlayerInfoByUserId(sourceCard.ownerPlayerUserId);
        const residingZone = state.getZoneByInstanceId(sourceCard.residingZoneInstanceId);
        // make sure it's still where it thinks it is
        if (residingZone.zoneEnum !== this.originZoneZoneEnum) {
            console.log('Returning out of resolve');
            return [
                new StackEvent(StackEventEnum.EffectResolve, state.clone(), false, 'Card is not in the correct zone', [new StackEntity(sourceCard.instanceId, StackEntityEnum.Source)]),
            ];
        }
        let originZone = ownerPlayer.zones.find((c) => c.zoneEnum === this.originZoneZoneEnum);
        let destinationZone = ownerPlayer.zones.find((c) => c.zoneEnum === this.destinationZoneZoneEnum);
        originZone.removeCard(sourceCard);
        destinationZone.addCard(sourceCard);
        return [
            new StackEvent(StackEventEnum.EffectResolve, state.clone(), true, 'Card moved to ' + ZoneEnum[this.destinationZoneZoneEnum], [new StackEntity(sourceCard.instanceId, StackEntityEnum.Source)]),
        ];
    }
    // #endregion
    // #region Available and Valid Checks
    areTargetsAvailable(state, sourceEntity) {
        return true;
    }
    isAllTargetInfoValid(sourceEntity, state, targetInfoList) {
        return true;
    }
    // #endregion
    // #region Effect To Text
    effectToString() {
        let outText = 'Move this card from ' +
            this.originZoneZoneEnum +
            ' to ' +
            this.destinationZoneZoneEnum +
            '. ';
        return outText;
    }
    // #endregion
    // #region Create Effect for Effect Solver
    static createMoveCardEffect(originZoneZoneEnum, destinationZoneZoneEnum) {
        return new MoveCardEffect(originZoneZoneEnum, destinationZoneZoneEnum);
    }
    // #endregion
    // #region JSON
    toJSON() {
        return {
            effectType: EffectType[this.effectType],
            originZoneZoneEnum: ZoneEnum[this.originZoneZoneEnum],
            destinationZoneZoneEnum: ZoneEnum[this.destinationZoneZoneEnum],
        };
    }
    clone() {
        return new MoveCardEffect(this.originZoneZoneEnum, this.destinationZoneZoneEnum);
    }
}
// we don't need to register this with Effect becuase this won't be created from JSON
// and has it's own creation function
export default MoveCardEffect;
