// external
import React from 'react';
// font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// internal
import TitleButton from './NavbarButtons/TitleButton';

// css
import { MobileNavbarStyled } from './styles/MobileNavbar.styled';

function MobileNavbar({ toggleModal, playClick }) {
  return (
    <MobileNavbarStyled>
      {/* <div className='navbar-item' onClick={toggleModal}>
        <div className='clickable-link' onClick={playClick}>
          <FontAwesomeIcon icon='fa-solid fa-bars' />
        </div>
      </div> */}
      <TitleButton />
      {/* <div className='navbar-item' onClick={toggleModal}>
        <div className='clickable-link' onClick={playClick}>
          <FontAwesomeIcon icon='fa-solid fa-bars' />
        </div>
      </div> */}
    </MobileNavbarStyled>
  );
}

export default MobileNavbar;
