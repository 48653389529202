import { LandType } from '../../../Enums/LandAndBiome.js';
import BaseLandTile from './BaseLandTile.js';
class LibraryLandTile extends BaseLandTile {
    //#region JSON
    toJSON() {
        return {
            id: this.id,
            x: this.x,
            y: this.y,
            z: this.z,
            depth: this.depth,
            // store as a string
            landType: LandType[this.landType],
        };
    }
    //#endregion
    //#region OTHER UTILITIES
    mostCommonNeighborType(allLandTiles) {
        const typeCounts = {};
        for (let neighbor of this.findNeighbors(allLandTiles)) {
            if (neighbor.landType in typeCounts) {
                typeCounts[neighbor.landType] += 1;
            }
            else {
                typeCounts[neighbor.landType] = 1;
            }
        }
        let mostCommonType = 0;
        let mostCommonCount = 0;
        for (let type in typeCounts) {
            if (typeCounts[type] > mostCommonCount) {
                mostCommonType = parseInt(type);
                mostCommonCount = typeCounts[type];
            }
        }
        return mostCommonType;
    }
}
LibraryLandTile.fromJSON = (json) => {
    const tile = new LibraryLandTile(json.id, LandType[json.landType]);
    tile.x = json.x;
    tile.y = json.y;
    tile.z = json.z;
    tile.depth = json.depth;
    return tile;
};
export default LibraryLandTile;
