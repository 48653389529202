var TargetTypeEnum;
(function (TargetTypeEnum) {
    TargetTypeEnum[TargetTypeEnum["TargetCreature"] = 0] = "TargetCreature";
    TargetTypeEnum[TargetTypeEnum["TargetOpponentCreature"] = 1] = "TargetOpponentCreature";
    TargetTypeEnum[TargetTypeEnum["TargetFriendlyCreature"] = 2] = "TargetFriendlyCreature";
    TargetTypeEnum[TargetTypeEnum["TargetRow"] = 3] = "TargetRow";
    TargetTypeEnum[TargetTypeEnum["TargetOpponentRow"] = 4] = "TargetOpponentRow";
    TargetTypeEnum[TargetTypeEnum["TargetFriendlyRow"] = 5] = "TargetFriendlyRow";
    TargetTypeEnum[TargetTypeEnum["OpponentFrontRow"] = 6] = "OpponentFrontRow";
    TargetTypeEnum[TargetTypeEnum["OpponentBackRow"] = 7] = "OpponentBackRow";
    TargetTypeEnum[TargetTypeEnum["FriendlyFrontRow"] = 8] = "FriendlyFrontRow";
    TargetTypeEnum[TargetTypeEnum["FriendlyBackRow"] = 9] = "FriendlyBackRow";
    TargetTypeEnum[TargetTypeEnum["FriendlyBattleRow"] = 10] = "FriendlyBattleRow";
    TargetTypeEnum[TargetTypeEnum["OpponentBattleRow"] = 11] = "OpponentBattleRow";
})(TargetTypeEnum || (TargetTypeEnum = {}));
var BroadTargetTypeEnum;
(function (BroadTargetTypeEnum) {
    BroadTargetTypeEnum[BroadTargetTypeEnum["card"] = 0] = "card";
    BroadTargetTypeEnum[BroadTargetTypeEnum["zone"] = 1] = "zone";
})(BroadTargetTypeEnum || (BroadTargetTypeEnum = {}));
var TargetableTypeSelectionEnum;
(function (TargetableTypeSelectionEnum) {
    TargetableTypeSelectionEnum[TargetableTypeSelectionEnum["TargetableOnActivation"] = 0] = "TargetableOnActivation";
    TargetableTypeSelectionEnum[TargetableTypeSelectionEnum["TargetableOnQueueCall"] = 1] = "TargetableOnQueueCall";
    TargetableTypeSelectionEnum[TargetableTypeSelectionEnum["AutoTarget"] = 2] = "AutoTarget";
})(TargetableTypeSelectionEnum || (TargetableTypeSelectionEnum = {}));
class TargetMethods {
    static broadTargetType(targetTypeEnum) {
        switch (targetTypeEnum) {
            case TargetTypeEnum.TargetCreature:
                return BroadTargetTypeEnum.card;
            case TargetTypeEnum.TargetOpponentCreature:
                return BroadTargetTypeEnum.card;
            case TargetTypeEnum.TargetFriendlyCreature:
                return BroadTargetTypeEnum.card;
            case TargetTypeEnum.TargetOpponentRow:
                return BroadTargetTypeEnum.zone;
            case TargetTypeEnum.TargetRow:
                return BroadTargetTypeEnum.zone;
            case TargetTypeEnum.TargetFriendlyRow:
                return BroadTargetTypeEnum.zone;
            case TargetTypeEnum.OpponentFrontRow:
                return BroadTargetTypeEnum.zone;
            case TargetTypeEnum.OpponentBackRow:
                return BroadTargetTypeEnum.zone;
            case TargetTypeEnum.FriendlyFrontRow:
                return BroadTargetTypeEnum.zone;
            case TargetTypeEnum.FriendlyBackRow:
                return BroadTargetTypeEnum.zone;
            case TargetTypeEnum.FriendlyBattleRow:
                return BroadTargetTypeEnum.zone;
            case TargetTypeEnum.OpponentBattleRow:
                return BroadTargetTypeEnum.zone;
            default:
                throw new Error('Case Not Implemented for broadTargetType: ' +
                    TargetTypeEnum[targetTypeEnum]);
        }
    }
    static canBeTargetable(targetTypeEnum) {
        switch (targetTypeEnum) {
            case TargetTypeEnum.TargetCreature:
                return true;
            case TargetTypeEnum.TargetOpponentCreature:
                return true;
            case TargetTypeEnum.TargetFriendlyCreature:
                return true;
            case TargetTypeEnum.TargetRow:
                return true;
            case TargetTypeEnum.TargetOpponentRow:
                return true;
            case TargetTypeEnum.TargetFriendlyRow:
                return true;
            case TargetTypeEnum.OpponentFrontRow:
                return false;
            case TargetTypeEnum.OpponentBackRow:
                return false;
            case TargetTypeEnum.FriendlyFrontRow:
                return false;
            case TargetTypeEnum.FriendlyBackRow:
                return false;
            case TargetTypeEnum.FriendlyBattleRow:
                return false;
            case TargetTypeEnum.OpponentBattleRow:
                return false;
            default:
                throw new Error('Case Not Implemented for canBeTargetable: ' +
                    TargetTypeEnum[targetTypeEnum]);
        }
    }
    static playerSelectsTargets(targetableTypeSelectionEnum) {
        switch (targetableTypeSelectionEnum) {
            case TargetableTypeSelectionEnum.TargetableOnActivation:
                return true;
            case TargetableTypeSelectionEnum.TargetableOnQueueCall:
                return true;
            case TargetableTypeSelectionEnum.AutoTarget:
                return false;
            default:
                throw new Error('Case Not Implemented for playerSelectsTarget: ' +
                    TargetableTypeSelectionEnum[targetableTypeSelectionEnum]);
        }
    }
}
export { TargetTypeEnum, BroadTargetTypeEnum, TargetableTypeSelectionEnum, TargetMethods, };
