import { TargetTypeEnum, TargetableTypeSelectionEnum, TargetMethods, } from '../../Enums/Target.js';
import RuntimeCondition from '../Condition/RuntimeCondition.js';
import LibraryCondition from '../Condition/LibraryCondition.js';
class LibraryTargetCriteria {
    constructor(name, targetTypeEnum, minSelectionsRequired, maxSelectionsAllowed, minSelectionsThatMustRemain, targetableTypeSelectionEnum, conditions) {
        this.conditions = [];
        this.name = name;
        this.targetTypeEnum = targetTypeEnum;
        this.minSelectionsRequired = minSelectionsRequired;
        this.maxSelectionsAllowed = maxSelectionsAllowed;
        this.minSelectionsThatMustRemain = minSelectionsThatMustRemain;
        this.targetableTypeSelectionEnum = targetableTypeSelectionEnum;
        this.playerSelectsTarget = TargetMethods.playerSelectsTargets(this.targetableTypeSelectionEnum);
        this.conditions = conditions;
    }
    // #region JSON
    clone() {
        return new LibraryTargetCriteria(this.name, this.targetTypeEnum, this.minSelectionsRequired, this.maxSelectionsAllowed, this.minSelectionsThatMustRemain, this.targetableTypeSelectionEnum, this.conditions.map((c) => c.clone()));
    }
    toJSON() {
        return {
            name: this.name,
            targetTypeEnum: TargetTypeEnum[this.targetTypeEnum],
            minSelectionsRequired: this.minSelectionsRequired,
            maxSelectionsAllowed: this.maxSelectionsAllowed,
            minSelectionsThatMustRemain: this.minSelectionsThatMustRemain,
            targetableTypeSelectionEnum: TargetableTypeSelectionEnum[this.targetableTypeSelectionEnum],
            conditions: this.conditions.map((c) => c.toJSON()),
        };
    }
    static fromLibraryJSON(targetTypeJSON) {
        const crit = new LibraryTargetCriteria(targetTypeJSON.name, TargetTypeEnum[targetTypeJSON.targetTypeEnum], targetTypeJSON.minSelectionsRequired, targetTypeJSON.maxSelectionsAllowed, targetTypeJSON.minSelectionsThatMustRemain, TargetableTypeSelectionEnum[targetTypeJSON.targetableTypeSelectionEnum], targetTypeJSON.conditions.map((c) => LibraryCondition.fromJSON(c)));
        return crit;
    }
    static isLibraryJSONValid(json) {
        if (typeof json.name !== 'string') {
            console.log('Invalid JSON: name is not a string');
            return false;
        }
        if (typeof json.targetTypeEnum !== 'string' ||
            !Object.values(TargetTypeEnum).includes(json.targetTypeEnum)) {
            console.log('Invalid JSON: targetTypeEnum is not a valid TargetTypeEnum');
            return false;
        }
        if (typeof json.minSelectionsRequired !== 'number') {
            console.log('Invalid JSON: minSelectionsRequired is not a number');
            return false;
        }
        if (typeof json.maxSelectionsAllowed !== 'number') {
            console.log('Invalid JSON: maxSelectionsAllowed is not a number');
            return false;
        }
        if (typeof json.minSelectionsThatMustRemain !== 'number') {
            console.log('Invalid JSON: minSelectionsThatMustRemain is not a number');
            return false;
        }
        if (typeof json.targetableTypeSelectionEnum !== 'string' ||
            !Object.values(TargetableTypeSelectionEnum).includes(json.targetableTypeSelectionEnum)) {
            console.log('Invalid JSON: targetableTypeSelectionEnum is not a valid TargetableTypeSelectionEnum');
            return false;
        }
        if (!Array.isArray(json.conditions) ||
            !json.conditions.every((c) => RuntimeCondition.isLibraryJSONValid(c))) {
            console.log('Invalid JSON: conditions is not an array');
            return false;
        }
        return true;
    }
}
export default LibraryTargetCriteria;
