import PlayerQueueline from '../PlayerQueueline.js';
class ActivateAbilityPlayerQueueline extends PlayerQueueline {
    constructor(myPlayerUserId, sourceCardInstanceId, sourcePlayerUserId, queuePosition, paidCosts, targetInfoList, abilityIndex) {
        super();
        this.fillBaseInfo(myPlayerUserId, sourceCardInstanceId, sourcePlayerUserId, queuePosition);
        this.paidCosts = paidCosts;
        this.targetInfoList = targetInfoList;
        this.abilityIndex = abilityIndex;
    }
    sendEffectToPlayer(gameState, myPlayer) {
        if (!myPlayer) {
            throw new Error('Player not found');
        }
        return myPlayer.onActivateAbility(this.sourceCardInstanceId, this.targetInfoList, this.abilityIndex);
    }
    actionToString(gameState) {
        const sourcePlayer = gameState.getPlayerInfoByUserId(this.sourcePlayerUserId);
        const sourceCard = gameState.getCardFromAnywhere(this.sourceCardInstanceId);
        if (!sourcePlayer || !sourceCard) {
            throw new Error('Player or card not found');
        }
        return `${sourcePlayer.name}'s ${sourceCard.name} (Instance ID: ${sourceCard.instanceId}) activated ability ${this.abilityIndex}`;
    }
    clone() {
        return new ActivateAbilityPlayerQueueline(this.myPlayerUserId, this.sourceCardInstanceId, this.sourcePlayerUserId, this.queuePosition, this.paidCosts.map((x) => x.clone()), this.targetInfoList.map((x) => x.clone()), this.abilityIndex);
    }
}
export default ActivateAbilityPlayerQueueline;
