import styled from 'styled-components';
import '../../../../styles/constants.css';

export const ManaCircleStyled = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid var(--border-color-bright);
  background-color: #b3b3b3;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-position: center;
  height: ${({ cardWidth, active }) =>
    active ? `calc(${cardWidth}px / 4)` : `calc(${cardWidth}px / 5)`};
  width: ${({ cardWidth, active }) =>
    active ? `calc(${cardWidth}px / 4)` : `calc(${cardWidth}px / 5)`};
  font-size: ${({ cardWidth, active }) =>
    active ? `calc(${cardWidth}px / 4)` : `calc(${cardWidth}px / 5)`};
`;
