import styled from 'styled-components';
import '../../../../styles/constants.css';

export const TitleButtonStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  .navbar-title-text {
    font-family: var(--logo-font);
    line-height: var(--nav-bar-height);
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    transition: 0.2s ease;
    color: ${(props) =>
      props.activeOrHovered ? 'var(--lol-gold)' : 'var(--lol-red)'};
  }
  .navbar-title h3:hover {
    transform: scale(1.1);
  }
`;
