// a component that displays a player's mana
// external
import React, { useState, useEffect, useContext } from 'react';

// internal components
import StackListItem from './StackListItem';

// context
import { PlayerContext } from '../../../contexts/PlayerContextProvider';
import { GameElementSizeContext } from '../../../contexts/GameElementSizeContextProvider';
import { QueueReviewContext } from '../../../contexts/QueueReviewContextProvider';

// css
import { StackListStyled } from './styles/StackList.styled.js';
import { PlayerFlowEnum } from '../../../../../../LegendsOfLeakos/lib/Enums/PlayerFlow';

const StackList = () => {
  // context
  const { masterPlayer } = useContext(PlayerContext);
  const { gameContainerWidth } = useContext(GameElementSizeContext);
  const { startQueue, isRunningQueue } = useContext(QueueReviewContext);

  // UI
  const [amActive, setAmActive] = useState(false);

  useEffect(() => {
    if (!masterPlayer || masterPlayer.playerFlowEnum == null) return;
    if (masterPlayer.playerFlowEnum === PlayerFlowEnum.ReviewingQueue) {
      setAmActive(true);
    } else {
      setAmActive(false);
    }
  }, [masterPlayer && masterPlayer.playerFlowEnum]);

  // only show if player is reviewing queue
  if (masterPlayer?.playerFlowEnum !== PlayerFlowEnum.ReviewingQueue) {
    return null;
  }
  return (
    <StackListStyled
      className='stacklist'
      amActive={amActive}
      gameContainerWidth={gameContainerWidth}
    >
      <div className='stacklist-content'>
        {amActive && (
          <div className='play-button' onClick={() => startQueue()}>
            {isRunningQueue ? 'Queue Running...' : 'Play Queue ▶'}
          </div>
        )}
        <div className='triangle-button' onClick={() => setAmActive(!amActive)}>
          {amActive ? 'Hide ▼' : 'Show ▶'}
        </div>
        {amActive && (
          <div className='queue-content'>
            <div className='queuelines'>
              {masterPlayer.stack.map((stackEvent, i) => (
                <StackListItem
                  key={i}
                  stackEvent={stackEvent}
                  stackPosition={i}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </StackListStyled>
  );
};

export default StackList;
