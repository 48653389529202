// external
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// css
import { RealmButtonStyled } from './styles/RealmButton.styled';

// context
import { ImageContext } from '../../../contexts/ImageContextProvider';

function RealmButton() {
  const {
    realmButtonNavbarGlobe,
    realmButtonNavbarBanner,
    realmButtonNavbarRealmsText,
    realmButtonNavbarRedEarth,
  } = useContext(ImageContext);

  // navigate
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = location.pathname === '/collections';

  // hover state
  const [isHovered, setIsHovered] = useState(false);
  const [bannerScale, setBannerScale] = useState(0.1);

  const handleClick = () => {
    if (!isActive) {
      navigate('/collections');
    }
  };

  return (
    <RealmButtonStyled
      className='realm-button'
      amHovered={isHovered}
      bannerScale={bannerScale}
    >
      <div className='globe-image-container'>
        <img className='globe' src={realmButtonNavbarGlobe} />

        {(isActive || isHovered) && (
          <img className='red-earth' src={realmButtonNavbarRedEarth} />
        )}
        <div className='banner-container'>
          <img className='banner' src={realmButtonNavbarBanner} />
          <img className='realms' src={realmButtonNavbarRealmsText} />
        </div>
      </div>
      <div
        className={'click-area'}
        onClick={handleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      ></div>
    </RealmButtonStyled>
  );
}

export default RealmButton;
