import styled from 'styled-components';
import '../../../styles/constants.css';

export const EntityBuilderPageStyled = styled.div`
  position: relative;
  z-index: 1;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center;
  height: 100vh;
  overflow: auto;

  .main-content {
    padding: 10px;
  }

  .tab-button {
    display: inline-block;
    margin: 10px 10px 20px 0;
    color: white;
    font-weight: normal;
  }

  .tab-button.active {
    color: var(--lol-gold);
    font-weight: bold;
  }
`;
