import { PhaseEnum } from '../../Enums/Phase.js';
import { LibraryZone } from '../Zone/LibraryZone.js';
import Phase from '../Phase/Phase.js';
import { ZoneEnum, ZoneOwner, ZoneRefreshType, ZoneOwnerVisibility, ZoneOpponentVisibility, } from '../../Enums/Zone.js';
import Stat from '../Stat/Stat.js';
class GameProperties {
}
GameProperties.minPlayers = 2;
GameProperties.maxPlayers = 2;
GameProperties.realmLayout = [7, 10, 11, 12, 11, 12, 11, 10, 7];
GameProperties.upgradeTreeShape = {
    width: 10,
    height: 6,
};
GameProperties.gameZones = [
    new LibraryZone('Deck', ZoneEnum.Deck, ZoneOwner.Player, ZoneRefreshType.Static, ZoneOwnerVisibility.Hidden, ZoneOpponentVisibility.Hidden, false, 0),
    new LibraryZone('Hand', ZoneEnum.Hand, ZoneOwner.Player, ZoneRefreshType.Dynamic, ZoneOwnerVisibility.Visible, ZoneOpponentVisibility.Hidden, false, 0),
    new LibraryZone('FrontBoard', ZoneEnum.FrontBoard, ZoneOwner.Player, ZoneRefreshType.Dynamic, ZoneOwnerVisibility.Visible, ZoneOpponentVisibility.Visible, false, 0),
    new LibraryZone('BackBoard', ZoneEnum.BackBoard, ZoneOwner.Player, ZoneRefreshType.Dynamic, ZoneOwnerVisibility.Visible, ZoneOpponentVisibility.Visible, false, 0),
    new LibraryZone('Graveyard', ZoneEnum.Graveyard, ZoneOwner.Player, ZoneRefreshType.Static, ZoneOwnerVisibility.Visible, ZoneOpponentVisibility.Visible, false, 0),
    new LibraryZone('BattleBoard', ZoneEnum.BattleBoard, ZoneOwner.Player, ZoneRefreshType.Dynamic, ZoneOwnerVisibility.Visible, ZoneOpponentVisibility.Visible, false, 0),
];
GameProperties.gamePhases = [
    new Phase(PhaseEnum.Recruit),
    new Phase(PhaseEnum.Maneuver),
    new Phase(PhaseEnum.Skirmish),
    new Phase(PhaseEnum.Battle),
];
// phase actions
GameProperties.phasesCardsCanUpgradeIn = [PhaseEnum.Skirmish, PhaseEnum.Battle];
GameProperties.phasesCardsCanBePlayedIn = [PhaseEnum.Maneuver, PhaseEnum.Skirmish];
GameProperties.phasesCardsCanMoveRowsIn = [
    PhaseEnum.Maneuver,
    PhaseEnum.Skirmish,
    PhaseEnum.Battle,
];
GameProperties.playerStats = [
    new Stat(0, 'Life', 20, 20, 0, 99, [], []),
    new Stat(1, 'ForestMana', 0, 0, 0, 99, [], []),
    new Stat(2, 'OceanMana', 0, 0, 0, 99, [], []),
    new Stat(3, 'DesertMana', 0, 0, 0, 99, [], []),
    new Stat(4, 'MountainMana', 0, 0, 0, 99, [], []),
    new Stat(5, 'PrairieMana', 0, 0, 0, 99, [], []),
    new Stat(6, 'FellsMana', 0, 0, 0, 99, [], []),
    new Stat(7, 'TundraMana', 0, 0, 0, 99, [], []),
    new Stat(8, 'AnyMana', 0, 0, 0, 99, [], []),
];
GameProperties.cardStats = [
    new Stat(0, 'Attack', 0, 0, 0, 99, [], []),
    new Stat(1, 'Health', 0, 0, 0, 99, [], []),
    new Stat(2, 'Priority', 0, 0, 0, 99, [], []),
];
export default GameProperties;
