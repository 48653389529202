// external
import React, { useContext, useEffect, useState, useRef } from 'react';

// internal
import { PlayerContext } from './PlayerContextProvider';
import { TurnFlowContext } from './TurnFlowContextProvider';
import { ActionLineContext } from './ActionLineContextProvider';

// lol
import Player from '../../../../LegendsOfLeakos/lib/Classes/Player/Player';
import { PhaseEnum } from '../../../../LegendsOfLeakos/lib/Enums/Phase';
import RuntimeCard from '../../../../LegendsOfLeakos/lib/Classes/Card/RuntimeCard';
import { PlayerFlowEnum } from '../../../../LegendsOfLeakos/lib/Enums/PlayerFlow';

const AttackingContextProvider = ({ children }) => {
  // #region external context
  const {
    masterPlayer,
    displayState,
    queueAttack,
    queueBlock,
    cancelBlock,
    cancelAttack,
  } = useContext(PlayerContext);
  const { queuedMana, setQueuedMana } = useContext(TurnFlowContext);
  // #endregion

  // attacking (inc arrow)
  // we use both amActingInCombat and actingCard because it makes sokme of the tracking
  // way way easier
  const [amActingInCombat, setAmActingInCombat] = useState(false);

  // acting card
  const [actingCard, setActingCard] = useState<RuntimeCard>(null);
  const actingCardRef = useRef(actingCard);
  useEffect(() => {
    actingCardRef.current = actingCard;
  }, [actingCard]);

  const [actingCardPosition, setActingCardPosition] = useState({
    x: 0,
    y: 0,
  });

  // #region attacking and blocking (including arrow)
  // we use both amActingInCombat and actingCard because it makes sokme of the tracking
  // way way easier

  const startCombatTargeting = (event, card) => {
    if (!masterPlayer || !displayState) return;

    if (masterPlayer.playerFlowEnum !== PlayerFlowEnum.PlayerActing) {
      console.log('not in acting');
      return;
    }

    if (displayState.currentPhaseIndex !== PhaseEnum.Battle) {
      console.log('startCombatTargeting - not in battle phase');
      return;
    }

    if (!card) {
      console.log('startCombatTargeting - no card');
      return;
    }

    setAmActingInCombat(true);
    setActingCard(card);

    const rect = event.target.getBoundingClientRect();
    setActingCardPosition({
      x: rect.x + rect.width / 2,
      y: rect.y + rect.height / 2,
    });
  };

  const endCombatTargeting = (event, endingOnCard: RuntimeCard) => {
    if (!masterPlayer || !displayState) return;
    if (!actingCard) {
      console.log('endCombatTargeting - no actingCard');
      return;
    }
    if (!endingOnCard) {
      console.log('endCombatTargeting - no endingOnCard');
      return;
    }
    if (actingCard.instanceId === endingOnCard.instanceId) {
      console.log('endCombatTargeting - actingCard and endingOnCard are same');
      return;
    }

    if (actingCard.ownerPlayerUserId === endingOnCard.ownerPlayerUserId) {
      endBlockTargeting(event, endingOnCard);
    } else {
      endAttackTargeting(event, endingOnCard);
    }
  };

  const endAttackTargeting = (event, attackedCard: RuntimeCard) => {
    if (!!actingCardRef.current) {
      queueAttack(actingCardRef.current, attackedCard);
      setActingCard(null);
    }
  };

  const endBlockTargeting = (event, blockedCard: RuntimeCard) => {
    if (!!actingCardRef.current) {
      if (actingCardRef.current.instanceId === blockedCard.instanceId) {
        console.log('endBlockTargeting - actingCard and blockedCard are same');
        return;
      }

      queueBlock(actingCardRef.current.instanceId, blockedCard.instanceId);
      setActingCard(null);
    }
  };

  const _cancelAttack = (attackingCardInstanceId: number) => {
    if (!masterPlayer || !displayState) return;
    if (!attackingCardInstanceId) {
      console.log('cancelAttack - no attackingCardInstanceId');
      return;
    }
    cancelAttack(attackingCardInstanceId);
  };

  const _cancelBlock = (blockingCardInstanceId: number) => {
    console.log('cancelBlock');
    if (!masterPlayer || !displayState) return;
    if (!blockingCardInstanceId) {
      console.log('cancelBlock - no blockingCardInstanceId');
      return;
    }
    cancelBlock(blockingCardInstanceId);
  };

  // #endregion

  const value = {
    amActingInCombat,
    setAmActingInCombat,
    actingCard,
    actingCardPosition,
    startCombatTargeting,
    endCombatTargeting,
    cancelAttack: _cancelAttack,
    cancelBlock: _cancelBlock,
  };

  return (
    <AttackingContext.Provider value={value}>
      {children}
    </AttackingContext.Provider>
  );
};

interface AttackingContextInterface {
  amActingInCombat: boolean;
  setAmActingInCombat: (amActingInCombat: boolean) => void;
  actingCard: RuntimeCard;
  actingCardPosition: { x: number; y: number };
  startCombatTargeting: (event, card: RuntimeCard) => void;
  endCombatTargeting: (event, endignOnCard: RuntimeCard) => void;
  cancelAttack: (attackingCardInstanceId: number) => void;
  cancelBlock: (blockingCardInstanceId: number) => void;
}

const AttackingContext = React.createContext<AttackingContextInterface>(null);

export { AttackingContextProvider, AttackingContext };
