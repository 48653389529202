import AbilityKeywordRuntimeEntity from '../Entity/AbilityKeywordRuntimeEntity.js';
import RuntimeKeyword from '../Keyword/RuntimeKeyword/RuntimeKeyword.js';
import RuntimeAbility from '../Ability/RuntimeAbility.js';
class RuntimeEnchantment extends AbilityKeywordRuntimeEntity {
    constructor(name, imageName, libraryID, instanceId, creatingEntityInstanceId, creatingPlayerUserId, runtimeKeywords, abilities, residingZoneInstanceId, residingCardInstanceId = null) {
        super();
        this.libraryID = libraryID;
        this.ownerPlayerUserId = creatingPlayerUserId;
        this.instanceId = instanceId;
        this.residingZoneInstanceId = residingZoneInstanceId;
        this.creatingEntityInstanceId = creatingEntityInstanceId;
        this.residingCardInstanceId = residingCardInstanceId;
        this.name = name;
        this.imageName = imageName;
        this.keywords = runtimeKeywords;
        this.abilities = abilities;
    }
    clone() {
        const clone = new RuntimeEnchantment(this.name, this.imageName, this.libraryID, this.instanceId, this.creatingEntityInstanceId, this.ownerPlayerUserId, this.keywords.map((k) => k.clone()), this.abilities.map((a) => a.clone()), this.residingZoneInstanceId, this.residingCardInstanceId);
        return clone;
    }
    toJSON() {
        const json = {
            // start super
            name: this.name,
            instanceId: this.instanceId,
            residingZoneInstanceId: this.residingZoneInstanceId,
            ownerPlayerUserId: this.ownerPlayerUserId,
            runtimeKeywords: this.keywords.map((k) => k.toJSON()),
            abilities: this.abilities.map((a) => a.toJSON()),
            // end super
            imageName: this.imageName,
            libraryID: this.libraryID,
            creatingEntityInstanceId: this.creatingEntityInstanceId,
            residingCardInstanceId: this.residingCardInstanceId,
        };
        return json;
    }
    static fromRuntimeJSON(json) {
        const runtimeEnchantment = new RuntimeEnchantment(json.name, json.imageName, json.libraryID, json.instanceId, json.creatingEntityInstanceId, json.ownerPlayerUserId, json.runtimeKeywords.map((keyword) => {
            return RuntimeKeyword.fromRuntimeJSON(keyword);
        }), json.abilities.map((ability) => RuntimeAbility.fromRuntimeJSON(ability)), json.residingZoneInstanceId, json.residingCardInstanceId);
        return runtimeEnchantment;
    }
    static fromLibraryJSON(json, creatingEntity, ownerPlayer, residingZone, residingCard = null) {
        const runtimeEnchantment = new RuntimeEnchantment(json.name, json.imageName, json.libraryID, ownerPlayer.currentEntityInstanceId++, creatingEntity.instanceId, ownerPlayer.userId, json.keywords.map((keyword) => RuntimeKeyword.fromLibraryJSON(creatingEntity.instanceId, keyword)), json.abilities.map((ability) => RuntimeAbility.fromLibraryJSON(ability)), residingZone.instanceId, !!residingCard ? residingCard.instanceId : null);
        return runtimeEnchantment;
    }
}
export default RuntimeEnchantment;
