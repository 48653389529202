import StatModifier from './StatModifier.js';
import ModifiableInt from '../ModifableInt/ModifiableInt.js';
export class StatUpgrade {
    constructor(statId, value) {
        this.statId = statId;
        this.value = value;
    }
    upgradeStat(stat) {
        if (stat.statId !== this.statId)
            return;
        const mod = new StatModifier(this.value.effectiveValue);
        stat.addModifier(mod);
    }
    toJSON() {
        return {
            statId: this.statId,
            value: this.value.toJSON(),
        };
    }
    static fromJSON(json) {
        return new StatUpgrade(json.statId, ModifiableInt.fromJSON(json.value));
    }
}
export default StatUpgrade;
