import LibraryCard from '../Card/LibraryCard.js';
import LibraryEnchantment from '../Enchantment/LibraryEnchantment.js';
//
// In-game entry point to all data outside of game state.
// initializations
// keyword
import AttributeKeyword from '../Keyword/RuntimeKeyword/RuntimeKeywords/AttributeKeyword.js';
import DamageModificationKeyword from '../Keyword/RuntimeKeyword/RuntimeKeywords/DamageModificationKeyword.js';
import WarleaderKeyword from '../Keyword/RuntimeKeyword/RuntimeKeywords/WarleaderKeyword.js';
// effects
import DealSetDamageEffect from '../Effect/RuntimeEffects/CardTargetEffects/DealSetDamageEffect.js';
import EnchantEffect from '../Effect/RuntimeEffects/EnchantEffects/EnchantEffect.js';
// conditions
import HasKeywordCondition from '../Condition/Conditions/HasKeywordCondition.js';
class GameManager {
    // constructor
    constructor(cardLibrary, enchantmentLibrary) {
        // Properties now exist in their own static class - much easier to refernence that way
        // library objects
        this.cardLibrary = [];
        this.enchantmentLibrary = [];
        this.cardLibrary = cardLibrary;
        this.enchantmentLibrary = enchantmentLibrary;
    }
    static initializeClassesWithRegistration() {
        // initialize all registration functions
        // keyword
        AttributeKeyword.initialize();
        DamageModificationKeyword.initialize();
        WarleaderKeyword.initialize();
        // effects
        DealSetDamageEffect.initialize();
        EnchantEffect.initialize();
        // conditions
        HasKeywordCondition.initialize();
    }
    // methods
    getCardFromLibraryId(libraryId) {
        const card = this.cardLibrary.find((x) => x.libraryId === libraryId);
        if (card === undefined) {
            throw new Error(`Could not find card with libraryId ${libraryId}`);
        }
        return card;
    }
    getEnchantmentFromLibraryId(libraryId) {
        const enchantment = this.enchantmentLibrary.find((x) => x.libraryId === libraryId);
        if (enchantment === undefined) {
            throw new Error(`Could not find enchantment with libraryId ${libraryId}`);
        }
        return enchantment;
    }
    toJSON() {
        return {
            cardLibrary: this.cardLibrary.map((x) => x.toJSON()),
            enchantmentLibrary: this.enchantmentLibrary.map((x) => x.toJSON()),
        };
    }
    static fromJSON(json) {
        return new GameManager(json.cardLibrary.map((x) => LibraryCard.fromJSON(x)), json.enchantmentLibrary.map((x) => LibraryEnchantment.fromJSON(x)));
    }
    // utility methods
    static generateUniqueId(length = 20) {
        // need to make sure length is at least 20
        if (length < 20)
            length = 20;
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        const timePart = Date.now().toString(36);
        let randomPartLength = length - timePart.length;
        for (let i = 0; i < randomPartLength; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return timePart + result;
    }
}
export default GameManager;
