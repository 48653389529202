import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const RealmSelectionStyled = styled.div`
  flex: 1;
  padding: var(--margin-outside-main);
  position: relative;
  height: calc(100vh - var(--navbar-height));
  box-sizing: border-box;

  * {
    z-index: 3;
  }

  .realm-container {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - var(--navbar-height) - 100px);
    position: relative;
  }

  .underlined-title {
    position: relative;
    z-index: 3;
  }

  // .fader {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   background-color: var(--color-background-light);
  //   opacity: 0.1;
  //   z-index: 2;
  // }
`;
