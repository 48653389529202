// external
import React, { useState, useEffect, useContext } from 'react';

// internal components

// context
import { PlayerContext } from '../../../contexts/PlayerContextProvider';
import { GameElementSizeContext } from '../../../contexts/GameElementSizeContextProvider';

// css
import { CancelButtonStyled, ArrowStyled } from './styles/Arrow.styled.js';
import { PlayerFlowEnum } from '../../../../../../LegendsOfLeakos/lib/Enums/PlayerFlow';

const Arrow = ({ from, to, type, cancelAction = null }) => {
  const { boardCardWidth } = useContext(GameElementSizeContext);
  const { masterPlayer } = useContext(PlayerContext);

  const fromWeight = 0.6;
  const toWeight = 1 - fromWeight;

  const cancelPoint = {
    x: (from.x * fromWeight + to.x * toWeight) / (fromWeight + toWeight),
    y: (from.y * fromWeight + to.y * toWeight) / (fromWeight + toWeight),
  };

  return (
    <ArrowStyled type={type}>
      <svg className='svg-arrow'>
        <line x1={from.x} y1={from.y} x2={to.x} y2={to.y} />
        {/* Add more SVG elements here to create the arrowhead */}
      </svg>
      {!!cancelAction &&
        masterPlayer.playerFlowEnum === PlayerFlowEnum.PlayerActing && (
          <CancelButtonStyled
            color='red'
            boardCardWidth={boardCardWidth}
            cancelPoint={cancelPoint}
            onClick={() => {
              cancelAction();
            }}
          >
            <svg width='100%' height='100%' viewBox='0 0 40 40'>
              <line
                x1='0'
                y1='0'
                x2='40'
                y2='40'
                stroke='black'
                strokeWidth='2'
              />
              <line
                x1='0'
                y1='40'
                x2='40'
                y2='0'
                stroke='black'
                strokeWidth='2'
              />
            </svg>
          </CancelButtonStyled>
        )}
    </ArrowStyled>
  );
};

export default Arrow;
