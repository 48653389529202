import IntModifier from './IntModifier.js';
class ModifiableInt {
    constructor(baseValue, effectValueIntModifiers) {
        this.baseValue = baseValue;
        this.intModifiers = [];
        for (const i of effectValueIntModifiers) {
            this.intModifiers.push(new IntModifier(i.value, i.permanent));
        }
    }
    get effectiveValue() {
        let value = this.baseValue;
        for (const modifier of this.intModifiers) {
            value += modifier.value;
        }
        return value;
    }
    toJSON() {
        return {
            baseValue: this.baseValue,
        };
    }
    clone() {
        return new ModifiableInt(this.baseValue, this.intModifiers.map((i) => i.clone()));
    }
    static fromJSON(json) {
        const newModifiableInt = new ModifiableInt(json.baseValue, new Array());
        return newModifiableInt;
    }
    static isLibraryJSONValid(json) {
        if (typeof json.baseValue !== 'number') {
            console.log('Invalid JSON: baseValue is not a number');
            return false;
        }
        return true;
    }
}
export default ModifiableInt;
