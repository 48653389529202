var StackEventEnum;
(function (StackEventEnum) {
    StackEventEnum[StackEventEnum["QueueStart"] = 0] = "QueueStart";
    StackEventEnum[StackEventEnum["QueueEnd"] = 1] = "QueueEnd";
    StackEventEnum[StackEventEnum["Queueline"] = 2] = "Queueline";
    StackEventEnum[StackEventEnum["AttackResolve"] = 3] = "AttackResolve";
    StackEventEnum[StackEventEnum["AttackNoDamage"] = 4] = "AttackNoDamage";
    StackEventEnum[StackEventEnum["BlockResolve"] = 5] = "BlockResolve";
    StackEventEnum[StackEventEnum["EffectResolve"] = 6] = "EffectResolve";
    StackEventEnum[StackEventEnum["OtherStackEvent"] = 7] = "OtherStackEvent";
})(StackEventEnum || (StackEventEnum = {}));
var StackEntityEnum;
(function (StackEntityEnum) {
    StackEntityEnum[StackEntityEnum["Source"] = 0] = "Source";
    StackEntityEnum[StackEntityEnum["Target"] = 1] = "Target";
    StackEntityEnum[StackEntityEnum["Other"] = 2] = "Other";
})(StackEntityEnum || (StackEntityEnum = {}));
export { StackEventEnum, StackEntityEnum };
