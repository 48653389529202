import { ClientMessage } from '../MessageBase.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
export default class CancelActionMessage extends ClientMessage {
    constructor(messageId, senderUserId, messageIdToCancel) {
        super(messageId, senderUserId);
        this.messageEnum = NetworkProtocol.CancelAction;
        this.messageIdToCancel = messageIdToCancel;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { messageIdToCancel: this.messageIdToCancel });
    }
    static fromJSON(json) {
        return new CancelActionMessage(json.messageId, json.senderUserId, json.messageIdToCancel);
    }
    // check that all fields in the message are valid
    validate() {
        return super.validate() && this.messageIdToCancel != null;
    }
}
ClientMessage.registerFromJSON(NetworkProtocol.CancelAction, CancelActionMessage.fromJSON);
