import styled, { css, keyframes } from 'styled-components';
import '../../../styles/constants.css';
const spinRight = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
`;

const spinLeft = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-90deg);
  }
`;

const compassButtonHeight = 300;
const divFactor = 2.1;

export const ReadyButtonStyled = styled.div`
  // compass button height

  z-index: 10;
  position: absolute;
  top: calc(51.5% - ${compassButtonHeight / 2}px);
  left: calc(50% - ${compassButtonHeight / 2}px);
  height: ${compassButtonHeight}px;
  width: ${compassButtonHeight}px;
  pointer-events: none;

  .click-area {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: ${compassButtonHeight / divFactor}px;
    width: ${compassButtonHeight / divFactor}px;
    border-radius: 50%;
    z-index: 10;
    pointer-events: auto;
  }

  // #region compass image
  .compass-image-container {
    position: relative;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }

  .compass-image {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;

    img {
      position: absolute;
      bottom: 0;
      right: 0;
      height: ${compassButtonHeight}px;
      object-fit: cover;
      pointer-events: none;
    }
  }

  .banner-text-container {
    z-index: 2;
  }

  .banner-container {
    position: relative;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 1;

    // animate
    transform: ${({ bannerScale }) => `scale(${bannerScale})`};
    transition: transform 0.15s ease;
  }

  .static-container {
    z-index: 2;
  }

  .center-circle-container {
    z-index: 3;
    transform: ${({ centerCircleScale }) => `scale(${centerCircleScale})`};
    transition: transform 0.15s ease;
  }

  .small-triangles-container {
    z-index: 4;
  }

  .northwest-triangles-container {
    z-index: 5;
    ${({ spin }) =>
      spin &&
      css`
        animation: ${spinLeft} 0.3s linear;
      `}
  }

  .north-triangles-container {
    z-index: 7;
    ${({ spin }) =>
      spin &&
      css`
        animation: ${spinRight} 0.3s linear;
      `}
  }

  .center-container {
    z-index: 8;
  }

  // #endregion compass image
`;
