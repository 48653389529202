// external
import React, { useState, useEffect, useContext, useRef } from 'react';
import { throttle } from 'lodash';

// internal context
// import { PlayerContext } from '../../contexts/PlayerContextProvider';
import { AttackingContext } from '../../../contexts/AttackingContextProvider';
import { ActiveAbilityContext } from '../../../contexts/ActiveAbilityContextProvider';

// internal components
import Arrow from '../Arrow/Arrow.jsx';

function CurrentAction() {
  // context
  const { amActingInCombat, setAmActingInCombat, actingCardPosition } =
    useContext(AttackingContext);

  const { currentTargetInfo, actingAbilityEntityPosition, cancelAbility } =
    useContext(ActiveAbilityContext);

  // arrow
  const [arrowOn, setArrowOn] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    if (amActingInCombat) {
      if (!actingCardPosition) return;
      setArrowOn(true);
      setStartPosition({
        x: actingCardPosition.x,
        y: actingCardPosition.y,
      });
      return;
    }

    if (!!currentTargetInfo) {
      if (!actingAbilityEntityPosition) {
        return;
      }
      setArrowOn(true);
      setStartPosition({
        x: actingAbilityEntityPosition.x,
        y: actingAbilityEntityPosition.y,
      });
      return;
    }

    setArrowOn(false);
  }, [
    amActingInCombat,
    actingCardPosition,
    currentTargetInfo,
    actingAbilityEntityPosition,
  ]);

  // mouse up
  const handleMouseUp = (event) => {
    setAmActingInCombat(false);
  };

  // mouse pos vars
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const mousePositionRef = useRef(mousePosition);
  useEffect(() => {
    mousePositionRef.current = mousePosition;
  }, [mousePosition]);
  //

  const onMouseMoveWhileTargeting = throttle((event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  }, 25);

  useEffect(() => {
    const handleRightClick = (event) => {
      event.preventDefault(); // Prevent the default context menu from opening
      setAmActingInCombat(false);
      cancelAbility();
    };

    document.addEventListener('mousemove', onMouseMoveWhileTargeting);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('contextmenu', handleRightClick); // Listen for right-clicks

    return () => {
      document.removeEventListener('mousemove', onMouseMoveWhileTargeting);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('contextmenu', handleRightClick); // Clean up
    };
  }, []);

  return (
    <div>
      {arrowOn && (
        <Arrow
          from={startPosition}
          to={mousePositionRef.current}
          type={'current'}
        />
      )}
    </div>
  );
}

export default CurrentAction;
