import RuntimeCondition from '../Condition/RuntimeCondition.js';
import ModifiableInt from '../ModifableInt/ModifiableInt.js';
import { ConditionType } from '../../Enums/Condition.js';
import { TargetTypeEnum, TargetableTypeSelectionEnum, } from '../../Enums/Target.js';
class TargetCriteriaUpgrade {
    constructor(targetTypeIndex, newTargetTypeEnum, newTargetableTypeSelectionEnum, minSelectionsRequiredChange, maxSelectionsAllowedChange, minSelectionsThatMustRemainChange, newConditions, removeConditionsOfType) {
        this.targetTypeIndex = targetTypeIndex;
        this.newTargetTypeEnum = newTargetTypeEnum;
        this.newTargetableTypeSelectionEnum = newTargetableTypeSelectionEnum;
        this.minSelectionsRequiredChange = new ModifiableInt(minSelectionsRequiredChange.baseValue, minSelectionsRequiredChange.intModifiers);
        this.maxSelectionsAllowedChange = new ModifiableInt(maxSelectionsAllowedChange.baseValue, maxSelectionsAllowedChange.intModifiers);
        this.minSelectionsThatMustRemainChange = new ModifiableInt(minSelectionsThatMustRemainChange.baseValue, minSelectionsThatMustRemainChange.intModifiers);
        this.newConditions = newConditions;
        this.removeConditionsOfType = [...removeConditionsOfType];
    }
    toJSON() {
        return {
            targetTypeIndex: this.targetTypeIndex,
            newTargetTypeEnum: TargetTypeEnum[this.newTargetTypeEnum],
            newTargetableTypeSelectionEnum: TargetableTypeSelectionEnum[this.newTargetableTypeSelectionEnum],
            minSelectionsRequiredChange: this.minSelectionsRequiredChange.toJSON(),
            maxSelectionsAllowedChange: this.maxSelectionsAllowedChange.toJSON(),
            minSelectionsThatMustRemainChange: this.minSelectionsThatMustRemainChange.toJSON(),
            newConditions: this.newConditions.map((c) => c.toJSON()),
            removeConditionsOfType: this.removeConditionsOfType.map((c) => ConditionType[c]),
        };
    }
    static fromJSON(json) {
        return new TargetCriteriaUpgrade(json.targetTypeIndex, TargetTypeEnum[json.newTargetTypeEnum], TargetableTypeSelectionEnum[json.newTargetableTypeSelectionEnum], ModifiableInt.fromJSON(json.minSelectionsRequiredChange), ModifiableInt.fromJSON(json.maxSelectionsAllowedChange), ModifiableInt.fromJSON(json.minSelectionsThatMustRemainChange), json.newConditions.map((c) => RuntimeCondition.fromLibraryJSON(c)), json.removeConditionsOfType.map((c) => ConditionType[c]));
    }
}
export default TargetCriteriaUpgrade;
