import React, { useState, useEffect, useContext } from 'react';

// internal
import RoomTile from './RoomTile';
// import TileScroller from '../../../UtilityComponents/TileScroller.jsx';

// contexts
import { ImageContext } from '../../../../contexts/ImageContextProvider';
import { RoomsContext } from '../../../../contexts/RoomsContextProvider';

// css
import { RoomSelectionStyled } from './styles/RoomSelection.styled.js';

function RoomSelection() {
  const { bgIslands } = useContext(ImageContext);
  const { rooms, myRoomID, requestNewRoom, leaveRoom } =
    useContext(RoomsContext);

  return (
    <RoomSelectionStyled className='room-selection' backgroundImage={bgIslands}>
      <h3 className='underlined-title'>Select a Room</h3>
      <div className='button-bar'>
        <div className='menu-button' onClick={requestNewRoom}>
          Create Room
        </div>
        <div className='menu-button' onClick={leaveRoom}>
          Leave Room
        </div>
      </div>

      <div className='rooms'>
        {rooms.length > 0 ? (
          rooms.map((room, index) => {
            return (
              <RoomTile
                key={room.id + myRoomID + index + ''}
                room={room}
                selected={room.id === myRoomID}
              />
            );
          })
        ) : (
          <div className='no-rooms'>
            <p>No open rooms. Create a new one!</p>
          </div>
        )}
      </div>
    </RoomSelectionStyled>
  );
}

export default RoomSelection;
