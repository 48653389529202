import RuntimeKeyword from '../RuntimeKeyword.js';
import { KeywordType } from '../../../../Enums/Keyword.js';
import RuntimeCondition from '../../../Condition/RuntimeCondition.js';
import AttackEffect from '../../../Effect/RuntimeEffects/AttackEffects/AttackEffect.js';
import DealSetDamageEffect from '../../../Effect/RuntimeEffects/CardTargetEffects/DealSetDamageEffect.js';
import IntModifier from '../../../ModifableInt/IntModifier.js';
import ModifiableInt from '../../../ModifableInt/ModifiableInt.js';
import LibraryKeyword from '../../LibraryKeyword/LibraryKeyword.js';
import StackEvent from '../../../StackEvent/StackEvent.js';
import { StackEntityEnum, StackEventEnum } from '../../../../Enums/Stack.js';
import StackEntity from '../../../StackEvent/StackEntity.js';
class DamageModificationKeyword extends RuntimeKeyword {
    constructor(myEntityId, setIsPermanent, setDuration, isActive, imageName, conditions, modifyAbilityDamageAmount, modifyAttackAmount) {
        super();
        this.myEntityInstanceId = myEntityId;
        this.keywordType = KeywordType.DamageModification;
        this.isPermanent = setIsPermanent;
        this.duration = setDuration;
        this.isActive = isActive;
        this.imageName = imageName;
        this.conditions = conditions;
        this.modifyAbilityDamageAmount = modifyAbilityDamageAmount;
        this.modifyAttackAmount = modifyAttackAmount;
    }
    preResolveEffect(e, sourceEntityInstanceId, gameState, targetInfoList) {
        if (!this.isActive) {
            console.log('not active');
            return null;
        }
        const myEntity = gameState.getEntityFromAnywhere(this.myEntityInstanceId);
        if (!myEntity) {
            throw new Error('myEntity is null');
        }
        const sourceEntity = gameState.getEntityFromAnywhere(sourceEntityInstanceId);
        if (!sourceEntity) {
            throw new Error('sourceEntity is null');
        }
        switch (e.constructor) {
            case AttackEffect:
                // get effect
                const attackEffect = e;
                // attacked card
                const attackedCard = gameState.getCardFromAnywhere(targetInfoList[0].targetEntityInstanceIds[0]);
                if (!attackedCard)
                    throw new Error('attackedCard is null');
                // attacking card
                const sourceCard = sourceEntity;
                if (!sourceCard)
                    throw new Error('attackingCard is null');
                // set up the stack event string
                let stackEventString = '';
                // do the blocking cards
                attackEffect.blockInfo.forEach((b) => {
                    const blockingCard = gameState.getCardFromAnywhere(b.blockingCardInstanceId);
                    if (!blockingCard) {
                        throw new Error('blockingCard is null');
                    }
                    let meetsConditions = true;
                    for (let condition of this.conditions) {
                        if (!condition.isTrue(blockingCard.instanceId, myEntity.instanceId, gameState)) {
                            meetsConditions = false;
                        }
                    }
                    if (meetsConditions) {
                        b.damageToBlockingCard.intModifiers.push(new IntModifier(this.modifyAttackAmount.effectiveValue, false));
                        stackEventString += `Modify Damage to ${blockingCard.name} by ${this.modifyAttackAmount.effectiveValue}\n`;
                    }
                });
                // now do the same for the attacked card
                let attackedMeetsConditions = true;
                for (let condition of this.conditions) {
                    if (!condition.isTrue(attackedCard.instanceId, myEntity.instanceId, gameState)) {
                        attackedMeetsConditions = false;
                    }
                }
                if (attackedMeetsConditions) {
                    attackEffect.damageToAttackedCard.intModifiers.push(new IntModifier(this.modifyAttackAmount.effectiveValue, false));
                    stackEventString += `Modify Damage to ${attackedCard.name} by ${this.modifyAttackAmount.effectiveValue}\n`;
                }
                // now do the same for the attacking card
                let attackingMeetsConditions = true;
                for (let condition of this.conditions) {
                    if (!condition.isTrue(sourceCard.instanceId, myEntity.instanceId, gameState)) {
                        attackingMeetsConditions = false;
                    }
                }
                if (attackingMeetsConditions) {
                    attackEffect.damageToAttackingCard.intModifiers.push(new IntModifier(this.modifyAttackAmount.effectiveValue, false));
                    stackEventString += `Modify Damage to ${sourceCard.name} by ${this.modifyAttackAmount.effectiveValue}\n`;
                }
                return new StackEvent(StackEventEnum.OtherStackEvent, gameState.clone(), true, stackEventString, [
                    new StackEntity(myEntity.instanceId, StackEntityEnum.Other),
                    new StackEntity(sourceCard.instanceId, StackEntityEnum.Source),
                ]);
            case DealSetDamageEffect:
                const dealSetDamageEffect = e;
                if (targetInfoList.length !== 1) {
                    throw new Error('targetInfoList.length !== 1');
                }
                const attackedCards = targetInfoList[0].targetEntityInstanceIds.map((c) => {
                    const card = gameState.getCardFromAnywhere(c);
                    if (!card)
                        throw new Error('card is null');
                    return card;
                });
                let stackEventString2 = '';
                attackedCards.forEach((c, index) => {
                    let meetsConditions = true;
                    for (let condition of this.conditions) {
                        if (!condition.isTrue(c.instanceId, myEntity.instanceId, gameState)) {
                            meetsConditions = false;
                        }
                    }
                    if (meetsConditions) {
                        dealSetDamageEffect.damageAmountTracker[index].intModifiers.push(new IntModifier(this.modifyAbilityDamageAmount.effectiveValue, false));
                        stackEventString2 += `${index}. Modify Damage to ${c.name} by ${this.modifyAbilityDamageAmount.effectiveValue}. `;
                    }
                });
                return new StackEvent(StackEventEnum.OtherStackEvent, gameState.clone(), true, stackEventString2, [
                    new StackEntity(myEntity.instanceId, StackEntityEnum.Other),
                    new StackEntity(sourceEntityInstanceId, StackEntityEnum.Source),
                    ...attackedCards.map((c) => new StackEntity(c.instanceId, StackEntityEnum.Target)),
                ]);
            default:
                break;
        }
    }
    getDisplayText() {
        return `Modify Damage by ${this.modifyAbilityDamageAmount.effectiveValue} - ${this.modifyAttackAmount.effectiveValue}`;
    }
    // #region JSON
    toJSON() {
        return {
            keywordType: KeywordType[this.keywordType],
            myEntityInstanceId: this.myEntityInstanceId,
            isPermanent: this.isPermanent,
            duration: this.duration,
            isActive: this.isActive,
            imageName: this.imageName,
            conditions: this.conditions.map((c) => c.toJSON()),
            modifyAbilityDamageAmount: this.modifyAbilityDamageAmount,
            modifyAttackAmount: this.modifyAttackAmount,
        };
    }
    clone() {
        return new DamageModificationKeyword(this.myEntityInstanceId, this.isPermanent, this.duration, this.isActive, this.imageName, this.conditions.map((c) => c.clone()), this.modifyAbilityDamageAmount.clone(), this.modifyAttackAmount.clone());
    }
    static fromRuntimeJSON(json) {
        return new DamageModificationKeyword(json.myEntityInstanceId, json.isPermanent, json.duration, json.isActive, json.imageName, json.conditions.map((c) => RuntimeCondition.fromRuntimeJSON(c)), ModifiableInt.fromJSON(json.modifyAbilityDamageAmount), ModifiableInt.fromJSON(json.modifyAttackAmount));
    }
    static fromLibraryJSON(myEntityInstanceId, json) {
        return new DamageModificationKeyword(myEntityInstanceId, json.isPermanent, json.duration, json.isActive, json.imageName, json.conditions.map((c) => RuntimeCondition.fromLibraryJSON(c)), new ModifiableInt(json.data.modifyAbilityDamageAmount.value, []), new ModifiableInt(json.data.modifyAttackAmount.value, []));
    }
    static isLibraryJSONValid(json) {
        if (!json.keywordType ||
            !Object.values(KeywordType)
                .filter((value) => typeof value === 'string')
                .includes(json.keywordType)) {
            console.log('Invalid keywordType');
            return false;
        }
        if (json.isPermanent === undefined ||
            json.isPermanent === null ||
            typeof json.isPermanent !== 'boolean') {
            console.log('Invalid isPermanent');
            return false;
        }
        if (json.duration === undefined ||
            json.duration === null ||
            typeof json.duration !== 'number') {
            console.log('Invalid duration');
            return false;
        }
        if (json.isActive === undefined ||
            json.isActive === null ||
            typeof json.isActive !== 'boolean') {
            console.log('Invalid isActive');
            return false;
        }
        if (!json.imageName || typeof json.imageName !== 'string') {
            console.log('Invalid imageName');
            return false;
        }
        if (!json.conditions ||
            !Array.isArray(json.conditions) ||
            !json.conditions.every((c) => RuntimeCondition.isLibraryJSONValid(c))) {
            console.log('Invalid conditions');
            return false;
        }
        if (!json.data.modifyAbilityDamageAmount || !json.data.modifyAttackAmount) {
            console.log('Invalid modifyAbilityDamageAmount or modifyAttackAmount');
            return false;
        }
        return true;
    }
    static createSampleLibraryKeyword() {
        return new LibraryKeyword(KeywordType.DamageModification, '', false, 0, false, [], 'image', {
            modifyAbilityDamageAmount: {
                type: 'Number',
                value: 0,
            },
            modifyAttackAmount: {
                type: 'Number',
                value: 0,
            },
        });
    }
    // #endregion
    static initialize() {
        RuntimeKeyword.registerFromLibraryJSON(KeywordType.DamageModification, DamageModificationKeyword.fromLibraryJSON);
        RuntimeKeyword.registerFromRuntimeJSON(KeywordType.DamageModification, DamageModificationKeyword.fromRuntimeJSON);
        RuntimeKeyword.registerIsLibraryJSONValid(KeywordType.DamageModification, DamageModificationKeyword.isLibraryJSONValid);
        RuntimeKeyword.registerSampleLibraryKeyword(KeywordType.DamageModification, DamageModificationKeyword.createSampleLibraryKeyword);
    }
}
export default DamageModificationKeyword;
