import { ClientMessage } from '../../MessageBase.js';
import { NetworkProtocol } from '../../../../Enums/NetworkProtocol.js';
export default class PlayerReadyForQueueMessage extends ClientMessage {
    constructor(messageId, senderUserId) {
        super(messageId, senderUserId);
        this.messageEnum = NetworkProtocol.PlayerReadyForQueue;
    }
    toJSON() {
        return Object.assign({}, super.toJSON());
    }
    static fromJSON(json) {
        return new PlayerReadyForQueueMessage(json.messageId, json.senderUserId);
    }
    // check that all fields in the message are valid
    validate() {
        return super.validate();
    }
}
ClientMessage.registerFromJSON(NetworkProtocol.PlayerReadyForQueue, PlayerReadyForQueueMessage.fromJSON);
