// external
import React, { useContext, useEffect, useState, useRef } from 'react';

// components
import LibraryCardDisplay from '../LibraryCardDisplay';

// context
import { CardLibraryContext } from '../../../../contexts/CardLibraryContextProvider';
import { ImageContext } from '../../../../contexts/ImageContextProvider';

// css
import { CardListItemStyled } from './styles/CardListItem.styled.js';
import LibraryCardEntry from '../../../../../../../LegendsOfLeakos/lib/Classes/RealmsAndLand/Biome/LibraryCardEntry';
import LibraryCard from '../../../../../../../LegendsOfLeakos/lib/Classes/Card/LibraryCard';

interface CardListItemProps {
  entry: LibraryCardEntry;
  displayState: string;
  removeCard: (card: any) => void;
  removeAll: (card: any) => void;
}

const CardListItem: React.FC<CardListItemProps> = ({
  entry,
  displayState,
  removeCard,
  removeAll,
}) => {
  const { cardLibrary } = useContext(CardLibraryContext);
  const [libraryCard, setLibraryCard] = useState<LibraryCard | null>(null);
  const cardVisualsRef = useRef<HTMLDivElement>(null);
  const [availableWidth, setAvailableWidth] = useState<number>(0);

  useEffect(() => {
    if (!cardLibrary) return;
    const t = cardLibrary.find(
      (lc: LibraryCard) => lc.libraryId === entry.libraryId
    );
    setLibraryCard(t);
  }, [cardLibrary, entry]);

  useEffect(() => {
    const updateWidth = () => {
      if (cardVisualsRef.current) {
        setAvailableWidth(cardVisualsRef.current.offsetWidth);
      }
    };

    updateWidth();
    // Optional: Listen to window resize if you need the width to be responsive
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const handleRemoveCard = () => {
    removeCard(entry);
  };

  const handleRemoveAll = () => {
    removeAll(entry);
  };

  return (
    <CardListItemStyled onClick={handleRemoveCard}>
      <div className='library-card-holder' ref={cardVisualsRef}>
        <LibraryCardDisplay
          libraryCard={libraryCard}
          onClick={() => {}}
          canHover={false}
          availableWidth={availableWidth}
        />
      </div>
      <div className='amount'>{entry.amount}</div>

      {displayState === 'edit-biome' && (
        <div className='controls'>
          <div className='remove-all' onClick={handleRemoveAll}>
            X
          </div>
        </div>
      )}
    </CardListItemStyled>
  );
};

export default CardListItem;
