// external
import React, { useContext, useEffect, useState } from 'react';

// components
import AbilityBox from './AbilityBox';

// context
import { CardLibraryContext } from '../../../contexts/CardLibraryContextProvider';
import { ImageContext } from '../../../contexts/ImageContextProvider';
import { GameElementSizeContext } from '../../../contexts/GameElementSizeContextProvider';

// css
import { LibraryCardDisplayStyled } from './styles/LibraryCardDisplay.styled.js';
import LibraryCard from '../../../../../../LegendsOfLeakos/lib/Classes/Card/LibraryCard';
import { BiomeType } from '../../../../../../LegendsOfLeakos/lib/Enums/LandAndBiome';

// lol

interface LibraryCardDisplayProps {
  libraryCard: LibraryCard;
  availableWidth: number;
  canHover: boolean;
  onClick: (event: any) => void;
}

const LibraryCardDisplay: React.FC<LibraryCardDisplayProps> = ({
  libraryCard,
  availableWidth,
  canHover = false,
  onClick = null,
}) => {
  // #region context
  const { cardLibrary } = useContext(CardLibraryContext);
  const {
    defaultCardImage,
    activeBorder,
    inactiveBorder,
    forestBottomLand,
    mountainBottomLand,
    prairieBottomLand,
    tundraBottomLand,
    fellsBottomLand,
    oceanBottomLand,
    desertBottomLand,
  } = useContext(ImageContext);
  const { boardCardRatio } = useContext(GameElementSizeContext);

  // #endregion

  // #region hover
  const [hover, setHover] = useState(false);
  useEffect(() => {
    if (!canHover) return;
    setFinalHover(hover);
  }, [hover]);
  const [finalHover, setFinalHover] = useState(false);

  return (
    <LibraryCardDisplayStyled
      onClick={onClick}
      cardWidth={availableWidth}
      cardHeight={availableWidth * boardCardRatio}
    >
      <div className='card-visuals'>
        <div
          className={'card'}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <div className='expandable'>
            <div className='border'>
              <img src={activeBorder} alt='active border' />
            </div>

            {/* name */}
            <p className='name'>{libraryCard?.name}</p>

            {/* image */}
            <div className='main-image'>
              <img src={libraryCard?.imageName} alt={libraryCard?.name} />
            </div>

            {/* stats */}
            <div className='stat-holder'>
              <div className='attack-box stat-box'>
                <div className='background'></div>
                <p>{libraryCard?.attack}</p>
              </div>
              <div className='health-box stat-box'>
                <div className='background'></div>
                <p>{libraryCard?.health}</p>
                {/* <p>22</p> */}
              </div>
              <div className='priority-box stat-box'>
                <div className='background'></div>
                <p>{libraryCard?.priority}</p>
              </div>
            </div>
          </div>

          <div className='bottom-land'>
            {libraryCard?.biomeType === BiomeType.mountain ? (
              <div className='mountain'>
                <img src={mountainBottomLand} alt='' />
              </div>
            ) : libraryCard?.biomeType === BiomeType.ocean ? (
              <div className='ocean'>
                <img src={oceanBottomLand} alt='' />
              </div>
            ) : (
              <div className='forest'>
                <img src={forestBottomLand} alt='' />
              </div>
            )}
          </div>
        </div>

        {/* abilities, keywords, and enchantments */}

        {/* keywords */}
        <div className={'keywords' + (finalHover ? '' : ' invisible')}>
          {libraryCard?.keywords.map((keyword, index) => (
            <span key={index}>{keyword.description}</span>
          ))}
        </div>

        {/* abilities */}
        <div className={'abilities' + (finalHover ? '' : ' invisible')}>
          {libraryCard?.abilities.map((ability, index) => (
            <AbilityBox key={index} ability={ability} />
          ))}
        </div>
      </div>
    </LibraryCardDisplayStyled>
  );
};

export default LibraryCardDisplay;
