import React, { useState, useEffect, useContext } from 'react';

// lol
import RuntimeCondition from '../../../../../../LegendsOfLeakos/lib/Classes/Condition/RuntimeCondition.js';

// internal
// comps
import TitledCycler from '../Utility/TitledCycler.jsx';
import DataEditor from '../Data/DataEditor.jsx';
//css
import { ConditionItemStyled } from './styles/ConditionItem.styled.js';
import { ConditionType } from '../../../../../../LegendsOfLeakos/lib/Enums/Condition.js';

function ConditionItem({
  condition,
  conditions,
  createNewCondition,
  save,
  index,
}) {
  const changeConditionType = (conditionType) => {
    const newCondition = createNewCondition(conditionType);
    saveCondition(newCondition);
  };

  const saveCondition = (condition) => {
    const newCondition = condition.clone();
    const newLibraryConditions = conditions.map((item, i) =>
      i === index ? newCondition : item
    );
    save(newLibraryConditions);
  };

  return (
    <ConditionItemStyled>
      <div className='condition-item-content'>
        <button
          className='condition-delete-button'
          onClick={() => {
            const newLibraryConditions = conditions.filter(
              (item, i) => i !== index
            );
            save(newLibraryConditions);
          }}
        >
          Delete
        </button>
        <TitledCycler
          title='Condition Type'
          items={Object.keys(
            RuntimeCondition.sampleLibraryConditionMethods
          ).map((item) => ConditionType[item])}
          onItemChange={(conditionString) =>
            changeConditionType(ConditionType[conditionString])
          }
          currentItem={ConditionType[condition.conditionType]}
        />
        <DataEditor
          data={condition.data}
          save={(data) => {
            const newCondition = condition.clone();
            newCondition.data = data;
            saveCondition(newCondition);
          }}
        />
      </div>
    </ConditionItemStyled>
  );
}

export default ConditionItem;
