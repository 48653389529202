import styled from 'styled-components';
import '../../../../styles/constants.css';

export const RealmStyled = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

  z-index: ${(props) => (props.isVisible ? '999' : '50')};
  pointer-events: ${(props) => (props.isVisible ? 'auto' : 'none')};
  background-color: ${(props) =>
    props.isVisible ? 'rgba(0, 0, 0, 0.4)' : 'transparent'};

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .realm-wrapper {
    position: relative;
    margin: 3% 5%;
    height: 90%;
    padding: 10px;
  }

  .realm-map {
    height: 95%;
  }

  .show-realm-button {
    position: absolute;
    z-index: ${(props) => (props.isVisible ? '999' : '51')};
    pointer-events: auto;
  }

  .show-realm-button.opponent {
    top: 0;
    left: 0;
  }

  .show-realm-button.player {
    bottom: 0;
    right: 0;
  }
`;
