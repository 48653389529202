import React, { useContext } from 'react';

// internal components
import BoardEnchantmentItem from './BoardEnchantmentItem';

// contexts
import { GameElementSizeContext } from '../../../contexts/GameElementSizeContextProvider';

// css
import { BoardEnchantmentListStyled } from './styles/BoardEnchantmentList.styled';

// lol
import RuntimeEnchantment from '../../../../../../LegendsOfLeakos/lib/Classes/Enchantment/RuntimeEnchantment';

const BoardEnchantmentList = ({
  isPlayerBoard,
  isPlayerEnchantment,
  enchantments,
  playerInfo,
  amActing = false,
  amOnZone = false,
  zoneHovered = null,
}) => {
  const [amHovering, setAmHovering] = React.useState(false);
  const { boardCardWidth } = useContext(GameElementSizeContext);

  return (
    <BoardEnchantmentListStyled
      className={`enchantment-list`}
      onMouseEnter={() => setAmHovering(true)}
      onMouseLeave={() => setAmHovering(false)}
      amHovering={amHovering}
      boardCardWidth={boardCardWidth}
      zoneHovered={zoneHovered}
      isPlayerEnchantment={isPlayerEnchantment}
    >
      {amHovering && enchantments.length > 0 && (
        <div className='enchantments'>
          {enchantments.map((enchantment: RuntimeEnchantment, index) => (
            <BoardEnchantmentItem
              key={index}
              isPlayer={isPlayerEnchantment}
              playerInfo={playerInfo}
              enchantment={enchantment}
              amActing={amActing}
            />
          ))}
        </div>
      )}
      <div className='length-display'>
        {amOnZone && <div className='center-line'></div>}
        <div className='length-background'></div>
        <div className='length-text'>{enchantments.length}</div>
      </div>
    </BoardEnchantmentListStyled>
  );
};

export default BoardEnchantmentList;
