import LibraryKeyword from '../Keyword/LibraryKeyword/LibraryKeyword.js';
import LibraryAbility from '../Ability/LibraryAbility.js';
import { CardType } from '../../Enums/CardType.js';
class LibraryEnchantment {
    constructor() {
        this.cardTypes = [];
    }
    clone() {
        return LibraryEnchantment.fromJSON(this.toJSON());
    }
    toJSON() {
        return {
            libraryId: this.libraryId,
            name: this.name,
            cardTypes: this.cardTypes.map((x) => CardType[x]),
            description: this.description,
            keywords: this.keywords.map((x) => x.toJSON()),
            abilities: this.abilities.map((x) => x.toJSON()),
            imageName: this.imageName,
        };
    }
    static fromJSON(json) {
        const newEnchantment = new LibraryEnchantment();
        newEnchantment.libraryId = json.libraryId;
        newEnchantment.cardTypes = json.cardTypes.map((x) => CardType[x]);
        newEnchantment.name = json.name;
        newEnchantment.description = json.description;
        newEnchantment.keywords = json.keywords.map((x) => LibraryKeyword.fromJSON(x));
        newEnchantment.abilities = json.abilities.map((x) => LibraryAbility.fromJSON(x));
        newEnchantment.imageName = json.imageName;
        return newEnchantment;
    }
    static isJSONValid(json) {
        if (json.libraryId === undefined) {
            return false;
        }
        if (json.name === undefined) {
            return false;
        }
        if (json.description === undefined) {
            return false;
        }
        if (json.keywords === undefined) {
            return false;
        }
        if (json.abilities === undefined) {
            return false;
        }
        if (json.imageName === undefined) {
            return false;
        }
        return true;
    }
}
export default LibraryEnchantment;
