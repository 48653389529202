// a component that displays a realm representing a players land
// external
import React, { useState, useEffect, useContext } from 'react';

// internal components
import ManaCircle from '../../Hand/ManaCircle';

// context
import { PlayerContext } from '../../../../contexts/PlayerContextProvider';
import { GameElementSizeContext } from '../../../../contexts/GameElementSizeContextProvider';
import { ImageContext } from '../../../../contexts/ImageContextProvider';
import { CardLibraryContext } from '../../../../contexts/CardLibraryContextProvider';

// css
import {
  AbilityBoxStyled,
  AbilityInfoPanelStyled,
} from './styles/AbilityBox.styled.js';

// lol
import RuntimeAbility from '../../../../../../../LegendsOfLeakos/lib/Classes/Ability/RuntimeAbility';
import { PlayerFlowEnum } from '../../../../../../../LegendsOfLeakos/lib/Enums/PlayerFlow';

// lol

interface AbilityBoxProps {
  ability: RuntimeAbility;
  onClick: (event: any) => void;
}

const AbilityBox: React.FC<AbilityBoxProps> = ({ ability, onClick }) => {
  const { masterPlayer, displayState } = useContext(PlayerContext);
  const { boardCardWidth } = useContext(GameElementSizeContext);
  const { gameManager } = useContext(CardLibraryContext);
  const { defaultCardImage } = useContext(ImageContext);

  // player info
  const [playerInfo, setPlayerInfo] = useState(null);
  useEffect(() => {
    if (!masterPlayer || !displayState) return;
    setPlayerInfo(masterPlayer.getPlayerInfo(displayState));
  }, [masterPlayer]);

  const [myAbility, setMyAbility] = useState(null);
  useEffect(() => {
    setMyAbility(ability);
  }, [ability]);

  // #region image
  const [image, setImage] = useState(defaultCardImage);
  useEffect(() => {
    if (!masterPlayer || !displayState) return;
    const url = ability.image;
    if (!url) return;
    setImage(url);
  }, [myAbility]);
  // #endregion

  const [isActivatable, setIsActivatable] = useState(false);
  useEffect(() => {
    if (!masterPlayer || !displayState) return;
    if (!playerInfo) return;
    if (masterPlayer.playerFlowEnum !== PlayerFlowEnum.PlayerActing) return;
    setIsActivatable(ability.isActivatable(playerInfo, displayState));
  }, [myAbility, masterPlayer, playerInfo]);

  const handleClick = (event) => {
    if (!masterPlayer || !displayState) return;
    if (!playerInfo) return;
    if (!ability.isActivatable(playerInfo, displayState)) return;
    if (masterPlayer.playerFlowEnum !== PlayerFlowEnum.PlayerActing) return;
    const newAbility = myAbility.clone();
    newAbility.usesRemaining--;
    setMyAbility(newAbility);
    onClick(event);
  };

  const [isHovered, setIsHovered] = useState(false);

  return (
    <AbilityBoxStyled
      className='ability-box'
      onClick={(event) => handleClick(event)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      cardWidth={boardCardWidth}
      activatable={isActivatable}
      backgroundImage={image}
      isHovered={isHovered}
    >
      <div className='ability-box-content'>
        <div className='mana-cost-holder'>
          {ability.costs.map((cost, index) => {
            return (
              <ManaCircle
                key={index}
                cost={cost.value}
                costName={cost.costName()}
              />
            );
          })}
        </div>
        <p>{ability.name}</p>
        <div className='uses-remaining'>
          {'Left this turn: ' +
            ability.usesRemaining +
            '/' +
            ability.usesPerTurn}
        </div>
      </div>
      {isHovered && (
        <AbilityInfoPanelStyled cardWidth={boardCardWidth}>
          <p>{ability.effect.effectToString(gameManager)}</p>
        </AbilityInfoPanelStyled>
      )}
    </AbilityBoxStyled>
  );
};

export default AbilityBox;
