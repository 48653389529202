// external
import React, { useState, useEffect, useContext } from 'react';

// internal components
import BoardCard from '../BoardCard/BoardCard';
import BoardEnchantmentList from './BoardEnchantmentList';

// context
import { PlayerContext } from '../../../contexts/PlayerContextProvider';
import { TurnFlowContext } from '../../../contexts/TurnFlowContextProvider';
import { ActiveAbilityContext } from '../../../contexts/ActiveAbilityContextProvider';
import { AttackingContext } from '../../../contexts/AttackingContextProvider';
import { DraggedCardContext } from '../../../contexts/DraggedCardContextProvider';
import { GameElementSizeContext } from '../../../contexts/GameElementSizeContextProvider';

// css
import { BoardStyled } from './styles/Board.styled.js';

// lol
import { NetworkProtocol } from '../../../../../../LegendsOfLeakos/lib/Enums/NetworkProtocol';
import { ZoneEnum } from '../../../../../../LegendsOfLeakos/lib/Enums/Zone.js';
import PayResourceCost from '../../../../../../LegendsOfLeakos/lib/Classes/PayResourceCost/PayResourceCost';
import PlayerInfo from '../../../../../../LegendsOfLeakos/lib/Classes/Player/PlayerInfo';
import RuntimeZone from '../../../../../../LegendsOfLeakos/lib/Classes/Zone/RuntimeZone';
import QueuePlayCardMessage from '../../../../../../LegendsOfLeakos/lib/Classes/Networking/Cards/Play/QueuePlayCardMessage';
import QueueCardMovedRowMessage from '../../../../../../LegendsOfLeakos/lib/Classes/Networking/Cards/MovedRow/QueueCardMovedRowMessage';
import { PlayerFlowEnum } from '../../../../../../LegendsOfLeakos/lib/Enums/PlayerFlow';

interface BoardProps {
  isPlayer: boolean;
  zoneEnum: ZoneEnum;
}

const Board: React.FC<BoardProps> = ({ isPlayer, zoneEnum }) => {
  // #region context
  const { masterPlayer, displayState, queuePlayCard } =
    useContext(PlayerContext);
  const { queuedMana, setQueuedMana } = useContext(TurnFlowContext);
  const {
    startAbility,
    actingAbilityEntity,
    actingAbility,
    currentTargetCriteria,
    addTarget,
  } = useContext(ActiveAbilityContext);
  const { draggedCard, setDraggedCard } = useContext(DraggedCardContext);
  const { amActingInCombat, actingCard, startCombatTargeting } =
    useContext(AttackingContext);
  const { boardHeight, boardCardWidth } = useContext(GameElementSizeContext);

  // #endregion

  // #region vars
  const [myPlayerInfo, setMyPlayerInfo] = useState<PlayerInfo>(null);
  useEffect(() => {
    if (!masterPlayer || !displayState) return;
    if (isPlayer) {
      setMyPlayerInfo(masterPlayer.getPlayerInfo(displayState));
    } else {
      setMyPlayerInfo(masterPlayer.getOpponentInfo(displayState));
    }
  }, [masterPlayer, masterPlayer && displayState]);

  const [myZone, setMyZone] = useState<RuntimeZone>(null);
  useEffect(() => {
    if (!myPlayerInfo) return;
    setMyZone(myPlayerInfo.getFriendlyZoneFromZoneEnum(zoneEnum));
  }, [myPlayerInfo]);
  // #endregion

  // #region mouse events
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    // if can be a target
    if (!!actingAbility) {
      if (!!currentTargetCriteria) {
        if (
          currentTargetCriteria.isEntityAValidTarget(
            actingAbilityEntity.instanceId,
            myZone.instanceId,
            displayState
          )
        ) {
          setHovered(true);
          return;
        }
      }
    }
    // if be have a card played
    if (!!draggedCard) {
      if (!isPlayer) return;
      if (!playCard) {
        console.log('This shouldnt happen, isPlayer is true but no playCard');
        return;
      }
      if (!myZone.zoneEnum || myZone.zoneEnum === ZoneEnum.BattleBoard) return;
      if (draggedCard.isPlayable(displayState)) {
        setHovered(true);
      }
    }
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleMouseUp = (e) => {
    // play card
    if (!!draggedCard) {
      if (!isPlayer) return;
      if (!playCard) {
        console.log('This shouldnt happen, isPlayer is true but no playCard');
        return;
      }
      if (!myZone.zoneEnum || myZone.zoneEnum === ZoneEnum.BattleBoard) return;
      playCard(draggedCard, myZone.zoneEnum);
    }
    // ability targeting
    else if (!!actingAbility && !!currentTargetCriteria && !!addTarget) {
      if (
        currentTargetCriteria.isEntityAValidTarget(
          actingAbilityEntity.instanceId,
          myZone.instanceId,
          displayState
        )
      ) {
        addTarget(e, myZone);
      }
    }
  };
  // #endregion

  // #region cards
  const [cards, setCards] = useState([]);
  const [queuedCards, setQueuedCards] = useState([]);

  const playCard = (card, zoneEnum) => {
    if (!masterPlayer || !displayState) return;
    if (!card) {
      console.log('playCard - no card');
      return;
    }
    if (!zoneEnum) {
      console.log('playCard - no zoneEnum');
      return;
    }
    // check if the card is playable
    if (!card.isPlayable(displayState)) {
      console.log('playCard - card is not playable');
      return;
    }

    const queuedCosts = queuedMana.map((mana) => {
      return new PayResourceCost(mana.statId, mana.value);
    });

    queuePlayCard(card.instanceId, zoneEnum, queuedCosts, 0, []);

    // clean up display states
    setQueuedMana([]);
  };

  useEffect(() => {
    if (!myPlayerInfo || !myZone) {
      return;
    }

    const cardsInZone = myZone.cards;
    const cardsPlayedToThisZone = [];
    const cardsMovedToThisZone = [];
    const cardsMovedFromThisZone = [];

    if (
      !!masterPlayer.getPlayerInfo(displayState).queueMessages &&
      masterPlayer.playerFlowEnum === PlayerFlowEnum.PlayerActing &&
      isPlayer
    ) {
      for (
        let i = 0;
        i < masterPlayer.getPlayerInfo(displayState).queueMessages.length;
        i++
      ) {
        const preMessage =
          masterPlayer.getPlayerInfo(displayState).queueMessages[i];

        if (preMessage.messageEnum === NetworkProtocol.QueuePlayCard) {
          const message = preMessage as QueuePlayCardMessage;
          if (
            (message as QueuePlayCardMessage).destinationZoneZoneEnum ===
            myZone.zoneEnum
          ) {
            const card = myPlayerInfo.getCardFromInstanceId(
              message.cardInstanceId
            );
            if (!card) {
              throw new Error(
                'Could not find card with instanceId ' +
                  message.cardInstanceId +
                  ' in playerInfo: ' +
                  myPlayerInfo
              );
            }
            cardsPlayedToThisZone.push(card);
          }
        }

        if (preMessage.messageEnum === NetworkProtocol.QueueCardMovedRow) {
          const message = preMessage as QueueCardMovedRowMessage;
          if (message.destinationZoneZoneEnum === myZone.zoneEnum) {
            const card = myPlayerInfo.getCardFromInstanceId(
              message.movedCardInstanceId
            );
            if (!card) {
              throw new Error(
                'Could not find card with instanceId ' +
                  message.movedCardInstanceId
              );
            }
            cardsMovedToThisZone.push(card);
          }
          if (message.originZoneZoneEnum === myZone.zoneEnum) {
            const card = myPlayerInfo.getCardFromInstanceId(
              message.movedCardInstanceId
            );
            if (!card) {
              throw new Error(
                'Could not find card with instanceId ' +
                  message.movedCardInstanceId
              );
            }
            cardsMovedFromThisZone.push(card);
          }
        }
      }
    }

    const newCards = cardsInZone.filter(
      (card) =>
        !cardsMovedFromThisZone.find(
          (movedCard) => movedCard.instanceId === card.instanceId
        )
    );

    const newQueuedCards = [];
    newQueuedCards.push(...cardsMovedToThisZone);
    newQueuedCards.push(...cardsPlayedToThisZone);

    setCards(newCards);
    setQueuedCards(newQueuedCards);
  }, [
    myPlayerInfo,
    masterPlayer &&
      displayState && // needed for getPlayerInfo
      masterPlayer.getPlayerInfo(displayState) &&
      masterPlayer.getPlayerInfo(displayState).queueMessages,
    myZone,
  ]);
  // #endregion

  // component
  return (
    <BoardStyled
      className={'board' + (isPlayer ? ' player' : ' opponent')}
      // props for styling
      amPlayingCard={!!draggedCard}
      amPlayableBoard={isPlayer && myZone?.zoneEnum !== ZoneEnum.BattleBoard}
      hovered={hovered}
      amActing={amActingInCombat}
      boardHeight={boardHeight}
      // mouse events
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
    >
      <div className={'board-content'}>
        <div className='boardcard-container'>
          {cards.map((card, index) => {
            return (
              <BoardCard
                key={card.instanceId}
                index={index}
                card={card}
                isPlayer={isPlayer}
                playerInfo={myPlayerInfo}
              />
            );
          })}
          {queuedCards.map((card, index) => {
            return (
              <BoardCard
                key={card.instanceId}
                index={index}
                card={card}
                isPlayer={isPlayer}
                playerInfo={myPlayerInfo}
                isQueued={true}
              />
            );
          })}
        </div>
        <div className='board-enchantments player'>
          {!!myZone && (
            <BoardEnchantmentList
              enchantments={myZone.enchantments.filter(
                (enchantment) =>
                  (isPlayer &&
                    enchantment.ownerPlayerUserId === myPlayerInfo.userId) ||
                  (!isPlayer &&
                    enchantment.ownerPlayerUserId !== myPlayerInfo.userId)
              )}
              isPlayerBoard={isPlayer}
              isPlayerEnchantment={true}
              playerInfo={myPlayerInfo}
              amOnZone={true}
              zoneHovered={hovered}
            />
          )}
        </div>
        <div className='board-enchantments opponent'>
          {!!myZone && (
            <BoardEnchantmentList
              enchantments={myZone.enchantments.filter(
                (enchantment) =>
                  (!isPlayer &&
                    enchantment.ownerPlayerUserId === myPlayerInfo.userId) ||
                  (isPlayer &&
                    enchantment.ownerPlayerUserId !== myPlayerInfo.userId)
              )}
              isPlayerBoard={isPlayer}
              isPlayerEnchantment={false}
              playerInfo={myPlayerInfo}
              amOnZone={true}
              zoneHovered={hovered}
            />
          )}
        </div>
      </div>
    </BoardStyled>
  );
};

export default Board;
