import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
// internal
import { RealmTileStyled } from './styles/RealmTile.styled.js';
import RealmMap from '../Tiles/RealmMap';

function RealmTile({ realm, isSelected, onRealmClicked, deleteRealm }) {
  // TODO - relace with a Realm class function
  const [tiles, setTiles] = useState([]);
  useEffect(() => {
    if (!realm) return;
    setTiles(realm.getLandTiles());
  }, [realm]);

  return (
    <RealmTileStyled
      onClick={() => {
        onRealmClicked(realm);
      }}
      selected={isSelected}
    >
      <div className='title-area'>
        <div className='title'>{realm.name}</div>
      </div>
      <div className='delete-button-holder'>
        <div
          className='delete-button'
          onClick={(event) => {
            event.stopPropagation();
            deleteRealm(realm._id);
          }}
        >
          X
        </div>
      </div>

      <div className='background'>
        <div className='background-holder'>
          <RealmMap
            tiles={tiles}
            outlineOnHover={false}
            outlinedTileIndices={[]}
            onClickTile={() => {}}
          />
        </div>
      </div>
    </RealmTileStyled>
  );
}

export default RealmTile;
