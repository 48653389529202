import styled from 'styled-components';
import '../../../../styles/constants.css';

export const StackListStyled = styled.div`
  position: absolute;
  right: 1%;
  top: 15%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.amActive ? '22%' : '0')};
  z-index: 600;
  border-top: 1px solid var(--border-color-bright);

  .stacklist-content {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .triangle-button {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 10px;
    transform: translateY(-110%);
    text-align: right;
    width: ${(props) => `${props.gameContainerWidth * 0.1}px`};
    font-size: ${(props) => `${props.gameContainerWidth * 0.015}px`};
    color: var(--lol-gold);
    z-index: 601;
  }

  .play-button {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-110%);
    text-align: left;
    width: 50%;
    font-size: ${(props) => `${props.gameContainerWidth * 0.015}px`};
    color: var(--lol-gold);
    z-index: 605;
  }

  .queue-content {
    position: relative;
    display: ${(props) => (props.amActive ? 'block' : 'none')};
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    z-index: 602;
  }

  .queuelines {
    width: 100%;
    position: absolute;
    right: 0;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
`;
