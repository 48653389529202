import styled from 'styled-components';
import '../../../styles/constants.css';

export const LobbyPageStyled = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
  z-index: 1;

  .main-content {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    margin: 0 10px;
    min-height: 0;
    min-width: 0;
  }

  .left-column {
    position: relative;
    grid-column: 1;
    height: 100%;
    width: 100%;
    border: 1px solid var(--border-color-bright);
    background-color: var(--dark-green);

    .background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      user-select: none;

      background-image: ${({ leftBackground }) => `url(${leftBackground})`};
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      opacity: 0.1;
    }
  }

  .center-column {
    position: relative;
    grid-column: 2;
    height: 100%;
    width: 100%;
    display: flex;
  }

  .realm-map {
    height: 100%;
  }

  .right-column {
    position: relative;
    grid-column: 3;
    height: 100%;
    width: 100%;
    border: 1px solid var(--border-color-bright);
    background-color: var(--dark-green);

    .background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      user-select: none;

      background-image: ${({ rightBackground }) => `url(${rightBackground})`};
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      opacity: 0.1;
    }
  }

  .screen-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      pointer-events: none;
      user-select: none;
    }
  }
`;
