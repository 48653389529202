class StatBuff {
    constructor(value, details) {
        this.value = value;
        this.details = details;
    }
    clone() {
        return new StatBuff(this.value, this.details);
    }
}
export default StatBuff;
