import styled from 'styled-components';
import '../../../styles/constants.css';

export const TidingsPageStyled = styled.div`
  position: relative;
  z-index: 1;
  height: ${({ height }) => height}px;
  overflow: hidden;

  background-image: ${({ background }) => `url(${background})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .main-content {
    position: relative;
    width: calc(100vw - 2 * var(--screen-margin));
    height: calc(
      ${({ height }) => height}px - var(--navbar-height) - var(--screen-margin)
    );
    margin: 0 var(--screen-margin);
    border: 1px solid var(--lol-gold);
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;

    background-image: ${({ mainBackground }) => `url(${mainBackground})`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .main-message {
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 2;

    h1 {
      font-family: var(--bold-medieval-font);
      color: var(--lol-red);
      font-size: ${({ displayWidth }) =>
        displayWidth ? `${displayWidth / 6}px` : '24px'};
    }
  }

  .button {
    padding: 10px;
    margin: 0 10px;
    text-align: center;
    border: 1px solid var(--lol-red);
    background-color: var(--lol-gold);
    box-sizing: border-box;

    font-family: var(--logo-font);
    color: var(--lol-red);
    font-size: 1rem;

    text-decoration: none;

    :hover {
      background-color: var(--lol-red);
      cursor: pointer;
      color: var(--lol-gold);
      border: 1px solid var(--lol-gold);
    }
  }
`;
