import React, { useState, useEffect, useContext } from 'react';

// internal
import EffectItem from './EffectItem';
// Utils
import Intcreaser from '../Utility/Intcreaser.jsx';
import TitledIntcreaser from '../Utility/TitledIntcreaser.jsx';
import TitledCycler from '../Utility/TitledCycler.jsx';
import TitledCheckbox from '../Utility/TitledCheckbox.jsx';
import EditableText from '../Utility/EditableText.jsx';
import Costs from '../CardBuilder/Stats/Costs.jsx';

// css

import { AbilityItemStyled } from './styles/AbilityItem.styled.js';
import { PhaseEnum } from '../../../../../../LegendsOfLeakos/lib/Enums/Phase.js';

function AbilityItem({ index, ability, abilities, save }) {
  const handlePhaseChange = (phase, isChecked) => {
    const newAbility = ability.clone();
    console.log('newAbility', newAbility);

    if (isChecked) {
      // Add the phase if it's not already in the array
      if (!newAbility.useableInPhases.includes(phase)) {
        newAbility.useableInPhases.push(phase);
      }
    } else {
      // Remove the phase from the array
      newAbility.useableInPhases = newAbility.useableInPhases.filter(
        (p) => p !== phase
      );
    }

    saveAbility(newAbility);
  };

  const saveAbility = (newAbility) => {
    const newAbilities = abilities.map((item, i) =>
      i === index ? newAbility : item
    );
    save(newAbilities);
  };

  return (
    <AbilityItemStyled>
      <div className='ability-item-content'>
        <button
          className='ability-delete-button'
          onClick={() => {
            const newAbilities = abilities.filter((item, i) => i !== index);
            save(newAbilities);
          }}
        >
          Delete
        </button>
        <EditableText
          text={ability.name}
          onTextChange={(text) => {
            const newAbility = ability.clone();
            newAbility.name = text;
            saveAbility(newAbility);
          }}
        />
        <EditableText
          text={ability.image}
          onTextChange={(text) => {
            const newAbility = ability.clone();
            newAbility.image = text;
            saveAbility(newAbility);
          }}
        />
        <img
          style={{ width: '100px' }}
          src={ability.image}
          alt={ability.name}
        />
        <EffectItem
          effect={ability.effect}
          saveEffect={(effect) => {
            const newAbility = ability.clone();
            newAbility.effect = effect;
            saveAbility(newAbility);
          }}
        />
        <div className='costs-title'>Costs</div>
        <Costs
          costs={ability.costs}
          saveCosts={(costs) => {
            const newAbility = ability.clone();
            newAbility.costs = costs;
            saveAbility(newAbility);
          }}
        />
        <TitledIntcreaser
          title='Uses Per Turn'
          int={ability.usesPerTurn}
          onIntChange={(value) => {
            const newAbility = ability.clone();
            newAbility.usesPerTurn = value;
            saveAbility(newAbility);
          }}
          minValue={0}
          maxValue={100}
        />
        <TitledCheckbox
          title='Starts Active'
          checked={ability.isActive}
          onCheckedChange={(checked) => {
            const newAbility = ability.clone();
            newAbility.isActive = checked;
            saveAbility(newAbility);
          }}
        />
        <div className='phases'>
          {Object.keys(PhaseEnum)
            .filter((key) => !isNaN(key)) // Filter out string keys
            .map((strNumKey) => {
              const numKey = parseInt(strNumKey);
              const strValue = PhaseEnum[numKey];
              return (
                <div key={numKey}>
                  <label>
                    <input
                      type='checkbox'
                      checked={ability.useableInPhases.includes(numKey)}
                      onChange={(e) =>
                        handlePhaseChange(numKey, e.target.checked)
                      }
                    />
                    {strValue}
                  </label>
                </div>
              );
            })}
        </div>
      </div>
    </AbilityItemStyled>
  );
}

export default AbilityItem;
