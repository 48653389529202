import styled from 'styled-components';
import '../../../../styles/constants.css';

export const MenuIconStyled = styled.div`
  background: none;
  position: relative;
  transition: transform 0.5s ease;
  pointer-events: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 10px;

  .menu-icon-bar {
    position: absolute;
    width: 10px;
    transition: transform 0.5s ease;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 20px;
      height: 2px;
      background-color: var(--lol-gold);
      transition: transform 0.5s ease;
    }

    &::before {
      top: -5px; // Adjust this value to set the gap
      transform: ${(props) =>
        props.isOpen
          ? 'translateY(5px) rotate(45deg)'
          : 'translateY(0) rotate(0)'};
    }

    &::after {
      top: 5px; // Adjust this value to set the gap
      transform: ${(props) =>
        props.isOpen
          ? 'translateY(-5px) rotate(-45deg)'
          : 'translateY(0) rotate(0)'};
    }
  }
`;
