import styled from 'styled-components';

export const TypesStyled = styled.div`
  .add-type-button {
    &:hover {
      text-decoration: bold;
      cursor: pointer;
    }
  }

  .types-container {
    margin-top: 10px;
  }
`;
