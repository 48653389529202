class StatModifier {
    constructor(value, duration = StatModifier.PERMANENT) {
        this.value = value;
        this.duration = duration;
    }
    isPermanent() {
        return this.duration === StatModifier.PERMANENT;
    }
    clone() {
        return new StatModifier(this.value, this.duration);
    }
    toJSON() {
        return {
            value: this.value,
            duration: this.duration,
        };
    }
    static fromJSON(json) {
        return new StatModifier(json.value, json.duration);
    }
}
StatModifier.PERMANENT = 0;
export default StatModifier;
