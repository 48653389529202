import React from 'react';
import { Navigate } from 'react-router-dom';
// context
import { AuthContext } from '../../contexts/AuthContextProvider';
function AuthenticatedPage({ children }) {
  const { isLoggedIn } = React.useContext(AuthContext);

  if (!isLoggedIn) {
    return <Navigate to='/sign-in' replace />;
  }
  return children;
}
export default AuthenticatedPage;
