import React, { useState, useEffect, useContext } from 'react';

// lol
import RuntimeKeyword from '../../../../../../LegendsOfLeakos/lib/Classes/Keyword/RuntimeKeyword/RuntimeKeyword.js';
import { KeywordType } from '../../../../../../LegendsOfLeakos/lib/Enums/Keyword.js';

// internal
import KeywordItem from './KeywordItem.jsx';

// css
import { KeywordListStyled } from './styles/KeywordList.styled.js';

function KeywordList({ keywords, save }) {
  const handleNewKeyword = (keywordType) => {
    const newKeyword = createNewKeyword(keywordType);
    keywords.push(newKeyword);
    save(keywords);
  };

  const createNewKeyword = (keywordType) => {
    const newKeyword = RuntimeKeyword.createSampleLibraryKeyword(keywordType);
    return newKeyword;
  };

  return (
    <KeywordListStyled>
      <div className='keyword-content'>
        <button
          className='add-keyword-button'
          onClick={() => handleNewKeyword(KeywordType.DamageModification)}
        >
          Add Keyword
        </button>
        <div className='keyword-items-container'>
          {keywords.map((keyword, index) => (
            <KeywordItem
              key={index}
              index={index}
              keyword={keyword}
              keywords={keywords}
              createNewKeyword={createNewKeyword}
              save={save}
            />
          ))}
        </div>
      </div>
    </KeywordListStyled>
  );
}

export default KeywordList;
