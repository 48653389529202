// external
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';

// internal
// css
import { LobbyPageStyled } from './styles/LobbyPage.styled.js';
// components
import Navbar from '../Navbar/Navbar.jsx';
import RealmSelection from './Setup/Realms/RealmSelection.jsx';
import RoomSelection from './Setup/Rooms/RoomSelection';
import ReadyButton from './ReadyButton';
import RealmWrapper from './RealmWrapper';

// context
import { ImageContext } from '../../contexts/ImageContextProvider';
import { SoundContext } from '../../contexts/SoundContextProvider';
import { AuthContext } from '../../contexts/AuthContextProvider';

// lol
import LibraryRealm from '../../../../../LegendsOfLeakos/lib/Classes/RealmsAndLand/Realm/LibraryRealm.js';

function LobbyPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // vars
  const { userId } = useContext(AuthContext);
  const soundContext = React.useContext(SoundContext);
  const { justWater, topo } = useContext(ImageContext);

  //#region REALM SELECTION
  const [realms, setRealms] = useState<
    (LibraryRealm & { _id: string | null })[]
  >([]);
  const [selectedRealmID, setSelectedRealmID] = useState<string | null>(null);
  useEffect(() => {
    axios
      .get(`/realms/${userId}`)
      .then((res) => {
        const fetchedRealms = createRealmsFromJSON(res.data);
        setRealms(fetchedRealms);
        if (fetchedRealms.length > 0) {
          const firstRealmId = fetchedRealms[0]._id;
          setSelectedRealmID(firstRealmId);
          // Optionally, you can also directly set the selected realm
          setSelectedRealm(fetchedRealms[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [selectedRealm, setSelectedRealm] = useState(null);
  useEffect(() => {
    if (!selectedRealmID) return;
    const temp = realms.find((realm) => realm._id === selectedRealmID);
    if (!temp) return;
    setSelectedRealm(temp);
  }, [selectedRealmID, realms]);

  const selectRealm = (realm_id) => {
    soundContext.playClick();
    setSelectedRealmID(realm_id);
    axios
      .patch(`/realms/${userId}/selected-realm`, { realm_id })
      .then((res) => {
        setSelectedRealmID(realm_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createRealmFromJSON = (
    json: any
  ): LibraryRealm & { _id: string | null } => {
    const realm = LibraryRealm.fromJSON(json) as LibraryRealm & {
      _id: string | null;
    };
    // Ensure _id is added to realm, use null or a default value if not present in json
    realm._id = json._id || null;
    return realm;
  };
  const createRealmsFromJSON = (json) => {
    if (!Array.isArray(json)) {
      console.error('Invalid JSON: Expected an array of realms - got:', json);
      return [];
    }
    const realms = [];
    json.forEach((realm) => {
      realms.push(createRealmFromJSON(realm));
    });
    return realms;
  };
  //#endregion

  return (
    <LobbyPageStyled
      leftBackground={topo}
      rightBackground={topo}
      waterBackground={justWater}
    >
      <div className='screen-background'>
        <img
          className='screen-background-image'
          src={justWater}
          alt='background'
        />
      </div>
      <Navbar />
      <div className='main-content'>
        <div className='left-column'>
          <div className='background-image'></div>
          <RealmSelection
            realms={realms}
            selectRealm={selectRealm}
            selectedRealmID={selectedRealmID}
          />
        </div>
        <div className='center-column'>
          <RealmWrapper realm={selectedRealm} />
        </div>
        <div className='right-column'>
          <div className='background-image'></div>
          <RoomSelection />
        </div>
      </div>
      <ReadyButton selectedRealmID={selectedRealmID} />
    </LobbyPageStyled>
  );
}

export default LobbyPage;
