// external
import React, { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// css
import { TitleButtonStyled } from './styles/TitleButton.styled';

// context
import { ImageContext } from '../../../contexts/ImageContextProvider';

function TitleButton() {
  const location = useLocation();
  const navigate = useNavigate();

  // context
  const { titleButtonActiveImage, titleButtonInactiveImage } =
    useContext(ImageContext);

  // Determine if the current page is the tidings page
  const isActive = location.pathname === '/';

  // hover state
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    if (!isActive) {
      navigate('/');
    }
  };

  return (
    <TitleButtonStyled
      className='title-button'
      activeOrHovered={isHovered}
      onMouseDown={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <h3 className='navbar-title-text' onClick={handleClick}>
        Legends of Leakos
      </h3>
    </TitleButtonStyled>
  );
}

export default TitleButton;
