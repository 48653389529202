import PlayerQueueline from '../PlayerQueueline.js';
export default class FightCreaturePlayerQueueLine extends PlayerQueueline {
    constructor(myPlayerUserId, attackingCardInstanceId, attackedCardInstanceId, sourcePlayerUserId, queuePosition) {
        super();
        this.fillBaseInfo(myPlayerUserId, attackingCardInstanceId, sourcePlayerUserId, queuePosition);
        this.attackedCardInstanceId = attackedCardInstanceId;
    }
    sendEffectToPlayer(gameState, myPlayer) {
        if (!myPlayer) {
            throw new Error('Player not found');
        }
        return myPlayer.onCreatureAttacked(this.sourceCardInstanceId, this.attackedCardInstanceId);
    }
    actionToString(gameState) {
        const sourcePlayer = gameState.getPlayerInfoByUserId(this.sourcePlayerUserId);
        const sourceCard = gameState.getCardFromAnywhere(this.sourceCardInstanceId);
        const attackedCard = gameState.getCardFromAnywhere(this.attackedCardInstanceId);
        if (!sourcePlayer || !sourceCard || !attackedCard) {
            throw new Error('Player or card not found');
        }
        return `${sourcePlayer.name}'s ${sourceCard.name} (Instance ID: ${sourceCard.instanceId}) fought ${attackedCard.name} (Instance ID: ${attackedCard.instanceId})`;
    }
    clone() {
        return new FightCreaturePlayerQueueLine(this.myPlayerUserId, this.sourceCardInstanceId, this.attackedCardInstanceId, this.sourcePlayerUserId, this.queuePosition);
    }
}
