import React, { useState, useEffect, useContext } from 'react';

// internal
import Intcreaser from '../Utility/Intcreaser.jsx';
import TitledIntcreaser from '../Utility/TitledIntcreaser.jsx';
import TitledCycler from '../Utility/TitledCycler.jsx';
import TitledCheckbox from '../Utility/TitledCheckbox.jsx';
import DataEditor from '../Data/DataEditor.jsx';
import ConditionList from '../Conditions/ConditionList.tsx';
// css
import { KeywordItemStyled } from './styles/KeywordItem.styled.js';

// lol
import RuntimeKeyword from '../../../../../../LegendsOfLeakos/lib/Classes/Keyword/RuntimeKeyword/RuntimeKeyword.js';
import { KeywordType } from '../../../../../../LegendsOfLeakos/lib/Enums/Keyword.js';

function KeywordItem({ keyword, keywords, createNewKeyword, save, index }) {
  const changeKeywordType = (keywordType) => {
    const newKeyword = createNewKeyword(keywordType);
    console.log(newKeyword);
    saveKeyword(newKeyword);
  };

  const saveKeyword = (keyword) => {
    const newKeyword = keyword.clone();
    const newLibraryKeywords = keywords.map((item, i) =>
      i === index ? newKeyword : item
    );
    save(newLibraryKeywords);
  };

  return (
    <KeywordItemStyled>
      <div className='keyword-item-content'>
        <button
          className='keyword-delete-button'
          onClick={() => {
            const newLibraryKeywords = keywords.filter(
              (item, i) => i !== index
            );
            save(newLibraryKeywords);
          }}
        >
          Delete
        </button>
        <TitledCycler
          title='Keyword Type'
          items={Object.keys(RuntimeKeyword.sampleLibraryKeywordMethods).map(
            (item) => KeywordType[item]
          )}
          onItemChange={(keywordString) =>
            changeKeywordType(KeywordType[keywordString])
          }
          currentItem={KeywordType[keyword.keywordType]}
        />
        <TitledCheckbox
          title='Is Keyword Permanent'
          checked={keyword.isPermanent}
          onCheckedChange={(checked) => {
            keyword.isPermanent = checked;
            saveKeyword(keyword);
          }}
        />
        <TitledIntcreaser
          title='Duration'
          int={keyword.duration}
          onIntChange={(value) => {
            keyword.duration = value;
            saveKeyword(keyword);
          }}
          minValue={0}
          maxValue={100}
        />
        <TitledCheckbox
          title='Starts Active'
          checked={keyword.isActive}
          onCheckedChange={(checked) => {
            keyword.isActive = checked;
            saveKeyword(keyword);
          }}
        />
        <DataEditor
          data={keyword.data}
          save={(data) => {
            const newKeyword = keyword.clone();
            newKeyword.data = data;
            saveKeyword(newKeyword);
          }}
        />
        <ConditionList
          conditions={keyword.conditions}
          save={(conditions) => {
            const newKeyword = keyword.clone();
            newKeyword.conditions = conditions;
            saveKeyword(newKeyword);
          }}
        />
      </div>
    </KeywordItemStyled>
  );
}

export default KeywordItem;
