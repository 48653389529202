import { ServerMessage } from '../MessageBase.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
export default class LandExploredMessage extends ServerMessage {
    constructor(recipientUserId, playerUserId, explored, landTileId) {
        super(recipientUserId);
        this.messageEnum = NetworkProtocol.LandExplored;
        this.playerUserId = playerUserId;
        this.explored = explored;
        this.landTileId = landTileId;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { playerUserId: this.playerUserId, explored: this.explored, landTileId: this.landTileId });
    }
    static fromJSON(json) {
        return new LandExploredMessage(json.recipientUserId, json.playerUserId, json.explored, json.landTileId);
    }
    // check that all fields in the message are valid
    validate() {
        return (this.recipientUserId != null &&
            this.playerUserId != null &&
            this.explored != null &&
            this.landTileId != null);
    }
}
