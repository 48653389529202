import { ClientMessage } from '../MessageBase.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
export default class QueueFightCreatureMessage extends ClientMessage {
    constructor(messageId, senderUserId, attackingCardInstanceId, attackedCardInstanceId) {
        super(messageId, senderUserId);
        this.messageEnum = NetworkProtocol.QueueFightCreature;
        this.attackingCardInstanceId = attackingCardInstanceId;
        this.attackedCardInstanceId = attackedCardInstanceId;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { attackingCardInstanceId: this.attackingCardInstanceId, attackedCardInstanceId: this.attackedCardInstanceId });
    }
    static fromJSON(json) {
        return new QueueFightCreatureMessage(json.messageId, json.senderUserId, json.attackingCardInstanceId, json.attackedCardInstanceId);
    }
    // check that all fields in the message are valid
    validate() {
        return (super.validate() &&
            this.attackingCardInstanceId != null &&
            this.attackedCardInstanceId != null);
    }
}
ClientMessage.registerFromJSON(NetworkProtocol.QueueFightCreature, QueueFightCreatureMessage.fromJSON);
