import styled from 'styled-components';
import '../../../styles/constants.css';

export const Modal = styled.div`
  position: absolute;
  top: 105%;
  right: 0;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  background-color: var(--dark-green);
  padding: 10px;
  border: 1px solid var(--lol-gold);
`;

export const MenuItem = styled.div`
  text-align: right;
  color: var(--lol-gold);
  cursor: pointer;

  &:hover {
    color: var(--lol-red);
  }
`;
