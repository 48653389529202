import AbilityKeywordRuntimeEntity from '../Entity/AbilityKeywordRuntimeEntity.js';
import Stat from '../Stat/Stat.js';
import RuntimeEnchantment from '../Enchantment/RuntimeEnchantment.js';
import RuntimeKeyword from '../Keyword/RuntimeKeyword/RuntimeKeyword.js';
import RuntimeAbility from '../Ability/RuntimeAbility.js';
import LibraryCardEntry from '../RealmsAndLand/Biome/LibraryCardEntry.js';
import GameProperties from '../Game/GameProperties.js';
import { ZoneEnum } from '../../Enums/Zone.js';
class RuntimeCard extends AbilityKeywordRuntimeEntity {
    constructor(name, instanceId, ownerPlayerUserId, residingZoneInstanceId, keywords, abilities, libraryId, upgradesApplied, attack, health, priority, enchantments) {
        super();
        this.upgradesApplied = [];
        this.getStatList = () => [this.attack, this.health, this.priority];
        this.enchantments = [];
        // #region utilities
        this.isPlayable = (gameState) => {
            const phase = GameProperties.gamePhases[gameState.currentPhaseIndex];
            if (!GameProperties.phasesCardsCanBePlayedIn.includes(phase.phaseEnum)) {
                return false;
            }
            const ownerPlayer = gameState.getPlayerInfoByUserId(this.ownerPlayerUserId);
            const libraryCard = this.getLibraryCard(gameState);
            if (!ownerPlayer.canPayResourceCosts(libraryCard.costs)) {
                return false;
            }
            const zone = gameState.getZoneByInstanceId(this.residingZoneInstanceId);
            if (!zone || zone.zoneEnum !== ZoneEnum.Hand) {
                return false;
            }
            return true;
        };
        this.name = name;
        this.instanceId = instanceId;
        this.ownerPlayerUserId = ownerPlayerUserId;
        this.residingZoneInstanceId = residingZoneInstanceId;
        this.keywords = keywords;
        this.abilities = abilities;
        this.libraryId = libraryId;
        this.upgradesApplied = upgradesApplied;
        this.attack = attack;
        this.health = health;
        this.priority = priority;
        this.enchantments = enchantments;
    }
    getLibraryCard(gameState) {
        return gameState.gameManager.getCardFromLibraryId(this.libraryId);
    }
    // #endregion
    // #region post and pre resolve effect
    preResolveEffect(e, sourceEntityInstanceId, gameState, targetInfoList) {
        const finalResults = super.preResolveEffect(e, sourceEntityInstanceId, gameState, targetInfoList);
        for (const enchantment of this.enchantments) {
            const results = enchantment.preResolveEffect(e, sourceEntityInstanceId, gameState, targetInfoList);
            finalResults.push(...results);
        }
        return finalResults;
    }
    postResolveEffect(e, sourceEntityInstanceId, gameState, targetInfoList) {
        const finalResults = super.postResolveEffect(e, sourceEntityInstanceId, gameState, targetInfoList);
        for (const enchantment of this.enchantments) {
            const results = enchantment.postResolveEffect(e, sourceEntityInstanceId, gameState, targetInfoList);
            finalResults.push(...results);
        }
        return finalResults;
    }
    // #endregion
    // #region from LibraryCardEntry and from JSON (runtime and library)
    clone() {
        return new RuntimeCard(this.name, this.instanceId, this.ownerPlayerUserId, this.residingZoneInstanceId, this.keywords.map((k) => k.clone()), this.abilities.map((a) => a.clone()), this.libraryId, this.upgradesApplied.map((u) => u), this.attack.clone(), this.health.clone(), this.priority.clone(), this.enchantments.map((e) => e.clone()));
    }
    toJSON() {
        return {
            // super
            name: this.name,
            instanceId: this.instanceId,
            residingZoneInstanceId: this.residingZoneInstanceId,
            ownerPlayerUserId: this.ownerPlayerUserId,
            keywords: this.keywords.map((k) => k.toJSON()),
            abilities: this.abilities.map((a) => a.toJSON()),
            // super end
            libraryId: this.libraryId,
            upgradesApplied: this.upgradesApplied.map((u) => u),
            attack: this.attack.toJSON(),
            health: this.health.toJSON(),
            priority: this.priority.toJSON(),
            enchantments: this.enchantments.map((enchantment) => enchantment.toJSON()),
        };
    }
    static fromRuntimeJSON(json) {
        return new RuntimeCard(json.name, json.instanceId, json.ownerPlayerUserId, json.residingZoneInstanceId, json.keywords.map((k) => RuntimeKeyword.fromRuntimeJSON(k)), json.abilities.map((a) => RuntimeAbility.fromRuntimeJSON(a)), json.libraryId, json.upgradesApplied, Stat.fromRuntimeJSON(json.attack), Stat.fromRuntimeJSON(json.health), Stat.fromRuntimeJSON(json.priority), json.enchantments.map((e) => RuntimeEnchantment.fromRuntimeJSON(e)));
    }
}
RuntimeCard.cardFromLibraryJSON = (json, zone, ownerPlayer) => {
    const instanceId = ownerPlayer.currentEntityInstanceId++;
    const attackStat = GameProperties.cardStats
        .find((s) => s.name === 'Attack')
        .clone();
    attackStat.baseValue = json.attack;
    const healthStat = GameProperties.cardStats
        .find((s) => s.name === 'Health')
        .clone();
    healthStat.baseValue = json.health;
    const priorityStat = GameProperties.cardStats
        .find((s) => s.name === 'Priority')
        .clone();
    priorityStat.baseValue = json.priority;
    return new RuntimeCard(json.name, instanceId, ownerPlayer.userId, zone.instanceId, json.keywords.map((k) => RuntimeKeyword.fromLibraryJSON(instanceId, k)), json.abilities.map((a) => RuntimeAbility.fromLibraryJSON(a)), json.libraryId, [], // upgradesApplied
    attackStat, healthStat, priorityStat, [] // enchantments
    );
};
RuntimeCard.cardsFromLibraryCardEntry = (json, zone, ownerPlayer, cardLibrary) => {
    const libraryCardEntry = LibraryCardEntry.fromJSON(json);
    if (!libraryCardEntry)
        throw new Error('Invalid LibraryCardEntry');
    const libraryCard = cardLibrary.find((c) => c.libraryId === libraryCardEntry.libraryId);
    const cards = [];
    for (let i = 0; i < libraryCardEntry.amount; i++) {
        const card = RuntimeCard.cardFromLibraryJSON(libraryCard.toJSON(), zone, ownerPlayer);
        cards.push(card);
    }
    return cards;
};
export default RuntimeCard;
