import RuntimeEnchantment from '../../../Enchantment/RuntimeEnchantment.js';
import RuntimeEffect from '../../RuntimeEffect.js';
import { EffectType } from '../../../../Enums/Effect.js';
import TargetCriteria from '../../../Target/TargetCriteria.js';
import RuntimeZone from '../../../Zone/RuntimeZone.js';
import { TargetTypeEnum, TargetableTypeSelectionEnum, } from '../../../../Enums/Target.js';
import RuntimeCard from '../../../Card/RuntimeCard.js';
import LibraryEffect from '../../LibraryEffect.js';
import StackEvent from '../../../StackEvent/StackEvent.js';
import { ZoneEnum } from '../../../../Enums/Zone.js';
import { StackEntityEnum, StackEventEnum } from '../../../../Enums/Stack.js';
import StackEntity from '../../../StackEvent/StackEntity.js';
class EnchantEffect extends RuntimeEffect {
    targetCriterias() {
        return [this.enchantTargets];
    }
    constructor(enchantmentLibraryID, enchantTargets) {
        super();
        this.effectType = EffectType.Enchant;
        this.enchantmentLibraryID = enchantmentLibraryID;
        this.enchantTargets = enchantTargets;
    }
    // #region Resolve
    preEffect(state, sourceEntity, targetInfoList) {
        if (!this.isAllTargetInfoValid(sourceEntity, state, targetInfoList))
            return false;
        return true;
    }
    resolve(state, sourceEntity, targetInfoList) {
        if (!this.isAllTargetInfoValid(sourceEntity, state, targetInfoList))
            return [
                new StackEvent(StackEventEnum.EffectResolve, state.clone(), false, 'Invalid Target Info', [new StackEntity(sourceEntity.instanceId, StackEntityEnum.Source)]),
            ];
        const libraryEnchantment = state.gameManager.getEnchantmentFromLibraryId(this.enchantmentLibraryID);
        const creatingPlayer = state.getPlayerInfoByUserId(sourceEntity.ownerPlayerUserId);
        const enchantTargetInfo = targetInfoList[0];
        for (let targetInstanceId of enchantTargetInfo.targetEntityInstanceIds) {
            const targetEntity = state.getEntityFromAnywhere(targetInstanceId);
            let targetZone = null;
            let targetCard = null;
            if (targetEntity instanceof RuntimeZone) {
                targetZone = targetEntity;
            }
            else if (targetEntity instanceof RuntimeCard) {
                targetCard = targetEntity;
                targetZone = state.getZoneByInstanceId(targetCard.residingZoneInstanceId);
            }
            const runtimeEnchantment = RuntimeEnchantment.fromLibraryJSON(libraryEnchantment.toJSON(), sourceEntity, creatingPlayer, targetZone, targetCard);
            if (targetEntity instanceof RuntimeZone) {
                targetEntity.enchantments.push(runtimeEnchantment);
                return [
                    new StackEvent(StackEventEnum.EffectResolve, state.clone(), true, 'Enchantment added to ' + ZoneEnum[targetEntity.zoneEnum], [
                        new StackEntity(sourceEntity.instanceId, StackEntityEnum.Source),
                        new StackEntity(targetEntity.instanceId, StackEntityEnum.Target),
                    ]),
                ];
            }
            else if (targetEntity instanceof RuntimeCard) {
                targetEntity.enchantments.push(runtimeEnchantment);
                return [
                    new StackEvent(StackEventEnum.EffectResolve, state.clone(), true, 'Enchantment added to ' + targetEntity.getLibraryCard(state).name, [
                        new StackEntity(sourceEntity.instanceId, StackEntityEnum.Source),
                        new StackEntity(targetEntity.instanceId, StackEntityEnum.Target),
                    ]),
                ];
            }
        }
    }
    // #endregion
    // #region Targeting
    areTargetsAvailable(state, sourceEntity) {
        return this.enchantTargets.areTargetsAvailable(sourceEntity.instanceId, state);
    }
    isAllTargetInfoValid(sourceEntity, state, targetInfoList) {
        if (targetInfoList.length !== 1)
            return false;
        if (!this.enchantTargets.areTargetsAvailable(sourceEntity.instanceId, state))
            return false;
        return this.enchantTargets.isTargetInfoValid(sourceEntity.instanceId, targetInfoList[0], state);
    }
    // #endregion
    // #region Effect To Text
    effectToString(gameManager) {
        let outText = 'Enchant zone with ' +
            gameManager.getEnchantmentFromLibraryId(this.enchantmentLibraryID).name +
            ' enchantment';
        return outText;
    }
    // #endregion
    // #region JSON
    toJSON() {
        return {
            effectType: EffectType[this.effectType],
            enchantmentLibraryID: this.enchantmentLibraryID,
            enchantTargets: this.enchantTargets.toJSON(),
        };
    }
    clone() {
        return new EnchantEffect(this.enchantmentLibraryID, this.enchantTargets.clone());
    }
    static fromRuntimeJSON(json) {
        return new EnchantEffect(json.enchantmentLibraryID, TargetCriteria.fromRuntimeJSON(json.enchantTargets));
    }
    static fromLibraryJSON(json) {
        return new EnchantEffect(json.data.enchantmentLibraryID.value, TargetCriteria.fromLibraryJSON(json.data.enchantTargets.value));
    }
    static isLibraryJSONValid(json) {
        return (typeof json.data.enchantmentLibraryID.value === 'number' &&
            TargetCriteria.isLibraryJSONValid(json.data.enchantTargets.value));
    }
    // #endregion
    // #region Sample Effect
    static createSampleLibraryEffect() {
        const tc = new TargetCriteria('Enchant Target', TargetTypeEnum.TargetRow, 1, // minSelectionsRequired
        1, // maxSelectionsAllowed
        1, // minSelectionsThatMustRemain
        TargetableTypeSelectionEnum.TargetableOnActivation, [] // conditions
        );
        return new LibraryEffect(EffectType.Enchant, {
            enchantmentLibraryID: {
                type: 'EnchantmentLibraryId',
                value: 0,
            },
            enchantTargets: {
                type: 'TargetCriteria',
                value: tc.toJSON(),
            },
        });
    }
    // #endregion
    static initialize() {
        RuntimeEffect.registerFromRuntimeJSON(EffectType.Enchant, EnchantEffect.fromRuntimeJSON);
        RuntimeEffect.registerFromLibraryJSON(EffectType.Enchant, EnchantEffect.fromLibraryJSON);
        RuntimeEffect.registerSampleLibraryEffect(EffectType.Enchant, EnchantEffect.createSampleLibraryEffect);
        RuntimeEffect.registerIsLibraryJSONValid(EffectType.Enchant, EnchantEffect.isLibraryJSONValid);
    }
}
export default EnchantEffect;
