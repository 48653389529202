import { ConditionType } from '../../Enums/Condition.js';
class LibraryCondition {
    constructor(conditionType, data) {
        this.conditionType = conditionType;
        this.data = data;
    }
    toJSON() {
        return {
            conditionType: ConditionType[this.conditionType],
            data: this.data,
        };
    }
    clone() {
        return new LibraryCondition(this.conditionType, JSON.parse(JSON.stringify(this.data)));
    }
    static fromJSON(json) {
        const cond = new LibraryCondition(ConditionType[json.conditionType], json.data);
        return cond;
    }
}
export default LibraryCondition;
