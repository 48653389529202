import { ClientMessage } from '../../MessageBase.js';
import PayResourceCost from '../../../PayResourceCost/PayResourceCost.js';
import TargetInfo from '../../../Target/TargetInfo.js';
import { NetworkProtocol } from '../../../../Enums/NetworkProtocol.js';
export default class QueuePlayCardMessage extends ClientMessage {
    constructor(messageId, senderUserId, cardInstanceId, destinationZoneZoneEnum, paidCosts, targetInfoList) {
        // enum
        super(messageId, senderUserId);
        this.messageEnum = NetworkProtocol.QueuePlayCard;
        this.cardInstanceId = cardInstanceId;
        this.destinationZoneZoneEnum = destinationZoneZoneEnum;
        this.paidCosts = paidCosts;
        this.targetInfoList = targetInfoList;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { cardInstanceId: this.cardInstanceId, destinationZoneZoneEnum: this.destinationZoneZoneEnum, paidCosts: this.paidCosts.map((cost) => cost.toJSON()), targetInfoList: this.targetInfoList.map((info) => info.toJSON()) });
    }
    static fromJSON(json) {
        const paidCosts = json.paidCosts.map((cost) => PayResourceCost.fromJSON(cost));
        const targetInfoList = json.targetInfoList.map((info) => TargetInfo.fromJSON(info));
        return new QueuePlayCardMessage(json.messageId, json.senderUserId, json.cardInstanceId, json.destinationZoneZoneEnum, paidCosts, targetInfoList);
    }
    // check that all fields in the message are valid
    validate() {
        return (super.validate() &&
            this.cardInstanceId != null &&
            this.destinationZoneZoneEnum != null &&
            this.paidCosts != null &&
            this.targetInfoList != null);
    }
}
ClientMessage.registerFromJSON(NetworkProtocol.QueuePlayCard, QueuePlayCardMessage.fromJSON);
