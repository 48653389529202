import PayResourceCost from '../PayResourceCost/PayResourceCost.js';
import RuntimeEffect from '../Effect/RuntimeEffect.js';
import { PhaseEnum } from '../../Enums/Phase.js';
import GameProperties from '../Game/GameProperties.js';
// Import other required classes and types
class RuntimeAbility {
    constructor(setName, setEffect, setCosts, setUsesPerTurn, setUsesRemaining, useableInPhases, isActive, image) {
        this.useableInPhases = [];
        this.costs = [];
        this.name = setName;
        this.effect = setEffect;
        this.isActive = isActive;
        setCosts.forEach((cost) => {
            const temp = new PayResourceCost(cost.statId, cost.value);
            this.costs.push(temp);
        });
        this.usesPerTurn = setUsesPerTurn;
        this.usesRemaining = setUsesRemaining;
        useableInPhases.forEach((phase) => {
            this.useableInPhases.push(phase);
        });
        this.image = image;
    }
    isActivatable(owner, gameState) {
        if (this.usesRemaining <= 0) {
            return false;
        }
        if (!this.isActive) {
            return false;
        }
        if (!this.useableInPhases.includes(GameProperties.gamePhases[gameState.currentPhaseIndex].phaseEnum)) {
            return false;
        }
        if (!owner.canPayResourceCosts(this.costs)) {
            return false;
        }
        return true;
    }
    onEndTurn() {
        this.usesRemaining = this.usesPerTurn;
        // effect end turn
    }
    // #region JSON
    clone() {
        return new RuntimeAbility(this.name, this.effect.clone(), this.costs.map((cost) => cost.clone()), this.usesPerTurn, this.usesRemaining, this.useableInPhases, this.isActive, this.image);
    }
    toJSON() {
        return {
            name: this.name,
            effect: this.effect.toJSON(),
            costs: this.costs.map((cost) => cost.toJSON()),
            usesPerTurn: this.usesPerTurn,
            usesRemaining: this.usesRemaining,
            useableInPhases: this.useableInPhases.map((phase) => PhaseEnum[phase]),
            isActive: this.isActive,
            image: this.image,
        };
    }
    static fromRuntimeJSON(json) {
        return new RuntimeAbility(json.name, RuntimeEffect.fromRuntimeJSON(json.effect), json.costs.map((cost) => PayResourceCost.fromJSON(cost)), json.usesPerTurn, json.usesRemaining, json.useableInPhases.map((phase) => PhaseEnum[phase]), json.isActive, json.image);
    }
    static fromLibraryJSON(json) {
        return new RuntimeAbility(json.name, RuntimeEffect.fromLibraryJSON(json.effect), json.costs.map((cost) => PayResourceCost.fromJSON(cost)), json.usesPerTurn, json.usesPerTurn, // usesRemaining should be full on set
        json.useableInPhases.map((phase) => PhaseEnum[phase]), json.isActive, json.image);
    }
    static isLibraryJSONValid(json) {
        if (typeof json.name !== 'string') {
            console.log('Invalid JSON: name is not a string');
            return false;
        }
        if (!RuntimeEffect.isLibraryJSONValid(json.effect)) {
            console.log('Invalid JSON: effect is not valid');
            return false;
        }
        if (!Array.isArray(json.costs) ||
            !json.costs.every((c) => PayResourceCost.isLibraryJSONValid(c))) {
            console.log('Invalid JSON: costs is not an array');
            return false;
        }
        for (const cost of json.costs) {
            if (!PayResourceCost.isLibraryJSONValid(cost)) {
                console.log('Invalid JSON: cost is not valid');
                return false;
            }
        }
        if (typeof json.usesPerTurn !== 'number') {
            console.log('Invalid JSON: usesPerTurn is not a number');
            return false;
        }
        if (!Array.isArray(json.useableInPhases)) {
            console.log('Invalid JSON: useableInPhases is not an array');
            return false;
        }
        for (const phase of json.useableInPhases) {
            if (typeof phase !== 'string') {
                console.log('Invalid JSON: phase is not a string: ', phase);
                return false;
            }
            if (!Object.values(PhaseEnum).includes(phase)) {
                console.log('Invalid JSON: phase is not a valid PhaseEnum: ', phase);
                return false;
            }
        }
        return true;
    }
}
export default RuntimeAbility;
