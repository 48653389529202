import { KeywordType } from '../../../Enums/Keyword.js';
import LibraryCondition from '../../Condition/LibraryCondition.js';
class LibraryKeyword {
    constructor(keywordType, description, isPermanent, duration, isActive, conditions, imageName, data) {
        // conditions are for like - which cards will this buff, etc. I think it's largely for stat buffs
        this.conditions = [];
        this.keywordType = keywordType;
        this.description = description;
        this.isPermanent = isPermanent;
        this.duration = duration;
        this.isActive = isActive;
        this.conditions = conditions;
        this.imageName = imageName;
        this.data = data;
    }
    toJSON() {
        return {
            keywordType: KeywordType[this.keywordType],
            description: this.description,
            isPermanent: this.isPermanent,
            duration: this.duration,
            isActive: this.isActive,
            conditions: this.conditions.map((c) => c.toJSON()),
            imageName: this.imageName,
            data: this.data,
        };
    }
    clone() {
        return new LibraryKeyword(this.keywordType, this.description, this.isPermanent, this.duration, this.isActive, this.conditions.map((c) => c.clone()), this.imageName, JSON.parse(JSON.stringify(this.data)));
    }
    static fromJSON(json) {
        return new LibraryKeyword(KeywordType[json.keywordType], json.description, json.isPermanent, json.duration, json.isActive, json.conditions.map((c) => LibraryCondition.fromJSON(c)), json.imageName, json.data);
    }
}
export default LibraryKeyword;
