var CardType;
(function (CardType) {
    CardType[CardType["Human"] = 0] = "Human";
    CardType[CardType["Animal"] = 1] = "Animal";
    CardType[CardType["Plant"] = 2] = "Plant";
    CardType[CardType["Spirit"] = 3] = "Spirit";
    CardType[CardType["Curse"] = 4] = "Curse";
    CardType[CardType["Blessing"] = 5] = "Blessing";
})(CardType || (CardType = {}));
export { CardType };
