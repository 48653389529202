// external
import React, { useContext } from 'react';

// internal
// context
import { ActionLineContext } from '../../../contexts/ActionLineContextProvider';

// components
import AttackLine from './AttackLine';
import BlockLine from './BlockLine';

const ActionLines = () => {
  // external context
  const { attackLineData, blockLineData } = useContext(ActionLineContext);

  return (
    <div className='queuelines'>
      <div className='attack-lines'>
        {attackLineData?.map((data, index) => {
          return <AttackLine key={index} attackLineData={data} />;
        })}
      </div>
      <div className='block-lines'>
        {blockLineData?.map((data, index) => {
          return <BlockLine key={index} blockLineData={data} />;
        })}
      </div>
      <div className='ability-lines'></div>
    </div>
  );
};

export default ActionLines;
