// a component that displays a realm representing a players land
// external
import React, { useState, useEffect, useContext } from 'react';

// internal components
import RealmMap from '../../Tiles/RealmMap';
import RealmButton from '../Buttons/RealmButton.jsx';

// context
import { PlayerContext } from '../../../contexts/PlayerContextProvider';
import { TurnFlowContext } from '../../../contexts/TurnFlowContextProvider';
import { ImageContext } from '../../../contexts/ImageContextProvider';

// css
import { RealmStyled } from './styles/Realm.styled.js';

// lol
import { PhaseEnum } from '../../../../../../LegendsOfLeakos/lib/Enums/Phase.js';
import { PlayerFlowEnum } from '../../../../../../LegendsOfLeakos/lib/Enums/PlayerFlow';

function Realm({ isPlayerRealm }) {
  // #region context
  const { displayState, playerInfo, opponentInfo, exploreLand, masterPlayer } =
    useContext(PlayerContext);
  const { setPhaseButtonMessage } = useContext(TurnFlowContext);
  const { justWater } = useContext(ImageContext);
  // #endregion

  // #region realm and tiles

  const [realm, setRealm] = useState(null);
  const [tiles, setTiles] = useState(null);

  useEffect(() => {
    if (!displayState || !playerInfo) return;
    if (isPlayerRealm) {
      setRealm(playerInfo?.realm);
    } else {
      setRealm(opponentInfo?.realm);
    }
  }, [displayState, playerInfo, opponentInfo, isPlayerRealm]);

  useEffect(() => {
    if (!realm) {
      return;
    }
    setTiles(realm.landTiles);
  }, [realm]);

  // #endregion

  // #region vis

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!masterPlayer) return;
    if (!isPlayerRealm) {
      setIsVisible(false);
      return;
    }

    if (masterPlayer.playerFlowEnum === PlayerFlowEnum.PlayerExploring) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [masterPlayer && masterPlayer.playerFlowEnum]);

  // #endregion

  // #region outlined tiles

  const [outlinedTileIndices, setOutlinedTileIndices] = useState([]);

  useEffect(() => {
    if (!tiles) return;
    // indices
    const exploredTiles = tiles.filter((tile) => tile.explored);
    const exploredTileIndices = exploredTiles.map((tile) => tile.id);
    setOutlinedTileIndices(exploredTileIndices);
  }, [tiles]);

  // #endregion

  // #region calls to player

  const exploreTile = (landTileId) => {
    try {
      // check if it's your realm
      if (!isPlayerRealm) {
        console.log('Not your realm');
        return;
      }

      // get the land tile
      const landTile = playerInfo.realm.getLandTile(landTileId);
      if (!landTile) {
        throw new Error('No tile provided');
      }
      if (landTile.explored) {
        console.log('Tile already explored');
        return;
      }
      if (!playerInfo) {
        throw new Error('No player');
      }
      if (!landTile.anyNeighborExplored(playerInfo.realm.landTiles)) {
        console.log('No neighbors explored');
        return;
      }
      if (
        displayState.currentTurn <
        playerInfo.realm.landTiles.filter((tile) => tile.explored).length
      ) {
        console.log('Not enough turns');
        return;
      }
      setPhaseButtonMessage('Waiting for opponent');
      setIsVisible(false);

      exploreLand(landTile);
    } catch (err) {
      console.log(err);
    }
  };

  // #endregion

  return (
    <RealmStyled
      className='game-realm'
      isVisible={isVisible}
      backgroundImage={justWater}
    >
      <RealmButton
        isVisible={isVisible}
        onClick={() => setIsVisible((prev) => !prev)}
        isPlayer={isPlayerRealm}
      />
      {isVisible && (
        <div className='realm-wrapper'>
          {/* <div className='background'></div> */}
          <RealmMap
            tiles={tiles}
            outlineOnHover={true}
            outlinedTileIndices={outlinedTileIndices}
            onClickTile={exploreTile}
          />
        </div>
      )}
    </RealmStyled>
  );
}

export default Realm;
