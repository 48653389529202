import { ServerMessage } from '../MessageBase.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
export default class CreatureAttackedMessage extends ServerMessage {
    constructor(recipientUserId, attackingPlayerUserId, attackingCardInstanceId, attackedCardInstanceId, queuePosition) {
        super(recipientUserId);
        this.messageEnum = NetworkProtocol.CreatureAttacked;
        this.attackingPlayerUserId = attackingPlayerUserId;
        this.attackingCardInstanceId = attackingCardInstanceId;
        this.attackedCardInstanceId = attackedCardInstanceId;
        this.queuePosition = queuePosition;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { attackingPlayerUserId: this.attackingPlayerUserId, attackingCardInstanceId: this.attackingCardInstanceId, attackedCardInstanceId: this.attackedCardInstanceId, queuePosition: this.queuePosition });
    }
    static fromJSON(json) {
        return new CreatureAttackedMessage(json.recipientUserId, json.attackingPlayerUserId, json.attackingCardInstanceId, json.attackedCardInstanceId, json.queuePosition);
    }
    // check that all fields in the message are valid
    validate() {
        return (this.recipientUserId != null &&
            this.attackingPlayerUserId != null &&
            this.attackingCardInstanceId != null &&
            this.attackedCardInstanceId != null &&
            this.queuePosition != null);
    }
}
