// external
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';

// internal
// context
import { AuthContext } from '../../contexts/AuthContextProvider';
import { PlayerContext } from '../../contexts/PlayerContextProvider';
import { CardLibraryContext } from '../../contexts/CardLibraryContextProvider';
import { ImageContext } from '../../contexts/ImageContextProvider';
import { UserRealmsContext } from '../../contexts/UserRealmsContextProvider';

// hooks
import useWindowDimensions from '../../hooks/useWindowDimensions.js';
import useMouseDown from '../../hooks/useMouseDown.js';

// components
import Navbar from '../Navbar/Navbar';
import RealmWrapper from './RealmWrapper';
import RealmList from './RealmList';
import BiomeList from './BiomeList';
import CardDisplay from './Cards/CardDisplay/CardDisplay';
import TitleComponent from './TitleComponent';
import CardList from './Cards/CardList/CardList'; // Added import for CardList component

//css
import { RealmPageStyled } from './styles/RealmPage.styled.js';

interface LibraryRealmWithId extends LibraryRealm {
  _id: string | null;
}
// lol
import { LandType } from '../../../../../LegendsOfLeakos/lib/Enums/LandAndBiome';
import LibraryRealm from '../../../../../LegendsOfLeakos/lib/Classes/RealmsAndLand/Realm/LibraryRealm';

function RealmPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // context
  const { userId } = useContext(AuthContext);
  const { gameManager } = useContext(CardLibraryContext);
  const { topo, justWater } = useContext(ImageContext);
  const {
    realms,
    selectedRealm,
    setSelectedRealm,
    createNewRealm,
    saveRealm,
    deleteRealm,
  } = useContext(UserRealmsContext);

  //#region REALMS API SEND AND RECEIVE

  const onRealmTileClick = (realm) => {
    if (realm !== selectedRealm) {
      setSelectedRealm(realm);
    } else {
      setDisplayState('edit-realm');
    }
  };

  //#endregion

  // #region BIOMES

  const [selectedBiome, setSelectedBiome] = useState(null);
  const [selectedSubbiome, setSelectedSubbiome] = useState(null);
  const [selectedCards, setSelectedCards] = useState([]);

  useEffect(() => {
    if (!selectedRealm) return;
    setSelectedCards([...selectedRealm.getAllCards()]);
  }, [selectedRealm]);

  useEffect(() => {
    if (!selectedRealm) return;
    if (!selectedBiome) return;

    // when we switch the biome, set the subbiome to the one with depth 0
    const subbiome = selectedBiome.subBiomes.find((subbiome) => {
      return subbiome.biomeDepth === 1;
    });
    setSelectedSubbiome(subbiome);

    // update the cards
    if (!!selectedBiome) {
      setSelectedCards([...selectedBiome.getAllCardsInBiomeAndSubbiomes()]);
    } else if (!!selectedRealm) {
      setSelectedCards([...selectedRealm.getAllCards()]);
    }

    if (displayState === 'edit-realm') {
      setDisplayState('edit-biome');
    } else if (displayState === 'edit-biome') {
      // here we'll parse cards or stuff like that maybe
    }
  }, [selectedBiome]);

  useEffect(() => {
    // cards
    if (!!selectedBiome && !selectedSubbiome) {
      setSelectedCards([...selectedBiome.getAllCardsInBiomeAndSubbiomes()]);
    } else if (!!selectedSubbiome) {
      setSelectedCards([...selectedSubbiome.getAllCardsInBiomeAndSubbiomes()]);
    }

    // tiles
    if (displayState === 'edit-biome' && selectedSubbiome) {
      const tempIndices = [];
      selectedSubbiome.landTiles.forEach((tile) => {
        tempIndices.push(tile.id);
      });
      // sort tiles by id in ascending order
      tempIndices.sort((a, b) => a.id - b.id);
      setOutlinedTileIndices(tempIndices);
    } else {
      setOutlinedTileIndices([]);
    }
  }, [selectedSubbiome]);

  const addCard = (card) => {
    const biome = !!selectedSubbiome ? selectedSubbiome : selectedBiome;
    if (!biome) {
      console.log('Add Card - No biome selected');
      return;
    }
    const result = biome.addCardsToBiomeOrSubbiome(card, 1, gameManager);
    setSelectedCards([...biome.getAllCardsInBiomeAndSubbiomes()]);
  };

  const removeCard = (card) => {
    const biome = !!selectedSubbiome ? selectedSubbiome : selectedBiome;
    if (!biome) {
      console.log('Remove Card - No biome selected');
      return;
    }

    const message = biome.wouldRemovingThisCardCauseErrors(card, gameManager);
    const result = biome.removeSingleCardFromBiomeOrSubbiome(card);
    setSelectedCards([...biome.getAllCardsInBiomeAndSubbiomes()]);
  };

  const removeAllCards = (card) => {
    const biome = !!selectedSubbiome ? selectedSubbiome : selectedBiome;
    if (!biome) {
      console.log('Remove All Cards - No biome selected');
      return;
    }

    biome.removeCards(card);
    setSelectedCards([...biome.getAllCardsInBiomeAndSubbiomes()]);
  };

  //#endregion

  //#region DISPLAY STATE

  const [displayState, setDisplayState] = useState('select-realm');
  const handleBack = () => {
    // save the realm
    saveRealm();

    if (displayState === 'edit-realm') {
      setDisplayState('select-realm');
    } else if (displayState === 'edit-biome') {
      setDisplayState('edit-realm');
      setSelectedBiome(null);
      setSelectedSubbiome(null);
    }
  };

  //#endregion

  // #region HOVERING BIOMES FUNCTIONS

  const [outlinedTileIndices, setOutlinedTileIndices] = useState([]);
  const mouseOverBiome = (biome) => {
    if (!biome) {
      if (!!selectedSubbiome) {
        setOutlinedTileIndices(
          selectedSubbiome.landTiles.map((tile) => tile.id)
        );
        setSelectedCards([
          ...selectedSubbiome.getAllCardsInBiomeAndSubbiomes(),
        ]);
      } else {
        setOutlinedTileIndices([]);
        if (!!selectedBiome)
          setSelectedCards([...selectedBiome.getAllCardsInBiomeAndSubbiomes()]);
      }
      return;
    } else {
      setSelectedCards([...biome.getAllCardsInBiomeAndSubbiomes()]);
    }
    if (displayState === 'select-realm') return;

    setOutlinedTileIndices(biome.landTiles.map((tile) => tile.id));
  };

  // #endregion

  // #region LAND TYPE SELECTOR

  const [landTypeSelected, setLandTypeSelected] = useState(0);

  const landTileClicked = (tile_index) => {
    if (displayState === 'select-realm') return;
    changeTileType(tile_index, landTypeSelected);
  };

  const isMouseDown = useMouseDown();
  const landTileEntered = (tile_index) => {
    if (displayState === 'select-realm') return;
    if (isMouseDown) {
      changeTileType(tile_index, landTypeSelected);
    }
  };

  const selfSelectorReturn = (tile_index, landType) => {
    changeTileType(tile_index, landType);
  };

  const changeTileType = (tile_index, landType: LandType) => {
    const copy = LibraryRealm.copyRealm(selectedRealm) as LibraryRealmWithId;

    copy._id = selectedRealm._id;
    // if we're making a new city, we need to remove any old ones
    if (landType === LandType.city) {
      const tile = copy
        .getLandTiles()
        .find((tile) => tile.landType === LandType.city);
      if (tile) {
        // first add a city so it doesn't yell at us
        copy.changeLandTileType(tile_index, landType);
        // then change the old city to the most common type
        copy.changeLandTileType(
          tile.id,
          tile.mostCommonNeighborType(copy.getLandTiles())
        );
        setSelectedRealm(copy);
        return;
      }
    } else {
      // if we're not making a new city, we can't overwrite an existing one
      const tile = copy.getLandTiles().find((tile) => tile.id === tile_index);
      if (tile.landType === LandType.city) return;
      // change the tile type
      copy.changeLandTileType(tile_index, landType);
      setSelectedRealm(copy);
    }
  };

  //#endregion

  // #region NAME CHANGER
  const [editNameState, setEditNameState] = useState('display');
  const editName = (newName) => {
    const trimmedName = newName.trim();
    const truncatedName = trimmedName.substring(0, 20);
    console.log('editName: ', truncatedName);
    if (truncatedName && truncatedName !== selectedRealm.name) {
      console.log('got here');
      const copy = LibraryRealm.copyRealm(selectedRealm) as LibraryRealmWithId;
      copy._id = selectedRealm._id;
      copy.name = truncatedName;
      setSelectedRealm(copy);
    }
  };

  //#endregion

  return (
    <RealmPageStyled
      className='realm-page'
      leftBackground={topo}
      waterBackground={justWater}
    >
      <div className='screen-background'>
        <img
          className='screen-background-image'
          src={justWater}
          alt='background'
        />
      </div>

      <Navbar />

      <div className='main-content'>
        <div className='left-column'>
          <div className='background-image'></div>
          {displayState === 'edit-biome' && (
            <div className='small-realm'>
              <div className='small-realm-inner-border'>
                <RealmWrapper
                  realm={selectedRealm}
                  displayState={displayState}
                  setDisplayState={setDisplayState}
                  outlinedTileIndices={outlinedTileIndices}
                  setLandTypeSelected={setLandTypeSelected}
                  landTypeSelected={landTypeSelected}
                  landTileClicked={landTileClicked}
                  landTileEntered={landTileEntered}
                  landTileLeft={() => {}}
                  selfSelectorReturn={selfSelectorReturn}
                />
              </div>
            </div>
          )}
          {displayState === 'select-realm' && (
            <RealmList
              realms={realms}
              selectedRealm={selectedRealm}
              onRealmClicked={onRealmTileClick}
              deleteRealm={deleteRealm}
              createNewRealm={createNewRealm}
            />
          )}
          {displayState === 'edit-realm' && (
            <BiomeList
              biomes={selectedRealm?.biomes}
              displayState={displayState}
              selectedBiome={selectedBiome}
              setSelectedBiome={setSelectedBiome}
              mouseOverBiome={mouseOverBiome}
            />
          )}
          {displayState === 'edit-biome' && (
            <BiomeList
              biomes={selectedBiome?.subBiomes}
              displayState={displayState}
              selectedBiome={selectedSubbiome}
              setSelectedBiome={setSelectedSubbiome}
              mouseOverBiome={mouseOverBiome}
            />
          )}
        </div>
        <div className='center-column'>
          {displayState !== 'select-realm' && (
            <div className='back-button' onClick={handleBack}>
              {'<'}
            </div>
          )}

          {displayState === 'edit-realm' && (
            <TitleComponent
              selectedRealm={selectedRealm}
              editName={editName}
              editState={editNameState}
              setEditState={setEditNameState}
            />
          )}

          {displayState === 'edit-biome' && (
            <CardDisplay
              biome={!!selectedSubbiome ? selectedSubbiome : selectedBiome}
              addCard={addCard}
            />
          )}
          {/* <div
            className={
              'realm-div' + (displayState === 'edit-biome' ? ' small' : '')
            }
          > */}
          {displayState !== 'edit-biome' && (
            <RealmWrapper
              realm={selectedRealm}
              displayState={displayState}
              setDisplayState={setDisplayState}
              outlinedTileIndices={outlinedTileIndices}
              setLandTypeSelected={setLandTypeSelected}
              landTypeSelected={landTypeSelected}
              landTileClicked={landTileClicked}
              landTileEntered={landTileEntered}
              landTileLeft={() => {}}
              selfSelectorReturn={selfSelectorReturn}
            />
          )}
          {/* </div> */}
        </div>
        <div className='right-column'>
          {!!selectedRealm && (
            <CardList
              key={selectedRealm.getNumCards() + ''}
              cards={selectedCards}
              displayState={displayState}
              removeCard={removeCard}
              removeAll={removeAllCards}
            />
          )}
        </div>
      </div>
    </RealmPageStyled>
  );
}

export default RealmPage;
