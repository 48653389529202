import { ServerMessage } from '../MessageBase.js';
import PayResourceCost from '../../PayResourceCost/PayResourceCost.js';
import TargetInfo from '../../Target/TargetInfo.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
export default class AbilityActivatedMessage extends ServerMessage {
    constructor(recipientUserId, playerUsingAbilityUserId, entityUsingAbilityInstanceId, abilityIndex, paidCosts, targetInfoList, queuePosition) {
        super(recipientUserId);
        this.messageEnum = NetworkProtocol.AbilityActivated;
        this.playerUsingAbilityUserId = playerUsingAbilityUserId;
        this.entityUsingAbilityInstanceId = entityUsingAbilityInstanceId;
        this.abilityIndex = abilityIndex;
        this.paidCosts = paidCosts;
        this.targetInfoList = targetInfoList;
        this.queuePosition = queuePosition;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { playerUsingAbilityUserId: this.playerUsingAbilityUserId, entityUsingAbilityInstanceId: this.entityUsingAbilityInstanceId, abilityIndex: this.abilityIndex, paidCosts: this.paidCosts.map((cost) => cost.toJSON()), targetInfoList: this.targetInfoList.map((info) => info.toJSON()), queuePosition: this.queuePosition });
    }
    static fromJSON(json) {
        const paidCosts = json.paidCosts.map(PayResourceCost.fromJSON);
        const targetInfoList = json.targetInfoList.map(TargetInfo.fromJSON);
        return new AbilityActivatedMessage(json.recipientUserId, json.playerUsingAbilityUserId, json.entityUsingAbilityInstanceId, json.abilityIndex, paidCosts, targetInfoList, json.queuePosition);
    }
    // check that all fields in the message are valid
    validate() {
        return (this.recipientUserId != null &&
            this.playerUsingAbilityUserId != null &&
            this.entityUsingAbilityInstanceId != null &&
            this.abilityIndex != null &&
            this.paidCosts != null &&
            this.targetInfoList != null &&
            this.queuePosition != null);
    }
}
