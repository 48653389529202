// external
import React, { useEffect, useCallback, useRef } from 'react';
import io, { Socket } from 'socket.io-client';
import { useNavigate, useLocation } from 'react-router-dom';

// internal
// import { CONNECTION_URL } from '../network_constants.js';
const CONNECTION_URL = 'https://legendsofleakos.com';

import {
  GAME_STARTED_EVENT,
  REJOINED_GAME_EVENT,
  NOT_IN_GAME_EVENT,
  ROOMS_REQUEST,
  NEW_ROOM_REQUEST,
  JOIN_ROOM_REQUEST,
  LEAVE_ROOM_REQUEST,
  TOGGLE_READY_REQUEST,
  ROOMS_UPDATE_EVENT,
  YOU_JOINED_ROOM_EVENT,
  YOU_LEFT_ROOM_EVENT,
  SET_READY_EVENT,
  GAME_ENDED_EVENT,
} from '../constants/socketEvents.js';

const SocketContextProvider = ({ children }) => {
  // context
  const [socket, setSocket] = React.useState<Socket | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  // #region Socket Initialization
  const connectSocket = useCallback(() => {
    if (!socket) {
      const newSocket = io(CONNECTION_URL, { transports: ['websocket'] });
      setSocket(newSocket);
    }
  }, [socket]);

  const disconnectSocket = useCallback(() => {
    if (socket) {
      socket.disconnect();
      setSocket(null);
    }
  }, [socket]);

  // #endregion

  // #region Generic Event Handling

  // emit ref
  const emitEventRef = useRef<(eventString: string, data: any) => void>(
    () => {}
  );

  useEffect(() => {
    emitEventRef.current = (eventString: string, data: any) => {
      if (socket) {
        socket.emit(eventString, data);
      }
    };
  }, [socket]);

  const emitEvent = (eventString: string, data: any) => {
    emitEventRef.current(eventString, data);
  };

  const subscribeToEvent = useCallback(
    (eventString: string, handler: (data: any) => void) => {
      if (!socket) return () => {};
      socket.on(eventString, handler);
      return () => socket.off(eventString, handler);
    },
    [socket]
  );

  // #endregion

  // #region Game Status Check

  useEffect(() => {
    if (!socket) return;
    const handleGameStarted = (roomId) => {
      console.log(
        'Received Game Started message from out of game server socket manager'
      );
      navigate('/play');
    };

    const handleRejoinedGame = (roomId) => {
      console.log(
        'Received handleRejoinedGame message from out of game server socket manager'
      );
      navigate('/play');
    };

    const handleNotInGame = () => {
      console.log(
        'Received handleNotInGame message from out of game server socket manager'
      );
      if (location.pathname === '/play') {
        console.log('Not in game - Sending back to Lobby.');
        navigate('/lobby');
      }
    };

    // this isn't used any more
    // the game ended pop-up should be handled based on in-game messages
    // const handleGameEnded = () => {};

    socket.on(GAME_STARTED_EVENT, handleGameStarted);
    socket.on(REJOINED_GAME_EVENT, handleRejoinedGame);
    socket.on(NOT_IN_GAME_EVENT, handleNotInGame);
    // socket.on('game-ended', handleGameEnded);

    return () => {
      socket.off(GAME_STARTED_EVENT, handleGameStarted);
      socket.off(REJOINED_GAME_EVENT, handleRejoinedGame);
      socket.off(NOT_IN_GAME_EVENT, handleNotInGame);
      // socket.off('game-ended', handleGameEnded);
    };
  }, [socket, navigate]);

  // #endregion

  // #region Lobby - Rooms and Ready

  // ready
  const requestSetReady = () => {
    if (!socket) return;
    socket.emit(TOGGLE_READY_REQUEST);
  };

  const onSetReadyFromServer = useCallback(
    (handler) => {
      if (!socket) return () => {};

      socket.on(SET_READY_EVENT, handler);

      return () => {
        socket.off(SET_READY_EVENT, handler);
      };
    },
    [socket]
  );

  // rooms
  // when the socket connects, request a list of the rooms with request-rooms
  useEffect(() => {
    if (!socket) return;
    socket.emit(ROOMS_REQUEST);
  }, [socket]);

  // room emits
  const requestNewRoom = () => {
    console.log('requesting new room - socket: ', socket);
    if (!socket) return;
    socket.emit(NEW_ROOM_REQUEST);
  };

  const joinRoom = (roomId) => {
    socket.emit(JOIN_ROOM_REQUEST, {
      roomId,
    });
  };

  const leaveRoom = () => {
    socket.emit(LEAVE_ROOM_REQUEST);
  };

  // room events
  const onRoomsUpdate = useCallback(
    (handler) => {
      console.log('onRoomsUpdate');
      if (!socket) return () => {};
      console.log('onRoomsUpdate - cleared socket');
      socket.on(ROOMS_UPDATE_EVENT, handler);
      return () => {
        socket.off(ROOMS_UPDATE_EVENT, handler);
      };
    },
    [socket]
  );

  const onYouJoinedRoom = useCallback(
    (handler) => {
      console.log('onYouJoinedRoom');

      if (!socket) return () => {};
      socket.on(YOU_JOINED_ROOM_EVENT, handler);
      return () => {
        socket.off(YOU_JOINED_ROOM_EVENT, handler);
      };
    },
    [socket]
  );

  const onRejoinedGame = useCallback(
    (handler) => {
      console.log('onRejoinedGame');

      if (!socket) return () => {};
      socket.on(REJOINED_GAME_EVENT, handler);
      return () => {
        socket.off(REJOINED_GAME_EVENT, handler);
      };
    },
    [socket]
  );

  const onYouLeftRoom = useCallback(
    (handler) => {
      if (!socket) return () => {};
      socket.on(YOU_LEFT_ROOM_EVENT, handler);
      return () => {
        socket.off(YOU_LEFT_ROOM_EVENT, handler);
      };
    },
    [socket]
  );

  // #endregion

  const value: SocketContextType = {
    socket,
    connectSocket,
    disconnectSocket,
    // ready
    onSetReadyFromServer,
    requestSetReady,
    // rooms
    requestNewRoom,
    joinRoom,
    leaveRoom,
    onRoomsUpdate,
    onYouJoinedRoom,
    onYouLeftRoom,
    onRejoinedGame,
    // generic
    subscribeToEvent,
    emitEvent,
  };
  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  );
};

interface SocketContextType {
  socket: Socket | null;
  connectSocket: () => void;
  disconnectSocket: () => void;
  onSetReadyFromServer: (handler: (data: boolean) => void) => () => void;
  requestSetReady: () => void;
  requestNewRoom: () => void;
  joinRoom: (roomId: string) => void;
  leaveRoom: () => void;
  onRoomsUpdate: (handler: (data: any) => void) => () => void;
  onYouJoinedRoom: (handler: (data: string) => void) => () => void;
  onYouLeftRoom: (handler: (data: string) => void) => () => void;
  onRejoinedGame: (handler: (data: string) => void) => () => void;
  subscribeToEvent: (
    eventString: string,
    handler: (data: any) => void
  ) => () => void;
  emitEvent: (eventString: string, data: any) => void;
}

const SocketContext = React.createContext<SocketContextType | null>(null);

export { SocketContextProvider, SocketContext };
