import styled from 'styled-components';
import '../../../../styles/constants.css';

export const BoardCardStyled = styled.div`
  position: relative;
  display: inline-block;
  z-index: ${({ hover }) => (hover ? '1000' : 'var(--board-card-z-index)')};
  height: ${({ boardCardHeight }) => `${boardCardHeight}px`};
  width: ${({ boardCardWidth }) => `${boardCardWidth}px`};
  margin-right: ${({ boardCardWidth }) => `${boardCardWidth / 5}px`};

  .card {
    position: relative;
    height: ${({ boardCardHeight }) => `${boardCardHeight}px`};
    width: ${({ boardCardWidth }) => `${boardCardWidth}px`};
    text-align: center;
    box-sizing: border-box;
    z-index: 1;
  }

  .border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .name {
    font-size: calc(${({ boardCardWidth }) => `${boardCardWidth}px`} / 7);
    position: absolute;
    bottom: -15%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 2;
  }

  .stat-box {
    width: 18%;
    height: 18%;
    font-size: calc(${({ boardCardWidth }) => `${boardCardWidth}px`} / 7);
    color: ${({ isQueued }) =>
      !isQueued ? 'white' : 'var(--border-color-dim)'};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    p {
      position: relative;
      line-height: 1;
      z-index: 10;
    }
  }

  .bottom-land {
    z-index: -1;
    pointer-events: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .forest {
    position: absolute;
    width: 120%;
    bottom: -10%;
    left: -15%;
  }

  .mountain {
    position: absolute;
    width: 150%;
    bottom: -20%;
    left: -25%;
  }

  .ocean {
    position: absolute;
    width: 110%;
    bottom: -10%;
    left: -5%;
  }

  .main-image {
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    clip-path: polygon(50% 10%, 100% 50%, 75% 75%, 25% 75%, 0% 50%);
    overflow: hidden;
    pointer-events: none;
    border: 1px solid black;

    img {
      width: ${({ hover }) => (hover ? '110%' : '100%')};
      height: ${({ hover }) => (hover ? '110%' : '100%')};
      object-fit: cover;
      transform: ${({ hover }) => (hover ? 'translate(-5%, -5%)' : 'none')};
      transition: 0.4s ease;
      filter: ${({ isQueued, isTargetable, actingAbility }) =>
        isQueued || (!!actingAbility && !isTargetable)
          ? 'brightness(50%)'
          : 'none'};
    }
  }

  .abilities {
    // background-color: green;
    position: absolute;
    top: -10%;
    left: 100%;
    padding-left: calc(${({ boardCardWidth }) => `${boardCardWidth}px`} / 10);
    transform: translateZ(0);
    z-index: 2;
  }

  .abilities.invisible {
    display: none;
  }

  .keywords {
    position: absolute;
    top: 0;
    right: 120%;
    transform: translateZ(0);
    padding: calc(${({ boardCardWidth }) => `${boardCardWidth}px`} / 20);

    width: ${({ boardCardWidth }) => `${boardCardWidth}px`};
    height: calc(${({ boardCardWidth }) => `${boardCardWidth}px`} / 3);
    font-size: calc(${({ boardCardWidth }) => `${boardCardWidth}px`} / 7);
    text-align: center;
    background-color: black;
    color: white;
    z-index: 2;
  }

  .keywords.invisible {
    display: none;
  }

  .enchantments {
    z-index: 2;
    position: absolute;
    width: ${({ boardCardWidth }) => `${boardCardWidth}px`};
    top: 0;
  }
`;
