import React, { useState } from 'react';

// components
// utility

// css

function AdminPage() {
  return <div>AdminPage</div>;
}

export default AdminPage;
