// dependancies
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// imports
// context
import { AuthContext } from '../../../contexts/AuthContextProvider';
// css
import { SignInComponentStyled } from './styles/SignInComponent.styled.js';

function SignInComponent() {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState();

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    login(email, password)
      .then(() => {
        navigate('/tidings');
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };

  return (
    <SignInComponentStyled className='sign-in-component'>
      <form onSubmit={handleSubmit}>
        <div className='sign-in-wrapper'>
          <h1>Sign In</h1>
          <div className='sign-in-form'>
            <input
              type='text'
              placeholder='email'
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type='password'
              placeholder='password'
              onChange={(e) => setPassword(e.target.value)}
            />
            <input type='submit' value='Submit' />
          </div>
          {error && <p className='input-error'>{error}</p>}
        </div>
      </form>
    </SignInComponentStyled>
  );
}

export default SignInComponent;
