class StackEvent {
    constructor(eventEnum, gameState, successfullyRan, description, entities) {
        this.eventEnum = eventEnum;
        this.gameState = gameState;
        this.successfullyRan = successfullyRan;
        this.description = description;
        this.entities = entities.map((e) => (Object.assign({}, e)));
    }
    clone() {
        return new StackEvent(this.eventEnum, this.gameState.clone(), this.successfullyRan, this.description, this.entities.map((e) => (Object.assign({}, e))));
    }
}
export default StackEvent;
