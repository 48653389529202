import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const CardListStyled = styled.div`
  position: relative;
  height: calc(100vh - var(--navbar-height) - 3*var(--screen-margin));
  width: 100%;

  padding: var(--screen-margin);
  box-sizing: border-box;

  z-index: 1;

  overflow: hidden;

  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: 400% auto;
  background-repeat: repeat-y;


  .underlined-title {
    color: var(--lol-gold);
    margin-bottom: 0;
  }

  .card-item-holder {
    position: relative;
    height: calc(100vh - var(--navbar-height) - 70px);
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-x: visible;
    width: 100%;

    display: flex;
    align-items: center;
    flex-direction: column;
  
  }

  .card-item-holder::-webkit-scrollbar {
    display: none;
`;
