class LibraryCardEntry {
    constructor(libraryId, amount) {
        this.libraryId = libraryId;
        this.amount = amount;
    }
    toJSON() {
        return {
            libraryId: this.libraryId,
            amount: this.amount,
        };
    }
    static fromJSON(json) {
        const libraryCardEntry = new LibraryCardEntry(json.libraryId, json.amount);
        return libraryCardEntry;
    }
}
export default LibraryCardEntry;
