import BaseLandTile from './BaseLandTile.js';
import { LandType } from '../../../Enums/LandAndBiome.js';
class RuntimeLandTile extends BaseLandTile {
    get explored() {
        return this._explored;
    }
    set explored(value) {
        this._explored = value;
    }
    /**
     * I put this in place to remind me that exploring should really only be done by the
     * runtime realm, as there will be copies of landtiles across the biomes
     */
    explore(set = true) {
        this.explored = set;
    }
    anyNeighborExplored(allLandTiles) {
        const neighbors = this.findNeighbors(allLandTiles);
        return neighbors.some((neighbor) => neighbor.explored);
    }
    toJSON() {
        return {
            id: this.id,
            x: this.x,
            y: this.y,
            z: this.z,
            // as string of enum
            landType: LandType[this.landType],
            depth: this.depth,
            explored: this.explored,
        };
    }
    static fromJSON(json) {
        const tile = new RuntimeLandTile(json.id, LandType[json.landType]);
        tile.x = json.x;
        tile.y = json.y;
        tile.z = json.z;
        tile.depth = json.depth;
        tile.explored = json.explored !== undefined ? json.explored : false;
        return tile;
    }
}
export default RuntimeLandTile;
