import styled from 'styled-components';

export const AbilityBoxStyled = styled.div`
  position: relative;
  width: ${({ cardWidth }) => `${cardWidth}px`};
  height: calc(${({ cardWidth }) => `${cardWidth}px`} / 2.5);
  font-size: calc(${({ cardWidth }) => `${cardWidth}px`} / 7);
  text-align: center;
  color: white;
  z-index: 2;
  padding: calc(${({ cardWidth }) => `${cardWidth}px`} / 20);
  margin-bottom: calc(${({ cardWidth }) => `${cardWidth}px`} / 20);
  border: 1px solid
    ${({ activatable }) =>
      activatable ? 'var(--border-color-bright)' : 'var(--border-color-dim)'};

  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-position: center;
  transform: ${({ isHovered }) => (isHovered ? 'scale(1.05)' : 'scale(1)')};
  transition: transform 0.3s ease;

  .ability-box-content {
    height: 100%;
    width: 100%;
  }

  .mana-cost-holder {
    z-index: 2;
    position: absolute;
    top: calc(${({ cardWidth }) => `${cardWidth}px`} / -12);
    left: 0;
  }

  .mana-circle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #b3b3b3;
    height: calc(${({ cardWidth }) => `${cardWidth}px`} / 10);
    width: calc(${({ cardWidth }) => `${cardWidth}px`} / 10);
    font-size: calc(${({ cardWidth }) => `${cardWidth}px`} / 10);
  }
  .mana-circle.active {
    height: calc(${({ cardWidth }) => `${cardWidth}px`} / 6);
    width: calc(${({ cardWidth }) => `${cardWidth}px`} / 6);
    font-size: calc(${({ cardWidth }) => `${cardWidth}px`} / 6);
  }

  .uses-remaining {
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    height: calc(${({ cardWidth }) => `${cardWidth}px`} / 7);
    width: 100%;
    font-size: calc(${({ cardWidth }) => `${cardWidth}px`} / 10);
  }
`;

export const AbilityInfoPanelStyled = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 1);
  color: white;
  padding: 10px;
  top: -10%;
  left: 110%;
  width: ${({ cardWidth }) => `${cardWidth}px`};
  z-index: 3;
`;
