class LibraryZone {
    constructor(name, zoneEnum, owner, refreshType, ownerVisibility, opponentVisibility, hasMaxCards, maxCards) {
        this.name = name;
        this.zoneEnum = zoneEnum;
        this.owner = owner;
        this.refreshType = refreshType;
        this.ownerVisibility = ownerVisibility;
        this.opponentVisibility = opponentVisibility;
        this.hasMaxCards = hasMaxCards;
        this.maxCards = maxCards;
    }
}
export { LibraryZone };
