class TargetInfo {
    constructor(targetEntityInstanceIds, noTargetWasSelected, targetsAreSelectedLater) {
        this.targetEntityInstanceIds = [];
        this.targetEntityInstanceIds = [...targetEntityInstanceIds];
        this.noTargetWasSelected = noTargetWasSelected;
        this.targetsAreSelectedLater = targetsAreSelectedLater;
    }
    clone() {
        return TargetInfo.fromJSON(this);
    }
    toJSON() {
        return {
            targetEntityInstanceIds: this.targetEntityInstanceIds,
            noTargetWasSelected: this.noTargetWasSelected,
            targetsAreSelectedLater: this.targetsAreSelectedLater,
        };
    }
    static fromJSON(json) {
        return new TargetInfo(json.targetEntityInstanceIds, json.noTargetWasSelected, json.targetsAreSelectedLater);
    }
}
export default TargetInfo;
