import RuntimeCondition from '../RuntimeCondition.js';
import { ConditionType } from '../../../Enums/Condition.js';
import { KeywordType } from '../../../Enums/Keyword.js';
import AbilityKeywordRuntimeEntity from '../../Entity/AbilityKeywordRuntimeEntity.js';
import LibraryCondition from '../LibraryCondition.js';
class HasKeywordCondition extends RuntimeCondition {
    constructor(keywordType) {
        super();
        this.conditionType = ConditionType.HasKeywordOfType;
        this.keywordType = keywordType;
    }
    isTrue(entityInstanceId, sourceIntanceId, state) {
        // checks
        let entity = state.getEntityFromAnywhere(entityInstanceId);
        if (entity === null) {
            throw new Error('HasKeywordCardCondition requires a valid entity');
        }
        if (entity instanceof AbilityKeywordRuntimeEntity === false) {
            throw new Error('HasKeywordCardCondition requires a AbilityKeywordRuntimeEntity');
        }
        const keywordEntity = entity;
        // checks over
        return keywordEntity.hasKeyword(this.keywordType);
    }
    // #region Runtime Utility Methods
    getReadableString(gameProperties) {
        return `Has keyword ${this.keywordType}`;
    }
    // #endregion
    // #region JSON
    toJSON() {
        return {
            conditionType: ConditionType[this.conditionType],
            keywordType: KeywordType[this.keywordType],
        };
    }
    clone() {
        return new HasKeywordCondition(this.keywordType);
    }
    static fromRuntimeJSON(json) {
        return new HasKeywordCondition(KeywordType[json.keywordType]);
    }
    static fromLibraryJSON(json) {
        return new HasKeywordCondition(KeywordType[json.data.keywordType.value]);
    }
    static isLibraryJSONValid(json) {
        if (!json.conditionType || typeof json.conditionType !== 'string') {
            console.log('Invalid conditionType:', json.conditionType);
            return false;
        }
        if (!Object.values(ConditionType).includes(json.conditionType)) {
            console.log('conditionType not in ConditionType. Expected values are:', Object.values(ConditionType).join(', '), 'but received:', json.conditionType);
            return false;
        }
        if (!json.data.keywordType.value ||
            typeof json.data.keywordType.value !== 'string') {
            console.log('Invalid keywordType:', json.data.keywordType.value);
            return false;
        }
        if (!Object.values(KeywordType).includes(json.data.keywordType.value)) {
            console.log('keywordType not in KeywordType. Expected values are:', Object.values(KeywordType).join(', '), 'but received:', json.data.keywordType.value);
            return false;
        }
        return true;
    }
    // #endregion
    // #region Builder Methods
    static createSampleLibraryCondition() {
        return new LibraryCondition(ConditionType.HasKeywordOfType, {
            keywordType: {
                type: 'Enum',
                enum: 'KeywordType',
                value: KeywordType[KeywordType.DamageModification],
            },
        });
    }
    // #endregion
    static initialize() {
        RuntimeCondition.registerFromLibraryJSON(ConditionType.HasKeywordOfType, HasKeywordCondition.fromLibraryJSON);
        RuntimeCondition.registerFromRuntimeJSON(ConditionType.HasKeywordOfType, HasKeywordCondition.fromRuntimeJSON);
        RuntimeCondition.registerIsLibraryJSONValid(ConditionType.HasKeywordOfType, HasKeywordCondition.isLibraryJSONValid);
        RuntimeCondition.registerSampleLibraryCondition(ConditionType.HasKeywordOfType, HasKeywordCondition.createSampleLibraryCondition);
    }
}
export default HasKeywordCondition;
