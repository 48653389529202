import React, { useState, useEffect, useContext } from 'react';

// internal
// comps
import TitledIntcreaser from '../Utility/TitledIntcreaser.jsx';
import TitledCheckbox from '../Utility/TitledCheckbox.jsx';
import TargetCriteriaItem from '../Abilities/TargetCriteriaItem.jsx';
import TitledCycler from '../Utility/TitledCycler.jsx';

// hooks
import { CardLibraryContext } from '../../../contexts/CardLibraryContextProvider';

// css
import { DataEditorStyled } from './styles/DataEditor.styled.js';
import LibraryTargetCriteria from '../../../../../../LegendsOfLeakos/lib/Classes/Target/LibraryTargetCriteria.js';

function DataEditor({ data, save }) {
  const { enchantmentLibrary } = useContext(CardLibraryContext);

  return (
    <DataEditorStyled>
      {Object.keys(data).map((key, index) => {
        const object = data[key];
        switch (object.type) {
          case 'Number':
            return (
              <TitledIntcreaser
                key={index}
                title={key}
                int={object.value}
                onIntChange={(value) => {
                  object.value = value;
                  save(data);
                }}
                minValue={object.minValue}
                maxValue={object.maxValue}
              />
            );
          case 'EnchantmentLibraryId':
            const currentEnchantment = enchantmentLibrary.find(
              (item) => item.libraryId === object.value
            );
            if (enchantmentLibrary.length === 0) {
              return <div key={index}>No enchantments in library</div>;
            }
            if (!currentEnchantment) {
              object.value = enchantmentLibrary[0].libraryId;
              save(data);
              return <div key={index}>Unknown enchantment: {object.value}</div>;
            }
            return (
              <TitledCycler
                key={index}
                title={'Enchantment'}
                items={enchantmentLibrary.map((item) => item.name)}
                onItemChange={(value) => {
                  object.value = enchantmentLibrary.find(
                    (item) => item.name === value
                  ).libraryId;
                  save(data);
                }}
                currentItem={
                  enchantmentLibrary.find(
                    (item) => item.libraryId === object.value
                  ).name
                }
              />
            );
          case 'Boolean':
            return (
              <TitledCheckbox
                key={index}
                title={key}
                isChecked={object.value}
                onCheckChange={(isChecked) => {
                  object.value = isChecked;
                  save(data);
                }}
              />
            );
          case 'TargetCriteria':
            return (
              <TargetCriteriaItem
                key={index}
                title={key}
                targetCriteria={LibraryTargetCriteria.fromLibraryJSON(
                  object.value
                )}
                saveTargetCriteria={(targetCriteria) => {
                  object.value = targetCriteria;
                  save(data);
                }}
              />
            );
          case 'Enum':
            const selectedEnum = Enums[object.enum];
            if (!selectedEnum) {
              return <div key={index}>Unknown enum: {object.enum}</div>;
            }
            // get string values from numeric enum
            const stringValues = Object.keys(selectedEnum)
              .map((item) => selectedEnum[item])
              .filter((value) => isNaN(value));
            return (
              <TitledCycler
                key={index}
                title={key}
                items={stringValues}
                onItemChange={(value) => {
                  object.value = value;
                  save(data);
                }}
                currentItem={object.value}
              />
            );
          default:
            return <div key={index}>Unknown type: {object.type}</div>;
        }
      })}
    </DataEditorStyled>
  );
}

export default DataEditor;
