// external
import React, { useState, useEffect, useContext } from 'react';

// css
import { ReadyButtonStyled } from './styles/ReadyButton.styled';

// context
import { ImageContext } from '../../contexts/ImageContextProvider';
import { RoomsContext } from '../../contexts/RoomsContextProvider';
import { SocketContext } from '../../contexts/SocketContextProvider';

function ReadyButton({ selectedRealmID }) {
  // context
  const {
    // banners
    selectRoomAndRealm,
    selectRoom,
    selectRealm,
    setReady,
    ready,
    // inactive
    inactiveCompassStatic,
    inactiveCompassCenter,
    inactiveCompassNorthTriangles,
    inactiveCompassNorthWestTriangles,
    inactiveCompassSmallTriangles,
    inactiveCompassBanner,
    brownCircle,
    // active
    activeCompassStatic,
    // hover
    hoverCompassNorthTriangles,
    hoverCompassNorthWestTriangles,
    hoverCompassSmallTriangles,
    hoverCompassBanner,
    redCircle,
  } = useContext(ImageContext);
  const { myRoomID } = useContext(RoomsContext);
  const { socket, onSetReadyFromServer, requestSetReady } =
    useContext(SocketContext);

  // button ready
  const [amReady, setAmReady] = useState(false);
  useEffect(() => {
    onSetReadyFromServer((data) => {
      setAmReady(data);
    });
    return () => {
      onSetReadyFromServer(() => {});
    };
  }, [socket, onSetReadyFromServer]);

  const [readyButtonMessage, setReadyButtonMessage] = useState(
    'Select Realm and Room'
  );
  const [buttonActive, setButtonActive] = useState(false);

  useEffect(() => {
    if (!selectedRealmID && !myRoomID) {
      setReadyButtonMessage('Select Realm and Room');
      setButtonActive(false);
      return;
    }
    if (!selectedRealmID) {
      setReadyButtonMessage('Select Realm');
      setButtonActive(false);
      return;
    }
    if (!myRoomID) {
      setReadyButtonMessage('Select Room');
      setButtonActive(false);
      return;
    }
    if (!amReady) {
      setReadyButtonMessage('Set Ready');
      setButtonActive(true);
      return;
    }
    setReadyButtonMessage('Ready!');
    setButtonActive(true);
  }, [selectedRealmID, myRoomID, amReady]);

  // hover state
  const [amHovered, setAmHovered] = useState(false);

  // #region bannerScale
  const [bannerScale, setBannerScale] = useState(0.95);
  const goalBannerScale = () => {
    if (amHovered) {
      return 1;
    }
    return 0.95;
  };

  useEffect(() => {
    setBannerScale(goalBannerScale);
  }, [amHovered]);

  useEffect(() => {
    setBannerScale(0.1);

    const timer = setTimeout(() => {
      setBannerScale(goalBannerScale());
    }, 300);

    return () => clearTimeout(timer);
  }, [readyButtonMessage, buttonActive]);
  // #endregion

  // #region center circle scale
  const [centerCircleScale, setCenterCircleScale] = useState(1);
  useEffect(() => {
    if (!buttonActive) {
      setCenterCircleScale(0.9);
      return;
    }
    if (amHovered) {
      setCenterCircleScale(1.1);
      return;
    }
    setCenterCircleScale(1);
  }, [buttonActive, amHovered]);

  // #endregion

  // #region spin compass

  const [spin, setSpin] = useState(false);

  const handleReadyButtonClickModified = () => {
    if (!buttonActive) return;

    setSpin(true);
    requestSetReady();
    setTimeout(() => setSpin(false), 300); // Reset after animation
  };

  // #endregion

  return (
    <ReadyButtonStyled
      className='phase-button-container'
      amHovered={amHovered}
      bannerScale={bannerScale}
      buttonActive={buttonActive}
      centerCircleScale={centerCircleScale}
      spin={spin}
    >
      <div className='compass-image-container'>
        <div className='static-container compass-image'>
          <img className='static' src={inactiveCompassStatic} />
          {buttonActive && <img className='static' src={activeCompassStatic} />}
        </div>
        <div className='center-circle-container compass-image'>
          <img className='center-circle' src={brownCircle} />
          {(readyButtonMessage === 'Ready!' || (buttonActive && amHovered)) && (
            <img className='center-circle' src={redCircle} />
          )}
        </div>
        <div className='small-triangles-container compass-image'>
          <img
            className='small-triangles'
            src={inactiveCompassSmallTriangles}
          />
          {buttonActive && amHovered && (
            <img className='small-triangles' src={hoverCompassSmallTriangles} />
          )}
        </div>
        <div className='northwest-triangles-container compass-image'>
          <img
            className='northwest-triangles'
            src={inactiveCompassNorthWestTriangles}
          />
          {buttonActive && amHovered && (
            <img
              className='northwest-triangles'
              src={hoverCompassNorthWestTriangles}
            />
          )}
        </div>
        <div className='north-triangles-container compass-image'>
          <img
            className='north-triangles'
            src={inactiveCompassNorthTriangles}
          />
          {buttonActive && amHovered && (
            <img className='north-triangles' src={hoverCompassNorthTriangles} />
          )}
        </div>
        <div className='center-container compass-image'>
          <img className='center' src={inactiveCompassCenter} />
        </div>
        <div className='banner-container compass-image'>
          <img
            className='banner'
            src={
              amHovered && buttonActive
                ? hoverCompassBanner
                : inactiveCompassBanner
            }
          />
          <div className='compass-image banner-text-container'>
            {readyButtonMessage === 'Select Realm and Room' && (
              <img className='banner-text' src={selectRoomAndRealm} />
            )}
            {readyButtonMessage === 'Select Realm' && (
              <img className='banner-text' src={selectRealm} />
            )}
            {readyButtonMessage === 'Select Room' && (
              <img className='banner-text' src={selectRoom} />
            )}
            {readyButtonMessage === 'Set Ready' && (
              <img className='banner-text' src={setReady} />
            )}
            {readyButtonMessage === 'Ready!' && (
              <img className='banner-text' src={ready} />
            )}
          </div>
        </div>
      </div>
      <div
        className={'click-area'}
        onClick={handleReadyButtonClickModified}
        onMouseEnter={() => setAmHovered(true)}
        onMouseLeave={() => setAmHovered(false)}
      ></div>
    </ReadyButtonStyled>
  );
}

export default ReadyButton;
