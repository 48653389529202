import styled from 'styled-components';
import '../../../styles/constants.css';

export const RealmPageStyled = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
  z-index: 1;

  .main-content {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    margin: 0 10px;
    min-height: 0;
    min-width: 0;
  }

  .left-column {
    position: relative;
    grid-column: 1;
    height: 100%;
    width: 100%;
    border: 1px solid var(--border-color-bright);
    background-color: var(--dark-green);

    .background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      user-select: none;

      background-image: ${({ leftBackground }) => `url(${leftBackground})`};
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      opacity: 0.1;
    }
  }

  .center-column {
    position: relative;
    grid-column: 2;
    height: 100%;
    width: 100%;
  }

  .right-column {
    position: relative;
    grid-column: 3;
    height: 100%;
    width: 100%;
    border: 1px solid var(--border-color-bright);
    background-color: var(--dark-green);
  }

  // .small.realm-div {
  //   position: absolute;
  //   bottom: 10px;
  //   left: 10px;
  // }

  .small-realm {
    width: 100%;
    border-bottom: 1px solid var(--border-color-bright);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
  }

  .small-realm-inner-border {
    margin: 5px;
    border: 1px solid var(--border-color-bright);

    background-image: ${({ waterBackground }) => `url(${waterBackground})`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    z-index: 2;
  }

  .screen-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      pointer-events: none;
      user-select: none;
    }
  }

  .realm-title {
    font-size: 1.5rem;
    font-family: var(--bold-medieval-font);
    font-weight: 900;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 50px;
    line-height: 50px;
    text-align: center;
    min-width: 200px;
  }

  .back-button {
    position: absolute;
    top: 0;
    left: 10px;
    width: 20px;
    height: 20px;
    font-size: 3rem;
    font-weight: 900;
    padding: 5px;
    color: var(--border-color-bright);
    border: 1px solid var(--border-color-bright);
    text-align: center;
    line-height: 0.2;
    font-family: var(--logo-font);
    transition: 0.5s ease;
    z-index: 5;
  }
  .back-button:hover {
    border: 1px solid var(--lol-red);
    color: var(--lol-red);
  }
`;
