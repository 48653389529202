import styled from 'styled-components';
import '../../../../styles/constants.css';

export const StackListItemStyled = styled.div`
  position: relative;
  color: black;
  border: 1px solid var(--border-color-dim);
  width: 100%;
  margin: 2px 1px;
  padding: 1px;
  box-sizing: border-box;
  font-size: ${(props) => `${props.gameContainerWidth * 0.015}px`};

  .stack-event-title {
    font-weight: bold;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) =>
      props.eventEnum === 'Queueline' ? 'var(--lol-gold)' : 'var(--paper)'};
    opacity: 1;
    z-index: -1;
  }

  ${({ active }) =>
    active &&
    `
      color: white;
      border: 2px solid var(--border-color-bright);
      .background {
        background-color: var(--dark-green);
      }
    `}
`;
