import PlayerQueueline from '../PlayerQueueline.js';
class MoveRowPlayerQueueline extends PlayerQueueline {
    constructor(myPlayerUserId, sourceCardInstanceId, sourcePlayerUserId, queuePosition, originZoneZoneEnum, destinationZoneZoneEnum) {
        super();
        this.fillBaseInfo(myPlayerUserId, sourceCardInstanceId, sourcePlayerUserId, queuePosition);
        this.originZoneZoneEnum = originZoneZoneEnum;
        this.destinationZoneZoneEnum = destinationZoneZoneEnum;
    }
    sendEffectToPlayer(gameState, myPlayer) {
        if (!myPlayer) {
            throw new Error('Player not found');
        }
        return myPlayer.onCardMovedRow(this.sourceCardInstanceId, this.originZoneZoneEnum, this.destinationZoneZoneEnum);
    }
    actionToString(gameState) {
        const sourcePlayer = gameState.getPlayerInfoByUserId(this.sourcePlayerUserId);
        const sourceCard = gameState.getCardFromAnywhere(this.sourceCardInstanceId);
        if (!sourcePlayer || !sourceCard) {
            throw new Error('Player or card not found');
        }
        return `${sourcePlayer.name}'s ${sourceCard.name} (Instance ID: ${sourceCard.instanceId}) moved from ${this.originZoneZoneEnum} to ${this.destinationZoneZoneEnum}`;
    }
    clone() {
        return new MoveRowPlayerQueueline(this.myPlayerUserId, this.sourceCardInstanceId, this.sourcePlayerUserId, this.queuePosition, this.originZoneZoneEnum, this.destinationZoneZoneEnum);
    }
}
export default MoveRowPlayerQueueline;
