import React from 'react';
import { MenuIconStyled } from './styles/MenuIcon.styled';

function MenuIcon({ isOpen, onClick }) {
  return (
    <MenuIconStyled className='menu-icon' isOpen={isOpen} onClick={onClick}>
      <div className='menu-icon-bar'></div>
    </MenuIconStyled>
  );
}

export default MenuIcon;
