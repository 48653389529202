import React from 'react';
import { ActiveAbilityContextProvider } from './ActiveAbilityContextProvider';
import { AttackingContextProvider } from './AttackingContextProvider';
import { AuthContextProvider } from './AuthContextProvider';
import { CardLibraryContextProvider } from './CardLibraryContextProvider';
import { DraggedCardContextProvider } from './DraggedCardContextProvider';
import { PlayerContextProvider } from './PlayerContextProvider';
import { ActionLineContextProvider } from './ActionLineContextProvider';
import { SocketContextProvider } from './SocketContextProvider';
import { SoundContextProvider } from './SoundContextProvider';
import { TurnFlowContextProvider } from './TurnFlowContextProvider';
import { ImageContextProvider } from './ImageContextProvider';
import { AnimationContextProvider } from './AnimationContextProvider';
import { CardRefContextProvider } from './CardRefContextProvider';
import { GameStatusContextProvider } from './GameStatusContextProvider';
import { QueueReviewContextProvider } from './QueueReviewContextProvider';
import { RoomsContextProvider } from './RoomsContextProvider';
import { ScreenSizeContextProvider } from './ScreenSizeContextProvider';
import { GameElementSizeContextProvider } from './GameElementSizeContextProvider';
import { UserRealmsContextProvider } from './UserRealmsContextProvider';

const composeProviders = (providers, children) => {
  return providers.reduceRight(
    (kids, Parent) => <Parent>{kids}</Parent>,
    children
  );
};

const providers = [
  SoundContextProvider,
  CardRefContextProvider, // needs nothing
  GameStatusContextProvider, // needs nothing
  AnimationContextProvider, // needs cardRef
  ScreenSizeContextProvider,
  GameElementSizeContextProvider, // needs screen size
  CardLibraryContextProvider,
  SocketContextProvider,
  AuthContextProvider, // needs socket
  PlayerContextProvider, // needs socket
  RoomsContextProvider, // needs socket
  UserRealmsContextProvider, // needs CardLibrary and Auth
  QueueReviewContextProvider, // needs player, animation
  TurnFlowContextProvider, // needs player, queue review
  ActionLineContextProvider, // needs player, queue review
  ActiveAbilityContextProvider, // needs player, turn flow
  AttackingContextProvider, // needs player, turn flow, queuedline
  DraggedCardContextProvider, // needs nothing
  ImageContextProvider, // needs nothing
];

const MasterContextProvider = ({ children }) => {
  return composeProviders(providers, children);
};

export default MasterContextProvider;
