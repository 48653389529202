import React, { useState, useEffect, useContext } from 'react';

import {
  TargetTypeEnum,
  TargetableTypeSelectionEnum,
} from '../../../../../../LegendsOfLeakos/lib/Enums/Target.js';

// components
// util comps
import TitledIntcreaser from '../Utility/TitledIntcreaser.jsx';
import ConditionList from '../Conditions/ConditionList.tsx';

function TargetCriteriaItem({ title, targetCriteria, saveTargetCriteria }) {
  return (
    <div
      className='target-criteria-item'
      style={{ border: '1px solid white', padding: '5px' }}
    >
      <div className='target-criteria-item-title'>{title}</div>
      <TitledIntcreaser
        title='Target Type'
        int={targetCriteria.targetTypeEnum}
        onIntChange={(value) => {
          targetCriteria.targetTypeEnum = value;
          saveTargetCriteria(targetCriteria);
        }}
        _enum={TargetTypeEnum}
      />
      <TitledIntcreaser
        title='Targetable Type Selection'
        int={targetCriteria.targetableTypeSelectionEnum}
        onIntChange={(value) => {
          targetCriteria.targetableTypeSelectionEnum = value;
          saveTargetCriteria(targetCriteria);
        }}
        _enum={TargetableTypeSelectionEnum}
      />
      <TitledIntcreaser
        title='Min Selections Required'
        int={targetCriteria.minSelectionsRequired}
        onIntChange={(value) => {
          targetCriteria.minSelectionsRequired = value;
          saveTargetCriteria(targetCriteria);
        }}
        minValue={0}
        maxValue={100}
      />
      <TitledIntcreaser
        title='Max Selections Allowed'
        int={targetCriteria.maxSelectionsAllowed}
        onIntChange={(value) => {
          targetCriteria.maxSelectionsAllowed = value;
          saveTargetCriteria(targetCriteria);
        }}
        minValue={0}
        maxValue={100}
      />
      <TitledIntcreaser
        title='Min Selections That Must Remain'
        int={targetCriteria.minSelectionsThatMustRemain}
        onIntChange={(value) => {
          targetCriteria.minSelectionsThatMustRemain = value;
          saveTargetCriteria(targetCriteria);
        }}
        minValue={0}
        maxValue={100}
      />
      <ConditionList
        conditions={targetCriteria.conditions}
        save={(conditions) => {
          targetCriteria.conditions = conditions;
          saveTargetCriteria(targetCriteria);
        }}
      />
    </div>
  );
}

export default TargetCriteriaItem;
