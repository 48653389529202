// external
import React, { useRef, useState, useEffect, useContext } from 'react';
import axios from 'axios';

// lol imports
import LibraryEnchantment from '../../../../LegendsOfLeakos/lib/Classes/Enchantment/LibraryEnchantment';
import LibraryCard from '../../../../LegendsOfLeakos/lib/Classes/Card/LibraryCard.js';
import GameManager from '../../../../LegendsOfLeakos/lib/Classes/Game/GameManager';

const CardLibraryContextProvider = ({ children }) => {
  const [cardLibrary, setCardLibrary] = useState([]);
  const [enchantmentLibrary, setEnchantmentLibrary] = useState([]);
  const [gameManager, setGameManager] = useState<GameManager>(null);
  useEffect(() => {
    if (!cardLibrary || !enchantmentLibrary) return;
    const newGM = new GameManager(cardLibrary, enchantmentLibrary);
    setGameManager(newGM);
  }, [cardLibrary, enchantmentLibrary]);

  // axios call to get card library
  useEffect(() => {
    axios
      .get('/cards')
      .then((res) => {
        const tempLib = res.data.map((card) => {
          const libraryCard = LibraryCard.fromJSON(card);
          return libraryCard;
        });
        setCardLibrary(tempLib);
      })
      .catch((err) => {
        console.log('err:', err);
      });
  }, []);

  // axios call to get enchantment library
  useEffect(() => {
    axios
      .get('/enchantments')
      .then((res) => {
        const tempLib = res.data.map((enchantment) => {
          const libraryEnchantment = LibraryEnchantment.fromJSON(enchantment);
          return libraryEnchantment;
        });
        setEnchantmentLibrary(tempLib);
      })
      .catch((err) => {
        console.log('err:', err);
      });
  }, []);

  const value = {
    gameManager,
    cardLibrary,
    setCardLibrary,
    enchantmentLibrary,
    setEnchantmentLibrary,
  };

  return (
    <CardLibraryContext.Provider value={value}>
      {children}
    </CardLibraryContext.Provider>
  );
};

const CardLibraryContext = React.createContext(null);

export { CardLibraryContextProvider, CardLibraryContext };
