import React, { useContext } from 'react';
import { PlayerContext } from '../../../contexts/PlayerContextProvider';
import { ActiveAbilityContext } from '../../../contexts/ActiveAbilityContextProvider';
import { TargetInfoPanelStyled } from '../styles/TargetInfoPanel.styled.js';

function TargetInfoPanel() {
  const { displayState } = useContext(PlayerContext);
  const {
    actingAbility,
    currentTargetInfo,
    currentTargetCriteriaIndex,
    currentTargetCriteria,
    currentTargetInfoViable,
    cancelAbility,
    finishedSelectingTargetsForCurrentTargetInfo,
  } = useContext(ActiveAbilityContext);

  if (!currentTargetInfo) return null;

  return (
    <TargetInfoPanelStyled active={!!currentTargetInfo}>
      <div className='column column-info'>
        <h3 className='target-name'>
          {
            actingAbility.effect.targetCriterias()[currentTargetCriteriaIndex]
              .name
          }
        </h3>
        <p>
          {'Min Selections Required: ' +
            currentTargetCriteria.minSelectionsRequired}
        </p>
        <p>
          {'Max Selections Allowed: ' +
            currentTargetCriteria.maxSelectionsAllowed}
        </p>
      </div>
      <div className='column column-selections'>
        <p>
          {'Current Selections: ' +
            currentTargetInfo.targetEntityInstanceIds.length}
        </p>
        {currentTargetInfo.targetEntityInstanceIds.map((instanceId, index) => {
          const entity = displayState.getEntityFromAnywhere(instanceId);
          if (!entity) {
            return <p key={'key' + instanceId + index}>Entity not found</p>;
          }
          return <p key={'key' + instanceId + index}>{entity.name}</p>;
        })}
      </div>
      <div className='column column-actions'>
        {currentTargetInfoViable && (
          <button onClick={finishedSelectingTargetsForCurrentTargetInfo}>
            Finished Adding Targets
          </button>
        )}
        <button onClick={cancelAbility}>Cancel</button>
      </div>
    </TargetInfoPanelStyled>
  );
}

export default TargetInfoPanel;
