// EXTERNAL
import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// INTERNAL
// css
import '../styles/styles.css';
// context
import MasterContextProvider from '../contexts/MasterContextProvider';

// components
// pages
import LobbyPage from './Lobby/LobbyPage';
import GamePage from './Game/GamePage';
import SignInPage from './Auth/SignIn/SignInPage';
import RealmPage from './Realms/RealmPage';
import EntityBuilderPage from './EntityBuilder/EntityBuilderPage';
import WaitlistPage from './Auth/Waitlist/WaitlistPage';
import AllowlistPage from './Auth/Allowlist/AllowlistPage';
import TidingsPage from './Tidings/TidingsPage';
import RegisterPage from './Auth/Register/RegisterPage';
import AdminPage from './Admin/AdminPage';

// utility components
import AnimatedCursor from '../../helpers/animated_cursor.js';
import AuthenticatedPage from './UtilityComponents/AuthenticatedPage.jsx';
import AuthorizedPage from './UtilityComponents/AuthorizedPage.jsx';

// font awesome import
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
library.add(faBars);

// App
// we're doing the Root thing to allow for useNavigate, which needs to be inside BrowserRouter
function App() {
  return (
    <BrowserRouter>
      <MasterContextProvider>
        <Root />
      </MasterContextProvider>
    </BrowserRouter>
  );
}
function Root() {
  return (
    <div>
      {/* <AnimatedCursor /> */}
      <Routes>
        <Route path='/' element={<TidingsPage />} />
        <Route
          path='/lobby'
          element={
            <AuthenticatedPage>
              <LobbyPage />
            </AuthenticatedPage>
          }
        />
        <Route
          path='/card-builder'
          element={
            <AuthorizedPage>
              <EntityBuilderPage />
            </AuthorizedPage>
          }
        />
        <Route
          path='/play'
          element={
            <AuthenticatedPage>
              <GamePage />
            </AuthenticatedPage>
          }
        />
        <Route
          path='/collections'
          element={
            <AuthenticatedPage>
              <RealmPage />
            </AuthenticatedPage>
          }
        />
        <Route path='/sign-in' element={<SignInPage />} />
        <Route path='/tidings' element={<TidingsPage />} />
        <Route path='/waitlist' element={<WaitlistPage />} />
        <Route
          path='/allowlist'
          element={
            <AuthorizedPage>
              <AllowlistPage />
            </AuthorizedPage>
          }
        />
        <Route path='/register' element={<RegisterPage />} />
        <Route path='/admin' element={<AdminPage />} />
      </Routes>
    </div>
  );
}

export default App;
