// external
import React, { useState, useEffect, useContext } from 'react';

// css
import { RealmButtonStyled } from './styles/RealmButton.styled';

// context
import { GameElementSizeContext } from '../../../contexts/GameElementSizeContextProvider';
import { ImageContext } from '../../../contexts/ImageContextProvider';

function RealmButton({ isVisible, onClick, isPlayer }) {
  // context
  const { realmButtonHeight } = useContext(GameElementSizeContext);
  const {
    playerRealmButtonGlobe,
    playerRealmButtonBanner,
    playerRealmButtonShow,
    playerRealmButtonHide,
    playerRealmButtonRedEarth,
    opponentRealmButtonGlobe,
    opponentRealmButtonBanner,
    opponentRealmButtonShow,
    opponentRealmButtonHide,
    opponentRealmButtonRedEarth,
  } = useContext(ImageContext);
  // hover state
  const [amHovered, setAmHovered] = useState(false);
  const [bannerScale, setBannerScale] = useState(0.1);

  useEffect(() => {
    setBannerScale(0.1);

    const timer = setTimeout(() => {
      setBannerScale(1);
    }, 300);

    return () => clearTimeout(timer);
  }, [isVisible]);

  const handleClick = () => {
    console.log('PlayerRealmButton handleClick');
    onClick();
  };

  return (
    <RealmButtonStyled
      className='realm-button'
      realmButtonHeight={realmButtonHeight}
      isPlayer={isPlayer}
      amHovered={amHovered}
      bannerScale={bannerScale}
    >
      <div className='globe-image-container'>
        <img
          className='globe'
          src={isPlayer ? playerRealmButtonGlobe : opponentRealmButtonGlobe}
        />

        {(isVisible || amHovered) && (
          <img
            className='red-earth'
            src={
              isPlayer ? playerRealmButtonRedEarth : opponentRealmButtonRedEarth
            }
          />
        )}
        <div className='banner-container'>
          <img
            className='banner'
            src={isPlayer ? playerRealmButtonBanner : opponentRealmButtonBanner}
          />
          {!isVisible && (
            <img
              className='show'
              src={isPlayer ? playerRealmButtonShow : opponentRealmButtonShow}
            />
          )}
          {isVisible && (
            <img
              className='hide'
              src={isPlayer ? playerRealmButtonHide : opponentRealmButtonHide}
            />
          )}
        </div>
      </div>
      <div
        className={'click-area'}
        onClick={handleClick}
        onMouseEnter={() => setAmHovered(true)}
        onMouseLeave={() => setAmHovered(false)}
      ></div>
    </RealmButtonStyled>
  );
}

export default RealmButton;
