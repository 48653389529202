// external
import React, { useState, useEffect, useContext } from 'react';

// context
import { PlayerContext } from './PlayerContextProvider';
import { AnimationContext } from './AnimationContextProvider';

// lol
import { PlayerFlowEnum } from '../../../../LegendsOfLeakos/lib/Enums/PlayerFlow';
import StackEvent from '../../../../LegendsOfLeakos/lib/Classes/StackEvent/StackEvent';

const QueueReviewContextProvider = ({ children }) => {
  // #region Context
  const {
    masterPlayer,
    finishedQueueActions: masterPlayerFinishedActions,
    setDisplayState,
    onStartPhase: masterPlayerOnStartPhase,
  } = useContext(PlayerContext);

  const { hasAnimationForEvent, runAnimationForEvent } =
    useContext(AnimationContext);

  // #endregion

  // #region STATE

  // current stack index and event
  const [currentEventIndex, setCurrentEventIndex] = useState<number>(null);
  const [currentStackEvent, setCurrentStackEvent] = useState<StackEvent>(null);

  // running queue
  const [isRunningQueue, setIsRunningQueue] = useState<boolean>(true);

  // #endregion

  // #region SET currentStackEvent from currentEventIndex
  useEffect(() => {
    if (!masterPlayer) return;
    if (currentEventIndex === null) {
      return;
    }
    if (masterPlayer.playerFlowEnum !== PlayerFlowEnum.ReviewingQueue) {
      setCurrentStackEvent(null);
      return;
    }

    const event = masterPlayer.stack[currentEventIndex];
    if (event) {
      setCurrentStackEvent(event);
    } else {
      console.log(
        'ERROR: no event found: currentEventIndex:',
        currentEventIndex,
        'masterPlayer.stack:',
        masterPlayer.stack
      );
    }
  }, [currentEventIndex]);
  // #endregion

  // UseEffect to START THE QUEUE
  useEffect(() => {
    if (!masterPlayer) return;
    // if we're reviewing the queue, don't erase the current event index
    if (masterPlayer.playerFlowEnum === PlayerFlowEnum.ReviewingQueue) {
      startQueue();
    } else {
      setCurrentEventIndex(null);
    }
  }, [masterPlayer && masterPlayer.playerFlowEnum]);

  // #region QUEUE CONTROLS

  const finishedActions = () => {
    masterPlayerFinishedActions();
  };

  const startQueue = () => {
    if (masterPlayer.stack.length - 1 === currentEventIndex) {
      setCurrentEventIndex(0);
    }
    setIsRunningQueue(true);
  };

  const stopQueue = () => {
    setIsRunningQueue(false);
  };

  const endQueueReview = () => {
    setCurrentStackEvent(null);
    masterPlayerOnStartPhase();
  };

  const loadStackPosition = (eventIndex: number) => {
    stopQueue();
    setCurrentEventIndex(eventIndex);
    setDisplayState(masterPlayer.stack[eventIndex].gameState);
  };

  // #endregion

  // #region MAIN STACK UPDATE / ANIMATION LOOP
  useEffect(() => {
    let intervalId;

    const runQueueAsync = async () => {
      if (
        !masterPlayer ||
        masterPlayer.playerFlowEnum !== PlayerFlowEnum.ReviewingQueue ||
        !isRunningQueue
      ) {
        return;
      }

      let startIndex = currentEventIndex !== null ? currentEventIndex : 0;

      for (let i = startIndex; i < masterPlayer.stack.length; i++) {
        if (!isRunningQueue) {
          break;
        }

        // this is for the side bar and board cards - we don't actually need it for the animation
        setCurrentEventIndex(i);

        const event = masterPlayer.stack[i].clone();

        if (hasAnimationForEvent(event)) {
          await runAnimationForEvent(event, setDisplayState);
        } else {
          setDisplayState(event.gameState);
          await new Promise(
            (resolve) => (intervalId = setTimeout(resolve, 1000))
          );
        }
      }

      setIsRunningQueue(false);
    };

    runQueueAsync();

    // Cleanup function to clear the interval when the component unmounts or the dependencies change
    return () => clearTimeout(intervalId);
  }, [isRunningQueue, currentEventIndex, masterPlayer]); // Add other dependencies as needed

  // #endregion

  const value: QueueReviewContextType = {
    currentEventIndex,
    currentStackEvent,
    isRunningQueue,
    finishedActions,
    startQueue,
    stopQueue,
    endQueueReview,
    loadStackPosition,
  };

  return (
    <QueueReviewContext.Provider value={value}>
      {children}
    </QueueReviewContext.Provider>
  );
};

interface QueueReviewContextType {
  currentEventIndex: number;
  currentStackEvent: StackEvent;
  isRunningQueue: boolean;
  finishedActions: () => void;
  startQueue: () => void;
  stopQueue: () => void;
  endQueueReview: () => void;
  loadStackPosition: (eventIndex: number) => void;
}

const QueueReviewContext = React.createContext<QueueReviewContextType | null>(
  null
);

export { QueueReviewContext, QueueReviewContextProvider };
