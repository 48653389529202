import styled from 'styled-components';

export const ManaBarStyled = styled.div`
  width: 100%;
  z-index: 500;
  pointer-events: none;

  .mana-container {
    width: 100%;
  }

  .mana-circle {
    margin-bottom: 53%;
  }

  .forest-mana,
  .ocean-mana,
  .desert-mana,
  .mountain-mana,
  .prairie-mana,
  .fells-mana,
  .tundra-mana {
  }
`;
