import React, { useState } from 'react';

// components
import RealmButton from './NavbarButtons/RealmButton';
import BattleButton from './NavbarButtons/BattleButton';
import TitleButton from './NavbarButtons/TitleButton';
import MenuIcon from './NavbarButtons/MenuIcon';
import DesktopModal from './DesktopModal';
// utility
import AuthenticatedComponent from '../UtilityComponents/AuthenticatedComponent';

// css
import { DesktopNavbarStyled } from './styles/DesktopNavbar.styled';

function DesktopNavbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <DesktopNavbarStyled>
      {/* only show if signed in */}
      <AuthenticatedComponent>
        <RealmButton />
        <BattleButton />
      </AuthenticatedComponent>
      {/* always show */}
      <TitleButton />
      <MenuIcon isOpen={menuOpen} onClick={toggleMenu} />
      <DesktopModal isOpen={menuOpen} />
    </DesktopNavbarStyled>
  );
}

export default DesktopNavbar;
