import { ServerMessage } from '../MessageBase.js';
import PlayerInfo from '../../Player/PlayerInfo.js';
import GameManager from '../../Game/GameManager.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
export default class ServerSendingGamestateForRejoin extends ServerMessage {
    constructor(recipientUserId, turn, phaseIndex, rngSeed, player, opponent, gameManger, allBlocks) {
        super(recipientUserId);
        this.messageEnum = NetworkProtocol.ServerSendingGamestateForRejoin;
        this.turn = turn;
        this.phaseIndex = phaseIndex;
        this.rngSeed = rngSeed;
        this.player = player;
        this.opponent = opponent;
        this.gameManager = gameManger;
        // Filter blocks to include only those where the blocking card belongs to the player
        this.blocks = allBlocks.filter((block) => player.getCardFromInstanceId(block.blockingCardInstanceId) != null);
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { turn: this.turn, phaseIndex: this.phaseIndex, rngSeed: this.rngSeed, player: this.player.toJSONForPlayer(), opponent: this.opponent.toJSONForOpponent(), gameManger: this.gameManager.toJSON(), blocks: this.blocks });
    }
    static fromJSON(json) {
        return new ServerSendingGamestateForRejoin(json.recipientUserId, json.turn, json.phaseIndex, json.rngSeed, PlayerInfo.fromRuntimeJSON(json.player), PlayerInfo.fromRuntimeJSON(json.opponent), GameManager.fromJSON(json.gameManger), json.blocks);
    }
    // check that all fields in the message are valid
    validate() {
        return (this.recipientUserId != null &&
            this.turn != null &&
            this.phaseIndex != null &&
            this.rngSeed != null &&
            this.player != null &&
            this.opponent != null &&
            this.gameManager != null &&
            this.blocks != null);
    }
}
