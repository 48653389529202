import { ClientMessage } from '../MessageBase.js';
import PayResourceCost from '../../PayResourceCost/PayResourceCost.js';
import TargetInfo from '../../Target/TargetInfo.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
export default class QueueActivateAbilityMessage extends ClientMessage {
    constructor(messageId, senderUserId, sourceEntityInstanceId, abilityIndex, paidCosts, targetInfoList) {
        super(messageId, senderUserId);
        this.messageEnum = NetworkProtocol.QueueActivateAbility;
        this.sourceEntityInstanceId = sourceEntityInstanceId;
        this.abilityIndex = abilityIndex;
        this.paidCosts = paidCosts;
        this.targetInfoList = targetInfoList;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { sourceEntityInstanceId: this.sourceEntityInstanceId, abilityIndex: this.abilityIndex, paidCosts: this.paidCosts.map((cost) => cost.toJSON()), targetInfoList: this.targetInfoList.map((info) => info.toJSON()) });
    }
    static fromJSON(json) {
        const paidCosts = json.paidCosts.map(PayResourceCost.fromJSON);
        const targetInfoList = json.targetInfoList.map(TargetInfo.fromJSON);
        return new QueueActivateAbilityMessage(json.messageId, json.senderUserId, json.sourceEntityInstanceId, json.abilityIndex, paidCosts, targetInfoList);
    }
    // check that all fields in the message are valid
    validate() {
        return (super.validate() &&
            this.sourceEntityInstanceId != null &&
            this.abilityIndex != null &&
            this.paidCosts != null &&
            this.targetInfoList != null);
    }
}
ClientMessage.registerFromJSON(NetworkProtocol.QueueActivateAbility, QueueActivateAbilityMessage.fromJSON);
