import { EffectType } from '../../Enums/Effect.js';
import TargetCriteriaUpgrade from '../Target/TargetCriteriaUpgrade.js';
import { TargetableTypeSelectionEnum } from '../../Enums/Target.js';
class EffectUpgrade {
    constructor(effectType, targetTypeUpgrades) {
        this.effectEnum = effectType;
        this.targetTypeUpgrades = targetTypeUpgrades.map((upgrade) => new TargetCriteriaUpgrade(upgrade.targetTypeIndex, upgrade.newTargetTypeEnum, upgrade.newTargetableTypeSelectionEnum, upgrade.minSelectionsRequiredChange, upgrade.maxSelectionsAllowedChange, upgrade.minSelectionsThatMustRemainChange, upgrade.newConditions, upgrade.removeConditionsOfType));
    }
    upgradeEffect(effect) {
        if (effect.effectType !== this.effectEnum)
            return;
        for (const ttu of this.targetTypeUpgrades) {
            const tt = effect.targetCriterias()[ttu.targetTypeIndex];
            tt.targetTypeEnum = ttu.newTargetTypeEnum;
            tt.targetableTypeSelectionEnum = ttu.newTargetableTypeSelectionEnum;
            tt.playerSelectsTarget =
                ttu.newTargetableTypeSelectionEnum !==
                    TargetableTypeSelectionEnum.AutoTarget;
            tt.minSelectionsRequired +=
                ttu.minSelectionsRequiredChange.effectiveValue;
            tt.maxSelectionsAllowed += ttu.maxSelectionsAllowedChange.effectiveValue;
            tt.minSelectionsThatMustRemain +=
                ttu.minSelectionsThatMustRemainChange.effectiveValue;
        }
    }
    toJSON() {
        return {
            effectEnum: EffectType[this.effectEnum],
            targetTypeUpgrades: this.targetTypeUpgrades.map((ttu) => ttu.toJSON()),
        };
    }
    static fromJSON(json) {
        return new EffectUpgrade(EffectType[json.effectEnum], json.targetTypeUpgrades.map((ttu) => TargetCriteriaUpgrade.fromJSON(ttu)));
    }
}
export default EffectUpgrade;
