import styled from 'styled-components';
import '../../../styles/constants.css';

export const NavbarStyled = styled.div`
  // navbar holder essentially
  position: sticky;
  top: 0;
  z-index: 9999;
  padding: var(--screen-margin);

  .navbar-top {
    position: relative;
    height: calc(var(--navbar-height) - 2 * var(--screen-margin));
    width: 100%;

    background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    border: 1px solid var(--lol-gold);
    box-sizing: border-box;
  }

  .inner-border {
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border: 1px solid var(--lol-gold);
    pointer-events: none;
    z-index: 2000;
  }
`;
