import React, { useState } from 'react';

// context
import { SocketContext } from './SocketContextProvider';

const RoomsContextProvider = ({ children }) => {
  // context
  const {
    socket,
    requestNewRoom: requestNewRoomSocket,
    joinRoom: joinRoomSocket,
    leaveRoom: leaveRoomSocket,
    onRoomsUpdate,
    onYouJoinedRoom,
    onYouLeftRoom,
    onRejoinedGame,
  } = React.useContext(SocketContext);

  // state
  const [rooms, setRooms] = useState([]);
  const [myRoomID, setMyRoomID] = useState(null);

  // socket emits

  const requestNewRoom = () => {
    requestNewRoomSocket();
  };

  const joinRoom = (roomId) => {
    joinRoomSocket(roomId);
  };

  const leaveRoom = () => {
    leaveRoomSocket();
  };

  // socket events
  React.useEffect(() => {
    onRoomsUpdate((data) => {
      console.log('settign rooms: ', data);
      setRooms(data);
    });

    onYouJoinedRoom((roomId) => {
      setMyRoomID(roomId);
    });

    onYouLeftRoom(() => {
      setMyRoomID(null);
    });

    onRejoinedGame((roomId) => {
      setMyRoomID(roomId);
    });

    return () => {
      onRejoinedGame(() => {});
      onRoomsUpdate(() => {});
      onYouJoinedRoom(() => {});
      onYouLeftRoom(() => {});
    };
  }, [socket, onRoomsUpdate, onYouJoinedRoom, onYouLeftRoom]);

  const value: RoomsContextType = {
    rooms,
    setRooms,
    myRoomID,
    setMyRoomID,
    requestNewRoom,
    joinRoom,
    leaveRoom,
  };
  return (
    <RoomsContext.Provider value={value}>{children}</RoomsContext.Provider>
  );
};

interface RoomsContextType {
  rooms: any[];
  setRooms: React.Dispatch<React.SetStateAction<any[]>>;
  myRoomID: string | null;
  setMyRoomID: React.Dispatch<React.SetStateAction<string | null>>;
  requestNewRoom: () => void;
  joinRoom: (roomId: string) => void;
  leaveRoom: () => void;
}

const RoomsContext = React.createContext<RoomsContextType | null>(null);

export { RoomsContextProvider, RoomsContext };
