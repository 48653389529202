import styled from 'styled-components';
import '../../../../styles/constants.css';

export const RealmButtonStyled = styled.div`
  z-index: var(--buttons-z-index);
  position: relative;
  pointer-events: none;

  width: 130px;
  height: 130px;

  box-sizing: border-box;

  .click-area {
    position: absolute;
    top: 45%;
    left: 24%;
    height: 43%;
    width: 43%;
    cursor: pointer;
    border-radius: 50%;
    z-index: 7;
    pointer-events: auto;
  }

  .globe-image-container {
    position: relative;
    height: 100%;
    width: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
    pointer-events: none;
  }

  .realms,
  .hide,
  .show,
  .red-earth,
  .globe,
  .banner {
    z-index: 5;
  }

  .banner-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    transform: ${({ amHovered, bannerScale }) =>
      `scale(${amHovered && bannerScale === 1 ? 1.1 : bannerScale})`};
    transition: transform 0.15s ease;
  }
`;
