// external
import React, { useEffect, useState, useRef } from 'react';

// components
import LibraryCardDisplay from '../LibraryCardDisplay';

//css
import { CardStyled } from '../styles/Card.styled.js';

function DisplayCard({ libraryCard, addCard }) {
  const handleAddCard = () => {
    addCard(libraryCard);
  };

  const cardVisualsRef = useRef<HTMLDivElement>(null);
  const [availableWidth, setAvailableWidth] = useState<number>(0);

  useEffect(() => {
    const updateWidth = () => {
      if (cardVisualsRef.current) {
        setAvailableWidth(cardVisualsRef.current.offsetWidth);
      }
    };

    updateWidth();
    // Optional: Listen to window resize if you need the width to be responsive
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  return (
    <CardStyled onClick={handleAddCard}>
      <div className='library-card-holder' ref={cardVisualsRef}>
        <LibraryCardDisplay
          libraryCard={libraryCard}
          onClick={() => {}}
          canHover={false}
          availableWidth={availableWidth}
        />
      </div>
    </CardStyled>
  );
}

export default DisplayCard;
