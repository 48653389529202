import styled from 'styled-components';
import '../../../../styles/constants.css';

export const RegisterComponentStyled = styled.div`
  h1 {
    text-align: center;
    font-family: var(--logo-font);
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: var(--lol-red);
  }

  .register-wrapper {
    margin: 0 auto;
    padding: 20px;
    display: block;
    width: 300px;
    background-color: var(--dark-green);
    border: 1px solid var(--lol-gold);
  }

  .register-wrapper input {
    width: 100%;
    display: block;
    margin: 0;
    margin-bottom: 10px;
    box-sizing: border-box;
    height: 40px; // Increased height
    text-align: center; // Center text
    font-family: var(--logo-font); // Apply logo font
    font-size: 16px; // Set font size to 16px to prevent zooming on mobile
  }

  .register-wrapper input[type='submit'] {
    cursor: pointer;
    background-color: var(--lol-gold);
    border: none;
    border-radius: 0; // Remove rounding
    color: white;
    padding: 10px 20px;

    :hover {
      background-color: var(--lol-red);
    }
  }

  .input-error {
    font-size: 14px;
    color: red;
  }
`;
