import styled from 'styled-components';
import '../../../styles/constants.css';

export const BiomeListStyled = styled.div`
  height: ${({ displayState }) =>
    displayState !== 'edit-biome'
      ? 'calc(100vh - var(--navbar-height) - 3 * var(--screen-margin))'
      : '300px'};
  width: 100%;

  padding: var(--screen-margin);
  box-sizing: border-box;
  overflow: hidden;

  z-index: 1;

  transition: top 0.5s ease;

  .underlined-title {
    color: var(--lol-gold);
    margin-bottom: 0;
  }

  .biometile-holder {
    position: relative;
    height: calc(100vh - var(--navbar-height) - 70px);
    overflow-y: scroll;
    overflow-x: visible;
    width: 100%;
  }

  .biometile-holder::-webkit-scrollbar {
    display: none;
  }
`;
