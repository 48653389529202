// external
import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// internal
// components
import Navbar from '../Navbar/Navbar';
import Fog from './Fog';
// context
import { ImageContext } from '../../contexts/ImageContextProvider';
import { ScreenSizeContext } from '../../contexts/ScreenSizeContextProvider';

// css
import { TidingsPageStyled } from './styles/TidingsPage.styled.js';

function TidingsPage() {
  const navigate = useNavigate();

  // context
  const { masterGameContainerWidth } = useContext(ScreenSizeContext);
  const { justWater, endGamePopupBackgroundVictory, fog } =
    useContext(ImageContext);

  return (
    <TidingsPageStyled
      className='tidings-page'
      background={justWater}
      mainBackground={endGamePopupBackgroundVictory}
      displayWidth={masterGameContainerWidth}
      height={window.innerHeight}
    >
      <Navbar />
      <div className='main-content'>
        <Fog />
        <div className='main-message'>
          <h1>The Wars Begin</h1>
          <div className='button-container'>
            <button className='button' onClick={() => navigate('/waitlist')}>
              Waitlist
            </button>
            <a className='button' href='https://discord.gg/GPSZwSsV4r'>
              Discord
            </a>
          </div>
        </div>
      </div>
    </TidingsPageStyled>
  );
}

export default TidingsPage;
