import styled from 'styled-components';
import '../../../styles/constants.css';

export const TargetInfoPanelStyled = styled.div`
  display: ${(props) => (props.active ? 'flex' : 'none')};
  position: absolute;
  height: 12%;
  width: 90%;
  bottom: 2%;
  left: 5%;
  padding: 1%;
  box-sizing: border-box;
  background-color: var(--dark-green);
  border: 2px solid var(--border-color-bright);
  z-index: 500;

  .column {
    flex: 1;
    padding: 0 10px;
    display: flex;
    flex-direction: column; // Ensure column layout for the content
  }

  .column-actions {
    flex-direction: column;
    justify-content: space-around; // Distribute space evenly around the buttons

    button {
      width: 100%; // Make button take up the full width
      height: 48%; // Make button take up nearly half the height, allowing some space for margins
      margin: 1% 0; // Optional: add a small margin for spacing between buttons
    }
  }
`;
