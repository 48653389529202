// external
import React, { useEffect } from 'react';

// components
import WaitlistComponent from './WaitlistComponent';
import Navbar from '../../Navbar/Navbar';
import Fog from '../../Tidings/Fog';

// context
import { ImageContext } from '../../../contexts/ImageContextProvider';

// css
import { WaitlistPageStyled } from './styles/WaitlistPage.styled.js';

function WaitlistPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // context
  const { justWater, endGamePopupBackgroundVictory } =
    React.useContext(ImageContext);

  return (
    <WaitlistPageStyled
      className='waitlist-page'
      background={justWater}
      mainBackground={endGamePopupBackgroundVictory}
      height={window.innerHeight}
    >
      <Navbar />
      <Fog />
      <div className='main-content'>
        <WaitlistComponent />
      </div>
    </WaitlistPageStyled>
  );
}

export default WaitlistPage;
