// external
import React, { useContext, useEffect, useState, useRef } from 'react';

// internal
import { PlayerContext } from './PlayerContextProvider';
import { QueueReviewContext } from './QueueReviewContextProvider';

// lol
import QueueFightCreatureMessage from '../../../../LegendsOfLeakos/lib/Classes/Networking/Attacking/QueueFightCreatureMessage';
import QueueActivateAbilityMessage from '../../../../LegendsOfLeakos/lib/Classes/Networking/Abilities/QueueActivateAbilityMessage';
import { PlayerFlowEnum } from '../../../../LegendsOfLeakos/lib/Enums/PlayerFlow';
import {
  StackEntityEnum,
  StackEventEnum,
} from '../../../../LegendsOfLeakos/lib/Enums/Stack';

const ActionLineContextProvider = ({ children }) => {
  // external context
  const { masterPlayer, displayState } = useContext(PlayerContext);
  const { currentStackEvent } = useContext(QueueReviewContext);

  const [masterAttackLineData, setMasterAttackLineData] = useState<
    {
      attackingCardInstanceId: number;
      defendingCardInstanceId: number;
    }[]
  >([]);
  const [masterBlockLineData, setMasterBlockLineData] = useState<
    {
      blockingCardInstanceId: number;
      blockedCardInstanceId: number;
      blockOrder: number;
    }[]
  >([]);
  const [masterAbilityLineData, setMasterAbilityLineData] = useState([]);

  const [displayAttackLineData, setDisplayAttackLineData] = useState<
    {
      attackingCardInstanceId: number;
      defendingCardInstanceId: number;
    }[]
  >([]);
  const [displayBlockLineData, setDisplayBlockLineData] = useState<
    {
      blockingCardInstanceId: number;
      blockedCardInstanceId: number;
      blockOrder: number;
    }[]
  >([]);
  const [displayAbilityLineData, setDisplayAbilityLineData] = useState([]);

  // #region MASTER DATA UseEffect
  useEffect(() => {
    if (!masterPlayer) return;
    if (!displayState) return;

    // Update attack lines
    const newAttackLineData = masterPlayer
      .getPlayerInfo(displayState)
      .queueMessages.filter(
        (message) => message instanceof QueueFightCreatureMessage
      )
      .map((message: QueueFightCreatureMessage) => ({
        attackingCardInstanceId: message.attackingCardInstanceId,
        defendingCardInstanceId: message.attackedCardInstanceId,
      }));
    setMasterAttackLineData(newAttackLineData);

    // Update block lines
    const newBlockLineData = displayState.blocks.map((block) => ({
      blockingCardInstanceId: block.blockingCardInstanceId,
      blockedCardInstanceId: block.blockedCardInstanceId,
      blockOrder: block.blockOrder,
    }));
    setMasterBlockLineData(newBlockLineData);

    // update ability lines
    // this is hard

    // const playerInfo = masterPlayer.getPlayerInfo(displayState);
    // const newAbilityLineData = playerInfo.queueMessages
    //   .filter((message) => message instanceof QueueActivateAbilityMessage)
    //   .map((message: QueueActivateAbilityMessage) => ({
    //     abilityEntityInstanceId: message.sourceEntityInstanceId,
    //     defendingEntityInstanceIds: message.targetInfoList.flatMap(
    //       (targetInfo) => targetInfo.targetEntityInstanceIds
    //     ),
    //   }));
    // setAbilityLineData(newAbilityLineData);
  }, [masterPlayer, displayState]);

  // #endregion

  // #region DISPLAY DATA

  // #region Attack Line Data

  // Player Acting
  useEffect(() => {
    if (!masterPlayer) return;

    if (masterPlayer.playerFlowEnum === PlayerFlowEnum.PlayerActing) {
      setDisplayAttackLineData(masterAttackLineData);
      return;
    }
  }, [masterAttackLineData]);

  // Queue Review
  useEffect(() => {
    if (!masterPlayer) return;
    if (masterPlayer.playerFlowEnum === PlayerFlowEnum.PlayerActing) return;
    if (!currentStackEvent) {
      setDisplayAttackLineData([]);
      return;
    }

    if (currentStackEvent.eventEnum === StackEventEnum.QueueStart) {
      setDisplayAttackLineData(masterAttackLineData);
      return;
    } else if (currentStackEvent.eventEnum === StackEventEnum.Queueline) {
      const data = masterAttackLineData.filter((data) =>
        currentStackEvent.entities
          .filter((entity) => entity.entityEnum === StackEntityEnum.Source)
          .map((entity) => entity.instanceId)
          .includes(data.attackingCardInstanceId)
      );
      setDisplayAttackLineData(data);
      return;
    }

    setDisplayAttackLineData([]);
  }, [masterAttackLineData, currentStackEvent]);

  // #endregion

  // #region Block Line Data

  // Player Acting
  useEffect(() => {
    if (!masterPlayer) return;

    if (masterPlayer.playerFlowEnum === PlayerFlowEnum.PlayerActing) {
      setDisplayBlockLineData(masterBlockLineData);
      return;
    }
  }, [masterBlockLineData]);

  // Queue Review
  useEffect(() => {
    if (!masterPlayer) return;
    if (masterPlayer.playerFlowEnum === PlayerFlowEnum.PlayerActing) return;
    if (!currentStackEvent) {
      setDisplayBlockLineData([]);
      return;
    }

    if (currentStackEvent.eventEnum === StackEventEnum.QueueStart) {
      setDisplayBlockLineData(masterBlockLineData);
      return;
    } else {
      const data = masterBlockLineData.filter((data) =>
        currentStackEvent.entities
          .filter((entity) => entity.entityEnum === StackEntityEnum.Target)
          .map((entity) => entity.instanceId)
          .includes(data.blockedCardInstanceId)
      );
      setDisplayBlockLineData(data);
      return;
    }

    // setDisplayBlockLineData([]);
  }, [masterBlockLineData, currentStackEvent]);

  // #endregion

  // #endregion

  // OUT

  const value: ActionLineContextType = {
    attackLineData: displayAttackLineData,
    blockLineData: displayBlockLineData,
  };

  return (
    <ActionLineContext.Provider value={value}>
      {children}
    </ActionLineContext.Provider>
  );
};

interface ActionLineContextType {
  attackLineData: {
    attackingCardInstanceId: number;
    defendingCardInstanceId: number;
  }[];
  blockLineData: {
    blockingCardInstanceId: number;
    blockedCardInstanceId: number;
    blockOrder: number;
  }[];
}

const ActionLineContext = React.createContext<ActionLineContextType | null>(
  null
);

export { ActionLineContextProvider, ActionLineContext };
