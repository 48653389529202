import { ClientMessage } from '../MessageBase.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
export default class StopCardBlockingMessage extends ClientMessage {
    constructor(messageId, senderUserId, blockingCardInstanceId) {
        super(messageId, senderUserId);
        this.messageEnum = NetworkProtocol.StopCardBlocking;
        this.blockingCardInstanceId = blockingCardInstanceId;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { blockingCardInstanceId: this.blockingCardInstanceId });
    }
    static fromJSON(json) {
        return new StopCardBlockingMessage(json.messageId, json.senderUserId, json.blockingCardInstanceId);
    }
    // check that all fields in the message are valid
    validate() {
        return super.validate() && this.blockingCardInstanceId != null;
    }
}
ClientMessage.registerFromJSON(NetworkProtocol.StopCardBlocking, StopCardBlockingMessage.fromJSON);
