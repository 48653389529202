import ModifiableInt from '../../ModifableInt/ModifiableInt.js';
class KeywordUpgrade {
    constructor(keywordType, keywordUpgradeIndex, isPermanent, durationChange, isActive) {
        this.newConditions = [];
        this.removeConditionsOfType = [];
        this.keywordType = keywordType;
        this.keywordUpgradeIndex = keywordUpgradeIndex;
        this.isPermanent = isPermanent;
        this.durationChange = durationChange;
        this.isActive = isActive;
    }
    upgradeKeyword(keyword) {
        keyword.isPermanent = this.isPermanent;
        keyword.duration += this.durationChange.effectiveValue;
        this.newConditions.forEach((c) => {
            keyword.conditions.push(c);
        });
        this.removeConditionsOfType.forEach((ct) => {
            this.newConditions = this.newConditions.filter((c) => c.conditionType !== ct);
        });
        keyword.isActive = this.isActive;
    }
    toJSON() {
        return {
            keywordType: this.keywordType,
            keywordUpgradeIndex: this.keywordUpgradeIndex,
            isPermanent: this.isPermanent,
            durationChange: this.durationChange.toJSON(),
            isActive: this.isActive,
        };
    }
    static fromJSON(json) {
        const keywordType = json.keywordType;
        const keywordUpgradeIndex = json.keywordUpgradeIndex;
        const isPermanent = json.isPermanent;
        const durationChange = ModifiableInt.fromJSON(json.durationChange);
        const isActive = json.isActive;
        return new KeywordUpgrade(keywordType, keywordUpgradeIndex, isPermanent, durationChange, isActive);
    }
}
export default KeywordUpgrade;
