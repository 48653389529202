// a component that displays a realm representing a players land
// external
import React, { useState, useEffect, useContext } from 'react';

// internal components
import ManaCircle from '../../Game/Hand/ManaCircle';

// context
import { ImageContext } from '../../../contexts/ImageContextProvider';
import { CardLibraryContext } from '../../../contexts/CardLibraryContextProvider';

// css
import {
  AbilityBoxStyled,
  AbilityInfoPanelStyled,
} from './styles/AbilityBox.styled.js';

// lol
import LibraryAbility from '../../../../../../LegendsOfLeakos/lib/Classes/Ability/LibraryAbility';

// lol

interface AbilityBoxProps {
  ability: LibraryAbility;
}

const AbilityBox: React.FC<AbilityBoxProps> = ({ ability }) => {
  const { cardLibrary } = useContext(CardLibraryContext);
  const { defaultCardImage } = useContext(ImageContext);

  const [myAbility, setMyAbility] = useState(null);
  useEffect(() => {
    setMyAbility(ability);
  }, [ability]);

  // #region image
  const [image, setImage] = useState(defaultCardImage);
  useEffect(() => {
    if (!cardLibrary) return;
    const url = ability.image;
    if (!url) return;
    setImage(url);
  }, [myAbility]);
  // #endregion

  const [isHovered, setIsHovered] = useState(false);

  return (
    <AbilityBoxStyled
      className='ability-box'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      backgroundImage={image}
      isHovered={isHovered}
    >
      <div className='ability-box-content'>
        <div className='mana-cost-holder'>
          {ability.costs.map((cost, index) => {
            return (
              <ManaCircle
                key={index}
                cost={cost.value}
                costName={cost.costName()}
              />
            );
          })}
        </div>
        <p>{ability.name}</p>
      </div>
      {isHovered && (
        <AbilityInfoPanelStyled>
          <p>{ability.effect.effectType}</p>
        </AbilityInfoPanelStyled>
      )}
    </AbilityBoxStyled>
  );
};

export default AbilityBox;
