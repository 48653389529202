import styled from 'styled-components';
import '../../../styles/constants.css';

export const BiomeTileStyled = styled.div`
  --padding: 10px;

  display: block;
  position: relative;

  box-sizing: border-box;
  height: 50px;
  width: 100%;
  padding: var(--padding);
  margin: 10px 0;

  transition: 0.5s;
  overflow: hidden;
  z-index: 2;
  border: 1px solid
    ${({ isSelected }) =>
      isSelected ? 'var(--border-color-bright)' : 'var(--border-color-dim)'};
  :hover {
    border-color: var(--border-color-bright);
  }

  .background-holder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: 0.3s ease;
    z-index: 0;
  }
  .background:hover {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
  }

  .title {
    position: relative;
    text-align: left;
    font-size: 1rem;
    font-family: var(--bold-medieval-font);
    font-weight: 900;
    z-index: 1;
    pointer-events: none;
    color: white;
    text-shadow: -4px -4px 4px var(--light-green),
      4px -4px 4px var(--light-green), -4px 4px 4px var(--light-green),
      4px 4px 4px var(--light-green);
  }
`;
