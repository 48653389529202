import ActivatedAbilityUpgrade from '../Ability/RuntimeAbilityUpgrade.js';
import PayResourceCost from '../PayResourceCost/PayResourceCost.js';
import StatUpgrade from '../Stat/StatUpgrade.js';
import KeywordUpgrade from '../Keyword/KeywordUpgrade/KeywordUpgrade.js';
import GameProperties from '../Game/GameProperties.js';
class CardUpgrade {
    constructor() {
        this.costs = [];
        this.upgradeNeighbors = [];
        this.keywordUpgrades = [];
        this.activatedAbilityUpgrades = [];
    }
    potentialNeighbors(gameManager) {
        const width = GameProperties.upgradeTreeShape.width;
        let tempList = [];
        tempList.push(this.upgradeIndex - width);
        tempList.push(this.upgradeIndex - 1);
        tempList.push(this.upgradeIndex + 1);
        tempList.push(this.upgradeIndex + width);
        return tempList;
    }
    upgradeCard(card) {
        const attack = card.attack;
        if (attack)
            this.attackStatUpgrade.upgradeStat(attack);
        const life = card.health;
        if (life)
            this.lifeStatUpgrade.upgradeStat(life);
        const priority = card.priority;
        if (priority)
            this.priorityStatUpgrade.upgradeStat(priority);
        for (const k of this.keywordUpgrades) {
            const keyword = card.keywords.find((c) => c.keywordType === k.keywordType);
            if (keyword)
                k.upgradeKeyword(keyword);
        }
        for (const a of this.activatedAbilityUpgrades) {
            if (card.abilities.length >= a.abilityUpgradeIndex)
                continue;
            a.upgradeAbility(card.abilities[a.abilityUpgradeIndex]);
        }
        card.upgradesApplied.push(this.upgradeIndex);
    }
    toJSON() {
        return {
            name: this.name,
            upgradeIndex: this.upgradeIndex,
            isStartingUpgrade: this.isStartingUpgrade,
            description: this.description,
            attackStatUpgrade: this.attackStatUpgrade.toJSON(),
            lifeStatUpgrade: this.lifeStatUpgrade.toJSON(),
            priorityStatUpgrade: this.priorityStatUpgrade.toJSON(),
            costs: this.costs.map((cost) => cost.toJSON()),
            keywordUpgrades: this.keywordUpgrades.map((ku) => ku.toJSON()),
            activatedAbilityUpgrades: this.activatedAbilityUpgrades.map((aau) => aau.toJSON()),
        };
    }
    static fromJSON(json) {
        const newCardUpgrade = new CardUpgrade();
        newCardUpgrade.name = json.name;
        newCardUpgrade.upgradeIndex = json.upgradeIndex;
        newCardUpgrade.isStartingUpgrade = json.isStartingUpgrade;
        newCardUpgrade.description = json.description;
        newCardUpgrade.attackStatUpgrade = StatUpgrade.fromJSON(json.attackStatUpgrade);
        newCardUpgrade.lifeStatUpgrade = StatUpgrade.fromJSON(json.lifeStatUpgrade);
        newCardUpgrade.priorityStatUpgrade = StatUpgrade.fromJSON(json.priorityStatUpgrade);
        for (const c of json.costs) {
            newCardUpgrade.costs.push(PayResourceCost.fromJSON(c));
        }
        for (const c of json.keywordUpgrades) {
            newCardUpgrade.keywordUpgrades.push(KeywordUpgrade.fromJSON(c));
        }
        for (const c of json.activatedAbilityUpgrades) {
            newCardUpgrade.activatedAbilityUpgrades.push(ActivatedAbilityUpgrade.fromJSON(c));
        }
        return newCardUpgrade;
    }
}
export default CardUpgrade;
