import { ServerMessage } from '../../MessageBase.js';
import { NetworkProtocol } from '../../../../Enums/NetworkProtocol.js';
export default class QueueStartedMessage extends ServerMessage {
    constructor(recipientUserId, totalQueuelines, 
    // queuelinesThisMessage: number,
    // breakBeforeEndOfQueue: boolean,
    // breakingPlayerUserId: string,
    attackingCardInstanceIds, blocks) {
        super(recipientUserId);
        this.messageEnum = NetworkProtocol.QueueStarted;
        this.totalQueuelines = totalQueuelines;
        // this.queuelinesThisMessage = queuelinesThisMessage;
        // this.breakBeforeEndOfQueue = breakBeforeEndOfQueue;
        // this.breakingPlayerUserId = breakingPlayerUserId;
        this.attackingCardInstanceIds = attackingCardInstanceIds;
        this.blocks = blocks;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { totalQueuelines: this.totalQueuelines, 
            // queuelinesThisMessage: this.queuelinesThisMessage,
            // breakBeforeEndOfQueue: this.breakBeforeEndOfQueue,
            // breakingPlayerUserId: this.breakingPlayerUserId,
            attackingCardInstanceIds: this.attackingCardInstanceIds, blocks: this.blocks.map((b) => (Object.assign({}, b))) });
    }
    static fromJSON(json) {
        return new QueueStartedMessage(json.recipientUserId, json.totalQueuelines, 
        // json.queuelinesThisMessage,
        // json.breakBeforeEndOfQueue,
        // json.breakingPlayerUserId,
        json.attackingCardInstanceIds, json.blocks.map((b) => (Object.assign({}, b))));
    }
    // check that all fields in the message are valid
    validate() {
        return (this.recipientUserId != null &&
            this.totalQueuelines != null &&
            // this.queuelinesThisMessage != null &&
            // this.breakBeforeEndOfQueue != null &&
            // this.breakingPlayerUserId != null && // this can be NULL
            this.attackingCardInstanceIds != null &&
            this.blocks != null);
    }
}
