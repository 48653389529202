import RuntimeEffect from '../../RuntimeEffect.js';
import { EffectType } from '../../../../Enums/Effect.js';
import TargetCriteria from '../../../Target/TargetCriteria.js';
import RuntimeCard from '../../../Card/RuntimeCard.js';
import { TargetTypeEnum, TargetableTypeSelectionEnum, } from '../../../../Enums/Target.js';
import ModifiableInt from '../../../ModifableInt/ModifiableInt.js';
import LibraryEffect from '../../LibraryEffect.js';
import StackEvent from '../../../StackEvent/StackEvent.js';
import { StackEntityEnum, StackEventEnum } from '../../../../Enums/Stack.js';
import StackEntity from '../../../StackEvent/StackEntity.js';
class DealSetDamageEffect extends RuntimeEffect {
    targetCriterias() {
        return [this.targetsToBeDealtDamage];
    }
    // constructor
    constructor(damageAmount, targetsToBeDealtDamage) {
        super();
        this.effectType = EffectType.DealSetDamage;
        this.damageAmount = damageAmount;
        this.targetsToBeDealtDamage = targetsToBeDealtDamage;
    }
    // #region Effect Execution
    preEffect(state, sourceEntity, targetInfoList) {
        let targetsToBeDealtDamage = targetInfoList[0];
        this.damageAmountTracker = [];
        this.divineShieldHitTracker = [];
        for (let targetInstanceId of targetsToBeDealtDamage.targetEntityInstanceIds) {
            this.damageAmountTracker.push(this.damageAmount.clone());
            this.divineShieldHitTracker.push(false);
        }
        if (!this.isAllTargetInfoValid(sourceEntity, state, targetInfoList))
            return false;
        return true;
    }
    resolve(state, sourceEntity, targetInfoList) {
        let targetsToBeDealtDamage = targetInfoList[0];
        let outText = '';
        console.log('DealSetDamageEffect resolve: ', targetsToBeDealtDamage);
        console.log('DealSetDamageEffect length: ', targetsToBeDealtDamage.targetEntityInstanceIds.length);
        for (let i = 0; i < targetsToBeDealtDamage.targetEntityInstanceIds.length; i++) {
            console.log('DealSetDamageEffect index: ', i);
            const targetInstanceId = targetsToBeDealtDamage.targetEntityInstanceIds[i];
            const targetEntity = state.getEntityFromAnywhere(targetInstanceId);
            if (targetEntity === null) {
                throw new Error('DealSetDamageEffect requires a valid targetEntity');
            }
            if (targetEntity instanceof RuntimeCard === false) {
                throw new Error('DealSetDamageEffect requires a RuntimeCard as the targetEntity');
            }
            const targetCard = targetEntity;
            const damageAmount = this.damageAmountTracker[i].effectiveValue;
            if (!this.divineShieldHitTracker[i]) {
                targetCard.health.baseValue -= damageAmount;
                outText += `${i + 1}. ${sourceEntity.name} dealt ${damageAmount} damage to ${targetCard.name}. `;
            }
            else {
                outText += `${i + 1}. ${sourceEntity.name} dealt 0 damage to ${targetCard.name} because of Absolute Shield. `;
            }
        }
        return [
            new StackEvent(StackEventEnum.EffectResolve, state.clone(), true, outText, [
                new StackEntity(sourceEntity.instanceId, StackEntityEnum.Source),
                ...targetsToBeDealtDamage.targetEntityInstanceIds.map((id) => new StackEntity(id, StackEntityEnum.Target)),
            ]),
        ];
    }
    // #endregion
    // #region Targeting
    areTargetsAvailable(state, sourceEntity) {
        return this.targetsToBeDealtDamage.areTargetsAvailable(sourceEntity.instanceId, state);
    }
    isAllTargetInfoValid(sourceEntity, state, targetInfoList) {
        if (targetInfoList.length !== 1)
            return false;
        let targetsToBeDealtDamage = targetInfoList[0];
        if (!this.targetsToBeDealtDamage.isTargetInfoValid(sourceEntity.instanceId, targetsToBeDealtDamage, state))
            return false;
        return true;
    }
    // #endregion
    // #region Effect To Text
    effectToString() {
        let outText = 'Deal ' +
            this.damageAmount.effectiveValue +
            ' damage to between' +
            this.targetsToBeDealtDamage.minSelectionsRequired +
            ' and ' +
            this.targetsToBeDealtDamage.maxSelectionsAllowed +
            ' targets.';
        return outText;
    }
    // #endregion
    // #region JSON
    toJSON() {
        return {
            effectType: EffectType[this.effectType],
            damageAmount: this.damageAmount.toJSON(),
            targetsToBeDealtDamage: this.targetsToBeDealtDamage.toJSON(),
        };
    }
    clone() {
        return new DealSetDamageEffect(this.damageAmount.clone(), this.targetsToBeDealtDamage.clone());
    }
    static fromRuntimeJSON(json) {
        return new DealSetDamageEffect(ModifiableInt.fromJSON(json.damageAmount), TargetCriteria.fromRuntimeJSON(json.targetsToBeDealtDamage));
    }
    static fromLibraryJSON(json) {
        return new DealSetDamageEffect(new ModifiableInt(json.data.damageAmount.value, []), TargetCriteria.fromLibraryJSON(json.data.targetsToBeDealtDamage.value));
    }
    static createSampleLibraryEffect() {
        const tc = new TargetCriteria('Deal Damage', TargetTypeEnum.TargetCreature, 1, 2, 1, TargetableTypeSelectionEnum.TargetableOnActivation, []);
        return new LibraryEffect(EffectType.DealSetDamage, {
            damageAmount: {
                type: 'Number',
                value: 1,
            },
            targetsToBeDealtDamage: {
                type: 'TargetCriteria',
                value: tc.toJSON(),
            },
        });
    }
    static isLibraryJSONValid(json) {
        if (json.effectType !== EffectType[EffectType.DealSetDamage])
            return false;
        if (json.data.damageAmount.type !== 'Number')
            return false;
        if (!TargetCriteria.isLibraryJSONValid(json.data.targetsToBeDealtDamage.value))
            return false;
        return true;
    }
    // #endregion
    static initialize() {
        RuntimeEffect.registerFromRuntimeJSON(EffectType.DealSetDamage, DealSetDamageEffect.fromRuntimeJSON);
        RuntimeEffect.registerFromLibraryJSON(EffectType.DealSetDamage, DealSetDamageEffect.fromLibraryJSON);
        RuntimeEffect.registerSampleLibraryEffect(EffectType.DealSetDamage, DealSetDamageEffect.createSampleLibraryEffect);
        RuntimeEffect.registerIsLibraryJSONValid(EffectType.DealSetDamage, DealSetDamageEffect.isLibraryJSONValid);
    }
}
export default DealSetDamageEffect;
