import StatModifier from './StatModifier.js';
import StatBuff from './StatBuff.js';
export class Stat {
    constructor(statId, name, originalValue, baseValue, minValue, maxValue, modifiers, buffs) {
        this.modifiers = [];
        this.buffs = [];
        this.statId = statId;
        this.name = name;
        this.originalValue = originalValue;
        this.baseValue = baseValue;
        this.minValue = minValue;
        this.maxValue = maxValue;
        this.modifiers = modifiers;
        this.buffs = buffs;
    }
    get effectiveValue() {
        let value = this.baseValue;
        for (const modifier of this.modifiers) {
            value += modifier.value;
        }
        for (const buff of this.buffs) {
            value += buff.value;
        }
        if (value < this.minValue) {
            value = this.minValue;
        }
        else if (value > this.maxValue) {
            value = this.maxValue;
        }
        return value;
    }
    addModifier(modifier) {
        const oldValue = this.effectiveValue;
        this.modifiers.push(modifier);
        if (this.onValueChanged) {
            this.onValueChanged(oldValue, this.effectiveValue);
        }
    }
    addBuff(value, details) {
        this.buffs.push(new StatBuff(value, details));
        // we don't call it here because it's better to call it in DoEffect
    }
    onEndBattlePhase() {
        const oldValue = this.effectiveValue;
        const modifiersToRemove = [];
        const temporaryModifiers = this.modifiers.filter((x) => !x.isPermanent());
        for (const modifier of temporaryModifiers) {
            modifier.duration -= 1;
            if (modifier.duration <= 0) {
                modifiersToRemove.push(modifier);
            }
        }
        for (const modifier of modifiersToRemove) {
            const index = this.modifiers.indexOf(modifier);
            if (index !== -1) {
                this.modifiers.splice(index, 1);
            }
        }
        if (modifiersToRemove.length > 0 && this.onValueChanged) {
            this.onValueChanged(oldValue, this.effectiveValue);
        }
    }
    clone() {
        return new Stat(this.statId, this.name, this.originalValue, this.baseValue, this.minValue, this.maxValue, this.modifiers.map((modifier) => modifier.clone()), this.buffs.map((buff) => buff.clone()));
    }
    toJSON() {
        return {
            statId: this.statId,
            name: this.name,
            originalValue: this.originalValue,
            baseValue: this.baseValue,
            minValue: this.minValue,
            maxValue: this.maxValue,
            modifiers: this.modifiers.map((modifier) => modifier.toJSON()),
            // buffs: this.buffs.map((buff) => buff.toJSON()), // we shouldn't need buffs - these are caluculated live at runtime
        };
    }
    static fromLibraryInt(json) {
        console.log('stat json: ', json);
        return new Stat(json.statId, json.name, json.originalValue, json.baseValue, json.minValue, json.maxValue, json.modifiers.map((modifier) => StatModifier.fromJSON(modifier)), 
        // json.buffs.map((buff: any) => StatBuff.fromJSON(buff)) // again, shouldn't need this
        new Array());
    }
    static fromRuntimeJSON(json) {
        if (json.statId === undefined ||
            json.name === undefined ||
            json.originalValue === undefined ||
            json.baseValue === undefined ||
            json.minValue === undefined ||
            json.maxValue === undefined ||
            json.modifiers === undefined) {
            console.log('\nstat json: ', json);
            throw new Error('Invalid Stat JSON');
        }
        return new Stat(json.statId, json.name, json.originalValue, json.baseValue, json.minValue, json.maxValue, json.modifiers.map((modifier) => StatModifier.fromJSON(modifier)), 
        // json.buffs.map((buff: any) => StatBuff.fromJSON(buff)) // again, shouldn't need this
        new Array());
    }
}
export default Stat;
