import React, { useContext } from 'react';

// internal components
import EnchantmentItem from './CardEnchantmentItem';

// contexts
import { GameElementSizeContext } from '../../../../contexts/GameElementSizeContextProvider';

// css
import { CardEnchantmentListStyled } from './styles/CardEnchantmentList.styled';

const CardEnchantmentList = ({
  isPlayer,
  enchantments,
  playerInfo,
  amActing = false,
  amOnZone = false,
  zoneHovered = null,
}) => {
  const [amHovering, setAmHovering] = React.useState(false);
  const { boardCardWidth } = useContext(GameElementSizeContext);

  return (
    <CardEnchantmentListStyled
      className={`enchantment-list`}
      onMouseEnter={() => setAmHovering(true)}
      onMouseLeave={() => setAmHovering(false)}
      amHovering={amHovering}
      boardCardWidth={boardCardWidth}
      zoneHovered={zoneHovered}
    >
      {amHovering && enchantments.length > 0 && (
        <div className='enchantment-item-holder'>
          {enchantments.map((enchantment, index) => (
            <EnchantmentItem
              key={index}
              isPlayer={isPlayer}
              playerInfo={playerInfo}
              enchantment={enchantment}
              amActing={amActing}
            />
          ))}
        </div>
      )}
      <div className='length-display'>
        {amOnZone && <div className='center-line'></div>}
        <div className='length-background'></div>
        <div className='length-text'>{enchantments.length}</div>
      </div>
    </CardEnchantmentListStyled>
  );
};

export default CardEnchantmentList;
