// a component that displays a realm representing a players land
// external
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
} from 'react';

// internal components
import AbilityBox from './Abilities/AbilityBox';
import CardEnchantmentList from './Enchantments/CardEnchantmentList';

// context
import { PlayerContext } from '../../../contexts/PlayerContextProvider';
import { GameElementSizeContext } from '../../../contexts/GameElementSizeContextProvider';
import { CardRefContext } from '../../../contexts/CardRefContextProvider';
import { AttackingContext } from '../../../contexts/AttackingContextProvider';
import { ActiveAbilityContext } from '../../../contexts/ActiveAbilityContextProvider';
import { ImageContext } from '../../../contexts/ImageContextProvider';
import { QueueReviewContext } from '../../../contexts/QueueReviewContextProvider';

// css
import { BoardCardStyled } from './styles/BoardCard.styled.js';

// lol
import QueueFightCreatureMessage from '../../../../../../LegendsOfLeakos/lib/Classes/Networking/Attacking/QueueFightCreatureMessage.js';
import PlayerInfo from '../../../../../../LegendsOfLeakos/lib/Classes/Player/PlayerInfo.js';
import RuntimeAbility from '../../../../../../LegendsOfLeakos/lib/Classes/Ability/RuntimeAbility.js';
import RuntimeCard from '../../../../../../LegendsOfLeakos/lib/Classes/Card/RuntimeCard.js';
import { NetworkProtocol } from '../../../../../../LegendsOfLeakos/lib/Enums/NetworkProtocol';
import { PhaseEnum } from '../../../../../../LegendsOfLeakos/lib/Enums/Phase';
import { BiomeType } from '../../../../../../LegendsOfLeakos/lib/Enums/LandAndBiome';
import { PlayerFlowEnum } from '../../../../../../LegendsOfLeakos/lib/Enums/PlayerFlow';

interface BoardCardProps {
  index: number;
  card: RuntimeCard;
  isPlayer: boolean;
  playerInfo: PlayerInfo;
  isQueued?: boolean;
}

const BoardCard: React.FC<BoardCardProps> = ({
  index,
  card,
  isPlayer,
  playerInfo,
  isQueued = false,
}) => {
  // #region context
  const { masterPlayer, displayState, cancelPlayCard } =
    useContext(PlayerContext);
  const { currentStackEvent } = useContext(QueueReviewContext);
  const { boardCardWidth, boardHeight } = useContext(GameElementSizeContext);
  const { addCardRef } = useContext(CardRefContext);
  const {
    actingAbility,
    actingAbilityEntity,
    startAbility,
    addTarget,
    currentTargetCriteria,
  } = useContext(ActiveAbilityContext);
  const {
    amActingInCombat,
    actingCard,
    actingCardPosition,
    startCombatTargeting,
    endCombatTargeting,
  } = useContext(AttackingContext);
  const {
    defaultCardImage,
    activeBorder,
    inactiveBorder,
    forestBottomLand,
    mountainBottomLand,
    prairieBottomLand,
    tundraBottomLand,
    fellsBottomLand,
    oceanBottomLand,
    desertBottomLand,
  } = useContext(ImageContext);

  // #endregion

  // #region image
  const [image, setImage] = useState(defaultCardImage);
  useEffect(() => {
    if (!masterPlayer || !displayState) return;
    const url = card.getLibraryCard(displayState).imageName;
    if (!url) return;
    setImage(url);
  }, [card]);
  // #endregion

  // #region targetable
  const [isTargetable, setIsTargetable] = useState(false);
  useEffect(() => {
    if (!!actingAbilityEntity && !!currentTargetCriteria) {
      setIsTargetable(
        currentTargetCriteria.isEntityAValidTarget(
          actingAbilityEntity.instanceId,
          card.instanceId,
          displayState
        )
      );
    }
  }, [actingAbilityEntity, currentTargetCriteria]);
  // #endregion

  // #region stack event glow
  const [isStackEvent, setIsStackEvent] = useState(false);
  useEffect(() => {
    if (!currentStackEvent) {
      setIsStackEvent(false);
      return;
    }

    setIsStackEvent(
      !!currentStackEvent.entities.find(
        (entity) => entity.instanceId === card.instanceId
      )
    );
  }, [currentStackEvent]);
  // #endregion

  // #region hovering
  const [finalHover, setFinalHover] = useState(false);
  const [overallHover, setOverallHover] = useState(false);
  const [enchantmentHover, setHoverEnchantments] = useState(false);

  useEffect(() => {
    if (enchantmentHover) {
      setFinalHover(false);
      return;
    } else if (overallHover) {
      setFinalHover(true);
      return;
    }
    setFinalHover(false);
  }, [enchantmentHover, overallHover]);

  const enchantmentsRef = useRef(null);

  const [enchantmentsHeight, setEnchantmentsHeight] = useState(0);

  useLayoutEffect(() => {
    if (enchantmentsRef.current) {
      setEnchantmentsHeight(enchantmentsRef.current.offsetHeight);
    }
  }, [enchantmentHover]);

  // #endregion

  // #region action glow

  // this is used if there is an available action for the card
  const [canTakeAction, setCanTakeAction] = useState(false);

  useEffect(() => {
    if (!masterPlayer || !displayState) return;

    if (masterPlayer.playerFlowEnum !== PlayerFlowEnum.PlayerActing) {
      setCanTakeAction(false);
      return;
    }

    // if the card is not attacking or blocking, set true
    if (
      displayState.currentPhaseIndex === PhaseEnum.Battle &&
      !displayState.blocks.find(
        (block) => block.blockingCardInstanceId === card.instanceId
      ) &&
      !masterPlayer
        .getPlayerInfo(displayState)
        .queueMessages.find(
          (msg) =>
            msg.messageEnum === NetworkProtocol.QueueFightCreature &&
            (msg as QueueFightCreatureMessage).attackingCardInstanceId ===
              card.instanceId
        )
    ) {
      setCanTakeAction(true);
      return;
    }
    // if any of the abilities have uses remaining, and it's the correct phase for them
    if (
      !!card.abilities.find(
        (ability) =>
          ability.usesRemaining > 0 &&
          ability.useableInPhases.includes(displayState.currentPhaseIndex)
      )
    ) {
      setCanTakeAction(true);
      return;
    }
    setCanTakeAction(false);
  }, [masterPlayer, card]);
  // #endregion

  // #region mouse events

  const handleAbilityClick = (e, index) => {
    if (amActingInCombat) {
      console.log('already acting');
      return;
    }
    if (!!actingAbility) {
      console.log('already acting ability');
      return;
    }
    if (!isPlayer) {
      console.log('not your card');
      return;
    }
    if (!!startAbility) startAbility(e, card, index, cardRef);
  };

  const handleMouseDown = (e) => {
    if (isQueued) {
      if (amActingInCombat || actingAbility) {
        return;
      }
      if (masterPlayer.playerFlowEnum !== PlayerFlowEnum.PlayerActing) {
        console.log('already ready for queue');
        return;
      }
      console.log('canceling queued card');
      cancelPlayCard(card.instanceId);
      return;
    }
    // starting a new combat
    if (!amActingInCombat && isPlayer && !actingAbility) {
      // if player.QueueMessages contains a message of type FightCreaturePlayerQueueline
      // and that message has sourceCardInstanceId equal to card.instanceId, return
      if (
        masterPlayer &&
        masterPlayer.getPlayerInfo(displayState).queueMessages &&
        masterPlayer
          .getPlayerInfo(displayState)
          .queueMessages.find(
            (msg) =>
              msg.messageEnum === NetworkProtocol.QueueFightCreature &&
              (msg as QueueFightCreatureMessage).attackingCardInstanceId ===
                card.instanceId
          )
      ) {
        console.log('already attacking with this card');
        return;
      }

      if (
        masterPlayer &&
        displayState &&
        displayState.blocks.find(
          (block) => block.blockingCardInstanceId === card.instanceId
        )
      ) {
        console.log('already blocking with this card');
        return;
      }

      startCombatTargeting(e, card);
      return;
    } else if (!!actingAbility && !!currentTargetCriteria && !!addTarget) {
      console.log('add target');
      addTarget(e, card);
    }
  };

  const handleMouseUp = (e) => {
    if (amActingInCombat && !!actingCard && !!endCombatTargeting) {
      endCombatTargeting(e, card);
      return;
    }
  };

  const handleMouseEnter = () => {
    if (isQueued) return;
    if (!!actingAbility) {
      if (!!currentTargetCriteria) {
        if (isTargetable) {
          setOverallHover(true);
          return;
        }
      }
    } else {
      setOverallHover(true);
      return;
    }
  };

  // #endregion

  // #region update refs for drawing lines
  const cardRef = useRef(null);
  useLayoutEffect(() => {
    addCardRef(card.instanceId, cardRef);
  }, [cardRef]);

  // #endregion

  return (
    <BoardCardStyled
      className={`board-card`}
      boardCardHeight={boardHeight}
      boardCardWidth={boardCardWidth}
      hover={finalHover || enchantmentHover}
      enchantmentHover={enchantmentHover && card.enchantments.length > 0}
      isQueued={isQueued}
      isPlayer={isPlayer}
      actingAbility={!!actingAbility}
      isTargetable={isTargetable}
      enchantmentsHeight={enchantmentsHeight}
      onMouseUp={handleMouseUp}
      onMouseEnter={() => {
        handleMouseEnter();
      }}
      onMouseLeave={() => {
        setOverallHover(false);
      }}
      ref={cardRef}
    >
      <div className={'card'} onMouseDown={handleMouseDown}>
        <div className='expandable'>
          {/* border */}
          {!isQueued &&
          ((canTakeAction && isPlayer) ||
            (!!actingAbility && isTargetable) ||
            isStackEvent) ? (
            <div className='border active'>
              <img src={activeBorder} alt='active border' />
            </div>
          ) : (
            <div className='border inactive'>
              <img src={inactiveBorder} alt='inactive border' />
            </div>
          )}

          {/* name */}
          <p className='name'>{card.name + ' ' + card.instanceId}</p>

          {/* image */}
          <div className='main-image'>
            <img src={image} alt={card.name} />
          </div>

          {/* stats */}
          <div className='stat-holder'>
            <div
              className='attack-box stat-box'
              style={{
                position: 'absolute',
                left: '9%',
                bottom: '27%',
              }}
            >
              <div className='background'></div>
              <p>{card.attack.effectiveValue}</p>
            </div>
            <div
              className='health-box stat-box'
              style={{
                position: 'absolute',
                left: '29%',
                bottom: '9%',
              }}
            >
              <div className='background'></div>
              <p>{card.health.effectiveValue}</p>
              {/* <p>22</p> */}
            </div>
            <div
              className='priority-box stat-box'
              style={{
                position: 'absolute',
                right: '8%',
                bottom: '27%',
              }}
            >
              <div className='background'></div>
              <p>{card.priority.effectiveValue}</p>
            </div>
          </div>
        </div>

        <div className='bottom-land'>
          <div className='forest'>
            <img src={forestBottomLand} alt='' />
          </div>

          {/* {card.getLibraryCard(displayState).biomeType ===
            BiomeType.forest && (
            <div className='forest'>
              <img src={forestBottomLand} alt='' />
            </div>
          )}
          {card.getLibraryCard(displayState).biomeType ===
            BiomeType.mountain && (
            <div className='mountain'>
              <img src={mountainBottomLand} alt='' />
            </div>
          )}
          {card.getLibraryCard(displayState).biomeType ===
            BiomeType.prairie && (
            <div className='prairie'>
              <img src={prairieBottomLand} alt='' />
            </div>
          )}
          {card.getLibraryCard(displayState).biomeType ===
            BiomeType.tundra && (
            <div className='tundra'>
              <img src={tundraBottomLand} alt='' />
            </div>
          )}
          {card.getLibraryCard(displayState).biomeType ===
            BiomeType.fells && (
            <div className='fells'>
              <img src={fellsBottomLand} alt='' />
            </div>
          )}
          {card.getLibraryCard(displayState).biomeType ===
            BiomeType.ocean && (
            <div className='ocean'>
              <img src={oceanBottomLand} alt='' />
            </div>
          )}
          {card.getLibraryCard(displayState).biomeType ===
            BiomeType.desert && (
            <div className='desert'>
              <img src={desertBottomLand} alt='' />
            </div>
          )} */}
        </div>
      </div>

      {/* abilities, keywords, and enchantments */}

      {/* keywords */}
      <div
        className={
          'keywords' +
          (finalHover && !actingAbility && !amActingInCombat
            ? ''
            : ' invisible')
        }
      >
        {card.keywords.map((keyword, index) => (
          <span key={index}>{keyword.getDisplayText()}</span>
        ))}
      </div>

      {/* abilities */}
      <div
        className={
          'abilities' +
          (finalHover && !actingAbility && !amActingInCombat
            ? ''
            : ' invisible')
        }
      >
        {card.abilities.map((ability, index) => (
          <AbilityBox
            key={index}
            ability={ability}
            onClick={(e) => {
              handleAbilityClick(e, index);
            }}
          />
        ))}
      </div>

      {/* enchantments */}
      {card.enchantments.length > 0 && (
        <div
          className='enchantments'
          ref={enchantmentsRef}
          onMouseEnter={() => {
            setHoverEnchantments(true);
          }}
          onMouseLeave={() => {
            setHoverEnchantments(false);
          }}
        >
          <CardEnchantmentList
            enchantments={card.enchantments}
            isPlayer={isPlayer}
            playerInfo={playerInfo}
          />
        </div>
      )}
    </BoardCardStyled>
  );
};

export default BoardCard;
