// external
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';

// internal
// components
import DisplayCard from './DisplayCard';

//context
import { ImageContext } from '../../../../contexts/ImageContextProvider';
import { CardLibraryContext } from '../../../../contexts/CardLibraryContextProvider';

//css
import { CardDisplayStyled } from './styles/CardDisplay.styled.js';

// lol
import {
  BiomeAddCardEnum,
  BiomeDepth,
  BiomeType,
} from '../../../../../../../LegendsOfLeakos/lib/Enums/LandAndBiome.js';
import LibraryBiome from '../../../../../../../LegendsOfLeakos/lib/Classes/RealmsAndLand/Biome/LibraryBiome.js';
import LibraryCard from '../../../../../../../LegendsOfLeakos/lib/Classes/Card/LibraryCard.js';

function CardDisplay({ biome, addCard }) {
  // get card library from context
  const { cardLibrary, gameManager } = useContext(CardLibraryContext);
  const { someLand } = useContext(ImageContext);

  // filter settings
  const [filterSetting, setFilterSetting] = useState('all'); // ['all', 'landType', 'depth', 'addable']
  const [filteredCardLibrary, setFilteredCardLibrary] = useState([]);

  // filter card library
  useEffect(() => {
    if (!biome) return;
    if (!gameManager) return;

    if (filterSetting === 'all') {
      setFilteredCardLibrary(cardLibrary);
    } else if (filterSetting === 'landType') {
      setFilteredCardLibrary(
        cardLibrary.filter((card) => card.biomeType === biome.biomeType)
      );
    } else if (filterSetting === 'depth') {
      setFilteredCardLibrary(
        cardLibrary.filter(
          (card) =>
            (card.biomeDepth === biome.biomeDepth ||
              BiomeDepth[card.biomeDepth] === 'all') &&
            card.biomeType === biome.biomeType
        )
      );
    } else if (filterSetting === 'addable') {
      // filter
      setFilteredCardLibrary(
        cardLibrary.filter((card: LibraryCard) => {
          const message = (biome as LibraryBiome).cardsCanBeAddedToThisBiome(
            card,
            1,
            gameManager
          );
          console.log('message', message);
          return message.result === BiomeAddCardEnum.Success;
        })
      );
    }
  }, [filterSetting, cardLibrary, biome, gameManager]);

  return (
    <CardDisplayStyled className='card-display' background={someLand}>
      <div className='top-bar'>
        <div
          className={`filter-label ${filterSetting === 'all' ? 'active' : ''}`}
          onClick={() => setFilterSetting('all')}
        >
          All
        </div>
        <div
          className={`filter-label ${
            filterSetting === 'landType' ? 'active' : ''
          }`}
          onClick={() => setFilterSetting('landType')}
        >
          {BiomeType[biome?.biomeType]}
        </div>
        <div
          className={`filter-label ${
            filterSetting === 'depth' ? 'active' : ''
          }`}
          onClick={() => setFilterSetting('depth')}
        >
          {BiomeDepth[biome?.biomeDepth]}
        </div>
        <div
          className={`filter-label ${
            filterSetting === 'addable' ? 'active' : ''
          }`}
          onClick={() => setFilterSetting('addable')}
        >
          addable
        </div>
      </div>
      <div className='card-container'>
        {filteredCardLibrary.map((card, index) => {
          return (
            <DisplayCard
              key={index + (!!card.name ? card.name : '') + ''}
              libraryCard={card}
              addCard={addCard}
            />
          );
        })}
      </div>
    </CardDisplayStyled>
  );
}

export default CardDisplay;
